 

import lapiz from '../../assets/icons/Vector.svg';
import {TextField } from "@mui/material";
import style from './MainTopBar.module.scss'; 


import React, { useState, Fragment } from 'react';
import FormCreator from '../utils/FormCreator';
import feedbackData from '../../constants/feedbackData';
import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';
import { FirebaseContext } from '../../firebase';
import { Dialog, DialogTitle, Fab } from '@mui/material';
import { Comment } from '@mui/icons-material';
import { createNotification } from '../../reducers/notificationReducer';
import TeamGlobalSelector from '../teams/TeamGlobalSelector'

// Icons
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ProfileDrawer from '../utils/ProfileDrawer';
import BodySelector from '../bodys/BodySelector';

const MainTopBar = ({headComponent ,teamSelector, bodySelector }, ...rest) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    
    const { firebase } = useContext(FirebaseContext);
    const [open, setOpen] = useState(false);
    const [stateDrawer, setStateDrawer] = useState(false);

    
    const values = {};
    feedbackData.forEach(element => {
        values[element.varName] = element.defaultValue;
    });
    
    const handleSubmit = (data) => {
        firebase.createFeedback(data).then(() => {
            dispatch(createNotification({ message: "Comentario enviado", type: "success" }))
        }).catch((err) => {
            dispatch(createNotification({ message: "Error al enviar comentario", type: "error" }))
        })
    }
    
    
    return ( 
        
        <div className={style.mainbox}>
            {user && <ProfileDrawer {...rest} state={stateDrawer} setState={setStateDrawer} />}

            {headComponent && 
                <div className={style.headComponent}>
                    {headComponent}
                </div>
            }
            {/**Selectores */}

            {teamSelector && <TeamGlobalSelector/>}
            {bodySelector && <BodySelector/>}

            {/* Bloque de iconos */}
            <div className={style.botonerabox}>

                {/* Icono de chat */}
                <div className={style.iconbox} onClick={() => { setOpen(true) }}> 
                    <ChatBubbleOutlineOutlinedIcon className={style.icon}/>
                </div>

                {/* Icono de avisos */}
                <div className={` ${style.iconbox} deshabilitadoBR8`}> 
                    <NotificationsNoneOutlinedIcon className={style.icon}/>
                </div>
                
                {/* Icono de opciones */}
                <div className={style.iconbox} onClick={() => { setStateDrawer(true) }}> 
                    <SettingsOutlinedIcon className={style.icon}/>
                </div>


            </div>
            
            <Dialog open={open} onClose={() => { setOpen(false) }}>
                <DialogTitle>Enviar comentario</DialogTitle>
                <FormCreator schema={feedbackData} initialValues={values} action={handleSubmit} />
            </Dialog>
        </div>
        
    ) 
}

export default MainTopBar;
 