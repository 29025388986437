import { ArrowDropDown, ArrowDropUp, ContentCopy, Delete, DragIndicator, Edit } from '@mui/icons-material';
import { Grid, Paper, Typography, IconButton, Dialog } from '@mui/material';
import methodData from '../../../constants/method';
import React, { useContext, useState } from 'react'
import ValueTranslator from '../../utils/ValueTranslator';
import CreateMethod from './CreateMethod';
import { structuralRectangles, functionalRectangles } from '../../../constants/trainingZoneData';
import { useSelector } from 'react-redux';
import { FirebaseContext } from '../../../firebase';
import OptionLists from '../../../constants/OptionLists';
import ConfirmationDialog from '../../utils/ConfirmationDialog';

const MethodComponent = ({ methodId, item, draggable }) => {
    const { firebase } = useContext(FirebaseContext);
    const [detailsActive, setDetailsActive] = useState(false);
    const [editActive, setEditActive] = useState(false);
    const [copy, setCopy] = useState(false);

    const { sessions } = useSelector((state) => state.trainer)

    const d = item.main_adaptation === "funcional" ?
        functionalRectangles.find((elem) => elem.name === item.training_zone) :
        structuralRectangles.find((elem) => elem.name === item.training_zone);

    const handleClick = () => {
        setDetailsActive(!detailsActive);
    }

    const handleEdit = () => {
        setEditActive(!editActive);
    }

    const handleCopy = () => {
        setEditActive(!editActive);
        setCopy(true);
    }

    const getSessionsAfected = (id) => {
        var sessions_affected = Object.keys(sessions).map((session_key) => {
            const exercise_methods = sessions[session_key].method_ids;
            for (var i in exercise_methods) {
                if (exercise_methods[i].methodId === id) {
                    return session_key;
                }
            }
            return undefined;
        }).filter((e) => e !== undefined);
        return sessions_affected;
    }

    const deleteMethod = () => {
        var sessions_affected = getSessionsAfected(methodId);

        for (var i in sessions_affected) {
            let exercise_methods = [...sessions[sessions_affected[i]].method_ids];
            for (var em in exercise_methods) {
                if (exercise_methods[em].methodId === methodId) {
                    var removed = exercise_methods.splice(em, 1);
                    console.log("Eliminados del array : ", removed, " - comparados con ", methodId);
                }
            }
            firebase.modifySession(sessions_affected[i], { ...sessions[sessions_affected[i]], method_ids: exercise_methods }).then(() => {
                console.log("Modificada session correctamente");
            });
        }
        firebase.deleteMethod(methodId);
    }

    const handleDelete = () => {
        setOpenConfirmation(true);
    }

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const affectedSessions = getSessionsAfected(methodId);

    return (
        <Paper elevation={3} sx={{ m: 1, p: 1, borderRight: 8, borderColor: d ? d.color : "black" }}>
            {methodId ?
                <Dialog open={editActive} onClose={() => { setEditActive(false); setCopy(false) }} fullWidth scroll={'paper'}>
                    <CreateMethod methodId={methodId} copy={copy} onSubmit={() => { setEditActive(false); setCopy(false) }} />
                </Dialog> : <></>
            }
            {detailsActive ?
                <Grid container sx={{ py: 0.5 }}>
                    <Grid item xs={10}>
                        <Typography>{OptionLists.training_zone[item.training_zone]}_{item.reps ? item.reps + "r" : item.time + "s"}X{item.series}_{item.load}% </Typography>
                        <Typography variant='overline'>{item.name} </Typography>
                    </Grid>
                    <Grid item xs={2} >
                        <IconButton onClick={handleClick}>
                            <ArrowDropUp />
                        </IconButton>
                    </Grid>
                    {Object.keys(item).map((it) => it !== "name" ?
                        <Grid item xs={12}>
                            <ValueTranslator varName={it} value={item[it]} dictionaryList={methodData} />
                        </Grid> : <></>
                    )}
                    {openConfirmation === true ?
                        <ConfirmationDialog
                            message={"¿Seguro que quiere borrar el método? " + affectedSessions.map((sid) => sessions[sid].name)}
                            action={() => deleteMethod()}
                            open={openConfirmation}
                            setOpen={setOpenConfirmation} />
                        :
                        <></>
                    }
                    <Grid container item justifyContent="right">
                        <Grid item>
                            <IconButton onClick={handleDelete}>
                                < Delete />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCopy}>
                                < ContentCopy />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleEdit}>
                                <Edit />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                :
                <Grid container alignContent="center">
                    <Grid container item xs={10} alignContent="center">
                        <Grid container item xs={12} alignContent="center">
                            <Typography>{OptionLists.training_zone[item.training_zone]}_{item.reps ? item.reps + "r" : item.time + "s"}X{item.series}_{item.load}% </Typography>
                        </Grid>
                        <Grid container item xs={12} alignContent="center">
                            <Typography variant='overline'>{item.name} </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={2} >
                        {draggable ?
                            <></>
                            :
                            <IconButton onClick={handleClick}>
                                <ArrowDropDown />
                            </IconButton>
                        }
                    </Grid>
                </Grid>
            }
        </Paper>
    );
}

export default MethodComponent;