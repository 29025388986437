import React, { Component } from 'react';

import lapiz from '../../assets/icons/Vector.svg';  
import { Button, Dialog, Grid, IconButton, Typography } from "@mui/material";

import { Link, NavLink, useLocation } from "react-router-dom";

// Styles
import  style from './HeaderTop.module.scss'; 

// Icons
import { Add} from "@mui/icons-material"; 
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';


// CÓMO INVOCAR?
{/* 
    <HeaderTop
        title="Métodos"                                 -----> Nombre página
        subtitle="Listado de Métodos"                   -----> Descripción
        buttonActionFunction = {setDialogMethod}        -----> Función del botón de acción
        buttonText = "texto del botón"                  -----> Texto que quieres en el botó
        filters= {false}                                -----> Tiene filtros? (false si no)
    /> 
*/}
const HeaderTop = (props) => {
    
    const {title, subtitle, buttonActionFunction, buttonText, filters} = props;
    
    return ( 
        <div className={style.headerpagebox}> 
        
        <div className={style.bloquetituloyaccion}>
            <div className={style.bloquetexto}>
                <span>{title}</span> 
                <br/>{subtitle}
            </div> 
            
            {buttonActionFunction ?
            <Button sx={{ boxShadow: 0 }} variant="contained" onClick = {(e) => buttonActionFunction(true)}>
                <Add fontSize="medium"/> 
                <p>{buttonText}</p>
            </Button> 
            : <div></div>}
        </div> 

        {filters ?
            <div className={` ${style.bloquefiltro}  deshabilitadoBR8`}>
                
                <div className={style.iconbox}> 
                    <FilterListOutlinedIcon className={style.icon}/>
                </div>
                <p>Filtrar resultados</p>
                <KeyboardArrowDownOutlinedIcon fontSize="medium"/> 

            </div>
            :
            <div></div>
        }
    </div>
    )
}
export default HeaderTop;

// export default  class HeaderTop extends Component { 
//     render() {

//         const title = this.props.title;
//         const subtitle = this.props.subtitle;
//         const buttonActionFunction = this.props.buttonActionFunction;
//         const buttonText = this.props.buttonText;
//         const filtros = this.props.filters;

        
        
//         return ( 
//             <div className={style.headerpagebox}> 
            
//             <div className={style.bloquetituloyaccion}>
//                 <div className={style.bloquetexto}>
//                     <span>{title}</span> 
//                     <br/>{subtitle}
//                 </div> 
                
//                 {buttonActionFunction ?
//                 <Button sx={{ boxShadow: 0 }} variant="contained" onClick = {(e) => buttonActionFunction(true)}>
//                     <Add fontSize="medium"/> 
//                     <p>{buttonText}</p>
//                 </Button> 
//                 : <div></div>}
//             </div> 

//             {filtros ?
//                 <div className={style.bloquefiltro}>
                    
//                     <div className={style.iconbox}> 
//                         <FilterListOutlinedIcon className={style.icon}/>
//                     </div>
//                     <p>Filtrar resultados</p>
//                     <KeyboardArrowDownOutlinedIcon fontSize="medium"/> 

//                 </div>
//                 :
//                 <div></div>
//             }
//         </div>
//         )
//     }
// }
 