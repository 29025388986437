import { Grid, Paper, Typography } from "@mui/material";

const ResumeObjectExercise = ({ object }) => {
    return (
        <Grid container spacing={1}>
            <Grid item >
                <Paper elevation={3} sx={{ p: 1, m: 2 }}>
                    <Typography>
                        Velocidad máxima : {object.vel_max} mm/s
                    </Typography>
                </Paper>
            </Grid>
            <Grid item >
                <Paper elevation={3} sx={{ p: 1, m: 2 }}>
                    <Typography>
                        Fuerza media : {object.f_average} N
                    </Typography>
                </Paper>
            </Grid>
            <Grid item >
                <Paper elevation={3} sx={{ p: 1, m: 2 }}>
                    <Typography>
                        Fuerza máxima : {object.f_max} N
                    </Typography>
                </Paper>
            </Grid>
            <Grid item >
                <Paper elevation={3} sx={{ p: 1, m: 2 }}>
                    <Typography>
                        Series : {object.n_series}
                    </Typography>
                </Paper>
            </Grid>
        </Grid>);
}

export default ResumeObjectExercise;