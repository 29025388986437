import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

// Layer que envolverá a las vistas
const ViewLayer = (props) => {

  return (
    <Container component="main" >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {props.children}
      </Box>
    </Container>
  );
}
export default ViewLayer;