import { Button, Grid } from "@mui/material";
import Papa from 'papaparse';
import { useEffect, useRef, useState } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import { Download } from "@mui/icons-material";

// const SimplifyData = (data, x, y) => {
//     var newData = [];
//     newData.push({ x: data[0][x], y: data[0][y] })
//     for (let i in data) {
//         if (data[i][y] !== newData[newData.length - 1].y) {
//             let newRow = { x: data[i][x], y: data[i][y] };
//             newData.push(newRow);
//         }
//     }
//     return newData;
// }

const RawDataChart = ({ url, exercise, cheight }) => {
    const [options, setOptions] = useState()

    useEffect(() => {
        exercise && getData();
    }, [exercise])

    const getData = () => {
        
        var customheight = 0;
        if (cheight === undefined){
            
            customheight = 340 ;
        }
        else {
            customheight = Number(cheight) ;
        }


        var zones = []
        var index = 0;
        exercise.series.forEach((serie) => {
            serie.reps.forEach((rep) => {
                rep.division_index && zones.push({
                    value: index + rep.division_index,
                    color: 'rgba(0, 255, 0, 0.6)',
                    fillColor: {
                        linearGradient: [0, 0, 0, 300],
                        stops: [
                            [0, '#00FF00'],
                            [1, 'rgba(255,255,255,.1)']
                        ]
                    },
                })
                zones.push({
                    value: index + rep.f_serie.length - 1,
                    color: 'rgba(255, 0, 0, 0.6)',
                    fillColor: {
                        linearGradient: [0, 0, 0, 300],
                        stops: [
                            [0, '#FFC000'],
                            [1, 'rgba(255,255,255,.1)']
                        ]
                    }
                })
                index = index + rep.f_serie.length
            },)
        })

        setOptions({
            chart: {
                type: 'line',
                zoomType: 'x',
                panning: true,
                panKey: 'shift',  
                height: customheight 
            },
            title: {
                // text: 'Datos brutos del ejercicio'
                text: ''
            },
            xAxis: {
                title: {
                    text: 'Time'
                },
            },
            yAxis: {
                title: {
                    text: 'N - mm  - mm/s'
                },
                // Comentar si se prefieren quitar
                // plotLines: [{
                //     value: pos_average,
                //     // color: 'red',
                //     dashStyle: "Dash",
                //     width: 1,
                //     zIndex: 4,
                //     label: {
                //         text: 'Posición media',
                //         align: 'left',
                //         style: {
                //             color: 'gray'
                //         }
                //     }
                // },
                // {
                //     value: f_average,
                //     color: 'red',
                //     dashStyle: 'LongDashDotDot',
                //     width: 1,
                //     zIndex: 4,
                //     label: {
                //         text: 'Fuerza media (N)',
                //         align: 'left',
                //         style: {
                //             color: 'gray'
                //         }
                //     }
                // },
                // {
                //     value: f_max,
                //     color: 'red',
                //     dashStyle: 'LongDashDotDot',
                //     width: 1,
                //     zIndex: 4,
                //     label: {
                //         text: 'Fuerza pico',
                //         align: 'left',
                //         style: {
                //             color: 'gray'
                //         }
                //     }
                // },
                // {
                //     value: vel_average,
                //     color: 'red',
                //     dashStyle: 'LongDashDotDot',
                //     width: 1,
                //     zIndex: 4,
                //     label: {
                //         text: 'Velocidad media',
                //         align: 'left',
                //         style: {
                //             color: 'gray'
                //         }
                //     }
                // },
                // {
                //     value: vel_max,
                //     color: 'red',
                //     dashStyle: 'LongDashDotDot',
                //     width: 1,
                //     zIndex: 4,
                //     label: {
                //         text: 'Velocidad máxima',
                //         align: 'left',
                //         style: {
                //             color: 'gray'
                //         }
                //     }
                // }
                // ]
            },
            credits: {
                enabled: false
            },
            series: [{
                type: 'area',
                name: 'Fuerza (N)',
                data: exercise.f_serie,
                animationLimit: 1000,
                dataLabels: {
                    enabled: false
                },
                turboThreshold: 0,
                boostThreshold: 10000,
                boost: {
                    useGPUTranslations: true
                },
                zoneAxis: 'x',
                zones: zones,
            },
            {
                name: 'Fuerza pico (N)',
                dashStyle: 'LongDashDotDot',
                data: [{ y: exercise.f_max, x: 0 }, { y: exercise.f_max, x: exercise.f_serie.length - 1 }],
                animationLimit: 1000,
                dataLabels: {
                    enabled: false
                },
                turboThreshold: 0,
                boostThreshold: 10000,
                boost: {
                    useGPUTranslations: true
                }
            },
            {
                name: 'Fuerza media (N)',
                dashStyle: 'LongDashDotDot',
                data: [{ y: exercise.f_average, x: 0 }, { y: exercise.f_average, x: exercise.f_serie.length - 1 }],
                animationLimit: 1000,
                dataLabels: {
                    enabled: false
                },
                turboThreshold: 0,
                boostThreshold: 10000,
                boost: {
                    useGPUTranslations: true
                }
            },
            {
                name: 'Potencia (W)',
                dashStyle: 'Solid',
                data: exercise.pot_serie,
                animationLimit: 1000,
                dataLabels: {
                    enabled: false
                },
                turboThreshold: 0,
                boostThreshold: 10000,
                boost: {
                    useGPUTranslations: true
                }
            },
            {
                name: 'Velocidad (cm/s)',
                data: exercise.vel_serie,
                dashStyle: 'Solid',
                animationLimit: 1000,
                dataLabels: {
                    enabled: false
                },
                turboThreshold: 0,
                boostThreshold: 10000,
                boost: {
                    useGPUTranslations: true
                }
            },
            {
                name: 'Velocidad pico (cm/s)',
                dashStyle: 'LongDashDotDot',
                data: exercise.vel_max,
                animationLimit: 1000,
                dataLabels: {
                    enabled: false
                },
                turboThreshold: 0,
                boostThreshold: 10000,
                boost: {
                    useGPUTranslations: true
                }
            },
            {
                name: 'Velocidad media (cm/s)',
                dashStyle: 'LongDashDotDot',
                data: [{ y: exercise.vel__average, x: 0 }, { y: exercise.vel__average, x: exercise.vel_serie.length - 1 }],
                animationLimit: 1000,
                dataLabels: {
                    enabled: false
                },
                turboThreshold: 0,
                boostThreshold: 10000,
                boost: {
                    useGPUTranslations: true
                }
            },
            {
                name: 'Posición (cm)',
                dashStyle: 'Solid',
                data: exercise.pos_serie,
                animationLimit: 1000,
                dataLabels: {
                    enabled: false
                },
                turboThreshold: 0,
                boostThreshold: 10000,
                boost: {
                    useGPUTranslations: true
                }
            },

            ],
        })
    }


    return ( 
            options && 
                <HighchartsReact 
                    highcharts={Highcharts} 
                    options={options}  
                />
              
    );
}

export default RawDataChart;