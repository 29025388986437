import { Button, Grid, Paper, Slider, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { SliderForm } from "../utils/FormCreator";
import { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../../firebase";

const minDistance = 1;

const BodyMetForm = ({bodyId}) => {
  
    const body = useSelector((state) => state.trainer.bodys[bodyId]);    
    const {firebase} = useContext(FirebaseContext);
    const [values, setValues] = useState();
    const [mass, setMass] = useState();

    
    useEffect(() => {
        const defaultData = body && body.bodyMetInfo ? body.bodyMetInfo[body.bodyMetInfo.length - 1]
        :
        {
            p_bone_mass: 40,
            p_muscle_mass: 40,
            p_fat_mass: 20,
            mass: 80
        } 
        setValues([defaultData.p_muscle_mass, defaultData.p_bone_mass + defaultData.p_muscle_mass]);
        setMass(defaultData.mass);
    }, [body])

    // TODO: Este componente necesita un useReducer para simplificar la gestión de los cambios

    const handleChange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }
        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], 100 - minDistance);
                setValues([clamped, clamped + minDistance]);
            } else {
                const clamped = Math.max(newValue[1], minDistance);
                setValues([clamped - minDistance, clamped]);
            }
        } else { 
            setValues(newValue);
        }
    };

    const handleSubmit = () => {
        const newValues = {
            p_muscle_mass: values[0],
            p_bone_mass: values[1] - values[0],
            p_fat_mass: 100 - values[1],
            mass: mass
        } 
        console.log(newValues);
        firebase.updateMetInfo(bodyId, newValues).then(() => {
            console.log("Se ha hecho bien");
        }).catch((err) => {
            console.log("Algo ha ido mal", err);
        });
    }

    return ( values && 
        <Paper sx={{px:10, py:3}}>
            <Grid container>
                <Grid container>
                    <Typography variant="h5"> Añadir lectura</Typography>
                </Grid>

                <Grid container spacing={1} alignItems="center" sx={{my:2}}>
                    <Grid item xs={9} >
                        <Slider
                            value={mass}
                            onChange={(e) => setMass(e.target.value)}
                            valueLabelDisplay="on"
                            
                            />

                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Masa (Kg)"
                            type="number"
                            value={mass}
                            onChange={(e) => setMass(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center" sx={{my:2}}>
                    <Grid item xs={12}>
                        <Slider
                            value={values}
                            onChange={handleChange}
                            track="inverted"
                            valueLabelDisplay="on"
                            />
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                label="% Músculo"
                                type="number"
                                value={values[0]}
                                />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="% Magra"
                                type="number"
                                value={values[1]}
                                />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="% Óseo"
                                type="number"
                                value={ values[1] - values[0]}
                                />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="% Grasa"
                                type="number"
                                value={100 - values[1]}
                                />    
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center" justifyContent="right">
                    <Button variant="contained" onClick={handleSubmit}> Aceptar </Button>
                </Grid>
            </Grid>
        </Paper>
     );
}
 
export default BodyMetForm;