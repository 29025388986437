import styled from "@emotion/styled";
import { Chip } from "@mui/material";


const SubscriptionChip = styled(Chip)`
    background: linear-gradient(45deg, #F0B527 20%, #F49742 60%, #F0B527 100%);
    border: 0;
    color: white;
    padding: 10px 30px;
    margin: 5px;
    box-shadow: 0 3px 5px 2px rgba(255, 160, 0, 0.3);
  `;

export default SubscriptionChip;
