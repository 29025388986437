import { Grid, Paper } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import Model, { IExerciseData, IMuscleStats } from 'react-body-highlighter';
import MuscleCard from './MuscleCard';
import MuscleBox from './MuscleBox';
import MuscleCardResume from './MuscleCardResume';



const GeneralMuscularGroupResume = ({muscularGroups}) => {
    const [muscleSelected, setMuscleSelected] = useState();

    const handleClick = useCallback(({ muscle, data }) => {
        const { exercises, frequency } = data;
        setMuscleSelected((actual) => {
            // return actual === muscle ? undefined : muscle; 
            return muscle
        })
      }, []);
    
    return (<Grid container>
                <Grid item xs={2}>
                    <Model
                        data={
                            [
                                { name: 'Bilateral', 
                                muscles: muscularGroups, frequency: 1 },
                                // { name: 'Sin bilateral', 
                                // muscles: Object.keys(example).filter((k) => example[k] && !example[k].bilateral ), frequency: 2 },
                            ]
                        }
                        highlightedColors={['#FFBF00', '#e6cc5a', '#aecc5a']}
                        type="anterior"
                        style={{ width: '12rem', padding: '1rem' }}
                        onClick={handleClick}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Model
                        data={
                            [
                                { name: 'Bilateral', 
                                muscles: muscularGroups, frequency: 1 },
                                // { name: 'Sin bilateral', 
                                // muscles: Object.keys(example).filter((k) => example[k] && !example[k].bilateral ), frequency: 2 },
                            ]
                        }
                        highlightedColors={['#FFBF00', '#e6cc5a', '#aecc5a']}
                        type="posterior"
                        style={{ width: '12rem', padding: '1rem' }}
                        onClick={handleClick}
                    />
                </Grid>
                <Grid item xs={7}>
                    {muscleSelected && <Grid item xs={12}>
                        <MuscleCardResume muscle_key={muscleSelected}/>
                    </Grid>}
                    
                </Grid>
            </Grid>
    );
}
 
export default GeneralMuscularGroupResume;