import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// constants 
const initialState = {
    updatedComplexReport: false,
    complexReports: {},
}

const complexReportSlice = createSlice({
    name: 'complexReports',
    initialState,
    reducers: {
        setComplexReports(state, action){
            state.complexReports = action.payload;
            state.updatedComplexReport = true;
        },
        clean(state, action){
            state.updatedComplexReport = false;
            state.complexReports = {};
        },
    }
})

export const { setComplexReports, clean } = complexReportSlice.actions;
export default complexReportSlice.reducer;