import { Grid, Typography } from "@mui/material";

import BodyMetInfoComponent from "./BodyMetInfoComponent";


// Styles
import style from "./BodyInfoComponent.module.scss";

const BodyInfoComponent = ({bodyId, body }) => {
    
    return (body &&
        <Grid container spacing={2} alignItems="center"> 
            <Grid item xs={12} >
                <Typography variant="p">{body.comment} </Typography>
            </Grid>
            <Grid item xs={12} >
                <BodyMetInfoComponent bodyId={bodyId}></BodyMetInfoComponent>
            </Grid>
        </Grid>
    );
}

export default BodyInfoComponent;