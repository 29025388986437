import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DroppableRowExercise from './DroppableRowExercise';

const SortableItem = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id, data: { type: "sortable" } });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div style={style}  >
            <DroppableRowExercise
                index={props.index}
                item={props.item}
                deleteRow={props.deleteRow}
                listeners={listeners}
                attributes={attributes}
                setRef={setNodeRef} />
        </div >
    );
}

export default SortableItem;
