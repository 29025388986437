import React, { useContext, useState } from 'react';
import { Chip, Dialog, Grid, IconButton, Paper, Typography } from "@mui/material";
import { ArrowDropDown, ArrowDropUp, ContentCopy, Delete, Edit } from '@mui/icons-material';
import exerciseData from '../../../constants/exercise';
import ValueTranslator from '../../utils/ValueTranslator';
import GifCreator from '../../utils/GifCreator';
import CreateExercise from './CreateExercise';
import { FirebaseContext } from '../../../firebase';
import { useSelector } from 'react-redux';
import ConfirmationDialog from '../../utils/ConfirmationDialog';

const ExerciseComponent = ({ exerciseId, exercise }) => {
    const { firebase } = useContext(FirebaseContext);
    const { sessions } = useSelector((state) => state.trainer)

    const [detailsActive, setDetailsActive] = useState(false);
    const [editActive, setEditActive] = useState(false);
    const [copy, setCopy] = useState(false);

    const handleClick = () => {
        setDetailsActive((prevDetail) => !prevDetail);
    }
    const handleEdit = () => {
        setEditActive(!editActive);
    }

    const handleCopy = () => {
        setEditActive(!editActive);
        setCopy(true);
    }

    const getSessionsAfected = (id) => {
        var sessions_affected = Object.keys(sessions).map((session_key) => {
            const exercise_methods = sessions[session_key].method_ids;
            for (var i in exercise_methods) {
                if (exercise_methods[i].exerciseId === id) {
                    return session_key;
                }
            }
            return undefined;
        }).filter((e) => e !== undefined);
        return sessions_affected;
    }

    const deleteExercise = () => {
        var sessions_affected = getSessionsAfected(exerciseId);

        for (var i in sessions_affected) {
            let exercise_methods = [...sessions[sessions_affected[i]].method_ids];
            for (var em in exercise_methods) {
                if (exercise_methods[em].exerciseId === exerciseId) {
                    var removed = exercise_methods.splice(em, 1);
                    console.log("Eliminados del array : ", removed, " - comparados con ", exerciseId);
                }
            }
            firebase.modifySession(sessions_affected[i], { ...sessions[sessions_affected[i]], method_ids: exercise_methods }).then(() => {
                console.log("Modificada session correctamente");
            });
        }
        firebase.deleteExercise(exerciseId);
    }

    const handleDelete = () => {
        setOpenConfirmation(true);
    }
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const affectedSessions = getSessionsAfected(exerciseId);

    return (
        <Paper elevation={3} sx={{ m: 1, p: 1 }}>
            {exerciseId ?
                <Dialog open={editActive} onClose={() => { setEditActive(false); setCopy(false) }} fullWidth scroll={'paper'}>
                    <CreateExercise exerciseId={exerciseId} copy={copy} onSubmit={() => { setEditActive(false); }} />
                </Dialog> : <></>
            }
            {detailsActive ?
                <Grid container alignContent="center" sx={{ py: 0.5 }} >
                    <Grid item container xs={10} alignContent="center">
                        <Typography> {exercise.name} </Typography>
                    </Grid>
                    <Grid item xs={2} >
                        <IconButton onClick={handleClick}>
                            <ArrowDropUp />
                        </IconButton>
                    </Grid>
                    <GifCreator images={[exercise.iniImage, exercise.interImage, exercise.endImage]} />

                    {Object.keys(exercise).map((item) => item !== "name" ?
                        <Grid item xs={12}>
                            <ValueTranslator varName={item} value={exercise[item]} dictionaryList={exerciseData} />
                        </Grid> : <></>
                    )}
                    {openConfirmation === true ?
                        <ConfirmationDialog
                            message={"¿Seguro que quiere borrar el ejercicio? " + affectedSessions.map((sid) => sessions[sid].name)}
                            action={() => deleteExercise()}
                            open={openConfirmation}
                            setOpen={setOpenConfirmation} />
                        :
                        <></>
                    }
                    <Grid container item justifyContent="right">
                        <Grid item>
                            <IconButton onClick={handleDelete}>
                                < Delete />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCopy}>
                                < ContentCopy />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleEdit}>
                                <Edit />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                :
                <Grid container>
                    <Grid container item xs={10} alignContent="center">
                        <Typography> {exercise.name} </Typography>
                    </Grid>
                    <Grid item xs={2} >
                        <IconButton onClick={handleClick}>
                            <ArrowDropDown />
                        </IconButton>
                    </Grid>
                    {/* 
                    <Grid item xs={2} >
                        {extraButton}
                    </Grid> */}

                </Grid>
            }
        </Paper>
    );
}

export default ExerciseComponent;