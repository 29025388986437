
import { Button, Grid, MenuItem, Paper, Select, Step, StepButton, StepContent, Stepper, TextField, Typography } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RawDataController from './useExerciseModel';
import RepRawChart from './charts/RepRawChart';
import ResumeObjectExercise from './ResumeObjectExercise';
import RepSelector from './RepSelector';
import ComparativeChart from './charts/ComparativeChart';
import TableObjectSelector from './TableObjectSelector';
import { FirebaseContext } from '../../firebase';
import CreateBilateralIsoReport from './CreateBilateralIsoReport';
import complexReportData from '../../constants/complexReportData';
import SchemaGenerator from '../../schemas/SchemaGenerator';
import { useFormik } from 'formik';
import { createNotification } from '../../reducers/notificationReducer';
import { FormSelector } from '../utils/FormCreator';


const BilateralComponent = ({ bodyId, item }) => {

    const reportLeft = useSelector((state) => state.trainer.reports[bodyId][item.left])
    const reportRight = useSelector((state) => state.trainer.reports[bodyId][item.right])
    const reportBoth = useSelector((state) => state.trainer.reports[bodyId][item.both])

    const rawDataLeft = reportLeft && RawDataController(reportLeft.downloadUrl);
    const rawDataRight = reportRight && RawDataController(reportRight.downloadUrl);
    const rawDataBoth = reportBoth && RawDataController(reportBoth.downloadUrl);


    return (
        <Grid container spacing={1}>
            {rawDataLeft && <Grid item xs={6}>
                <ResumeObjectExercise object={rawDataLeft} />
            </Grid>}
            {rawDataRight && <Grid item xs={6}>
                <ResumeObjectExercise object={rawDataRight} />
            </Grid>}

            {rawDataLeft && <Grid item xs={6}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Typography>
                            Reticiones izquierda
                        </Typography>
                    </Grid>
                    <RepSelector exercise={rawDataLeft} />
                </Grid>
            </Grid>}
            {rawDataRight && <Grid item xs={6}>
                <Grid item xs={12}>
                    <Typography>
                        Reticiones derecha
                    </Typography>
                </Grid>
                <RepSelector exercise={rawDataRight} />
                <TableObjectSelector exercise={rawDataLeft} />
            </Grid>}
            <Grid item xs={6}>
                {rawDataLeft && rawDataRight && !rawDataBoth && < ComparativeChart title="Comparativa de fuerza" unit="N" leftObject={rawDataLeft} rightObject={rawDataRight} />}
                {rawDataLeft && rawDataRight && rawDataBoth && < ComparativeChart title="Comparativa de fuerza" unit="N" leftObject={rawDataLeft} rightObject={rawDataRight} bothObject={rawDataBoth} />}
            </Grid>
        </Grid>);
}

const SingleComponent = ({ bodyId, item }) => {
    const report = useSelector((state) => state.trainer.reports[bodyId][item.raw])
    const rawData = report && RawDataController(report.downloadUrl);

    return (
        rawData && <Grid container>
            <Grid xs={12}>
                <ResumeObjectExercise object={rawData} />
            </Grid>
            <Grid xs={12}>
                <RepSelector exercise={rawData} />
            </Grid>
        </Grid>);
}

const TextComponent = ({ item }) => {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    return (<Grid container>
        <Grid item>
            <TextField value={title} onChange={(event) => { setTitle(event.currentTarget.value) }} />
        </Grid>
        <Grid item>
            <TextField value={content} onChange={(event) => { setContent(event.currentTarget.value) }} />
        </Grid>
    </Grid>);
}

// ¿Como podemos referenciar una repetición de una serie de unos datos brutos?
// Necesitamos los siguientes parametros
// rawDataId, i_serie, i_rep


// baseReport es una lista de json que define cada seccion del informe y los identificadores de los datos brutos asociados 
const CreateComplexReport = ({ bodyId, reportType, baseReport, onSubmit }) => {
    const { firebase } = useContext(FirebaseContext)
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        setData(baseReport);
    }, [baseReport])

    useEffect(() => {
        checkCompleted();
    }, [activeStep])

    const validationSchema = SchemaGenerator(complexReportData);

    const formik = useFormik({
        initialValues: { name: baseReport.name ??= "" },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleFinish(values);
        }
    })

    const handleFinish = (values) => {
        const compReport = { ...values, sections: [...data], bodyId: bodyId, type: reportType }
        firebase.createComplexReport(compReport).then(() => {
            dispatch(createNotification({ message: "Creado informe complejo correctamente", type: "success" }))
            if(onSubmit) onSubmit();
        }).catch((e) => {
            dispatch(createNotification({ message: "Error al crear informe complejo", type: "error" }))
        });
    }

    const handleSubmitSection = (index, data) => {
        setData((old) => {
            const newbie = [...old];
            newbie[index] = { ...old[index], ...data, completed: true };
            setActiveStep((old) => old + 1);
            return newbie;
        })
        checkCompleted();
    }

    const checkCompleted = () => {
        if (!data.length > 0) {
            setCompleted(false);
        } else {
            for (var i in data) {
                if (!data[i].completed) {
                    setCompleted(false);
                    return
                }
            }
            setCompleted(true);
        }
    }


    return (data &&
        <Grid container>
            <Grid container justifyContent="space-between" item xs={12}>
                <Grid item >
                    {FormSelector(complexReportData[0], formik)}
                </Grid>
                <Grid item >
                    <Button disabled={!completed} onClick={formik.handleSubmit}> Crear informe </Button>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Stepper nonLinear activeStep={activeStep} orientation="vertical" >
                    {data.map((item, index) => {
                        if (item.type === "bilateral") {
                            return <BilateralComponent bodyId={bodyId} item={item} />
                        } else if (item.type === "single") {
                            return <SingleComponent bodyId={bodyId} item={item} />
                        } else if (item.type === "bilateralIso") {
                            return <Step key={index} completed={item.completed} >
                                <StepButton onClick={() => { setActiveStep(index) }}>
                                    {item.name ??= "Equilibrio bilateral "}
                                </StepButton>
                                <StepContent >
                                    <CreateBilateralIsoReport bodyId={bodyId} reportId={item.raw} data={item} onSubmit={(data) => { handleSubmitSection(index, data) }} />
                                </StepContent>
                            </Step>
                        }
                    })}
                </Stepper>
            </Grid>

        </Grid>

    );
}

export default CreateComplexReport;