import { Button, Grid } from "@mui/material";
import Papa from 'papaparse';
import { useEffect, useState } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";


const ExerciseRepsChart = ({ exercise }) => {

    const [options, setOptions] = useState()

    useEffect(() => {
        exercise && config();
    }, [exercise])

    const config = () => {

        setOptions({
            chart: {
                zoomType: 'x',
                panning: true,
                panKey: 'shift'
            },
            title: {
                text: ''
            },
            xAxis: {
                title: {
                    text: 'Time'
                },
            },
            yAxis: {
                title: {
                    text: 'N '
                },
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    type: 'line',
                    name: 'Ocultar todo',
                    data: [],
                    events: {
                      legendItemClick: function (ev) {
                        const series = ev.target.chart.series;
                        const visible = !series[0].visible;
                        series.forEach((series) => series.setVisible(visible, false));
                        this.redraw();
                      },
                    },
                  }
            ].concat(...exercise.series.map((serie) =>
                serie.reps.map((rep) => {
                    return {
                        type: 'area',
                        name: "Serie:" + rep.i_serie + " Rep: " + (rep.i_rep) + ' Fuerza (N)',
                        data: rep.f_serie,
                        animationLimit: 1000,
                        zoneAxis: 'x',
                        zones: [
                            {
                                value: rep.division_index,
                                color: 'rgba(0, 255, 0, 0.6)',
                                fillColor: {
                                    linearGradient: [0, 0, 0, 300],
                                    stops: [
                                        [0, '#00FF00'],
                                        [1, 'rgba(255,255,255,.1)']
                                    ]
                                },
                            },
                            {
                                color: 'rgba(255, 0, 0, 0.6)',
                                fillColor: {
                                    linearGradient: [0, 0, 0, 300],
                                    stops: [
                                        [0, '#FFC000'],
                                        [1, 'rgba(255,255,255,.1)']
                                    ]
                                },
                            },
                        ],
                        dataLabels: {
                            enabled: false
                        },
                        turboThreshold: 0,
                        boostThreshold: 10000,
                        boost: {
                            useGPUTranslations: true
                        },
                    }
                })
            ))
        })
    }

    return (options && <Grid container>
        <Grid item xs={12}>
            < HighchartsReact highcharts={Highcharts} options={options} />
        </Grid>
    </Grid>
    )
}

export default ExerciseRepsChart;