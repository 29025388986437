import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDroppable, useDraggable, DndContext } from '@dnd-kit/core';
import { Button, ButtonGroup, Grid, IconButton, Paper, TextField, Typography, InputAdornment } from '@mui/material';
import texts from '../../../constants/texts/texts';
import { FirebaseContext } from "../../../firebase";
import { Delete, DeleteForever, Search } from '@mui/icons-material';
import { createNotification } from '../../../reducers/notificationReducer';
import { useFormik } from 'formik';
import * as Yup from "yup"
import SessionColumnView from '../sessions/SessionColumnView';
import TooltipComponent from '../../utils/TooltipComponent';

const DroppableDay = ({ day, sessionId, deleteDay }) => {
    const { isOver, setNodeRef } = useDroppable({
        id: day,
    });
    const elevation = isOver ? 24 : 2;
    const session = useSelector((state) => sessionId ? state.trainer.sessions[sessionId] : undefined)

    return (
        <Paper sx={{ width: 180, height: 120 }} elevation={elevation} ref={setNodeRef}  >
            <Typography variant="h5">{day}</Typography>

            {sessionId ?
                <Paper elevation={6} sx={{ p: 1, m: 1 }}>
                    <Grid container>
                        <Grid item xs={9} sx={{ marginTop: 1 }}>
                            <TooltipComponent arrow placement="left" style={{ popper: { maxWidth: 'none' } }} title={<SessionColumnView sessionId={sessionId} />}>
                                <Typography alignContent="center"> {session.name} </Typography>
                            </TooltipComponent>
                        </Grid>
                        <Grid item xs={3}>
                            <IconButton onClick={() => deleteDay(day)}><Delete /></IconButton>

                        </Grid>
                    </Grid>
                </Paper>
                :
                <></>
            }
        </Paper>
    )
}

const DraggableSession = ({ sessionId, session }) => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({ id: sessionId })
    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;

    return (
        <Paper sx={{ p: 2, m: 1 }} ref={setNodeRef} style={style} {...listeners} {...attributes} >
            <TooltipComponent arrow placement="left" style={{ popper: { maxWidth: 'none' } }} title={<SessionColumnView sessionId={sessionId} />}>
                <Typography fontSize={14}> {session.name} </Typography>
            </TooltipComponent>
        </Paper>
    )

}

const CreateMicrocicle = ({ microcicle, deleteDay }) => {
    const days = ["L", "M", "X", "J", "V", "S", "D"]

    return (
        <Grid container justifyContent="center" spacing={1} sx={{ p: 1 }}>
            {days.map((day) =>
                <Grid item>
                    <DroppableDay day={day} sessionId={microcicle[day]} deleteDay={deleteDay} />
                </Grid>
            )}
        </Grid>
    )
}


const validationSchema = Yup.object({
    name: Yup.string()
        .min(3, texts.create_program_name_min)
        .required(texts.create_program_name_required)
})

const CreateProgram = ({ programId, copy, onSubmit }) => {
    const dispatch = useDispatch();
    const { firebase } = useContext(FirebaseContext);

    const { sessions } = useSelector((state) => state.trainer)
    const program = useSelector((state) => state.trainer.programs[programId])

    const [currentIndex, setCurrentIndex] = useState(0);
    const [microcicles, setMicrocicles] = useState(program ? [...program.sessions] : [{}]);

    useEffect(() => {
        if (program) {
            setMicrocicles(program ? program.sessions.map((sess) => { return { ...sess } }) : [])
        }
    }, [program])

    // Drag and drop para meter las sessiones en una semana
    const handleDragEnd = (event) => {
        if (event.over) {
            const { id } = event.active;
            const overId = event.collisions[0].id;
            setMicrocicles((microcicles) => {
                const mc = microcicles[currentIndex];
                mc[overId] = id;
                microcicles[currentIndex] = mc;
                return [...microcicles]
            })
        }
    }

    const handleDeleteSession = (day) => {
        setMicrocicles((microcicles) => {
            const mc = microcicles[currentIndex];
            delete mc[day];
            microcicles[currentIndex] = mc;
            return [...microcicles]
        })
    }

    const handleCreateNewMicrocicle = () => {
        setMicrocicles((mcs) => [...mcs, {}]);
        setCurrentIndex((old) => microcicles.length);
    }

    const handleDeleteMicrocicle = () => {
        setMicrocicles((mcs) => {
            if (mcs.length > 1) {
                mcs.splice(currentIndex, 1);
                setCurrentIndex((currentIndex) => currentIndex === 0 ? 0 : currentIndex - 1)
                return [...mcs]
            } else {
                return mcs
            }
        });

    }

    const handleCreateProgram = (values) => {
        const newProgram = {
            name: values.name,
            sessions: microcicles,
        }
        if (program && !copy) {
            firebase.modifyProgram(programId, newProgram).then(() => {
                dispatch(createNotification({ message: "Se ha modificado correctamente", type: "success" }))
            }).catch((err) => {
                dispatch(createNotification({ message: "Error al modificar programa", type: "error" }))
            });
        } else {
            firebase.createNewProgram(newProgram).then(() => {
                dispatch(createNotification({ message: "Se ha creado un programa", type: "success" }))
            }).catch((err) => {
                dispatch(createNotification({ message: "Error al crear programa", type: "error" }))
            });

        }
        if (onSubmit) { onSubmit() }
    }

    const formik = useFormik({
        initialValues: { name: program ? program.name : "" },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleCreateProgram(values);
        }
    })

    const [searchParam, setSearchParam] = useState("");

    const handleChange = (event) => {
        setSearchParam(event.target.value);
    };

    return (
        <DndContext onDragEnd={handleDragEnd}>
            <Typography variant="h4" sx={{ pt: 3, px: 3 }}>{program ? copy ? "Copiar programa" : "Modificar programa" : "Crear programa"}</Typography>
            <Grid container sx={{ pt: 2, px: 3 }}>
                <Grid item xs={4} sx={{ pr: 1 }}>
                    <TextField label="Nombre de Programa" name="name" id="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        fullWidth
                        size='small'
                    />
                </Grid>
                <Grid item xs={8} sx={{ pl: 1 }}>
                    <Button variant="contained" onClick={formik.handleSubmit} >{program && !copy ? "Modificar programa" : texts.create_program}</Button>
                </Grid>

                <Grid item xs={9} sx={{ pt: 2 }}>
                    <ButtonGroup variant="outlined">
                        {microcicles.map((mc, index) =>
                            index === currentIndex ?
                                <>
                                    <Button variant="contained" onClick={() => setCurrentIndex(index)}>MC: {index + 1}</Button>
                                    {microcicles.length > 1 ? <Button color="error" size='small' variant='contained' onClick={handleDeleteMicrocicle} > <DeleteForever fontSize="small" />  </Button> : <></>}
                                </>
                                :
                                <Button onClick={() => setCurrentIndex(index)}>MC: {index + 1}</Button>
                        )}
                        <Button variant="contained" color="info" onClick={handleCreateNewMicrocicle} > + </Button>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={3}>
                    <Typography>Lista de sesiones</Typography>
                    <TextField
                        margin="dense"
                        fullWidth
                        placeholder={"Nombre Sesión"}
                        value={searchParam}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>
                        }}
                    />
                </Grid>

            </Grid>
            <Grid container sx={{ pt: 0, pb: 2, px: 2 }}>

                <Grid item xs={9}>
                    {microcicles.map((mc, index) =>
                        index === currentIndex ?
                            <CreateMicrocicle microcicle={mc} deleteDay={handleDeleteSession} />
                            :
                            <></>
                    )}
                </Grid>

                <Grid item xs={3}>
                    <Grid container sx={{/*overflow:"auto",*/ height: 350 }}>
                        <Grid item xs={12}>
                            {Object.keys(sessions).map((key) =>
                                sessions[key].name.toLowerCase().startsWith(searchParam.toLowerCase()) ?
                                    <DraggableSession sessionId={key} session={sessions[key]} />
                                    :
                                    <></>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DndContext>
    );
}

export default CreateProgram;