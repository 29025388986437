import { useSelector } from "react-redux";
import MuscleGroups from "../../constants/MuscleGroups";
import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import ComparativeMiniBar from "./ComparativeMiniBar";
import style from "./MuscleCard.module.scss";
import RawDataComponent from "../reports/RawDataComponent";


const MuscleCardResume = ({muscle_key}) => {
    const [extended, setExtended] = useState(false);
    const name = MuscleGroups[muscle_key];
    
    const body_id = useSelector((state) => state.trainer.currentBodyId);
    const body = useSelector((state) => state.trainer.bodys[state.trainer.currentBodyId]);
    const bodyReports = useSelector((state) => state.trainer.reports[state.trainer.currentBodyId]);

    try {
        const imagePath = require(`../../assets/muscleIcons/${muscle_key??= "biceps"}.png`);
        return (
            <div className={`${style.mainbox}`} >
                <div className={` ${style.cabecera}`}> 
                <Grid container spacing={1} justifyContent="space-around">
                    <Grid container xs={12} onClick={() => { setExtended((old) => !old) }}>
                        <Grid container item xs={12} justifyContent="space-around">
                            <Grid container item xs={5} alignContent="center" alignItems="center">
                                <Grid item xs={6} >
                                    <img src={imagePath} alt="Imagen PNG" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>{name}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={7} alignContent="center" alignItems="center">
                                <Grid item xs={12} >
                                    <Typography sx={{mx:3}} variant="p">Número de informes: 
                                        {Object.keys(bodyReports).filter((br_k) => bodyReports[br_k].mainMuscle === muscle_key ).length}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{mx:3}} variant="p"> 1RM : 
                                        {body.muscular_rm && body.muscular_rm.hasOwnProperty(muscle_key) && body.muscular_rm[muscle_key] > 0?
                                                body.muscular_rm[muscle_key] + " kg" :
                                                " No se ha asignado 1RM"
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={3}>
                            {/**FIM */}
                            <Grid item xs={4}>
                                <Grid item container xs={12} justifyContent="center">
                                    <Typography>FIM</Typography>
                                </Grid>
                                <Grid item container xs={12} justifyContent="center">
                                    <Typography>IZQ - DER</Typography>
                                </Grid>
                                <Grid item container xs={12} justifyContent="center">
                                    <ComparativeMiniBar left={31} right={32}/>
                                </Grid>
                            </Grid>
                            {/**FDMcc (1RM) */}
                            <Grid item xs={4}>
                                <Grid item container xs={12} justifyContent="center">
                                    <Typography>FDMcc (1RM)</Typography>
                                </Grid>
                                <Grid item container xs={12} justifyContent="center">
                                    <Typography>IZQ - DER</Typography>
                                </Grid>
                                <Grid item container xs={12} justifyContent="center">
                                    <ComparativeMiniBar left={41} right={43}/>
                                </Grid>
                            </Grid>
                            {/**FEM (Pot Max) */}
                            <Grid item xs={4}>
                                <Grid item container xs={12} justifyContent="center">
                                    <Typography>FEM (Pot Max)</Typography>
                                </Grid>
                                <Grid item container xs={12} justifyContent="center">
                                    <Typography>IZQ - DER</Typography>
                                </Grid>
                                <Grid item container xs={12} justifyContent="center">
                                    <ComparativeMiniBar left={27} right={24}/>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                    </Grid>
            </Grid> 
            </div>
        </div> 
        );
        
    } catch (error) {
        return (<></>);
    }
}
 
export default MuscleCardResume;