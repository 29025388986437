import React from 'react'
import Gym from "../components/gym/Gym"

const GymView = () => {
  return (
    <div>

      <Gym/>

    </div>
  )
}

export default GymView