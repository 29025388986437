import React, { useContext, useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FirebaseContext } from "../firebase";
import { setExercises, setMethods, setPrograms, setSessions, setBodys, setBodyExecutions , setCurrentBody, setBodyReports } from "../reducers/trainerReducer";
import {Grid, Button, Dialog, ButtonGroup, ToggleButtonGroup, ToggleButton, Select, MenuItem, Paper, Typography } from "@mui/material";
import MethodSelector from "../components/trainer/methods/MethodSelector";
import ExerciseSelector from "../components/trainer/exercises/ExerciseSelector";
import CreateExercise from "../components/trainer/exercises/CreateExercise";
import CreateMethod from "../components/trainer/methods/CreateMethod";
import CreateSession from "../components/trainer/sessions/CreateSession";
import texts from "../constants/texts/texts";
import CreateProgram from "../components/trainer/programs/CreateProgram";
import SessionSelector from "../components/trainer/sessions/SessionSelector";
import ProgramSelector from "../components/trainer/programs/ProgramSelector";
import { setUserLocalData } from "../reducers/userReducer";
import { AlignVerticalCenter, FitnessCenter, ViewDay } from "@mui/icons-material"; 
import { setComplexReports } from "../reducers/complexReportReducer"; 

const TrainerView = () => {

    const { firebase } = useContext(FirebaseContext);
    // Redireccionamos si ya ha iniciado sesion, esto deberia estar en una funcion
    const {userUpdated, user, auth, logedIn} = useSelector((state) => state.user)
    const {bodys, updatedExercises, updatedMethods, updatedSessions, updatedPrograms,updatedBodys} = useSelector((state) => state.trainer);    
    const {updatedComplexReports} = useSelector((state) => state.complexReports);    
    const [dialogSession, setDialogSession] = useState(false);
    const [dialogExercise, setDialogExercise] = useState(false);
    const [dialogMethod, setDialogMethod] = useState(false);
    const [dialogProgram, setDialogProgram] = useState(false);
    
    const [toggleOption, setToggleOption] = useState("methods");
    
    const dispatch = useDispatch();
    
    // useEffect(() => {
    //     if( !userUpdated && logedIn) {            
    //         firebase.getUserInfo((data) => { dispatch(setUserLocalData(data)) });
    //     }
    //     if(!updatedExercises && logedIn){
    //         firebase.getExercises((data) => { dispatch(setExercises(data)) })
    //     }
    //     if(!updatedMethods && logedIn){
    //         firebase.getMethods((data) => { dispatch(setMethods(data)) })
    //     }
    //     if(!updatedSessions && logedIn){
    //         firebase.getSessions((data) => { dispatch(setSessions(data)) })
    //     }
    //     if(!updatedPrograms && logedIn){
    //         firebase.getPrograms((data) => { dispatch(setPrograms(data)) })
    //     }
    //     if(!updatedBodys && logedIn){
    //         firebase.getBodys((data) => { dispatch(setBodys(data)) })
    //     }
    //     if(!updatedComplexReports && logedIn){
    //         firebase.getComplexReports((data) => { dispatch(setComplexReports(data)) })
    //     }
    //     if(updatedBodys && logedIn){
    //         for( let bodyId of Object.keys(bodys)){
    //             if(bodyId !== "defaultBody"){
    //                 firebase.getSessionExecutions(bodyId, (data) => { dispatch(setBodyExecutions({bodyId: bodyId, executions: data})) })
    //             }
    //         }
    //     }
    //     if(updatedBodys && logedIn){
    //         for( let bodyId of Object.keys(bodys)){
    //             if(bodyId !== "defaultBody"){
    //                 firebase.getReports(bodyId, (data) => { dispatch(setBodyReports({bodyId: bodyId, reports: data})) })
    //             }
    //         }
    //     }
    // }, [dispatch, firebase, updatedExercises, updatedMethods, updatedSessions, updatedPrograms, updatedBodys, userUpdated])
    

    return (
        <Fragment>
            
            <Dialog maxWidth="md"  open={dialogSession} onClose={() => { setDialogSession(false) }} fullWidth scroll={'paper'}>
                <CreateSession/>
            </Dialog>
            <Dialog  open={dialogExercise} onClose={() => { setDialogExercise(false) }} fullWidth scroll={'paper'}>
                <CreateExercise/>
            </Dialog>
            <Dialog open={dialogMethod} onClose={() => { setDialogMethod(false) }} fullWidth scroll={'paper'}>
                <CreateMethod/>
            </Dialog>
            <Dialog maxWidth="md" open={dialogProgram} onClose={() => { setDialogProgram(false) }} fullWidth scroll={'paper'}>
                <CreateProgram/>
            </Dialog>
            

            {/* Bloque de página */}
 
            <Grid container>

                {/* Botonera superior */}
                <Grid item xs={12}>
                    <ButtonGroup variant="outlined" sx={{m:1}}>
                        <Button onClick = {() => {setDialogProgram(true)}} >
                            {texts.create_program}
                        </Button>
                        <Button onClick = {() => {setDialogSession(true)}} >
                            {texts.create_session}
                        </Button>
                        <Button onClick = {() => {setDialogExercise(true)}} >
                            {texts.create_exercise}
                        </Button>
                        <Button onClick = {() => {setDialogMethod(true)}} >
                            {texts.create_method}
                        </Button>
                    </ButtonGroup>
                </Grid>

                <Grid item xs={9} sx={{height: "100%"}}>
                    <ProgramSelector/> 
                </Grid>

                <Grid  item xs={3} >
                    <Paper  sx={{ p: 2, m: 0.5 }}>
                        <Grid container justifyContent="center">
                            <ToggleButtonGroup
                                sx={{m:1}}
                                
                                color="primary"
                                size="small"
                                exclusive
                                fullWidth
                                value={toggleOption}
                                onChange={(event) => setToggleOption(event.currentTarget.value)}
                                aria-label="Platform"
                                >
                                <ToggleButton sx={{fontSize: 12}} value="sessions"> 
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <ViewDay fontSize="medium"/> 
                                        </Grid>
                                        <Grid item md={12} display={{ sm: "none", xs: "none", lg: "block" }} >
                                            Sesiones 
                                        </Grid>
                                    </Grid>
                                </ToggleButton>
                                <ToggleButton sx={{fontSize: 12}} value="exercises">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FitnessCenter fontSize="medium"/> 
                                        </Grid>
                                        <Grid item md={12} display={{ sm: "none", xs: "none", lg: "block" }}>
                                            Ejercicios
                                        </Grid>
                                    </Grid>
                                </ToggleButton>
                                <ToggleButton sx={{fontSize: 12}} value="methods">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <AlignVerticalCenter fontSize="medium"/> 
                                        </Grid>
                                        <Grid item md={12} display={{ sm: "none", xs: "none", lg: "block" }}>
                                            Métodos 
                                        </Grid>
                                    
                                    </Grid> 
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        {toggleOption === "exercises"? <ExerciseSelector/>:<></> }
                        {toggleOption === "methods"? <MethodSelector/>:<></> }
                        {toggleOption === "sessions"? <SessionSelector/>:<></> }
                    </Paper>
                </Grid>

            </Grid>
        </Fragment>            
    );
}
 
export default TrainerView;