import { React, useState, useEffect, useContext } from 'react';
import { Typography, Step, Stepper, Grid, StepContent, StepButton, Paper, Button } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import ExerciseExecution from './ExerciseExecution';
import MethodComponent from '../trainer/methods/MethodComponent';
import CircularProgressLabel from '../utils/CircularProgressLabel';
import ExerciseMethodComponent from '../trainer/ExerciseMethod';
import { FirebaseContext } from '../../firebase';
import { createNotification } from '../../reducers/notificationReducer';

const calcPercent = (value, maxValue) => {
    return Math.round((value * 100 / maxValue) * 100) / 100;
}

const SessionExecution = ({ programId, sessionId, day, mc, onClose }) => {
    const { methods, exercises, currentBodyId } = useSelector((state) => state.trainer);
    const body = useSelector((state) => state.trainer.bodys[state.trainer.currentBodyId])
    const session = useSelector((state) => state.trainer.sessions[sessionId]);

    const [executionData, setExecutionData] = useState([]);
    const [activeStep, setActiveStep] = useState(0);

    const { firebase } = useContext(FirebaseContext);
    const dispatch = useDispatch();


    const handleFinish = () => {
        const data = {
            programId: programId,
            sessionId: sessionId,
            day: day ??= "",
            mc: mc ??= 0,
            bodyRm: body.muscular_rm ??= {},
            execution_data: executionData,
        }
        firebase.createSessionExecution(currentBodyId, data).then((doc) => {
            dispatch(createNotification({ message: "Guardada ejecución correctamente ", type: "success" }))
            if (onClose) onClose();
        }).catch((err) => {
            dispatch(createNotification({ message: "Error al guardar ejecución", type: "error" }))
        });
    }


    useEffect(() => {
        if (!session) return;
        const execData = session.method_ids.map((item) => {
            const method = methods[item.methodId];
            const exercise = exercises[item.exerciseId];
            if (method && exercise) {
                // Esto es muy importante, es la estructura de datos de ejecucion
                // Se irán modificando exec, doneReps y completed
                return {
                    methodId: item.methodId,
                    exerciseId: item.exerciseId,
                    completed: false,
                    series: method.series,
                    kg_per_rep: exercise.main_muscular_group && body.muscular_rm && body.muscular_rm[exercise.main_muscular_group] && body.muscular_rm[exercise.main_muscular_group] > 0 ?
                        body.muscular_rm[exercise.main_muscular_group] * body.weight * method.load / 10000
                        :
                        exercise.intensity * body.weight * method.load / 100,
                    type: method.reps ? "reps" : "seg",
                    exec: [...Array(method.series)].map((i) => 0),
                    reps: method.reps ? method.reps : method.time,
                    totalReps: method.reps ? method.reps * method.series : method.time * method.series,
                    doneReps: 0,
                }
            }
            return undefined;
        }).filter((e) => e !== undefined);
        setExecutionData(execData);
    }, [session])

    const onSubmitStep = () => {
        if (activeStep < executionData.length) {
            setExecutionData((old) => {
                old[activeStep].completed = true;
                return [...old];
            })
            setActiveStep((old) => old + 1)
        } else {
            console.log("Se ha terminado la ejecucion con estos datos ", executionData);
        }
    }

    const onChangeSerie = (i_step, i_serie, newValue) => {
        setExecutionData((old) => {
            old[i_step].exec[i_serie] = newValue;
            if (old[i_step].exec.length === 1) {
                old[i_step].doneReps = old[i_step].exec[0];
            } else {
                old[i_step].doneReps = old[i_step].exec.reduce((a, b) => a + b, 0);
            }
            return [...old];
        })
    }

    return (!session ? <></> :
        <Grid container justifyContent="center">
            <Stepper nonLinear activeStep={activeStep} orientation="vertical" >
                {executionData.map((item, i_step) => {
                    const method = methods[item.methodId];
                    const exercise = exercises[item.exerciseId];

                    return (
                        <Step key={i_step} completed={item.completed} >
                            <StepButton onClick={() => { setActiveStep(i_step) }}>
                                <Grid container sx={{ maxWidth: 600, minWidth: 400 }}>
                                    <Grid item xs={5}>
                                        <Paper elevation={3} sx={{ m: 1, p: 1, mr: 0 }}>
                                            <Typography> {exercise.name} </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MethodComponent methodId={item.methodId} draggable item={method} />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <CircularProgressLabel value={calcPercent(item.doneReps, item.totalReps)} />
                                    </Grid>
                                </Grid>
                            </StepButton>
                            <StepContent sx={{ maxWidth: 400, minWidth: 400 }}>
                                <ExerciseMethodComponent exerciseItem={exercise} methodItem={method} row />
                                <ExerciseExecution
                                    key={i_step + "exec"}
                                    executionData={item}
                                    handleChange={(i_serie, newValue) => { onChangeSerie(i_step, i_serie, newValue) }}
                                    handleFinish={onSubmitStep}
                                />
                            </StepContent>
                        </Step>
                    )
                })}
                <Step key={executionData.length} >
                    <StepContent>
                        <Button onClick={handleFinish}> Terminar sesión y Guardar ejecución</Button>
                    </StepContent>
                </Step>
            </Stepper>
        </Grid >
    );
}

export default SessionExecution