

// Styles
import style from "./SpiderChart.module.scss";
import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import MuscleGroups from '../../constants/MuscleGroups';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts';
HighchartsMore(Highcharts)


const listMuscles = [
// TS
    // "head",
    "neck",
    "trapezius",
    "biceps",
    "triceps",
    "chest",
    "upper-back",
    "lower-back",
    "front-deltoids",
// CORE
    "abs",
    "obliques",

// TI
    // "abs",
    "gluteal",
    // "obliques",
    "quadriceps",
    "adductor",
    "abductors",
    "hamstring",
    "calves",
    "knees",
    // "left-soleus",
    // "right-soleus",
]

const data = {
    // TS
    "head": 34,
    "neck": 65,
    "trapezius": 143,
    "biceps": 76,
    "triceps": 26,
    "chest": 94,
    "upper-back": 62,    
    "lower-back": 23,
    "front-deltoids": 65,
    // CORE
    "abs": 42,
    "obliques": 13,

    // TI
    "abs": 0,
    "gluteal": 76,
    "obliques": 95,
    "quadriceps": 76,
    "adductor": 59,
    "abductors": 45,
    "hamstring": 96,
    "calves": 54,
    "knees": 24,
    "left-soleus": 15,
    "right-soleus": 64,
}



const SpiderChart = ({bodyId, muscularRm}) => {
    const [options, setOptions] = useState();

    const chartRef = useRef(null);

    
    const def_musc = listMuscles.reduce((acc, key) => {
        acc[key] = muscularRm && muscularRm[key] ? muscularRm[key] : 0 ;
        return acc;
    }, {});


    useEffect(() => {
      if (chartRef.current) {
        // Obtén el contenedor del gráfico
        const chartContainer = chartRef.current.container;
  
        // Aplica estilos de fondo degradado
        chartContainer.style.background = 'linear-gradient(white 0%, white 80%, orange 80%, orange 100%)';
      }
    }, []);

    useEffect(() => {
        setOptions(
            {
                chart: {
                    polar: true,
                    type: 'column',
                },
            
                title: {
                    text: '1RM',
                    align: 'center'
                },
            
                pane: {
                    startAngle: 0,
                    endAngle: 360, 
                    background : {
                        backgroundColor: {
                            radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
                            stops: [
                                [0, 'white'],
                                [0.85, 'white'],   
                                [1, 'rgba(255, 177, 27, 0.5)'], 
                            ],
                          },
                        innerRadius: '0%',  
                        outerRadius: '120%',
                        borderColor: 'rgba(255, 177, 27, 0.3)',  
                    }, 
                    
                },
            
                legend: {
                    enabled: false,
                    align: 'right',
                    verticalAlign: 'top',
                    y: 100,
                    layout: 'vertical'
                },
            
                xAxis: {
                    tickmarkPlacement: 'on',
                    categories: listMuscles,
                    
                    labels: {
                        align: 'center', // Set the label alignment to center
                        x: 0,
                        y: 0,
                        useHTML: true,
                        formatter: function() {
                          const html  =  `<div class="tarjetitaIcon"> <img src="` + process.env.PUBLIC_URL +  `/muscleIcons/` + this.value + `.png" height="30" width="30">` +  `</div>`;
                          return html;
                        },
                      },
                },
            
                yAxis: {
                    min: 0,
                    endOnTick: false,
                    showLastLabel: true,
                    title: {
                        text: ''
                    },
                    labels: {
                        format: ''
                    },
                    reversedStacks: false
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: '%',
                    useHTML: true,
                    formatter: function() {
                        const seriesName = this.x; // Obtén el nombre de la serie
                        const value = this.y; // Obtén el valor
                        const html = `<div class="tarjetitaIcon" >
                                <img src="` + process.env.PUBLIC_URL + `/muscleIcons/` + seriesName + `.png" height="50" width="50" >
                                <div> 
                                    ${MuscleGroups[seriesName]} ${value}
                                </div>
                            </div>`;
                    
                        return html;
                      },
                },
            
                plotOptions: {
                    series: {
                        // stacking: 'normal',
                        shadow: false,
                        groupPadding: 0,
                        pointPlacement: 'on'
                    }
                },
                series: [
                    {
                        name: '1RM',
                        data: Object.keys(def_musc).map((key) => def_musc[key]),
                        type: 'area',
                        lineWidth: 1, // Ancho del borde
                        lineColor: '#FF3030', // Color del borde
                        color: 'rgba(255, 138, 0, 0.7)'
                    }, 
                ],
            
            }
        );

    }, [muscularRm])

    return (  
        <div class={style.SpiderBox} item xs={12}>
            {options && <HighchartsReact key={bodyId} highcharts={Highcharts} options={options} callback={chart => {chartRef.current = chart;}}/>}
        </div> 
        );
}
 
export default SpiderChart;