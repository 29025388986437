import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";

const ComparativeGlobalReps = ({ objects }) => {
    useEffect(() => {
        getData();
    }, [])

    const [options, setOptions] = useState()
    const getData = (result) => {
        setOptions({
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false
            },
            title: {
                text: "",
                // text: objects[0].i_rep ? "F. Repeticiones" : "F. Series",
            },
            xAxis: {
                categories: objects.map((o) => {
                    if (o.i_rep) {
                        return "S" + o.i_serie + " R" + o.i_rep;
                    } else {
                        return "S" + o.i_serie;
                    }
                }),
                labels: {
                    rotation: -45, // Ángulo de rotación en grados
                    align: "right"
                }

            },
            yAxis: [{
                min: 0,
                title: {
                    text: "N",
                }
            },
                // {
                //     title: {
                //         text: "N",
                //     },
                //     opposite: true
                // }, {
                //     title: {
                //         text: "N",
                //     },
                // }
            ],
            boost: {
                useGPUTranslations: true
            },
            legend: {
                shadow: false
            },
            tooltip: {
                shared: true
            },
            plotOptions: {
                column: {
                    grouping: false,
                    shadow: true,
                    borderWidth: 0
                }
            },
            series: [
                {
                    name: 'Fuerza media (N)',
                    color: 'rgba(165,170,217,1)',
                    data: objects.map((o) => o.f_average),
                    tooltip: {
                        valuePrefix: '',
                        valueSuffix: ' N'
                    },
                    pointPadding: 0.3,
                    pointPlacement: 0
                }, {
                    name: 'Fuerza pico (N)',
                    color: 'rgba(126,86,134,.9)',
                    data: objects.map((o) => o.f_max),
                    tooltip: {
                        valuePrefix: '',
                        valueSuffix: ' N'
                    },
                    pointPadding: 0.4,
                    pointPlacement: 0
                }].filter((item) => item !== undefined)
        })
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                {options && < HighchartsReact highcharts={Highcharts} options={options} />}
            </Grid>
        </Grid>
    );
}

export default ComparativeGlobalReps;