import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router";
import { FirebaseContext } from "../firebase";
import { login } from "../reducers/userReducer";
import {ProtectedRoute} from "./helperRoutes";

import NotFoundView from "../views/utils/NotFoundView";

import ForgotPasswordView from "../views/auth/ForgotPasswordView";
import SignInView from "../views/auth/SignInView";
import SignUpView from "../views/auth/SignUpView";
import ModifyProfileView from "../views/profile/ModifyProfileView";
import ProfileView from "../views/profile/ProfileView";

import ReportsView from "../views/ReportsView";

import TrainerView from "../views/TrainerView";
import GymView from "../views/GymView";
import MethodsView from "../views/trainer/MethodsView";
import ExercisesView from "../views/trainer/ExercisesView";

import AboutUs from "../views/AboutView";
import BodysView from "../views/bodys/BodysView";
import SessionsView from "../views/trainer/SessionsView";
import ProgramsView from "../views/trainer/ProgramsView";

import InitializeData from "../firebase/InitializeData";
import PaymentView from "../views/payments/PaymentView";
import SuccessPayment from "../views/payments/SuccessPayment";
import TeamsView from "../views/teams/TeamsView";

const RootRoutes = () => {
    const dispatch = useDispatch();
    const {firebase} = useContext(FirebaseContext);
    const {auth} = useSelector((state) => state.user);

    useEffect(() => {
        firebase.ListenAuthChanges((data) =>{ dispatch(login(data))});
    }, [firebase, dispatch ])
  
    InitializeData();

    const routesList = [
        {
            path: '/dashboard',
            type: 'private',
            view: <TrainerView />
        },
        {
            path: '/about',
            type: 'public',
            view: <AboutUs/>
        },
        {
            path: '/successPayment',
            type: 'private',
            view: <SuccessPayment/>
        },
        {
            path: '/signin',
            type: 'public',
            view: <SignInView/>
        },
        {
            path: '/signup',
            type: 'public',
            view: <SignUpView/>
        },
        {
            path: '/forgotpassword',
            type: 'public',
            view: <ForgotPasswordView/>
        },
        {
            path: '/gym',
            type: 'private',
            view: <GymView />
        },
        {
            path: '/reports',
            type: 'private',
            view: <ReportsView />
        },
        {
            path: '/payment',
            type: 'private',
            view: <PaymentView />
        },
        {
            path: '/profile',
            type: 'private',
            view: <ProfileView />
        },
        {
            path: '/profile/modifyprofile',
            type: 'private',
            view: <ModifyProfileView />
        },
        {
            path: '/*',
            type: 'public',
            view: <NotFoundView />
        },
        {
            path: '/profile/:id',
            type: 'private',
            view: <ProfileView />,
        },

        // New routes 
        {
            path: '/',
            type: 'private',
            view: <BodysView />,
        },
        {
            path: '/bodys',
            type: 'private',
            view: <BodysView />,
        },
        {
            path: '/teams',
            type: 'private',
            view: <TeamsView />,
        },        
        {
            path: '/methods',
            type: 'private',
            view: <MethodsView />,
        },
        {
            path: '/exercises',
            type: 'private',
            view: <ExercisesView />,
        },
        {
            path: '/sessions',
            type: 'private',
            view: <SessionsView />,
        },
        {
            path: '/programs',
            type: 'private',
            view: <ProgramsView />,
        },
    ];

    return ( 
        <Routes>
            {routesList.map((item) =>
                item.type === "private"?
                    <Route exact path={item.path}
                        key={item.path}
                        element={
                            <ProtectedRoute auth={auth}>
                                {item.view}
                            </ProtectedRoute>
                        }
                    />
                :
                    <Route exact path={item.path} key={item.path} element={item.view}/>
            )}
        </Routes>
     );
}
 
export default RootRoutes;