import { useSelector } from "react-redux";
import style from "./TeamComponent.module.scss";
import { Avatar, AvatarGroup, Paper } from "@mui/material";

const TeamSmallCard = ({teamId}) => {
    const team = useSelector((state) => state.trainer.teams[teamId]);
    const nameAvatar = useSelector((state) => {
        if(team.bodys){
            return team.bodys.map((id) => {
                
                const obj = state.trainer.bodys[id] ? {
                    name: state.trainer.bodys[id].name,
                    avatarUrl: state.trainer.bodys[id].avatarUrl                
                }
                :
                {
                    name: "",
                    avatarUrl: ""                
                }
                return obj;
            })
        } 
    })

    return ( 
        team &&
            <div class="seccionInteriorTarjeta">
                {/* <AvatarGroup  max={1} spacing="medium" sx={{alignItems:"center"}} >
                    {nameAvatar.map((item) => 
                        <Avatar src={item.avatarUrl}>{item.name[0] + item.name[1]}</Avatar>)                        
                    }
                </AvatarGroup> */}
                {/* Bloque izquierdo: Título de dato bruto */}
                <div className={`${style.bloqueizquierdo} `} >
                    <p className="tituloTarjeta"> {team.name} </p>
                    <span></span>
                    <p className="textoTarjeta"><b>Nº Deportistas:</b> {team.bodys.length}  </p>  
                </div>            
            </div>
     );
}
 
export default TeamSmallCard;