import { Grid } from "@mui/material";
import { AgonistAntagonistPairs } from "../../constants/MuscleGroups";
import React, { useState, useEffect } from 'react';
import MuscleBox from "./MuscleBox";
import { CompareArrows } from "@mui/icons-material";



const AgonistAntagonistComponent = ({musclesAffected}) => {
    const [pairs, setPairs] = useState([]);

    useEffect(() => {
        setPairs(AgonistAntagonistPairs.filter((agon_antagon) => 
            musclesAffected.includes(agon_antagon.agonist) && musclesAffected.includes(agon_antagon.antagonist)))
    }, musclesAffected)
    
    return ( <Grid container>
            {pairs && pairs.map((agon_antagon) => {
                return <Grid container item xs={12} justifyContent="space-between">
                    <Grid item xs={5}>
                        <MuscleBox muscle_key={agon_antagon.agonist}/>
                    </Grid>
                    <Grid item container xs={2} alignContent="center">
                        <CompareArrows fontSize='large' />
                    </Grid>
                    <Grid item xs={5}>
                        <MuscleBox muscle_key={agon_antagon.antagonist}/>
                    </Grid>
                </Grid>})
            }
        </Grid>
     );
}
 
export default AgonistAntagonistComponent;