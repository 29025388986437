// const firebaseConfigDev = {
//   apiKey: "AIzaSyBw6CnBGRqNXxFaZdKug02KQ9-TJmE4jXk",
//   authDomain: "myoquality-5d029.firebaseapp.com",
//   projectId: "myoquality-5d029",
//   storageBucket: "myoquality-5d029.appspot.com",
//   messagingSenderId: "324258710123",
//   appId: "1:324258710123:web:eb32c591e363749c33272e"
// };

// const firebaseConfigDev2 = {
//   apiKey: "AIzaSyCGjNfBmFnRZYQTM0QldW7oUgQZSEH-fc0",
//   authDomain: "myotrainerdevelopment.firebaseapp.com",
//   projectId: "myotrainerdevelopment",
//   storageBucket: "myotrainerdevelopment.appspot.com",
//   messagingSenderId: "543934564367",
//   appId: "1:543934564367:web:1de9774c62b3b89e403b7a",
//   measurementId: "G-4VERW0FC3Z"
// }

const firebaseConfig = {
  apiKey: "AIzaSyAmZ1UAf2U_aTmMmo81LW6mW8JsOqBJAxk",
  authDomain: "myotrainer-f617f.firebaseapp.com",
  projectId: "myotrainer-f617f",
  storageBucket: "myotrainer-f617f.appspot.com",
  messagingSenderId: "712333407802",
  appId: "1:712333407802:web:302e64f3776f3c3c1be703",
  measurementId: "G-416RRCLP1K"
};

export default firebaseConfig;