import React, { useState, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Grid } from '@mui/material';
import MuscleGroups from '../../constants/MuscleGroups';

const MuscleGroupsBodyChart = ({muscleData}) => {
    const [options, setOptions] = useState();

    useEffect(() => {
        const musclesValidData = muscleData ? Object.keys(muscleData)
            .filter(element => Object.keys(MuscleGroups).includes(element) && muscleData[element] > 0 )
            .map((key) => {
            return {
                name: key,
                legendName: MuscleGroups[key],
                id: key,
                y: muscleData[key],
            }
        }): [];
        console.log(musclesValidData);

        try{
            setOptions(
            {
                chart: {
                    type: 'bar',
                },
                title: {
                    text: 'Resumen GM',
                },

                xAxis: {
                    type: 'category',
                    labels: {
                      y: -0,
                      useHTML: true,
                      formatter: function() {
                        // const url = getImagePath(this.value);
                        const html  =  `<div class="tarjetitaIcon"> <img src="` + process.env.PUBLIC_URL +  `/muscleIcons/` + this.value + `.png" height="40" width="40">` +  `</div>`;
                        // const html  =  `<br><img src="` + process.env.PUBLIC_URL +  `/Logo1.png" height="40" width="40"></br>` + this.value;
                        return html;
                      },
                    },
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                }, 
                plotOptions: {
                    series: {
                      minPointLength: 20,
                    }
                },
                series: [
                    {
                        name: "1RM",
                        useHTML: true,
                        data: musclesValidData,
                        dataLabels: {
                            enabled: true,
                            formatter: function() { 
                                return MuscleGroups[this.point.name];
                            },
                        },                
                        // color: '#f2d766',
                        color: {
                            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                            stops: [
                            [0, '#EDC418 '], // Color inicial 
                            [1, '#F49742'], // Color final 
                            ],
                        },
                        
                    },
                ],
              }
        );}catch(err){console.log(err);}

    }, [muscleData])

    // return <></>
    return ( options && <HighchartsReact highcharts={Highcharts} options={options}/>);
}

export default MuscleGroupsBodyChart;