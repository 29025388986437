import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, AccordionSummary, Accordion, AccordionDetails } from '@mui/material';


// Styles
import style from "./DataTableResumeExercise.module.scss";


// Icons  
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const DataTableResumeExercise = ({ exercise }) => {
    const [selectedRow, setSelectedRow] = useState(null);

    const handleRowClick = (index) => {
        if (selectedRow === index) {
            setSelectedRow(null);
        } else {
            setSelectedRow(index);
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Serie</TableCell>
                        <TableCell align="center">Tiempo (s)</TableCell>
                        {/* <TableCell align="center">T. bajo tensión (s)</TableCell> */}
                        <TableCell align="center">F. media (N)</TableCell>
                        <TableCell align="center">F. pico (N)</TableCell>
                        <TableCell align="center">T. al pico (s)</TableCell>
                        <TableCell align="center">P. Max</TableCell>
                        <TableCell align="center">Impulso (N·s)</TableCell>
                        <TableCell align="center"> </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {exercise.series.map((serie, index) =>
                        <React.Fragment key={index}>
                            <TableRow onClick={() => handleRowClick(index)}  className={`${selectedRow === index ? style.seleccionada : style.noseleccionada} ${style.ctabla}  `}>
                                <TableCell component="code" scope="row">Serie {serie.i_serie}</TableCell>
                                <TableCell align="center">{serie.time}</TableCell>
                                {/* <TableCell align="center">{serie.time}</TableCell> */}
                                <TableCell align="center">{serie.f_average}</TableCell>
                                <TableCell align="center">{serie.f_max}</TableCell>
                                <TableCell align="center">{serie.time_to_peak.toFixed(2)}</TableCell>
                                <TableCell align="center">{serie.pot_max}</TableCell>
                                <TableCell align="center">{serie.inertia}</TableCell>
                                <TableCell align="center"><KeyboardArrowDownIcon className={`${selectedRow === index ? style.pabajo : style.parriba}  `} /></TableCell>
                            </TableRow>
                            {/* Bloque de fila seleccionada */}
                            {selectedRow === index && (
                                <TableRow >
                                    <TableCell className={style.filaSeleccionadaBox} colSpan={9}>
                                        <p className={style.titulosubtabla}>Visualización de repeticiones</p> 
                                        <Table size="small" aria-label="subtable">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Rep</TableCell>
                                                    <TableCell align="center">Tiempo (s)</TableCell>
                                                    {/* <TableCell align="center">T. bajo tensión (s)</TableCell> */}
                                                    <TableCell align="center">F. media (N)</TableCell>
                                                    <TableCell align="center">F. pico (N)</TableCell>
                                                    <TableCell align="center">T. al pico (s)</TableCell>
                                                    <TableCell align="center">P. Max</TableCell>
                                                    <TableCell align="center">Impulso (N·s)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {serie.reps.map((rep, index_rep) =>
                                                    <TableRow >
                                                        <TableCell component="code" scope="row">Rep {rep.i_rep}</TableCell>
                                                        <TableCell align="center">{rep.time}</TableCell>
                                                        {/* <TableCell align="center">{rep.time}</TableCell> */}
                                                        <TableCell align="center">{rep.f_average}</TableCell>
                                                        <TableCell align="center">{rep.f_max}</TableCell>
                                                        <TableCell align="center">{rep.time_to_peak}</TableCell>
                                                        <TableCell align="center">{rep.pot_max}</TableCell>
                                                        <TableCell align="center">{rep.inertia}</TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
export default DataTableResumeExercise;