import React, { Fragment, useContext, useState } from 'react';
import { Grid, Typography, Dialog, IconButton, Avatar,  } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FirebaseContext } from '../../firebase';
import { createNotification } from '../../reducers/notificationReducer';
import BodyInfoComponent from './BodyInfoComponent';
import CreateBody from './CreateBody';
import { Delete, Edit, Download, Male, Female} from '@mui/icons-material';
import { setCurrentBody } from '../../reducers/trainerReducer';
import ConfirmationDialog from '../utils/ConfirmationDialog';
import ExecutionResultsComponent from '../gym/ExecutionResultsComponent';

import '../../styles/global.scss'
import MuscleListViewSelector from '../muscleGroups/MuscleListViewSelector';

// Styles
import style from "./ManageBody.module.scss";
import IconoPeso from '../elements/advanceIcons/Peso';
import IconoAltura from '../elements/advanceIcons/Altura';


const BodyManager = () => {
    const { firebase } = useContext(FirebaseContext);
    const dispatch = useDispatch();
    const { executions, currentBodyId } = useSelector((state) => state.trainer);
    const body = useSelector((state) => state.trainer.bodys[state.trainer.currentBodyId]);
    const [openEdit, setOpenEdit] = useState(false);
    
    const deleteBody = (bodyId) => {
        dispatch(setCurrentBody("defaultBody"));
        console.log("Se intenta eliminar ", bodyId);
        firebase.deleteBody(bodyId).then(() => {
            dispatch(createNotification({ message: "Deportista eliminado correctamente", type: "success" }))
        }).catch((err) => {
            dispatch(createNotification({ message: "Error al eliminar deportista" + err, type: "error" }))
        })
    }
    
    const [openConfirmation, setOpenConfirmation] = useState(false);

    return (
        currentBodyId !== "defaultBody" && body ?
            <Fragment>
                <Dialog open={openEdit} onClose={() => { setOpenEdit(false) }} fullWidth scroll={'paper'}>
                    <CreateBody key={currentBodyId} bodyId={currentBodyId}></CreateBody>
                </Dialog>
                {
                    openConfirmation && <ConfirmationDialog message={"¿Seguro que quiere borrar el deportista?"} action={() => deleteBody(currentBodyId)} open={openConfirmation} setOpen={setOpenConfirmation} />}

                    {/* Tarjeta de detalle deportista */}
                    <div className={style.mainbox}>

                        {/* Cabecera */}
                        <div className={style.seccion}>
                            {/* Bloque izquierdo: Título de dato bruto */}
                            <div className={`${style.bloqueizquierdo} `} >
                                {/* <p className="tituloTarjeta"> Detalle deportista </p> */}

                                <div className={style.topResumenBox}>
                                    <div className={style.resumenBodyBox}>
                                        <Avatar variant="circular" sx={{ height: 75, width: 75 }} src={body.avatarUrl} >
                                            {body.name[0] + body.name[1]}
                                        </Avatar>
                                        <div className={style.mainTextResumenBox}>
                                            <div class="tituloTarjeta">
                                                {body.name}
                                                {body.gender && body.gender === "male" ? 
                                                    <Male sx={{ml: 2}} fontSize='small'/> 
                                                    : body.gender === "female" ? <Female sx={{ml: 2}} fontSize='small'/> 
                                                : <></>}
                                            </div> 
                                            <div className={style.filaIconos}>
                                                <IconoPeso peso={body.weight} texto={false} />
                                                <IconoAltura altura={body.height} texto={false} />
                                            </div>
                                        </div>
                                    </div>                            
                                </div>
                            </div>
                            
                            {/* Bloque derecho: iconos */}
                            <div className={style.bloquederechobox}>
                                <IconButton className={`${style.CButton} ${style.Bedit} `} onClick={() => { (setOpenEdit(true)) }} >
                                    <Edit />
                                </IconButton>
                                <IconButton className={`${style.CButton} ${style.Bdelete} `} onClick={() => setOpenConfirmation(true)}>
                                    <Delete />
                                </IconButton>

                                <IconButton disabled={true} className={`${style.CButton} ${style.BDownload} `} 
                                    // onClick={() => setDownloadDial(true)}
                                    >
                                    <Download />
                                </IconButton>
                            </div> 
                        </div>

                        {/*------ */}
                        <div className={style.seccion}>
                            <BodyInfoComponent bodyId={currentBodyId} body={body} />       
                        </div>
                        
                        <Grid container> 
                            {/**Tab de grupos musculares y agrupaciones*/}
                            <MuscleListViewSelector/>
                        </Grid>
                    </div>
                
                <Grid item xs={12} sx={{ m: 1, mt: 3, p: 6 }} className='deshabilitadoBR8'>
                    {executions && executions[currentBodyId] && Object.keys(executions[currentBodyId]).map((k) => {
                        return <ExecutionResultsComponent execution={executions[currentBodyId][k]} />
                    })}
                </Grid>

            </Fragment>
            :
            <Grid container justifyContent="space-around" sx={{ mt: 5 }}>
                <Typography variant="h4"> Selecciona un deportista</Typography>
            </Grid>
    );
}

export default BodyManager;