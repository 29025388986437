import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import style from "./MuscleCard.module.scss";
import { KeyboardArrowDown } from '@mui/icons-material';
import MuscleGroups from '../../constants/MuscleGroups';
import { useSelector } from 'react-redux';
import RawDataComponent from '../reports/RawDataComponent';
import ComparativeMiniBar from './ComparativeMiniBar';

const MuscleCard = ({muscle_key}) => {
    const [extended, setExtended] = useState(false);
    const name = MuscleGroups[muscle_key];
    
    const body_id = useSelector((state) => state.trainer.currentBodyId);
    const bodyReports = useSelector((state) => state.trainer.reports[state.trainer.currentBodyId]);

    try {
        const imagePath = require(`../../assets/muscleIcons/${muscle_key??= "biceps"}.png`);
        return (
            <div className={`${extended && style.tarjetaactiva} ${style.mainbox}`} >
                <div className={`${extended && style.cabeceraactiva} ${style.cabecera}`}> 
      
        {/* <Paper elevation={6} sx={{m: 2}} onClick={() => { setExtended((old) => !old) }}> */}
            <Grid container spacing={1} justifyContent="space-around">
                <Grid container xs={12} onClick={() => { setExtended((old) => !old) }}>
                    <Grid item xs={3}>
                        <Grid container item xs={12} justifyContent="center">
                            <img src={imagePath} alt="Imagen PNG" />

                        </Grid>
                        <Grid container item xs={12} justifyContent="center">
                            <Typography>{name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={9}>
                        {/**FIM */}
                        <Grid item xs={3.5}>
                            <Grid item container xs={12} justifyContent="center">
                                <Typography>FIM</Typography>
                            </Grid>
                            <Grid item container xs={12} justifyContent="center">
                                <Typography>IZQ - DER</Typography>
                            </Grid>
                            <Grid item container xs={12} justifyContent="center">
                                <ComparativeMiniBar left={31} right={32}/>
                            </Grid>
                        </Grid>
                        {/**FDMcc (1RM) */}
                        <Grid item xs={3.5}>
                            <Grid item container xs={12} justifyContent="center">
                                <Typography>FDMcc (1RM)</Typography>
                            </Grid>
                            <Grid item container xs={12} justifyContent="center">
                                <Typography>IZQ - DER</Typography>
                            </Grid>
                            <Grid item container xs={12} justifyContent="center">
                                <ComparativeMiniBar left={41} right={43}/>
                            </Grid>
                        </Grid>
                        {/**FEM (Pot Max) */}
                        <Grid item xs={3.5}>
                            <Grid item container xs={12} justifyContent="center">
                                <Typography>FEM (Pot Max)</Typography>
                            </Grid>
                            <Grid item container xs={12} justifyContent="center">
                                <Typography>IZQ - DER</Typography>
                            </Grid>
                            <Grid item container xs={12} justifyContent="center">
                                <ComparativeMiniBar left={27} right={24}/>
                            </Grid>
                        </Grid>
                        
                        {/* Bloque derecho: iconos */}
                        <div className={style.bloquederechobox}> 
                        
                            {extended ?
                                <KeyboardArrowDown className={style.pabajo}/>
                                :
                                <KeyboardArrowDown className={style.parriba}/>
                            }
                        </div>
                    </Grid>
                </Grid>

                {extended && <Grid item container xs={12}>
                    <Grid item xs={12}>
                        <Typography></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {Object.keys(bodyReports).map((br_k) => {
                            if(bodyReports[br_k].mainMuscle === muscle_key ){
                                return <Grid item sx={{m:1}} xs={12}> 
                                        <RawDataComponent bodyId={body_id} reportId={br_k}/>
                                    </Grid>
                            }
                        })}

                    </Grid>
                </Grid>}
            </Grid> 
        {/* </Paper>   */}
            </div>
        </div> 
        );
        
    } catch (error) {
        return (<></>);
    }

}
 
export default MuscleCard;