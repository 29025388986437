import { Dialog, Grid } from "@mui/material";
import HeaderTop from "../../components/elements/HeaderTop";
import MainTopBar from "../../components/elements/MainTopBar";
import CreateTeamComponent from "../../components/teams/CreateTeamComponent";
import { useState } from "react";
import TeamSelector from "../../components/teams/TeamSelector";

const TeamsView = () => {
    const [dialogTeam, setDialogTeam] = useState(false);

    return ( 
        <div>              
            <MainTopBar
            teamSelector
            bodySelector
            headComponent={
                <HeaderTop
                    title="Equipos"
                    subtitle="Detalles de los equipos" 
                    buttonActionFunction = {setDialogTeam}
                    buttonText = "Crear Equipo"
                />
            }/>

            <Dialog maxWidth="md" open={dialogTeam} onClose={() => { setDialogTeam(false) }} scroll={'paper'}>
                <CreateTeamComponent/>
            </Dialog>
            <Grid item xs={12}>
                <TeamSelector/>
            </Grid>
        </div>

     );
}
 
export default TeamsView;