
const complexReportData = [
    {
        name: 'Nombre',
        varName: 'name',
        formType: 'textField',
        type: 'string',
        required: true,
        unit: '',
        //Range
        minValue: '0',
        maxValue: '100',
        defaultValue: "",
        description: 'Nombre dado a la sección'
    },
]
export default complexReportData