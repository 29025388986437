import es from "./es";
import en from "./en";

const GetTexts = () => {
    // Leer localStorage si hay definido un idioma
    // ES by default
    const lang = localStorage.getItem("lang");    
    // TODO: No se actualiza en tiempo real, habrá que utilizar redux o un contexto
    if(lang === "es"){
        return es;
    }
    if(lang === "en"){
         return en;
    }
    return es;

}
 
export default GetTexts();