import { Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { FirebaseContext } from "../../firebase";
import ComplexReportComponent from "./ComplexReportComponent";

const ManageReports = () => {
    const { complexReports } = useSelector(state => state.complexReports);
    const { firebase } = useContext(FirebaseContext);
    return (
        <Grid container>
            <Grid item xs={12}>
                {Object.keys(complexReports).map((k) =>
                    <ComplexReportComponent crId={k} data={complexReports[k]} />
                )}

            </Grid>
        </Grid>
    );
}

export default ManageReports;