import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentBody } from '../../reducers/trainerReducer';
import BodyButton from './BodyButton';

const BodySelector = () => {
    const dispatch = useDispatch();
    
    const { currentBodyId } = useSelector((state) => state.trainer)
    const bodys = useSelector((state) => {
        const team = state.trainer.teams[state.trainer.currentTeamId];
        if(team && team.bodys){
            var bodys = {};
            team.bodys.concat(["defaultBody"]).map((key) => {
                if(state.trainer.bodys.hasOwnProperty(key)){
                    bodys[key] = state.trainer.bodys[key];
                }
            }) 
            return bodys;
        }else{
            return state.trainer.bodys;
        }            
    })
    
    useEffect(() => {
        if(!bodys.hasOwnProperty(currentBodyId)){
            dispatch(setCurrentBody("defaultBody"));
        }
    }, [bodys])

    const handleSelectBody = (event) => {
        dispatch(setCurrentBody(event.target.value));
    }

    return (
        <FormControl>
            <InputLabel >Deportista</InputLabel>
            <Select
                id="currentBody"
                name="CurrentBody"
                label="CurrentBody" 
                defaultValue={currentBodyId}
                value={currentBodyId}
                onChange={handleSelectBody}
                sx={{ my: 1 }}
            >
                {Object.keys(bodys).map((key) => key === "defaultBody" ? 
                    <MenuItem key="defaultBody" value="defaultBody">
                        <Typography>Selecciona deportista</Typography>
                    </MenuItem>
                    :
                    <MenuItem key={key} value={key}>
                        {/* {bodys[key].name} */}
                        
                        <BodyButton bodyId={key}></BodyButton>
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
}

export default BodySelector;