import OptionLists from "./OptionLists"
const rawDataReport = [
    {
        name: 'Nombre',
        varName: 'name',
        formType: 'textField',
        type: 'string',
        required: true,
        unit: '',
        //Range
        minValue: 3,
        maxValue: 100,
        defaultValue: '',
        description: 'Nombre que servirá como etiqueta para diferenciar un reporte de datos brutos de otro'
    },
    {
        name: 'Observaciones',
        varName: 'observations',
        formType: 'textFieldMultiline',
        type: 'string',
        required: false,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 500,
        defaultValue: '',
        description: 'Observaciones acerca del ejercicio que se ha realizado como posibles irregularidades'
    },
];

export default rawDataReport