import React, { useState} from 'react';
import { Button, Dialog, Grid, Typography } from "@mui/material";
import { Add} from "@mui/icons-material";
import ExerciseSelector from "../../components/trainer/exercises/ExerciseSelector";
import CreateExercise from '../../components/trainer/exercises/CreateExercise';




// Components
import MainTopBar from "../../components/elements/MainTopBar";
import HeaderTop from "../../components/elements/HeaderTop";

// Styles
import "../../styles/global.scss";


const MethodsView = () => {    
    const [dialogExercise, setDialogExercise] = useState(false);

    return (

        <div>

            
            <MainTopBar
                teamSelector
                bodySelector
                headComponent={
                    <HeaderTop
                        title="Ejercicios"
                        subtitle="Crea o consulta información sobre los ejercicios " 
                        buttonActionFunction = {setDialogExercise}
                        buttonText = "Nuevo ejercicio"
                        filters= {false}
                    />
                }
            />


    
    
            <Grid container>
                <Dialog open={dialogExercise} onClose={() => { setDialogExercise(false) }} fullWidth scroll={'paper'}>
                    <CreateExercise/>
                </Dialog>

                <Grid item xs={12}>
                    <ExerciseSelector/>
                </Grid>
            </Grid>
            
        </div>
    );
}
 
export default MethodsView;