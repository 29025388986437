import { ContentCopy, Delete, Edit } from '@mui/icons-material';
import { Dialog, Grid, IconButton, Paper, Typography } from '@mui/material';
import React, { useState, useEffect, Fragment } from 'react';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FirebaseContext } from '../../../firebase';
import { createNotification } from '../../../reducers/notificationReducer';
import CreateProgram from './CreateProgram';
import MicrocicleComponent from './MicrocicleComponent';
import ProgramNavigator from './ProgramNavigator';
import ProgramSummary from './ProgramSummary';
import WeekChart from './WeekChart';
import ConfirmationDialog from '../../utils/ConfirmationDialog';

const ProgramComponent = ({ programId, close }) => {
    const { firebase } = useContext(FirebaseContext);
    const dispatch = useDispatch();
    const program = useSelector((state) => state.trainer.programs[programId]);
    const { currentBodyId, sessions, methods, exercises } = useSelector((state) => state.trainer);
    const [indexMicrocicle, setIndexMicrocicle] = useState(undefined);
    const [openModify, setOpenModify] = useState(false);
    const [copy, setCopy] = useState(false);


    // const [programSummary, setProgramSummary] = useState(ProgramSummary(program));
    var programSummary = ProgramSummary(program, sessions, methods, exercises);;

    useEffect(() => {
        setIndexMicrocicle(undefined)
    }, [programId, program])

    const handleSelectMicrocicle = (index) => {
        setIndexMicrocicle(index);
    }

    const handleDelete = () => {
        firebase.deleteProgram(programId).then(() => {
            dispatch(createNotification({ message: "Programa eliminado correctamente", type: "success" }))
        }).catch(() => {
            dispatch(createNotification({ message: "Error al elimnar programa", type: "error" }))
        });
    }

    const handleCopy = () => {
        setCopy(true);
        setOpenModify(true);
    }


    const [openConfirmation, setOpenConfirmation] = useState(false);

    return (program && programSummary ?
        <Paper elevation={12} sx={{ p: 2, m: 0.5, width: "100%" }}>
            {
                openConfirmation === true ?
                    <ConfirmationDialog message={"¿Seguro que quiere borrar el programa?"} action={() => handleDelete()} open={openConfirmation} setOpen={setOpenConfirmation} />
                    :
                    <></>
            }
            <Dialog maxWidth="md" open={openModify} onClose={() => { setOpenModify(false); setCopy(false); }} onSubmit={() => { setOpenModify(false) }}>
                <CreateProgram programId={programId} copy={copy} onSubmit={() => { setOpenModify(false); setCopy(false); }} />
            </Dialog>
            <Grid container>
                <Grid container item xs={12}>
                    <Grid item xs={7}>
                        <Typography variant="h5">{program.name}</Typography>
                    </Grid>
                    <Grid container item xs={5} justifyContent="right">

                        <Grid item >
                            <IconButton onClick={() => { setOpenConfirmation(true) }}>
                                <Delete />
                            </IconButton>
                        </Grid>
                        <Grid item >
                            <IconButton onClick={handleCopy}>
                                < ContentCopy />
                            </IconButton>
                        </Grid>
                        <Grid item >
                            <IconButton onClick={() => { setOpenModify(true) }}>
                                <Edit />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <ProgramNavigator key={programId + currentBodyId} items={program.sessions} selected={indexMicrocicle} setSelected={handleSelectMicrocicle} programSummary={programSummary} />
                    <Typography sx={{}}>Selector de microciclos</Typography>
                </Grid>
                <Grid container item xs={6} sx={{ maxWidth: 1000 }} justifyContent="center" alignContent="center">
                    {indexMicrocicle !== undefined && program.sessions[indexMicrocicle] ?
                        <>
                            <Typography>Intensidad por días</Typography>
                            <WeekChart key={"Microcicle" + indexMicrocicle + currentBodyId} week={program.sessions[indexMicrocicle]} microcicleSummary={programSummary[indexMicrocicle]} />
                        </>
                        :
                        <Typography>Selecciona un microciclo</Typography>
                    }
                </Grid>

                {indexMicrocicle !== undefined ?
                    <Grid item xs={12} >
                        <MicrocicleComponent key={"Microcicle" + indexMicrocicle + currentBodyId} microCicle={program.sessions[indexMicrocicle]} microcicleSummary={programSummary[indexMicrocicle]} />
                    </Grid>
                    : <></>
                }
            </Grid>
        </Paper>
        : <></>
    );
}

export default ProgramComponent;