import { Grid, Typography } from "@mui/material";
import TeamComponent from "./TeamComponent";
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

const TeamSelector = () => {
    const {currentTeam, teams} = useSelector((state) => state.trainer);
    
    return ( 
        <div class="listadoTarjetasBoxs"> 
            {Object.keys(teams).length > 0 ?
                Object.keys(teams).map((teamId) => {
                    return <TeamComponent teamId={teamId}/>
                })
            : 
            <Grid item xs={12}>
                <Typography> No tienes ningún equipo</Typography> 
            </Grid>            
            }
        </div>    
    );
}
 
export default TeamSelector;