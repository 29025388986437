import React, { Fragment } from 'react';
import { useFormik } from 'formik';
import { Button, ButtonGroup, DialogContent, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, Slider, TextField, Typography } from '@mui/material';
import SchemaGenerator from '../../schemas/SchemaGenerator';
import ImageForm from "./ImageForm"


export const ButtonGroupSelect = ({ formik, schema }) => {
    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ my: 2 }}>
            <Typography sx={{ mx: 3 }}> {schema.name}</Typography>
            <ButtonGroup
                maxWidth
                size="small"
                variant='text'
            >
                {Object.keys(schema.optionList).map((key, i) =>
                    <Button
                        key={key}
                        variant={formik.values[schema.varName] === key ? "contained" : "outlined"}
                        onClick={() => { formik.setFieldValue(schema.varName, key) }} >
                        {schema.optionList[key]}
                    </Button>
                )}
            </ButtonGroup>
            {formik.errors[schema.varName] ?
                <FormHelperText>{formik.errors[schema.varName]}</FormHelperText>
                : <></>}
        </Grid>
    )
}


export const VerticalSliderForm = ({ formik, schema }) => {

    return (
        <Grid item xs={2} spacing={2} alignItems={"center"}>
            <Grid item container justifyContent="center" xs={12} sx={{ height: 220 }} >
                <Slider
                    sx={{ mt: 2 }}
                    value={formik.values[schema.varName]}
                    size="small"
                    id={schema.varName}
                    name={schema.varName}
                    step={schema.step}
                    max={schema.maxValue}
                    min={schema.minValue}
                    orientation="vertical"
                    valueLabelDisplay="auto"
                    onChange={formik.handleChange}
                />
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
                <TextField
                    label={schema.name}
                    margin="dense"
                    sx={{ fontSize: 10, mt: 4 }}
                    id={schema.varName}
                    name={schema.varName}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{schema.unit}</InputAdornment>,
                    }}
                    placeholder={schema.name}
                    value={formik.values[schema.varName]}
                    onChange={formik.handleChange}
                    error={formik.touched[schema.varName] && Boolean(formik.errors[schema.varName])}
                    helperText={formik.touched[schema.varName] && formik.errors[schema.varName]}
                />
            </Grid>
        </Grid>
    )
}


export const SliderForm = ({ formik, schema }) => {

    return (
        <Grid container spacing={2} alingItems="center">
            <Grid item xs={8}>
                <Slider
                    value={formik.values[schema.varName]}
                    size="small"
                    id={schema.varName}
                    name={schema.varName}
                    step={schema.step}
                    max={schema.maxValue}
                    min={schema.minValue}
                    valueLabelDisplay="auto"
                    onChange={formik.handleChange}
                />
            </Grid>
            <Grid item xs>
                <TextField
                    label={schema.name}
                    margin="dense"
                    fullWidth
                    id={schema.varName}
                    name={schema.varName}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{schema.unit}</InputAdornment>,
                    }}
                    placeholder={schema.name}
                    value={formik.values[schema.varName]}
                    onChange={formik.handleChange}
                    error={formik.touched[schema.varName] && Boolean(formik.errors[schema.varName])}
                    helperText={formik.touched[schema.varName] && formik.errors[schema.varName]}
                />
            </Grid>
        </Grid>
    )
}

export const NumberFieldForm = ({ formik, schema }) => {
    return (
        <TextField
            label={schema.name}
            margin="dense"
            fullWidth
            id={schema.varName}
            name={schema.varName}
            InputProps={{
                step: schema.step, min: schema.minValue, max: schema.maxValue, type: 'number',
                endAdornment: <InputAdornment position="end">{schema.unit}</InputAdornment>,
            }}
            placeholder={schema.name}
            value={formik.values[schema.varName]}
            onChange={formik.handleChange}
            error={formik.touched[schema.varName] && Boolean(formik.errors[schema.varName])}
            helperText={formik.touched[schema.varName] && formik.errors[schema.varName]}
        />
    )
}

export const TextFieldForm = ({ formik, schema }) => {
    return (
        <TextField
            label={schema.name}
            margin="dense"
            required={schema.required}
            fullWidth
            id={schema.varName}
            name={schema.varName}
            placeholder={schema.name}
            value={formik.values[schema.varName]}
            onChange={formik.handleChange}
            error={formik.touched[schema.varName] && Boolean(formik.errors[schema.varName])}
            helperText={formik.touched[schema.varName] && formik.errors[schema.varName]}
        />
    )
}

export const TextFieldMultilineForm = ({ formik, schema }) => {
    return (
        <TextField
            label={schema.name}
            margin="dense"
            multiline
            // minRows={1}
            // maxRows={10}
            required={schema.required}
            fullWidth
            id={schema.varName}
            name={schema.varName}
            placeholder={schema.name}
            value={formik.values[schema.varName]}
            onChange={formik.handleChange}
            error={formik.touched[schema.varName] && Boolean(formik.errors[schema.varName])}
            helperText={formik.touched[schema.varName] && formik.errors[schema.varName]}
        />
    )
}


export const MultiSelectForm = ({ formik, schema }) => {
    return (
        <FormControl sx={{ my: 1 }} error={formik.touched[schema.varName] && Boolean(formik.errors[schema.varName])} fullWidth>
            <InputLabel required>{schema.name}</InputLabel>
            <Select
                id={schema.varName}
                name={schema.varName}
                label={schema.name}
                fullWidth
                multiple
                value={formik.values[schema.varName]}
                onChange={formik.handleChange}
            >
                {Object.keys(schema.optionList).map((key) =>
                    <MenuItem key={key} value={key}>
                        {schema.optionList[key]}
                    </MenuItem>
                )}
            </Select>
            {formik.touched[schema.varName] && formik.errors[schema.varName] ?
                <FormHelperText>{formik.errors[schema.varName]}</FormHelperText>
                : <></>}
        </FormControl>
    )
}

export const SelectForm = ({ formik, schema }) => {
    return (
        <FormControl sx={{ my: 1 }} error={formik.touched[schema.varName] && Boolean(formik.errors[schema.varName])} fullWidth>
            <InputLabel required>{schema.name}</InputLabel>
            <Select
                id={schema.varName}
                name={schema.varName}
                label={schema.name}
                fullWidth
                value={formik.values[schema.varName]}
                onChange={formik.handleChange}
            >
                {Object.keys(schema.optionList).map((key) =>
                    <MenuItem key={key} value={key}>
                        {schema.optionList[key]}
                    </MenuItem>
                )}
            </Select>
            {formik.touched[schema.varName] && formik.errors[schema.varName] ?
                <FormHelperText>{formik.errors[schema.varName]}</FormHelperText>
                : <></>}
        </FormControl>
    )
}

export const FormSelector = (item, formik) => {
    if (item.formType === "select") {
        return (
            <SelectForm formik={formik} schema={item} />
        )
    } else if (item.formType === "multiSelect") {
        return (
            <MultiSelectForm formik={formik} schema={item} />
        )
    } else if (item.formType === "textField") {
        return (
            <TextFieldForm formik={formik} schema={item} />
        )
    } else if (item.formType === "numberField") {
        return (
            <NumberFieldForm formik={formik} schema={item} />
        )
    } else if (item.formType === "textFieldMultiline") {
        return (
            <TextFieldMultilineForm formik={formik} schema={item} />
        )
    } else if (item.formType === "slider") {
        return (
            <SliderForm formik={formik} schema={item} />
        )
    } else if (item.formType === "verticalSlider") {
        return (
            <VerticalSliderForm formik={formik} schema={item} />
        )
    } else if (item.formType == "buttonGroupSelect") {
        return (
            <ButtonGroupSelect formik={formik} schema={item} />
        )
    }
}


const FormCreator = ({ schema, initialValues, action }) => {

    const handleSubmit = (values) => {
        if (action) {
            action(values)
        }
    }
    const validationSchema = SchemaGenerator(schema);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    })


    return (
        <Fragment>
            <DialogContent>

                {schema.map((item) => {
                    if (item.formType === "select") {
                        return (
                            <SelectForm formik={formik} schema={item} />
                        )
                    } else if (item.formType === "multiSelect") {
                        return (
                            <MultiSelectForm formik={formik} schema={item} />
                        )
                    } else if (item.formType === "textField") {
                        return (
                            <TextFieldForm formik={formik} schema={item} />
                        )
                    } else if (item.formType === "textFieldMultiline") {
                        return (
                            <TextFieldMultilineForm formik={formik} schema={item} />
                        )
                    } else if (item.formType === "slider") {
                        return (
                            <SliderForm formik={formik} schema={item} />
                        )
                    } else if (item.formType === "verticalSlider") {
                        return (
                            <VerticalSliderForm formik={formik} schema={item} />
                        )
                    } else if (item.formType === "image") {
                        return (
                            <ImageForm formik={formik} schema={item} />
                        )
                    } else if (item.formType == "buttonGroupSelect") {
                        return (
                            <ButtonGroupSelect formik={formik} schema={item} />
                        )
                    }

                })}
            </DialogContent>
            <Button
                type="submit"
                onClick={formik.handleSubmit}
                variant="contained">Aceptar</Button>
        </Fragment>
    );
}

export default FormCreator;

