import { utils, write } from "xlsx";

export const convertToXLSX = (sheetList) => {
    const workbook = utils.book_new();
    sheetList.map((item, index) => {
        const worksheet = utils.json_to_sheet(item);
        // const a = item[0]["Serie"] ??= index
        utils.book_append_sheet(workbook, worksheet, 'serie_'+item[0]["serie"] );
    })
    const xlsxFile = write(workbook, { type: 'buffer', bookType: 'xlsx' });
    return xlsxFile;
};
  
const downloadXLSX = (sheetList, filename) => {
    const xlsxContent = convertToXLSX(sheetList);
    const blob = new Blob([xlsxContent], { type: 'application/xlsx' });
    // Crear un elemento de enlace y asignar los atributos necesarios para descargar el archivo XLSX
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${filename}.xlsx`;
    link.click();
};
  
  export default downloadXLSX;
  
  
  