import { Button, Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import MethodComponent from "../methods/MethodComponent";
import SessionSummary from "../sessions/SessionSummary"
import SessionComponent from "../sessions/SessionComponent"
import texts from "../../../constants/texts/texts";


const SessionColumnView = ({ sessionId, onClick, day }) => {
    const { exercises, methods } = useSelector((state) => state.trainer);
    const session = useSelector((state) => state.trainer.sessions[sessionId]);
    const [extendedView, setExtendedView] = useState(false);
    const sessionSummary = SessionSummary(session);

    return session ?
        extendedView ?
            <Fragment sx={{ maxWidth: 600, minWidth: 400 }}>
                <SessionComponent session={session} sessionSummary={sessionSummary} day={""} />
            </Fragment>
            :
            <Paper sx={{ p: 1, m: 1, minWidth: 350, maxWidth: 400 }} elevation={3} >
                <Grid container >
                    {onClick && day &&
                        <Grid item container justifyContent="space-between" >
                            <Grid item >
                                <Typography sx={{ p: 1 }}>{texts.days[day]} </Typography>
                            </Grid>

                            <Grid item >
                                <Button onClick={() => { onClick(sessionId) }}> Ejecutar </Button>
                            </Grid>
                        </Grid>}
                    <Grid container item xs={12} justifyContent="center" sx={{ p: 1, m: 1 }} >
                        <Grid item >
                            <Typography variant="h5">{session.name}</Typography>
                        </Grid>
                        {/* <Grid item >
                            <Button onClick={() => { setExtendedView((old) => !old) }} >Extendida</Button>
                        </Grid> */}
                    </Grid>
                    {session.method_ids.length > 0 ? session.method_ids.map((item) =>
                        <Grid container item xs={12} >
                            <Grid item xs={6}>
                                <Paper elevation={3} sx={{ m: 1, p: 1, mr: 0 }}>
                                    <Typography> {exercises[item.exerciseId].name} </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <MethodComponent methodId={item.methodId} draggable item={methods[item.methodId]} />
                            </Grid>
                        </Grid>
                    ) :
                        <Grid container justifyContent="center">
                            <Typography>No hay ejercicios en esta sesión</Typography>
                        </Grid>
                    }
                </Grid >
            </Paper>
        :
        <Grid container >
            <Paper sx={{ p: 1, m: 1 }}>
                <Typography>No hay ejercicios</Typography>
            </Paper>
        </Grid>
}

export default SessionColumnView;