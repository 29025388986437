import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";

const RepRawChart = ({ rawObject }) => {
    const [options, setOptions] = useState();
    useEffect(() => {
        if (rawObject) {
            setOptions({
                chart: {
                    zoomType: 'x',
                    panning: true,
                    panKey: 'shift'
                },
                title: {
                    text: "Serie: " + (rawObject.i_serie) + "REP: " + (rawObject.i_rep)
                },
                xAxis: {
                    title: {
                        text: 'Time'
                    },
                },
                yAxis: {
                    title: {
                        text: 'N - mm  - mm/s'
                    },
                },
                credits: {
                    enabled: false
                },
                series: [
                    {
                        type: 'area',
                        name: ' Fuerza (N)',
                        data: rawObject.f_serie,
                        animationLimit: 1000,
                        zoneAxis: 'x',
                        zones: [
                            {
                                value: rawObject.division_index,
                                color: 'rgba(0, 255, 0, 0.6)',
                                fillColor: {
                                    linearGradient: [0, 0, 0, 300],
                                    stops: [
                                        [0, '#00FF00'],
                                        [1, 'rgba(255,255,255,.1)']
                                    ]
                                },
                            },
                            {
                                color: 'rgba(255, 0, 0, 0.6)',
                                fillColor: {
                                    linearGradient: [0, 0, 0, 300],
                                    stops: [
                                        [0, '#FFC000'],
                                        [1, 'rgba(255,255,255,.1)']
                                    ]
                                },
                            },
                        ],
                        dataLabels: {
                            enabled: false
                        },
                        turboThreshold: 0,
                        boostThreshold: 10000,
                        boost: {
                            useGPUTranslations: true
                        },
                    }
                ]

            })
        }
    }, [rawObject])

    return (options && < HighchartsReact highcharts={Highcharts} options={options} />);
}

export default RepRawChart;