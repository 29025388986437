import AboutUs from '../components/AboutUs'

const AboutView = () => {

    return (
        <div>
            <AboutUs />
        </div>
    )
}

export default AboutView;