import { useEffect, useState } from 'react';
import  {ReactComponent as ElasticoIcon} from '../../assets/icons/modos_maquina/elastico.svg'; 
import  {ReactComponent as CineticoIcon} from '../../assets/icons/modos_maquina/cinetico.svg'; 
import  {ReactComponent as AlturaIcon} from '../../assets/icons/modos_maquina/Altura.svg'; 
import  {ReactComponent as InercialIcon} from '../../assets/icons/modos_maquina/inercial.svg'; 
import  {ReactComponent as IsometricoIcon} from '../../assets/icons/modos_maquina/isometrico.svg'; 
import  {ReactComponent as TonicoIcon} from '../../assets/icons/modos_maquina/tonico.svg'; 
import  {ReactComponent as VibratorioIcon} from '../../assets/icons/modos_maquina/vibratorio.svg'; 
import  {ReactComponent as ConicoIcon} from '../../assets/icons/modos_maquina/conico.svg'; 
import  {ReactComponent as ErrorIcon} from '../../assets/icons/modos_maquina/error.svg'; 

import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import texts from '../../constants/texts/texts';
// Styles
import style from './IconMode.module.scss'

// Conical
// Vibratory
// Tonic
// Elastic
// Isometric
// Elastic

const ModeIcon = ({mode}) => {
    if(mode === "kinetic"){
        return <CineticoIcon/>
    }else if(mode === "elastic"){
        return <ElasticoIcon />;
    }else if(mode === "inertial"){
        return <InercialIcon />;
    }else if(mode === "isometric"){
        return <IsometricoIcon/>;
    }else if(mode === "tonic"){        
        return <TonicoIcon/>;
    }else if(mode === "vibratory"){        
        return <VibratorioIcon/>;
    }else if(mode === "conical"){        
        return <ConicoIcon/>;
    }else{
        return <ErrorIcon/>
    }
 
} 

const ModeIconWithText=({mode}) => {
    return (
        <div className={style.modobox}>
            <div className={style.boxiconomodo}>
                <ModeIcon mode={mode}/>
            </div>
            <p>{texts.icon_mode[mode]}</p>
        </div>
    )
}



export default ModeIconWithText;