import { Fragment, useContext, useState, useEffect } from "react";
import { FirebaseContext } from "../../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { createNotification } from "../../../reducers/notificationReducer";
import texts from '../../../constants/texts/texts'

import methodData from "../../../constants/method";
import { FormSelector } from "../../utils/FormCreator";
import SchemaGenerator from "../../../schemas/SchemaGenerator";
import { useFormik } from "formik";
import { Button, ButtonGroup, Checkbox, DialogContent, FormHelperText, Grid, Paper, Switch, Typography } from "@mui/material";
import ZoneChart from "../../utils/ZoneChart";
import { structuralRectangles, functionalRectangles } from '../../../constants/trainingZoneData'

const getDataObject = (varName, objectList) => {
    const formValues = objectList.find((item) => item.varName === varName);
    return formValues;
}

const MainAdaptationSelect = ({ formik }) => {
    const schema = getDataObject("main_adaptation", methodData);
    const handleSelect = (value) => {
        if (value === "funcional") {
            formik.setFieldValue("main_adaptation", value)
            formik.setFieldValue("training_zone", "NN")
            formik.setFieldValue("velocity", functionalRectangles[0].rangesVel[2])
            formik.setFieldValue("load", functionalRectangles[0].rangesRM[2])
            formik.setFieldValue("reps", functionalRectangles[0].rangesReps[2])
            formik.setFieldValue("effort_index", functionalRectangles[0].rangesRIR[2])
        } else {
            formik.setFieldValue("main_adaptation", value)
            formik.setFieldValue("training_zone", "MN")
            formik.setFieldValue("velocity", structuralRectangles[0].rangesVel[2])
            formik.setFieldValue("load", structuralRectangles[0].rangesRM[2])
            formik.setFieldValue("reps", structuralRectangles[0].rangesReps[2])
            formik.setFieldValue("effort_index", structuralRectangles[0].rangesRIR[2])
        }
    }

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ my: 2 }}>
            <Typography sx={{ mx: 3 }}> {schema.name}</Typography>
            <Grid item container >
                {Object.keys(schema.optionList).map((key, i) =>
                    <Grid item xs={6} >
                        <Paper
                            elevation={formik.values[schema.varName] === key ? 6 : 1}
                            key={key}
                            sx={{ bgcolor: key === "funcional" ? "#ff0000" : "#4472c4", justifyContent: "center", border: formik.values[schema.varName] === key ? 1 : 0 }}
                            onClick={() => handleSelect(key)} >
                            <Typography align="center">
                                {schema.optionList[key]}
                            </Typography>
                        </Paper>
                    </Grid>
                )}
            </Grid>
            {
                formik.errors[schema.varName] ?
                    <FormHelperText>{formik.errors[schema.varName]}</FormHelperText>
                    : <></>
            }
        </Grid >
    )
}

const TrainingZoneSelect = ({ formik }) => {
    const schema = getDataObject("training_zone", methodData);
    const handleSelect = (item) => {
        formik.setFieldValue("training_zone", item.name)
        formik.setFieldValue("main_adaptation", item.type)
        formik.setFieldValue("velocity", item.rangesVel[2])
        formik.setFieldValue("load", item.rangesRM[2])
        formik.setFieldValue("reps", item.rangesReps[2])
        formik.setFieldValue("effort_index", item.rangesRIR[2])
    }
    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ my: 2 }}>
            <Typography sx={{ mx: 3 }}> {schema.name}</Typography>
            <Grid item container >
                {functionalRectangles.map((item) =>
                    <Grid item xs={12 / 5} >
                        <Paper
                            elevation={formik.values[schema.varName] === item.name ? 6 : 1}
                            key={item.name}
                            sx={{
                                bgcolor: formik.values["main_adaptation"] === "funcional" ?
                                    item.color : "#A4A4A4",
                                justifyContent: "center",
                                border: formik.values[schema.varName] === item.name ?
                                    1 : 0,
                                mt: 1
                            }}
                            onClick={() => handleSelect(item)} >
                            <Typography align="center">
                                {schema.optionList[item.name]}
                            </Typography>
                        </Paper>
                    </Grid>
                )}
                {structuralRectangles.map((item) =>
                    <Grid item xs={12 / 5} >
                        <Paper
                            elevation={formik.values[schema.varName] === item.name ? 6 : 1}
                            key={item.name}
                            sx={{
                                bgcolor: formik.values["main_adaptation"] === "estructural" ?
                                    item.color : "#A4A4A4",
                                justifyContent: "center",
                                border: formik.values[schema.varName] === item.name ?
                                    1 : 0,
                                mt: 1
                            }}
                            onClick={() => handleSelect(item)} >
                            <Typography align="center">
                                {schema.optionList[item.name]}
                            </Typography>
                        </Paper>
                    </Grid>
                )}
            </Grid>
            {
                formik.errors[schema.varName] ?
                    <FormHelperText>{formik.errors[schema.varName]}</FormHelperText>
                    : <></>
            }
        </Grid >
    )
}


const ZoneForm = ({ formik }) => {
    return (
        <Grid>
            <MainAdaptationSelect formik={formik} />
            <TrainingZoneSelect formik={formik} />
        </Grid>
    )
}


const CreateMethod = ({ methodId, onSubmit, copy }) => {
    const method = useSelector((state) => state.trainer.methods[methodId])

    const dispatch = useDispatch();
    const { firebase } = useContext(FirebaseContext);
    const [repsSwitch, setRepsSwitch] = useState(true);
    const [advanced, setAdvanced] = useState(true);
    const values = {};
    methodData.forEach(element => {
        values[element.varName] = methodId && method && method[element.varName] ? method[element.varName] : element.defaultValue;
    });

    const validationSchema = SchemaGenerator(methodData);

    const getDataObjects = (varNames, objectList) => {
        const formValues = varNames.map((nam) => objectList.find((item) => item.varName === nam)).filter((item) => item !== undefined);
        return formValues
    }

    const formik = useFormik({
        initialValues: values,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const data = values;
            if (repsSwitch) {
                delete data.time;
            } else {
                delete data.reps;
            }
            for (const key in data) {
                if (data[key] === "" || data[key] == undefined) {
                    delete data[key];
                }
            }
            handleSubmit(data);
        }
    })

    const handleSubmit = (data) => {
        if (method && !copy) {
            firebase.modifyMethod(methodId, data).then(() => {
                dispatch(createNotification({ message: "Modificado correctamente", type: "success" }))
            }).catch((error) => {
                dispatch(createNotification({ message: "Error al modificar", type: "error" }))
            })
        } else {
            firebase.createNewMethod(data).then(() => {
                dispatch(createNotification({ message: texts.create_method_success, type: "success" }))
            }).catch((error) => {
                dispatch(createNotification({ message: texts.create_method_error, type: "error" }))
            })
        }
    }

    const part1 = getDataObjects([
        "name",
    ], methodData);

    const more = getDataObjects([
        "range_of_motion",
        "working_mode",
        "short_description",
        "benefits",
    ], methodData);
    const part3 = getDataObjects(["load", "velocity"], methodData);
    const part2 = getDataObjects([
        "effort_index",
        "series",
        "recovery_time",
    ], methodData);

    const time_series = getDataObjects([
        "reps",
        "time",
    ], methodData);

    return (
        <Fragment>
            {/* <FormCreator schema={method} initialValues={values} action={handleSubmit} /> */}
            <DialogContent sx={{ px: 3, py: 1 }}>
                <Grid container sx={{ pt: 2 }}>
                    <Grid item xs={6} container justifyContent="start" alignItems="center">
                        <Typography variant="h4" >{method ? copy ? "Copiar Método" : "Modificar Método" : "Crear Método"}</Typography>
                    </Grid>
                    <Grid item xs={6} container justifyContent="end" alignItems="center">
                        <Checkbox checked={advanced} onChange={(event) => { setAdvanced((old) => !old) }} />
                        <Typography> Configuracion avanzada</Typography>
                    </Grid>
                </Grid>
                {part1.map((item) => {
                    return FormSelector(item, formik);
                })}
                <ZoneForm formik={formik} />
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={10}>
                        <ZoneChart
                            key={formik.values["main_adaptation"]}
                            x={formik.values["velocity"]}
                            y={formik.values["load"]}
                            areas={formik.values["main_adaptation"] === "estructural" ?
                                structuralRectangles :
                                functionalRectangles} />
                    </Grid>
                    {FormSelector(part3[0], formik)}
                </Grid>
                {FormSelector(part3[1], formik)}
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={2}>
                        <Switch value={repsSwitch} onChange={() => setRepsSwitch((old) => !old)}></Switch>
                    </Grid>
                    <Grid item xs={3} >
                        <Typography >Reps / Time</Typography>
                    </Grid>
                    <Grid item xs={7} ></Grid>
                </Grid>
                {repsSwitch ?
                    FormSelector(time_series[0], formik)
                    :
                    FormSelector(time_series[1], formik)
                }
                {part2.map((item) => {
                    return FormSelector(item, formik);
                })}
                {advanced ? more.map((item) => {

                    return FormSelector(item, formik);
                }) : <></>}
            </DialogContent>
            <Button
                type="submit"
                // fullWidth
                onClick={formik.handleSubmit}
                variant="contained"
            >
                Aceptar
            </Button>
        </Fragment >
    );
}

export default CreateMethod;