import { Grid, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FirebaseContext } from "../../firebase";
import { createNotification } from "../../reducers/notificationReducer";
import BodyMuscularSelector from "../bodys/BodyMuscularSelector";
import * as Yup from "yup"
import texts from "../../constants/texts/texts";
import rawDataReport from "../../constants/rawDataReport";
import SchemaGenerator from "../../schemas/SchemaGenerator";
import { FormSelector } from "../utils/FormCreator";
import useExerciseModel from "./useExerciseModel";
import SerieSideSelector from "./SerieSideSelector";
/**
 * Component to create or modify a current rawData report
 * Need to set name and mainMucle 
 * Its necesary to create optionReport  
*/

const CreateReportData = ({ bodyId, reportId, report, onClose }) => {
    const [muscles, setMuscles] = useState();
    const [seriesSides, setSeriesSides] = useState();
    const [used, setUsed] = useState(false);
    const { firebase } = useContext(FirebaseContext)
    const dispatch = useDispatch();

    const exerciseObject = useExerciseModel(report.downloadUrl);

    useEffect(() => {
        if (report) {
            if (report.mainMuscle && report.secondaryMuscles)
                setMuscles({ mainMuscle: report.mainMuscle, secondaryMuscles: report.secondaryMuscles });
        }
    }, [report])

    const values = {};
    rawDataReport.forEach(element => {
        values[element.varName] = report && report[element.varName] ? report[element.varName] : element.defaultValue;
    });

    const validationSchema = SchemaGenerator(rawDataReport);
    const formik = useFormik({
        initialValues: values,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const series_resume = {}
            for (let serie of exerciseObject.series) {
                series_resume[serie.i_serie] = { ...serie.getJsonResume(), side: seriesSides[serie.i_serie] };
            }
            console.log("_-----");
            console.log( {
                ...values,
                ...muscles,
                series_sides: seriesSides,
                resume: exerciseObject.getJsonResume(),
                series_resume: series_resume,
            });
            handleSubmit(
                {
                    ...values,
                    ...muscles,
                    series_sides: seriesSides,
                    resume: exerciseObject.getJsonResume(),
                    series_resume: series_resume,
                });
        }
    })

    const handleSubmit = (data) => {
        firebase.modifyReport(bodyId, reportId, data).then(() => {
            setUsed(true)
            dispatch(createNotification({ message: "Modificado datos de reporte", type: "success" }))
        }).catch(() => {
            dispatch(createNotification({ message: "Error al modificar datos de reporte", type: "error" }))
        })
        if (onClose) onClose();
        setUsed(true);
    }

    const handleFinish = (data) => {
        setMuscles(data);
        formik.handleSubmit();
    }

    return (
        <Grid container sx={{ p: 3 }} spacing={2} >
            <Grid item xs={4}>
                <BodyMuscularSelector
                    disabled={used}
                    muscles={muscles}
                    onChange={setMuscles}
                    onSubmit={handleFinish}
                />
            </Grid>
            <Grid container item xs={8} alignContent="flex-start">
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12}>
                    {FormSelector(rawDataReport[0], formik)}
                </Grid>
                <Grid item xs={12}>
                    {FormSelector(rawDataReport[1], formik)}
                </Grid>
                <Grid container item xs={12}>
                    <SerieSideSelector report={report} rawDataObject={exerciseObject} setSeriesSides={setSeriesSides} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CreateReportData;