import React from 'react';
import { Grid, Paper, Typography } from "@mui/material";
import exerciseData from '../../constants/exercise';
import methodData from '../../constants/method';
import ValueTranslator from '../utils/ValueTranslator';
import GifCreator from '../utils/GifCreator';
import { Box } from '@mui/system';
import { functionalRectangles, structuralRectangles } from '../../constants/trainingZoneData';

const ExerciseMethodComponent = ({ exerciseItem, methodItem, row }) => {

    const imageList = [
        exerciseItem.iniImage,
        exerciseItem.interImage,
        exerciseItem.endImage,
    ]
    const d = methodItem.main_adaptation === "funcional" ?
        functionalRectangles.find((elem) => elem.name === methodItem.training_zone) :
        structuralRectangles.find((elem) => elem.name === methodItem.training_zone);

    return (
        row ?
            <Paper elevation={6} sx={{ m: 1, p: 2, width: 500, maxWidth: 600 }} >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography fontSize={20}> {exerciseItem.name} </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ my: 2 }}>
                        <GifCreator images={imageList} />
                    </Grid>
                    <Grid item xs={6}>

                        <Grid container item xs={12} sx={{ p: 2 }}>

                            {Object.keys(exerciseItem).map((item, index) => item !== "name" ?
                                <Grid item xs={12} sx={{ mt: 0.5 }}>
                                    <ValueTranslator key={index + item} varName={item} value={exerciseItem[item]} dictionaryList={exerciseData} />
                                </Grid> : <></>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid item xs={12} sx={{ border: 1, borderRadius: 1 }}>
                            <Box sx={{ p: 2, borderRight: 8, borderColor: d ? d.color : "black" }}>
                                <Typography fontSize={20}> {methodItem.name} </Typography>
                                {Object.keys(methodItem).map((item, index) => item !== "name" ?
                                    <Grid sx={{ mt: 0.5 }} item xs={12} >
                                        <ValueTranslator key={index + item} varName={item} value={methodItem[item]} dictionaryList={methodData} />
                                    </Grid> : <></>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

            </Paper >
            :
            <Paper elevation={6} sx={{ m: 1, p: 2, minWidth: 300, maxWidth: 300 }} >
                <Grid container >
                    <Grid item xs={12}>
                        <Typography fontSize={20}> {exerciseItem.name} </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ my: 2 }}>
                        <GifCreator images={imageList} />
                    </Grid>
                    <Grid item xs={12} sx={{ border: 1, borderRadius: 1 }}>
                        <Box sx={{ p: 2, borderRight: 8, borderColor: d ? d.color : "black" }}>
                            <Typography fontSize={20}> {methodItem.name} </Typography>
                            {Object.keys(methodItem).map((item, index) => item !== "name" ?
                                <Grid sx={{ mt: 0.5 }} item xs={12} >
                                    <ValueTranslator key={index + item} varName={item} value={methodItem[item]} dictionaryList={methodData} />
                                </Grid> : <></>
                            )}
                        </Box>
                    </Grid>
                    <Grid container item xs={12} sx={{ p: 2 }}>

                        {Object.keys(exerciseItem).map((item, index) => item !== "name" ?
                            <Grid item xs={12} sx={{ mt: 0.5 }}>
                                <ValueTranslator key={index + item} varName={item} value={exerciseItem[item]} dictionaryList={exerciseData} />
                            </Grid> : <></>
                        )}
                    </Grid>
                </Grid>
            </Paper>
    );
}

export default ExerciseMethodComponent;