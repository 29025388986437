import React, { Fragment } from "react";

import { Grid, Paper, Typography, Avatar } from "@mui/material";
import texts from "../../constants/texts/texts";

import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import UploadImage from "../utils/UploadImage";
import SubscriptionPlans from "./SubscriptionPlans";
import MainTopBar from "../elements/MainTopBar";
import HeaderTop from "../elements/HeaderTop";
import styled from "@emotion/styled";
import SubscriptionSection from '../../components/suscriptions/SubscriptionSection';



// Styles
import  style from './Profile.module.scss'; 

// Icons
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';

const Profile = ({ userObj }) => {
    return (userObj && userObj.profileCompleted ?
                <Fragment>
                    
                    <MainTopBar
                        headComponent={
                            <HeaderTop
                                title="Perfil"
                                subtitle="Edita tu información personal"        
                                filters= {false}
                            />
                        }
                    />
                    
                    <div className={style.mainmainBox}>

                        <div className={style.mainBox}>
                            <div className={`${style.boxGrupo} tituloTarjeta`}>
                                Información de perfil
                            </div>
                            <div className={`${style.boxSecciones} tarjetaSinBordeBox`}>
                                <div className={style.seccion}>
                                    <div className={style.filaConIcon}>
                                        <div className={style.bloqueTituloYTexto}>
                                            <div className={`${style.titulo} tituloElemento`}>
                                                Imagen de perfil
                                            </div>
                                            <Grid container
                                                direction="column"
                                                justifyContent="left"
                                                alignItems="center"
                                                marginBottom={2}
                                            >
                                                <Grid item xs={12} >
                                                    <UploadImage>
                                                        <Avatar
                                                            alt={userObj.userObjname}
                                                            src={userObj.avatarUrl}
                                                            sx={{ width: 150, height: 150 }}
                                                        />
                                                    </UploadImage>
                                                </Grid>
                                            </Grid>
                                        </div>
                                          
                                    </div>
                                    <div className={style.filaConIcon}>
                                        <div className={style.bloqueTituloYTexto}>
                                            <div className={`${style.titulo} tituloElemento`}>
                                                Nombre de usuario
                                            </div>
                                            <div>
                                                {userObj.username}
                                            </div>
                                        </div>
                                         
                                        <div className={style.iconbox} 
                                            // onClick={() => { setOpen(true) }}
                                        > 
                                            <EditIcon className={style.icon}/>
                                        </div>
                                    </div>
                                    <div className={style.filaConIcon}>
                                        <div className={style.bloqueTituloYTexto}>
                                            <div className={`${style.titulo} tituloElemento`}>
                                                Correo electrónico
                                            </div>
                                            <div>
                                                {userObj.email}
                                            </div>
                                        </div>
                                         
                                        <div className={style.iconbox} 
                                            // onClick={() => { setOpen(true) }}
                                        > 
                                            <EditIcon className={style.icon}/>
                                        </div>
                                    </div>
                                    {/* <div className={style.fila}>
                                        <div className={`${style.titulo} tituloElemento`}>
                                            Nombre de usuario
                                        </div>
                                        <div>
                                            asfasaf
                                        </div>
                                    </div> */}
                                    
                                </div>
                            </div>

                        </div>


                        {/* 
                        <div className={style.mainBox}>
                            <div className={`${style.boxGrupo} tituloTarjeta`}>
                                Sobre tu suscripción
                            </div>
                            <div className={style.boxSecciones}>
                                <div className={style.seccion}> 
                                    <SubscriptionSection /> 
                                    <div>Texto de pie de sección</div>
                                </div>
                            </div>

                        </div>


                        
                        <Grid container
                            direction="column"
                            justifyContent="left"
                            alignItems="center"
                            marginBottom={2}
                        >
                            <Grid item xs={12} >
                                <UploadImage>
                                    <Avatar
                                        alt={userObj.userObjname}
                                        src={userObj.avatarUrl}
                                        sx={{ width: 150, height: 150 }}
                                    />
                                </UploadImage>
                            </Grid>
                        </Grid>
                        <Grid container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            marginTop={2}
                        >
                            <Grid item xs={12} >
                                <Typography variant="h5" > {userObj.username} </Typography>
                            </Grid>
                            <Grid container xs={12} direction="row" justifyContent="center" alignItems="center">
                                <Typography variant="body1" justifyContent="center" align="center" > {userObj.email} </Typography>

                                {userObj.emailVerified ? <MarkEmailReadIcon fontSize="small" /> : <MarkEmailUnreadIcon fontSize="small" />}
                            </Grid>
                            <Grid item xs={12} >
                            </Grid>
                        </Grid> */}
                    </div>
                    {/* <SubscriptionPlans/> */}

                </Fragment>
                :
                <></>
    );
}

export default Profile;