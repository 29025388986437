import { Grid, Paper, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../../firebase";
import styled from "@emotion/styled";
const GradientPaperProfesional = styled(Paper)`
    background: linear-gradient(45deg, #F0B527 20%, #F49742 60%, #F0B527 100%);
    border: 0;
    color: white;
    padding: 10px 30px;
    margin: 5px;
    box-shadow: 0 3px 5px 2px rgba(255, 160, 0, 0.3);
  `;

const SuccessPayment = () => {
    const [role, setRole] = useState();
    const {firebase} = useContext(FirebaseContext);
    useEffect(() => {
        const role = firebase.getCustomClaimRole();
        setRole(role.stripeRole);
    },[]);

    return ( 
        <Paper>
            <Grid container>
                <Grid item xs={12}>
                    <Typography>
                        Su pago se ha realizado con éxito
                        Su suscripción acaba de comenzar.
                        <GradientPaperProfesional>
                            {role? role : "No tienes rol"}
                        </GradientPaperProfesional>

                    </Typography>
                </Grid>
            </Grid>
        </Paper>

     );
}
 
export default SuccessPayment;