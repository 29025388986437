import React from 'react';

// Icons
import { ReactComponent as Altura } from '../../../assets/icons/Altura.svg';

// Styles
import style from './Altura.module.scss';

const  IconoAltura = (props) => {
    
        return (        
            <div className={style.iconbox}>
                <div className={style.boxsuperior}>
                    <Altura />
                    <strong> {props.altura} cm</strong>                    
                </div>
                {props.texto ? <p className='textoIcono'>Peso</p> : <></>}
            </div>
        ) 
}

export default IconoAltura;