import firebaseConfig from './config';
import app from 'firebase/compat/app';
import 'firebase/compat/firestore';

import {
    getFirestore,
    addDoc,
    setDoc,
    collection,
    onSnapshot,
    doc,
    updateDoc,
    deleteDoc,
    query,
    where,
    getDoc,
    getDocs,
    arrayUnion,
    arrayRemove,
    deleteField,
    serverTimestamp,
    Timestamp,
    
} from 'firebase/firestore';
import {
    browserSessionPersistence,
    createUserWithEmailAndPassword,
    getAuth,
    setPersistence,
    signInWithEmailAndPassword,
    signOut,
    signInWithCustomToken,
    sendEmailVerification,
    sendPasswordResetEmail,
    onAuthStateChanged,
    onIdTokenChanged,
    browserLocalPersistence,
} from "firebase/auth";

import {
    getStorage,
    uploadBytes,
    uploadBytesResumable,
    getDownloadURL,
    ref,
    deleteObject,
} from "firebase/storage"

import { getStripePayments, getProducts, createCheckoutSession } from "@stripe/firestore-stripe-payments";


import moment from 'moment';

class Firebase {

    constructor() {
        // Por si hay error
        if (!app.apps.length) {
            this.app = app.initializeApp(firebaseConfig);
        }
        
        this.auth = getAuth();
        this.db = getFirestore()
        this.storage = getStorage();          
        this.InitializeStripe();
    }
    
    
    
    // STRIPE / PAYMENTS / ROLES
    InitializeStripe = () => {
        console.log("Initialice brownies");
        this.payments = getStripePayments(this.app, {
            productsCollection: "products",
            customersCollection: "customers",
        });
        this.GetProductsStripe();        
    }
    
    GetProductsStripe = async () => {
        const products = await getProducts(this.payments, {
            includePrices: true,
            activeOnly: true,
        });
        console.log("Estos son los productos");
        for (const product of products) {
            console.log(product);
        }
        const stripeRol = await this.getCustomClaimRole();
        console.log("Este es el rol? ", stripeRol);
    }

    getCustomClaimRole = async () => {
        if(this.auth.currentUser){
            await this.auth.currentUser.getIdToken(true);
            const decodedToken = await this.auth.currentUser.getIdTokenResult();
            return decodedToken.claims.stripeRole;
        }
      }

    GetCheckoutUrl = async (priceId) => {
        const session = await createCheckoutSession(this.payments, 
            {
                price: priceId,
                success_url: "http://localhost:3000/",
            }
        );
        window.location.assign(session.url);
    }

    UpgradePlan = async (type) => {
        if( type === "premium"){
            const priceId = "price_1NwQniLVqUcbhxrVBQXs4AXy"; 
            await this.GetCheckoutUrl(priceId);
        }else if( type === "profesional"){
            const priceId = "price_1NxAqrLVqUcbhxrVfhpEo1dM"; 
            await this.GetCheckoutUrl(priceId);
        }else if( type === "test"){
            const priceId = "price_1NxqRtLVqUcbhxrVeNKrBiFy"; 
            await this.GetCheckoutUrl(priceId);
        }
    }

    //END STRIPE / PAYMENTS / ROLES


    SignUp = async (email, password) => {
        return createUserWithEmailAndPassword(this.auth, email, password)
    }

    SendEmailVerification = async () => {
        return sendEmailVerification(this.auth.currentUser)
    }
    sendPasswordResetEmail = async (email) => {
        return sendPasswordResetEmail(this.auth, email);
    }

    SignIn = async (email, password, remember) => {
        const promise =  signInWithEmailAndPassword(this.auth, email, password)
        if (remember) {
            setPersistence(this.auth, browserLocalPersistence);
        } 
        return promise;
    }

    ListenAuthChanges = async ( login ) => {
        onAuthStateChanged(this.auth, async (user) => {
            if(user){
                const role = await this.getCustomClaimRole();
                const userData = {
                    name: user.email,
                    role: role ? role : ""
                }
                login(userData);
            }
        });
    }


    SignOut = async () => {
        return signOut(this.auth);
    }

    SignInWithToken = async (accessToken) => {
        return signInWithCustomToken(this.auth, accessToken)
    }

    SubscribeAuthChanges = async (setData) => {
        //TODO: Cambiar por enviar codigo y formulario en el front
        // ALTERNATIVA: Crear cloud function que actualice una varible en un doc privado para cada usuario que sea solo modificable por admin

        // Esto es un parche para que se actualice emailVerified
        // Los cambios de este flag no cuentan en onAuthStateChanged pero si en el token
        // La otra alternativa es enviarle un codigo y hacer el formulario para pedirlo  
        const onIdTokenChangedUnsubscribe = onIdTokenChanged(this.auth, (user) => {
            // TODO : ESTÁ MAL NO SE PUEDE QUEDAR ASI
            if (user && user.emailVerified) {
                const data = {
                    emailVerified: user.emailVerified,
                    uid: user.uid,
                    email: user.email,
                }
                setData(data)
                return onIdTokenChangedUnsubscribe(); //unsubscribe
            }
            // Parece que se van generando cada vez mas llamadas 
            // y solo para si verificas email 
            // Es provisional 
            setTimeout(() => {
                // this.auth.currentUser.reload();
                this.auth.currentUser.getIdToken(true);
            }, 10000);
        });
        return onAuthStateChanged(this.auth, (userAuth) => {
            if (userAuth !== null) {
                const data = {
                    emailVerified: userAuth.emailVerified,
                    uid: userAuth.uid,
                    email: userAuth.email,
                }
                setData(data)
            } else {
                setData(null)
            }
        })
    }

    // Gestión e interacción con firestore
    setUserInfo = async (data) => {
        console.log("VAMOS A ACTUALIZAR ", data);
        return setDoc(doc(this.db, "users", this.auth.currentUser.uid), data);
    }
    
    checkUsernameAvaiable = async (value) => {
        const usersRef = collection(this.db, 'users');
        const usernameQuery = query(usersRef, where('username', '==', value));
        const usernameSnapshot = await getDocs(usernameQuery);
        return usernameSnapshot.empty;
    }


    getUserInfo = async (setUser) => {
        return onSnapshot(doc(this.db, "users", this.auth.currentUser.uid), (doc) => {
            if (doc.exists()) {
                setUser(doc.data());
            } else {
                setUser(null);
            }
        });
    }

    getUserFromId = async (id, setUserObject) => {
        if(id){
            const snapshot = await getDoc(doc(this.db, "users", id));
    
            if (snapshot.exists()){
                setUserObject(snapshot.data())
            }
        }
    }

    // BODYS 
    createNewBody = async (data) => {        
        const new_body = { ...data, 
            programs: [], 
            creator: this.auth.currentUser.uid, 
            owner: this.auth.currentUser.uid,
        }
        return addDoc(collection(this.db, "bodys"), { ...new_body})
    }

    // Storage
    updateBodyImage = (id, file) => {
        // Esta función debe subir la imagen al bucket 
        // Si ha salido bien debe actualizar el campo avatarURL del documento del usuario de firestore
        const image_ref = 'bodys/' + id + ".jpg";
        const spaceRef = ref(this.storage, image_ref, { contentType: 'image/jpeg' });
        uploadBytes(spaceRef, file).then(() => {
            //Al generar la url probablemente hagamos público dicho documento 
            // y no podemos controlar su acceso mediante dicha url
            getDownloadURL(spaceRef).then((url) => {
                const ref = doc(this.db, 'bodys', id);
                updateDoc(ref, { avatarUrl: url });
            })
        })
    }

    getBodys = async (setData) => {
        const q = query(collection(this.db, "bodys"), where("creator", "==", this.auth.currentUser.uid));

        return onSnapshot(q, (querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push(doc);
            });
            const exercises = {};
            docs.map((doc) => {
                exercises[doc.id] = doc.data();
            })
            setData(exercises)
        })
    }

    modifyBody = async (id, data) => {
        const ref = doc(this.db, 'bodys', id);
        return updateDoc(ref, data);
    }

    deleteBody = async (id) => {
        const ref = doc(this.db, "bodys", id);
        return deleteDoc(ref);
    }

    addBodyProgram = async (id, idProgram) => {
        const ref = doc(this.db, 'bodys', id);
        return updateDoc(ref, { programs: arrayUnion({ "idProgram": idProgram, "creator": this.auth.currentUser.uid }) });
    }
    deleteBodyProgram = async (id, idProgram) => {
        const ref = doc(this.db, 'bodys', id);
        return updateDoc(ref, { programs: arrayRemove({ "idProgram": idProgram, "creator": this.auth.currentUser.uid }) });
    }
    addBodyMusclesRM = async (id, muscular_rm) => {
        const ref = doc(this.db, 'bodys', id);
        return updateDoc(ref, { muscular_rm: muscular_rm });
        
    }
    removeBodyProgram = async (id, idProgram) => {
        const ref = doc(this.db, 'bodys', id);
        return updateDoc(ref, { programs: arrayRemove(idProgram) });
    }
    updateBodyRm = async (id, muscular_rm) => {
        const ref = doc(this.db, 'bodys', id);
        return updateDoc(ref, { muscular_rm: muscular_rm });
    }

    updateMetInfo = async (id, dataMetInfo) => {
        // El percent progress hace referencia a los valores de 
        // % grasa, % osea, % masa muscular, masa total 
        // Están representados en un vector de objetos donde cada uno tiene
        const ref = doc(this.db, 'bodys', id);
        const docSnapshot = await getDoc(ref);
        
        if (docSnapshot.exists()) {
            const existingData = docSnapshot.data();
            const time = Timestamp.now().toMillis();
            console.log("EL TIEMPO : ", time);
            if (existingData.hasOwnProperty('bodyMetInfo')) {
                // Si 'bodyMetInfo' existe en el documento
                console.log("  si esta la variable ", existingData);
                const bodyMetInfo = existingData.bodyMetInfo;
    
                // Comprueba si hay al menos un objeto en el array
                if (bodyMetInfo.length > 0) {
                    // Obtén el timestamp del último objeto en el array
                    const lastTimestamp = bodyMetInfo[bodyMetInfo.length - 1].timestamp;
    
                    // Calcula la diferencia de tiempo en milisegundos
                    const timeDifference = time - lastTimestamp;
    
                    // Define la cantidad máxima de tiempo (en milisegundos) que se considera "menos de un día"
                    const maxTimeDifference = 24 * 60 * 60 * 1000; // Un día en milisegundos
    
                    if (timeDifference <= maxTimeDifference) {
                        // Si la diferencia es menor o igual a un día, reemplaza el último objeto
                        bodyMetInfo[bodyMetInfo.length - 1] = { ...dataMetInfo, timestamp: time };
                    } else {
                        // Si la diferencia es mayor a un día, agrega un nuevo objeto al array
                        bodyMetInfo.push({ ...dataMetInfo, timestamp: time });
                    }
                } else {
                    // Si el array está vacío, simplemente agrega el nuevo objeto
                    bodyMetInfo.push({ ...dataMetInfo, timestamp: time });
                }
    
                // Actualiza el documento con el array modificado
                return updateDoc(ref, {weight: dataMetInfo.mass, bodyMetInfo: bodyMetInfo });
            } else {
                console.log("  No esta la variable ", existingData);
                // Si 'bodyMetInfo' no existe en el documento, crea la variable con el nuevo objeto
                return updateDoc(ref, {
                    weight: dataMetInfo.mass,
                    bodyMetInfo: [{ ...dataMetInfo, timestamp: time }]
                });
            }
        }
    }

    // END BODY

    // MUSCLES GROUPS

    createMuscleGroup = async (data) => {
        console.log("Se crea muscle group" , data);
        
    }
    
    updateMuscleGroupWithReport = async (data) => {
        console.log("Se actualiza muscle group con maquina" , data);
        
    }
    updateMuscleGroupWithTrainer = async (data) => {
        console.log("Se actualiza con entrenamiento" , data);
        
    }
    
    updateMuscleGroupManual = async (data) => {
        console.log("Se actualiza manualmente" , data);
        
    }

    getMusclesGroups = async (setData) => {
        console.log("Se descargan muscleGroups");
        
    }

    // END MUSCLES GROUPS


    // TEAMS

    createTeam = async ( data ) => {

        return addDoc(collection(this.db, "/teams/"),
            {   
                // ¿Que forma tiene data? {name: "", bodys: ""};
                
                // lista simple de bodys
                ...data,
                creator: this.auth.currentUser.uid,
                timestamp: serverTimestamp()
            }
        )
    } 

    modifyTeam = async (id, data) => {
        const ref = doc(this.db, "/teams/" , id);        
        return setDoc(ref, data, {merge: true});
    }

    updateTeamImage = (id, file) => {
        // Esta función debe subir la imagen al bucket 
        // Si ha salido bien debe actualizar el campo avatarURL del documento del usuario de firestore
        const image_ref = 'teams/' + id + ".jpg";
        const spaceRef = ref(this.storage, image_ref, { contentType: 'image/jpeg' });
        uploadBytes(spaceRef, file).then(() => {
            //Al generar la url probablemente hagamos público dicho documento 
            // y no podemos controlar su acceso mediante dicha url
            getDownloadURL(spaceRef).then((url) => {
                const ref = doc(this.db, 'teams', id);
                updateDoc(ref, { avatarUrl: url });
            })
        })
    }

    deleteTeamImage = (id) =>{
        const pathReference = ref(this.storage, 'teams/' + id + ".jpg")
        deleteObject(pathReference).then(()=>{

        })
    }

    getTeams = async (setData) => {
        const q = query(collection(this.db, "teams"), where("creator", "==", this.auth.currentUser.uid));

        return onSnapshot(q, (querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push(doc);
            });
            const teams = {};
            docs.map((doc) => {
                teams[doc.id] = doc.data();
                if(teams[doc.id].timestamp){
                    teams[doc.id].timestamp = teams[doc.id].timestamp.seconds;
                    teams[doc.id].date = moment.unix(teams[doc.id].timestamp).format('DD/MM/YYYY');
                }
            })
            setData(teams)
        })
    }

    deleteTeam = async (id) => {
        const ref = doc(this.db, "teams", id);
        return deleteDoc(ref);
    }

    // END TEAMS

    // Exercises
    getExercises = async (setData) => {

        return onSnapshot(collection(this.db, this.auth.currentUser.uid + "/trainer/exercises"), (querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push(doc);
            });
            const exercises = {};
            docs.map((doc) => {
                exercises[doc.id] = doc.data();
            })
            setData(exercises)
        })
    }
    createNewExercise = async (data) => {
        // Crea un documento con un nuevo ejercicio
        // La ruta para un ejercicio es {user_uid}/trainer/exercises/{doc_id}
        // addDoc genera un id del documento aleatoriamente
        const { iniImage, interImage, endImage, ...rest } = data;
        return addDoc(collection(this.db, this.auth.currentUser.uid + "/trainer/exercises"), { ...rest}).then((doc) => {
            if(iniImage) firebase.updateExerciseImage(iniImage, "iniImage", doc.id) ;
            if(interImage)  firebase.updateExerciseImage(interImage, "interImage", doc.id);
            if(endImage) firebase.updateExerciseImage(endImage, "endImage", doc.id);
        })
    }
    
    modifyExercise = async (id, data) => {
        const { iniImage, interImage, endImage, ...rest } = data;
        const ref = doc(this.db, this.auth.currentUser.uid + "/trainer/exercises", id);        
        return setDoc(ref, rest, {merge: true}).then(()=> {
            if(iniImage === undefined && typeof iniImage !== "string" ){
                this.deleteExerciseImage("iniImage", ref.id);
                setDoc(ref, {"iniImage": deleteField()}, {merge: true});
            }else if(typeof iniImage === "object" ){
                this.updateExerciseImage(iniImage, "iniImage", ref.id) 
            } 
            if(interImage === undefined && typeof interImage !== "string"){
                this.deleteExerciseImage("interImage", ref.id);
                setDoc(ref, {"interImage": deleteField()}, {merge: true});
            }else if(typeof interImage === "object"   ){
                this.updateExerciseImage(interImage, "interImage", ref.id) 
            }
            if(endImage === undefined && typeof endImage !== "string"){
                this.deleteExerciseImage("endImage", ref.id);
                setDoc(ref, {"endImage": deleteField()}, {merge: true});
            }else if(typeof endImage === "object" ){
                this.updateExerciseImage(endImage, "endImage", ref.id) 
            }
        });
    } 

    deleteExercise = async (id) => {
        const ref = doc(this.db, this.auth.currentUser.uid + "/trainer/exercises", id);
        this.deleteExerciseImage("iniImage", id);
        this.deleteExerciseImage("interImage", id);
        this.deleteExerciseImage("endImage", id);
        return deleteDoc(ref);
    }


    // Methods
    createNewMethod = async (data) => {
        return addDoc(collection(this.db, this.auth.currentUser.uid + "/trainer/methods"), { ...data})
    }

    // this.deleteMethod = async (doc_id) => { // doc_id o referencia completa?
    //     return deleteDoc(doc(this.db, this.auth.currentUser.uid + "/trainer/methods" , doc_id));
    // }

    getMethods = async (setData) => {
        // const q = query(collection(this.db, this.auth.currentUser.uid+"/trainer/methods") , where("__name__", "in", list_of_ids ));

        return onSnapshot(collection(this.db, this.auth.currentUser.uid + "/trainer/methods"), (querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push(doc);
            });

            const methods = {};
            docs.map((doc) => {
                methods[doc.id] = doc.data();
            })
            setData(methods)
        })
    }

    modifyMethod = async (id, data) => {
        const ref = doc(this.db, this.auth.currentUser.uid + "/trainer/methods", id);
        return setDoc(ref, data);
    }

    deleteMethod = async (id) => {
        const ref = doc(this.db, this.auth.currentUser.uid + "/trainer/methods", id);
        return deleteDoc(ref);
    }

    // Sessions
    getSessions = async (setData) => {
        // const q = query(collection(this.db, this.auth.currentUser.uid+"/trainer/sessions") , where('__name__', 'in', list_of_ids ));
        return onSnapshot(collection(this.db, this.auth.currentUser.uid + "/trainer/sessions"), (querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push(doc)
            });
            const sessions = {}
            docs.map((doc) => {
                sessions[doc.id] = doc.data();
            })
            setData(sessions)
        })
    }
    createNewSession = async (data) => {
        return addDoc(collection(this.db, this.auth.currentUser.uid + "/trainer/sessions"), data)
    }
    modifySession = async (id, data) => {
        const ref = doc(this.db, this.auth.currentUser.uid + "/trainer/sessions", id);
        return setDoc(ref, { ...data} );
    } 
    deleteSession = async (id) => {
        const ref = doc(this.db, this.auth.currentUser.uid + "/trainer/sessions", id);
        return deleteDoc(ref);
    }


    // Programs
    getPrograms = async (setData) => {

        return onSnapshot(collection(this.db, this.auth.currentUser.uid + "/trainer/programs"), (querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push(doc)
            });
            const programs = {}
            docs.map((doc) => {
                programs[doc.id] = doc.data();
            })
            setData(programs);
        })
    }
    createNewProgram = async (data) => {
        return addDoc(collection(this.db, this.auth.currentUser.uid + "/trainer/programs"), data)
    }
    modifyProgram = async (id, data) => {
        const ref = doc(this.db, this.auth.currentUser.uid + "/trainer/programs", id);
        return setDoc(ref, { ...data} );
    } 
    deleteProgram = async (id) => {
        const ref = doc(this.db, this.auth.currentUser.uid + "/trainer/programs", id);
        return deleteDoc(ref);
    }

    // Gym -> Sessions executions
    getSessionExecutions = (bodyId ,setData) => {
        return onSnapshot(collection(this.db, "bodys/" + bodyId + "/executions"), (querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push(doc)
            });
            const executions = {}
            docs.map((doc) => {
                executions[doc.id] = doc.data();
                executions[doc.id].timestamp = executions[doc.id].timestamp.seconds;

            })
            setData( executions);
        })
    }
    
    createSessionExecution = (bodyId, data) => { 
        return addDoc(collection(this.db, "bodys/" + bodyId + "/executions"), {timestamp: serverTimestamp(),...data})
    } 
    
    // get reports of machine
    getReports = (bodyId, setData) => {
        return onSnapshot(collection(this.db, "bodys/" + bodyId + "/reports"), (querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push(doc);
            });
            const reports = {};
            docs.map((doc) => {
                // Para dar nombre
                var str = doc.data().downloadUrl.split(this.auth.currentUser.uid+"%2F").pop().split(".csv")[0];                
                reports[doc.id] = {
                    ...doc.data(),
                    bodyId: bodyId,
                    filename: str,
                    date: "",
                };
                if(reports[doc.id].timestamp){
                    reports[doc.id].timestamp = reports[doc.id].timestamp.seconds;
                    reports[doc.id].date = moment.unix(reports[doc.id].timestamp).local().format('DD/MM/YYYY - HH:mm:ss');
                }
            })
            setData(reports)
        })
    }

    modifyReport = (bodyId, reportId, newData) => {
        const ref = doc(this.db,"bodys/" + bodyId + "/reports", reportId);
        return updateDoc(ref, {...newData, updated: true});
    }

    deleteReport = async (bodyId, reportId, data) => {
        const { downloadUrl } = data;
        const refFile = ref(this.storage, downloadUrl);

        deleteObject(refFile).then(()=>{
            console.log("Eliminado archivo correctamente");
        }).catch(() =>{
            console.log("Error al eliminar archivo");
        })

        const refer = doc(this.db,"bodys/" + bodyId + "/reports", reportId);
        return deleteDoc(refer);
    }
    
    getDownloadUrlReport = (setUrl) => {
        const pathReference = ref(this.storage, 'reports/examples/02db.csv')
        return getDownloadURL(pathReference).then((downloadURL) => {
            console.log(downloadURL);
            setUrl(downloadURL);
        });
    }

    //MuscularReports
    createMuscularReport = (bodyId, data) => {
        return addDoc(collection(this.db, "complexreport" ), {creator: this.auth.currentUser.uid, ...data, timestamp: serverTimestamp(), })

    }


    // complexReport
    createComplexReport = (data) => {
        return addDoc(collection(this.db, "complexreport" ), {creator: this.auth.currentUser.uid, ...data, timestamp: serverTimestamp(), })
    }

    modifyComplexReport = (id, data) => {
        const ref = doc(this.db,"complexreport", id);
        return setDoc(ref, {timestamp: serverTimestamp(), ...data})
    }

    getComplexReports = ( setData ) => {
        const q = query(collection(this.db, "complexreport"), where("creator", "==", this.auth.currentUser.uid));

        return onSnapshot(q, (querySnapshot) => {
            
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push(doc)
            });
            const complexreports = {}
            
            docs.map((doc) => {
                complexreports[doc.id] = { ...doc.data(), date: ""};
                if(complexreports[doc.id].timestamp){
                    complexreports[doc.id].timestamp = complexreports[doc.id].timestamp.seconds;
                    complexreports[doc.id].date = moment.unix(complexreports[doc.id].timestamp).format('DD/MM/YYYY');
                }
            })
            setData( complexreports);
        })
    }
    
    deleteComplexReport = (id) => {
        const ref = doc(this.db,"complexreport/", id);
        return deleteDoc(ref);
    }

    // Storage
    updateUserImage = (file) => {
        // Esta función debe subir la imagen al bucket 
        // Si ha salido bien debe actualizar el campo avatarURL del documento del usuario de firestore
        // Deberiamos coger la foto de una url o directamente del bucket...
        const image_ref = 'images/' + this.auth.currentUser.uid + ".jpg";
        const spaceRef = ref(this.storage, image_ref, { contentType: 'image/jpeg' });
        uploadBytes(spaceRef, file).then(() => {
            //Al generar la url probablemente hagamos público dicho documento 
            // y no podemos controlar su acceso mediante dicha url
            getDownloadURL(spaceRef).then((url) => {
                const userRef = doc(this.db, 'users', this.auth.currentUser.uid);
                updateDoc(userRef, { avatarUrl: url });
            })
        })
    }
    
    deleteExerciseImage = (varName, exerciseId) =>{
        const pathReference = ref(this.storage, 'images/' + varName + exerciseId + ".jpg")
        getDownloadURL(pathReference).then((url) => {
            return deleteObject(pathReference).then(()=>{
            }).catch((err) => {
            });
        }, error => {
        })
    }
    //file será la imagen a subir
    //varName contendrá un string que nos indicará si queremos subir la foto a la inicial, intermedia o final
    //exerciseId contendrá el identificador de ejercicio para saber dónde subir la imagen
    updateExerciseImage = (file, varName, exerciseId) => {
        const pathReference = ref(this.storage, 'exercises/' + varName + exerciseId + ".jpg")
        
        const metadata = {
            contentType: 'image/jpeg'
        };
        const uploadTask = uploadBytesResumable(pathReference, file, metadata);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            //console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case 'paused':
                //console.log('Upload is paused');
                break;
                case 'running':
                //console.log('Upload is running');
                break;
            }
        }, 
        (error) => {
            // Handle unsuccessful uploads
            switch (error.code) {
                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    //console.log("error storage unauthorized:", error.code);
                    break;
                case 'storage/canceled':
                    // User canceled the upload
                    //console.log("error storage canceled:", error.code);
                    break;
          
                case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    //console.log("error storage unknown:", error.code);
                    break
                default:
                    // Unknown error
                    //console.log("Error unknown:", error.code);
                    break
            }
        }, 
        () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            //console.log("uploadtaskref: ", uploadTask);
            getDownloadURL(pathReference).then((downloadURL) => {
                const exerciseRef = doc(this.db, this.auth.currentUser.uid + '/trainer/exercises', exerciseId);
                //Dependiendo de si es inicial, intermedia o final, lo subiremos a un campo u otro
                if (varName === "iniImage") {
                    updateDoc(exerciseRef, { iniImage: downloadURL });
                } else if (varName === "interImage") {
                    updateDoc(exerciseRef, { interImage: downloadURL });
                } else if (varName === "endImage"){
                    updateDoc(exerciseRef, { endImage: downloadURL });
                }
            });
        }
        );

        /*const image_ref = 'images/' + varName + exerciseId + ".jpg";
        const spaceRef = ref(this.storage, image_ref, { contentType: 'image/jpeg' });

        uploadBytes(spaceRef, file).then(() => {
            //Al generar la url probablemente hagamos público dicho documento 
            // y no podemos controlar su acceso mediante dicha url
            getDownloadURL(spaceRef).then((url) => {
                const exerciseRef = doc(this.db, this.auth.currentUser.uid + '/trainer/exercises', exerciseId);
                //Dependiendo de si es inicial, intermedia o final, lo subiremos a un campo u otro
                if (varName === "iniImage") {
                    updateDoc(exerciseRef, { iniImage: url });
                } else if (varName === "interImage") {
                    updateDoc(exerciseRef, { interImage: url });
                } else if (varName === "endImage"){
                    updateDoc(exerciseRef, { endImage: url });
                }
            })
        })*/
    }

    createFeedback = async (data) => {
        return addDoc(collection(this.db, "feedback"), {...data, creator: this.auth.currentUser.uid, timestamp: serverTimestamp()});
    }


    // Program Execution
    createExecution = async (data) => {
        // addDoc genera un id del documento aleatoriamente
        /* Esto necesita:
        * body Id: ---- ,
        * Program Id: ----, 
        * sessionsList : [
        *   {
        *       sessionId: ----,
        *       steps: [
        *           { completed: --,
        *             timestamp: --,
        *                
        *           }    
        *       ]
        *    }
        * ]
        */
        return addDoc(collection(this.db, "gym/"), data)
    }

    updateExecution = async (data) => {
        // addDoc genera un id del documento aleatoriamente
        return addDoc(collection(this.db, "bodys/"), data)
    }

}

const firebase = new Firebase();
export default firebase;