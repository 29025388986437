import React, { useState, useEffect } from 'react';
import { useContext } from "react";
import { FirebaseContext } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import BilateralData from './BilateralData';
import DonutChart from './charts/DonutChart';
import { useFormik } from 'formik';
import reportSectionData from '../../constants/reportSectionData';
import SchemaGenerator from '../../schemas/SchemaGenerator';
import { FormSelector } from '../utils/FormCreator';


const CreateBilateralIsoReport = ({ bodyId, reportId, data, onSubmit }) => {
    const report = useSelector((state) => state.trainer.reports[bodyId][reportId]);
    const body = useSelector((state) => state.trainer.bodys[bodyId]);

    const [loading, setLoading] = useState(true);
    const [right, setRight] = useState(data.right ??= undefined);
    const [left, setLeft] = useState(data.left ??= undefined);
    const [bilateralData, setBilateralData] = useState(data.bilateralData ??= undefined);

    const validationSchema = SchemaGenerator(reportSectionData);

    const formik = useFormik({
        initialValues: data ? { name: data.name ??= "", observations: data.observations ??= "" } : { name: "", observations: "" },
        validationSchema: validationSchema,
        onSubmit: (newData) => {
            handleFinish(newData);
        }
    })

    const handleFinish = (newData) => {
        if (right && left && bilateralData && onSubmit) {
            onSubmit(
                { ...data, ...newData, bodyId: bodyId, reportId: reportId, right: right, left: left, bilateralData: bilateralData }
            );
        }

    }

    useEffect(() => {
        if (report.series_resume && !data.right) {
            let leftSide, rightSide;
            for (const key in report.series_resume) {
                if (report.series_resume.hasOwnProperty(key)) {
                    const serie = report.series_resume[key];
                    if (serie.side === "left") {
                        leftSide = serie;
                    } else if (serie.side === "right") {
                        rightSide = serie;
                    }
                }
            }
            if (leftSide && rightSide) {
                setLeft(leftSide);
                setRight(rightSide);
                const bilateralObject = new BilateralData(leftSide, rightSide);
                setBilateralData(bilateralObject.toJSON());
            }
            setLoading(false);
        } else if (data.right && data.left) {
            setLeft(data.left);
            setRight(data.right);
            setLoading(false);

        }
    }, [report])

    useEffect(() => {
        if (right && left) {
            const bilateralObject = new BilateralData(left, right);
            setBilateralData(bilateralObject.toJSON());
        }
    }, [right, left])

    return (
        <Paper elevation={6} sx={{ p: 2, m: 1 }}>
            {report && <Grid container>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                        {FormSelector(reportSectionData[0], formik)}
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => formik.handleSubmit()} > Aceptar</Button>
                    </Grid>
                    <Grid item xs={6}>
                        {FormSelector(reportSectionData[1], formik)}
                    </Grid>

                </Grid>
            </Grid>}
            {loading && !bilateralData ?
                <Grid container>
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                </Grid>
                :
                <Grid container spacing={2}>

                    <Grid container>
                        <Grid container item alignContent="center" xs={6}>
                            <TableContainer >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>Izquierdo</TableCell>
                                            <TableCell>Derecho</TableCell>
                                            <TableCell>Totales</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell variant='head'>Promedio (f_average)</TableCell>
                                            <TableCell>{bilateralData.left_f_average}</TableCell>
                                            <TableCell>{bilateralData.right_f_average}</TableCell>
                                            <TableCell>{bilateralData.f_average_total}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant='head'>Máximo (f_max)</TableCell>
                                            <TableCell>{bilateralData.left_f_max}</TableCell>
                                            <TableCell>{bilateralData.right_f_max}</TableCell>
                                            <TableCell>{bilateralData.f_max_total}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant='head'>Impulso (inertia)</TableCell>
                                            <TableCell>{bilateralData.left_impulse}</TableCell>
                                            <TableCell>{bilateralData.right_impulse}</TableCell>
                                            <TableCell>{bilateralData.impulse_total}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant='head'>Tiempo (time)</TableCell>
                                            <TableCell>{bilateralData.left_time}</TableCell>
                                            <TableCell>{bilateralData.right_time}</TableCell>
                                            <TableCell>{bilateralData.time_total}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant='head'>Tiempo pico (time_to_peak)</TableCell>
                                            <TableCell>{bilateralData.left_time_peak}</TableCell>
                                            <TableCell>{bilateralData.right_time_peak}</TableCell>
                                            <TableCell>{bilateralData.time_peak_total}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item container alignContent="center" justifyContent="center" xs={6}>
                            <DonutChart bilateralData={bilateralData} type="force" />
                        </Grid>
                        <Grid item container alignContent="center" justifyContent="center" xs={6}>
                            <DonutChart bilateralData={bilateralData} type="time" />
                        </Grid>
                        <Grid item container alignContent="center" justifyContent="center" xs={6}>
                            <DonutChart bilateralData={bilateralData} type="impulse" />
                        </Grid>
                    </Grid>

                </Grid>
            }
        </Paper>
    );
}

export default CreateBilateralIsoReport;