import { Button, ButtonGroup, Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SessionComponent from '../sessions/SessionComponent';

const MicrocicleComponent = ({ microCicle, microcicleSummary }) => {

    const { sessions } = useSelector((state) => state.trainer);
    const days = ["L", "M", "X", "J", "V", "S", "D"]
    const [daySelected, setDaySelected] = useState();

    return (
        <Box sx={{ p: 2 }}>
            <ButtonGroup sx={{ mx: 5, mb: 4 }} >

                {days.map((day) => {
                    const session_id = microCicle[day];
                    return (session_id ?
                        <Button key={day} size='large' variant={day === daySelected ? "contained" : "outlined"} onClick={() => { setDaySelected(day) }}>{day}</Button>
                        :
                        <Button key={day} size='large' disabled>{day}</Button>
                    )

                })}
            </ButtonGroup>

            <Grid container>
                {microCicle && daySelected && sessions[microCicle[daySelected]] ?

                    <SessionComponent key={daySelected} session={sessions[microCicle[daySelected]]} sessionSummary={microcicleSummary[daySelected]} day={daySelected} />

                    : <></>
                }
            </Grid>
        </Box>

    );
}

export default MicrocicleComponent;