const feedbackData = [
    {
        name: 'Titulo',
        varName: 'title',
        formType: 'textField',
        type: 'string',
        required: true,
        unit: '',
        //Range
        minValue: 4,
        maxValue: 20,
        defaultValue: '',
        description: 'Titulo del comentario'
    },
    {
        name: 'Tipo de comentario',
        varName: 'commentType',
        formType: 'select',
        type: 'string',
        optionList: {
            error: "Error",
            suggestion: "Sugerencia",
        },
        required: true,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 100,
        defaultValue: "suggestion",
        description: 'Tipo de comentario que se va a realizar; Bug, sugerencia...'
    },
    {
        name: 'Comentario',
        varName: 'comment',
        formType: 'textFieldMultiline',
        type: 'string',
        required: true,
        unit: '',
        //Range
        minValue: 4,
        maxValue: 300,
        defaultValue: '',
        description: 'Texto del comentario'
    },
]

export default feedbackData