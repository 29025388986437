import { ContentCopy, Delete, Edit } from "@mui/icons-material";
import { Dialog, Grid, IconButton, List, Paper, Typography } from "@mui/material";
import { Fragment, useContext } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { FirebaseContext } from "../../../firebase";
import CreateSession from "./CreateSession";
import ConfirmationDialog from "../../utils/ConfirmationDialog";
import SessionColumnView from "./SessionColumnView";
import TooltipComponent from "../../utils/TooltipComponent";

const SessionSelector = () => {
    const { firebase } = useContext(FirebaseContext)
    const { updatedSessions, sessions, programs } = useSelector((state) => state.trainer)
    const [dialogSession, setDialogSession] = useState(false);
    const [sessionId, setSessionId] = useState(undefined);
    const [copy, setCopy] = useState(false);

    const handleModify = (id) => {
        setSessionId(id);
        setDialogSession(true);
    }
    const handleCopy = (id) => {
        setSessionId(id);
        setCopy(true);
        setDialogSession(true);
    }

    const afectedPrograms = (id) => {
        const afectedReturn = Object.keys(programs).map((programId) => {
            const currentSessions = programs[programId].sessions;
            for (var i in currentSessions) {
                for (var j in currentSessions[i]) {
                    if (currentSessions[i][j] === id) {
                        return programId
                    }
                }
            }
            return undefined;
        }).filter((e) => e !== undefined);

        const programList = afectedReturn.map((pid) => programs[pid].name);
        console.log("PL: ", programList.length);
        if (programList.length > 0) {
            return ("Se modificarán los siguientes programas: " + afectedReturn.map((pid) => programs[pid].name));
        }
        else {
            return "";
        }
    }

    // FUNCION IMPORTANTE:
    // Comprueba todos los programas para ver si tienen la sesion que se quiere eliminar
    // Si la tienen se les elimina la referencia 
    // Cuando termina este proceso se elimina la sesion de firestore
    const handleDelete = (id) => {
        const afectedPrograms = Object.keys(programs).map((programId) => {
            const currentSessions = programs[programId].sessions;
            for (var i in currentSessions) {
                for (var j in currentSessions[i]) {
                    if (currentSessions[i][j] === id) {
                        return programId
                    }
                }
            }
            return undefined;
        }).filter((e) => e !== undefined);

        for (var i in afectedPrograms) {
            let microcicles = [...programs[afectedPrograms[i]].sessions];
            for (var mc in microcicles) {
                let newMc = { ...microcicles[mc] };
                for (var day in microcicles[mc]) {
                    if (microcicles[mc][day] === id) {
                        delete newMc[day];
                    }
                }
                microcicles[mc] = newMc;
            }
            firebase.modifyProgram(afectedPrograms[i], { ...programs[afectedPrograms[i]], sessions: microcicles })
        }
        firebase.deleteSession(id);
    }

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [sessionKeyDelete, setSessionKeyDelete] = useState("");

    return (
        updatedSessions ?
            <List>
                {sessionId ?
                    <Dialog maxWidth="md" open={dialogSession} onClose={() => { setDialogSession(false) }} fullWidth scroll={'paper'}>
                        <CreateSession sessionId={sessionId} copy={copy} onSubmit={() => { setDialogSession(false); setCopy(false) }} />
                    </Dialog> : <></>
                }
                {openConfirmation === true ?
                    <ConfirmationDialog message={"¿Seguro que quiere borrar la sesión? " + afectedPrograms(sessionKeyDelete)} action={() => handleDelete(sessionKeyDelete)} open={openConfirmation} setOpen={setOpenConfirmation} />
                    :
                    <></>
                }
                {Object.keys(sessions).map((key) =>
                    <Paper key={key} elevation={3} sx={{ m: 1, p: 1 }} >

                        <Grid container alignContent="center" justifyContent="space-between">
                            <Grid item alignContent="center">
                                <TooltipComponent arrow placement="left" style={{ popper: { maxWidth: 'none' } }} title={<Fragment><SessionColumnView sessionId={key} /></Fragment>}>
                                    <Typography > {sessions[key].name} </Typography>
                                </TooltipComponent>
                            </Grid>
                            <Grid item justifyContent="right">
                                <IconButton size="small" onClick={() => { setOpenConfirmation(true); setSessionKeyDelete(key) }}>
                                    <Delete />
                                </IconButton>
                                <IconButton size="small" onClick={() => { handleCopy(key) }}>
                                    < ContentCopy />
                                </IconButton>
                                <IconButton size="small" onClick={() => { handleModify(key) }}>
                                    <Edit />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Paper>
                )}
            </List>
            :
            <></>
    );
}

export default SessionSelector;