import NotFound from '../../components/utils/NotFound'

const NotFoundView = () => {
  return (
    <div>
      <NotFound/>
    </div>
  )
}

export default NotFoundView