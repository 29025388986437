import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import style from "./MuscleCard.module.scss";
import { KeyboardArrowDown } from '@mui/icons-material';
import MuscleGroups from '../../constants/MuscleGroups';

const MuscleBox = ({muscle_key}) => {

    const [extended, setExtended] = useState(false);
    const name = MuscleGroups[muscle_key];

    try {
        const imagePath = require(`../../assets/muscleIcons/${muscle_key}.png`);
        return (
            <Grid container justifyContent="center">
                <Grid item container xs={12} justifyContent="center" >
                    <img src={imagePath} alt="Imagen PNG" />
                </Grid>
                <Grid item container xs={12} justifyContent="center" >
                    <Typography>{name}</Typography>
                </Grid>
            </Grid>
        );
        
    } catch (error) {
        return (<></>);
    }

}
 
export default MuscleBox;