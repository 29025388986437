import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import texts from '../../constants/texts/texts';

const ConfirmationDialog = ({ message, action, open, setOpen }) => {

    return (
        <Dialog open={open} maxWidth="sm" onClose={() => setOpen(false)} fullWidth>
            <DialogTitle>{texts.confirmDialogMessage}</DialogTitle>
            <Box position="absolute" top={0} right={0}>
                <IconButton onClick={() => setOpen(false)}>
                    <Close />
                </IconButton>
            </Box>
            <DialogContent>
                <Typography>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button color="error" variant="contained" onClick={() => setOpen(false)}>
                    {texts.cancel}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => { action(); setOpen(false) }}
                >
                    {texts.confirm}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;