import React, { useState } from 'react';
import { IconButton, Typography, ImageListItem, ImageListItemBar, useTheme, Paper, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import texts from '../../constants/texts/es'
import BodySelector from '../bodys/BodySelector';
import BodyButton from '../bodys/BodyButton';
import SessionSelector from './SessionSelector';
import { useEffect } from 'react';

const Gym = () => {
    const currentBody = useSelector((state) => state.trainer.bodys[state.trainer.currentBodyId])
    const { updatedPrograms, updatedBodys, programs, currentBodyId } = useSelector((state) => state.trainer);

    const [programSelected, setProgramSelected] = useState(undefined);
    const [sessionSelected, setSessionSelected] = useState(undefined);


    const theme = useTheme();
    const [exeSummary, setExeSummary] = useState(false);

    const handleSelectProgram = (key) => {
        setProgramSelected(key);
        setSessionSelected(undefined);
        setExeSummary(false);
    }

    useEffect(() => {
        setProgramSelected(undefined);
        setSessionSelected(undefined);
    }, [currentBody])
    return (
        updatedPrograms && updatedBodys ?
            <div>
                <Grid container >
                    <Grid item sx={{ p: 3 }} xs={12} sm={4}>
                        <BodySelector />
                        <BodyButton bodyId={currentBodyId} trainerView />
                    </Grid>
                    <Grid item xs={12} sm={8} sx={{ p: 3 }}>
                        <Typography variant="h5">{texts.userPrograms}</Typography>
                        <div style={{ width: "100%", overflow: "auto", display: "inline-flex" }}  >
                            {currentBody.programs.map((item) => {
                                return (programs[item.idProgram] ?
                                    <ImageListItem key={item.idProgram}>
                                        <Paper
                                            key={item.idProgram}
                                            elevation={programSelected === item.idProgram ? 6 : 1}
                                            onClick={() => { programSelected === item.idProgram ? handleSelectProgram(undefined) : handleSelectProgram(item.idProgram) }}
                                            sx={{ border: 0.5, borderRadius: 2, m: 1 }}>
                                            <IconButton disableRipple disableFocusRipple sx={{ width: 1, '&:hover': { backgroundColor: 'transparent' } }} >
                                                <img
                                                    src={theme.palette.mode === "light" ?
                                                        require("../../assets/LogoWhiteTheme.png")
                                                        :
                                                        require("../../assets/LogoDarkTheme.png")
                                                    }
                                                    alt={programs[item.idProgram].name}
                                                    style={{ maxWidth: 200 }}
                                                    loading="lazy"
                                                />
                                            </IconButton>
                                            <ImageListItemBar
                                                sx={{ ml: 1 }}
                                                title={programs[item.idProgram].name}
                                                position="below"
                                            />
                                        </Paper>
                                    </ImageListItem>
                                    :
                                    <></>
                                )
                            })}
                        </div>
                    </Grid>

                </Grid>

                {programSelected ?
                    <SessionSelector key={programSelected} sessionSel={sessionSelected} programId={programSelected} setSessionSel={setSessionSelected} executionSummary={exeSummary} setExecutionSummary={setExeSummary} />
                    :
                    <></>
                }
            </div>
            :
            <Typography>{texts.programsUnavailable}</Typography>
    );
}


export default Gym