import { Grid, Paper, Typography } from '@mui/material';
import texts from '../../../constants/texts/texts';
import VerticalBarChart from '../../utils/VerticalBarChart';


const SessionSummaryComponent = ({ sessionSummary }) => {

    return (
        <Paper sx={{ m: 1, p: 2 }} elevation={3}>
            <Grid container>
                <Grid item xs={6}>
                    {sessionSummary['MuscularResume'] ?
                        <VerticalBarChart muscularResume={sessionSummary['MuscularResume']} />
                        : <></>}
                </Grid>
                <Grid container item xs={6} spacing={0} sx={{ maxHeight: 200 }}>
                    <Grid item xs={12}>
                        <Typography> Resumen de la sesión </Typography>
                    </Grid>

                    {Object.keys(sessionSummary).map((key, index) =>
                        !["MuscularResume", "MuscularCounter"].includes(key) ?
                            <Grid item key={key + index} xs={6} sx={{ boder: 1 }} justifyContent="center">
                                {/* <Divider></Divider> */}
                                {/* <Chip key={key} label={key + ":" + sessionSummary[key]} /> */}
                                <Typography fontSize={12} sx={{ my: 0 }} variant="subtitle2" >{texts[key] + ":"} {Math.round(sessionSummary[key] * 100) / 100}</Typography>
                                {/* {index % 2 === 0 ? <Divider orientation="vertical"></Divider> : <></>} */}
                            </Grid>

                            : <></>
                    )}
                </Grid>
            </Grid>
        </Paper >
    );
}

export default SessionSummaryComponent;