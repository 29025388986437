import React, { useContext, useState, useRef } from "react";
import { FirebaseContext } from "../../firebase";
import { Button, Grid, IconButton, Dialog, Badge } from "@mui/material";
import { Edit } from "@mui/icons-material";
import texts from "../../constants/texts/texts";
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const UploadImage = (props) => {
    const { firebase } = useContext(FirebaseContext)
    const [dialogActive, setDialogActive] = useState(false);
    const [imageUpload, setImageUpload] = useState(null);
    const [cropper, setCropper] = useState(undefined);

    const uploadFile = () => {
        if (cropper) {
            cropper.getCroppedCanvas({
                maxWidth: 4096,
                maxHeight: 4096,
                imageSmoothingEnabled: false,
                imageSmoothingQuality: 'medium',
            }).toBlob((blob) => {
                firebase.updateUserImage(blob);
            }, 'image/jpeg', 1);
        }
        setDialogActive(false);
        setImageUpload(undefined);
    }

    const handleSelectImage = (event) => {
        event.preventDefault();
        const reader = new FileReader();
        reader.onload = () => {
            setImageUpload(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
        setDialogActive(true);
    }

    const uploadInputRef = useRef(null);

    return (
        <div className="UploadImage">
            <Grid container justifyContent="center" spacing={3}>
                <Grid item>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <IconButton onClick={() => { uploadInputRef.current.click() }} style={{ backgroundColor: "#D0D0D0" }}>
                                <input
                                    type="file"
                                    id="icon_button"
                                    ref={uploadInputRef}
                                    hidden
                                    accept="image/*"
                                    capture="user"
                                    onChange={handleSelectImage}
                                    onClick={(event) => { event.target.value = '' }}
                                />
                                <Edit color="dark" />
                            </IconButton>
                        }
                    >
                        {props.children}
                    </Badge>
                </Grid>

            </Grid>
            <Dialog open={dialogActive} onClose={() => { setDialogActive(false); }}>
                <Grid container>
                    <Cropper
                        id="cropper"
                        style={{ height: 400, width: "100%" }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        aspectRatio={1}
                        preview=".img-preview"
                        src={imageUpload}
                        viewMode={1}
                        dragMode="move"
                        toggleDragModeOnDblclick={false}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        guides={true}
                    />
                    <Grid item>
                        <Button
                            variant="outlined"
                            component="label"
                            onClick={() => {
                                setDialogActive(false);
                                setImageUpload(undefined);
                            }}
                            color="primary"
                        >
                            {texts.cancel}
                        </Button>
                    </Grid>
                    <Grid item>
                        <IconButton aria-label="delete" onClick={() => {
                            cropper.rotate(90);
                        }}>
                            <Rotate90DegreesCcwIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={uploadFile}
                            variant="contained"
                        >
                            {texts.uploadImage}
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
}

export default UploadImage;