import OptionLists from "./OptionLists"
const exercise = [
    {
        name: 'Nombre',
        varName: 'name',
        formType: 'textField',
        type: 'string',
        required: true,
        unit: '',
        //Range
        minValue: 1,
        maxValue: 100,
        defaultValue: '',
        description: 'Nombre dado al ejercicio, existen muchas formas de nombrar los ejercicios, lo mejor es que el nombre defina qué se hace. Ejem. Remo sentado con agarre estrecho'
    },
    {
        name: 'Breve Descripción',
        varName: 'short_description',
        formType: 'textField',
        type: 'string',
        required: false,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 300,
        defaultValue: '',
        description: 'Breve descripción del ejercicio'
    },
    {
        name: 'Puntos importantes',
        varName: 'important_points',
        formType: 'textField',
        type: 'string',
        required: false,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 200,
        defaultValue: '',
        description: 'Destacar los puntos más importantes a tener en cuenta durante la ejecución'
    },
    {
        name: 'Imagen Inicial',
        varName: 'iniImage',
        formType: 'image',
        type: 'caca',
        required: false,
        unit: '',
        //Range
        minValue: '',
        maxValue: '',
        defaultValue: undefined,
        description: 'Imagen de la posición inicial de un ejercicio'
    },
    {
        name: 'Imagen Intermedia',
        varName: 'interImage',
        formType: 'image',
        type: 'caca',
        required: false,
        unit: '',
        //Range
        minValue: '',
        maxValue: '',
        defaultValue: undefined,
        description: 'Imagen de la posición intermedia de un ejercicio'
    },
    {
        name: 'Imagen Final',
        varName: 'endImage',
        formType: 'image',
        type: 'caca',
        required: false,
        unit: '',
        //Range
        minValue: '',
        maxValue: '',
        defaultValue: undefined,
        description: 'Imagen de la posición final de un ejercicio'
    },
    // {
    //     name: 'Video',
    //     varName: 'video',
    //     formType: 'auto',
    //     type: '',
    //     required: false,
    //     unit: '',
    //     //Range
    //     minValue: '',
    //     maxValue: '',
    //     description: 'Video corto del ejercicio'
    // },
    {
        name: 'Grupo muscular principal',
        varName: 'main_muscular_group',
        formType: 'select',
        optionList: OptionLists.muscular_group,
        type: 'string',
        required: true,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 200,
        defaultValue: "pectoral",
        description: 'Grupo muscular principal que trabaja'
    },
    {
        name: 'Grupos musculares secundarios',
        varName: 'muscular_group',
        formType: 'multiSelect',
        optionList: OptionLists.muscular_group,
        type: 'array',
        required: true,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 200,
        defaultValue: [],
        description: 'Grupo muscular principal que trabaja'
    },
    {
        name: 'Articulación',
        varName: 'joint',
        formType: 'select',
        optionList: OptionLists.joint,
        type: 'string',
        required: false,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 200,
        defaultValue: '',
        description: 'Articulación o articulaciones que intervienen'
    },
    {
        name: 'Movimiento Articular',
        varName: 'joint_movement',
        formType: 'select',
        optionList: OptionLists.joint_movement,
        type: 'string',
        required: false,
        //Range
        minValue: 0,
        maxValue: 200,
        defaultValue: '',
        description: 'Movimiento del segmento con respecto a la posición anatómica general'
    },
    {
        name: 'Cadena de movimiento',
        varName: 'move_chain',
        formType: 'select',
        optionList: OptionLists.move_chain,
        type: 'string',
        required: false,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 200,
        defaultValue: '',
        description: 'Predominancia o dominancia de un gesto sobre una articulación'
    },
    {
        name: 'Aplicación',
        varName: 'aplication',
        formType: 'select',
        optionList: OptionLists.aplication,
        type: 'string',
        required: false,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 200,
        defaultValue: '',
        description: 'Indica a qué acción o gesto está orientado; a qué gesto deportivo o actividad de la vida diaria ayuda'
    },
    {
        name: 'Coeficiente de Intensidad',
        varName: 'position',
        formType: 'slider',
        type: 'number',
        required: false,
        unit: '/1',
        //Range
        step: 0.1,
        minValue: 0,
        maxValue: 1,
        defaultValue: 0.5,
        description: 'Es un factor corrector (un valor calculado o estimado) que se aplica cuando no se tiene un valor de fuerza definido tras la realización de un test máximo; se calcula multiplicando el peso corporal por un índice o valor que va entre 0 a 3'
    },
    {
        name: 'Plano anatómico/Posición',
        varName: 'anatomic_plane',
        formType: 'select',
        optionList: OptionLists.anatomic_plane,
        type: 'string',
        required: false,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 200,
        defaultValue: '',
        description: 'Plano anatómico en el que se realiza el gesto, en la mayoría de los casos se pasa por más de un plano, pero se indica cuál predomina/también se puede indicar la posición en la que se ejecuta el gesto o tarea'
    },
    {
        name: 'Beneficios',
        varName: 'exercise_benefits',
        formType: 'textField',
        type: 'string',
        required: false,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 200,
        defaultValue: '',
        description: 'Aspectos que se quieren trabajar con el ejercicio, qué se desea mejorar o alcanzar'
    },
];

export default exercise