import React from 'react'
import FormUserInfo from '../../components/user/FormUserInfo'
import { useSelector } from 'react-redux'

const ModifyProfileView = () => {
    const {user} = useSelector((state) => state.user);

    return ( <FormUserInfo userData={user}/> );
}

export default ModifyProfileView;