import { Grid } from "@mui/material";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";

const BarsBilateralComparativeChart = ({ bilateralData }) => {
    const data = {
        categories: ['F Average (N)', 'F Max (N)', 'Impulse (N·s)', 'Time (cseg)', 'Time to Peak (cseg)'],
        leftValues: [
            bilateralData.left_f_average,
            bilateralData.left_f_max,
            bilateralData.left_impulse,
            bilateralData.left_time * 100,
            bilateralData.left_time_peak * 100,
        ],
        rightValues: [
            bilateralData.right_f_average,
            bilateralData.right_f_max,
            bilateralData.right_impulse,
            bilateralData.right_time * 100,
            bilateralData.right_time_peak * 100,
        ],
    };

    const newData = data.categories.map((cat, i) => {
        return {
            name: cat,
            data: [data.leftValues[i], data.rightValues[i]]
        }

    })

    
    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: '',
            // text: 'Comparativa general',
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: data.categories,
        },
        yAxis: {
            title: {
                text: 'Value',
            },
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
            shadow: false
        },
        tooltip: {
            shared: true
        },
        plotOptions: {
            column: {
                grouping: true,
                shadow: true,
                borderWidth: 0
            }
        },
        series: [
            {
                name: 'Izquierda',
                data: data.leftValues,
            },
            {
                name: 'Derecha',
                data: data.rightValues,
            },
        ],
    };

    return (
        <Grid container>
                <Grid item xs={12}>
                    {options && <HighchartsReact highcharts={Highcharts} options={options} />}
                </Grid>
        </Grid>
    );
}

export default BarsBilateralComparativeChart;