import React, { useState, useEffect } from 'react';
import { Button, Dialog, Grid, IconButton, Typography } from "@mui/material";
import MethodSelector from "../../components/trainer/methods/MethodSelector";
import { Add} from "@mui/icons-material";
import CreateMethod from "../../components/trainer/methods/CreateMethod";



// Components
import MainTopBar from "../../components/elements/MainTopBar";
import HeaderTop from "../../components/elements/HeaderTop";

// Styles
import "../../styles/global.scss";


const MethodsView = () => {    
    const [dialogMethod, setDialogMethod] = useState(false);

    return (
    
    <div>
        
        <MainTopBar
            teamSelector
            bodySelector
            headComponent={
                <HeaderTop
                    title="Métodos"
                    subtitle="Listado de Métodos"
                    buttonActionFunction = {setDialogMethod}
                    buttonText = "Añadir método"
                    filters= {true}
                />
            }
        />
    
        <Grid container >

            <Dialog open={dialogMethod} onClose={() => { setDialogMethod(false) }} fullWidth scroll={'paper'}>
                <CreateMethod/>
            </Dialog>

            <Grid item xs={12}>
                <MethodSelector/>
            </Grid>
        </Grid>

    </div>
    
    );
}
 
export default MethodsView;