import React, { useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import SubscriptionPlans from '../user/SubscriptionPlans';
import SubscriptionChip from '../user/SubscriptionChip';
import { useSelector } from 'react-redux';
import CreateTeamComponent from '../teams/CreateTeamComponent';

const PaymentComponent = () => {
  const [loading, setLoading] = useState(false);
  const  {user} = useSelector((state) => state.user)

  return (
      <Grid container>
        <Grid container>
          <Paper elevation={12} sx={{m:1, p:2}}>
            {user.role ?
                <Grid container item xs={12}>
                    <Typography> Tu subscripcion actual: </Typography>
                    <SubscriptionChip label={user.role}/>
                </Grid>
              :
                <Grid container item xs={12}>
                    <Typography> No tienes ninguna suscripción activa </Typography>
                </Grid>
              }
            </Paper>
          </Grid>
        <Grid item xs={12}>
          <SubscriptionPlans/>
        </Grid>
      </Grid>
  );
};

export default PaymentComponent;