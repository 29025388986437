
export const functionalRectangles = [
    {
        name: "NN",
        type: "funcional",
        color: '#ff0000',
        // [minValue, maxValue, defaultValue]
        rangesRM: [95, 120, 110],
        rangesVel: [0.10, 0.30, 0.20],
        rangesReps: [1, 3, 2],
        rangesRIR: [1, 1, 1],
        code: 10,
    },
    {
        name: "NM",
        type: "funcional",
        color: '#c55a11',
        rangesRM: [80, 95, 90],
        rangesVel: [0.30, 0.50, 0.40],
        rangesReps: [3, 6, 4],
        rangesRIR: [2, 3, 2],
        code: 9,
    },
    {
        name: "Nn",
        type: "funcional",
        color: '#ff9300',
        rangesRM: [70, 80, 75],
        rangesVel: [0.50, 0.70, 0.60],
        rangesReps: [6, 10, 8],
        rangesRIR: [3, 5, 4],
        code: 8,
    },
    {
        name: "nN",
        type: "funcional",
        color: '#ffc000',
        rangesRM: [60, 70, 65],
        rangesVel: [0.70, 1.0, 0.85],
        rangesReps: [10, 15, 12],
        rangesRIR: [3, 6, 3],
        code: 7,
    },
    {
        name: "nn",
        type: "funcional",
        color: '#fffc40',
        rangesRM: [30, 60, 45],
        rangesVel: [1.0, 1.50, 1.25],
        rangesReps: [15, 20, 17],
        rangesRIR: [4, 6, 5],
        code: 6,
    },
]

export const structuralRectangles = [
    {
        name: "MN",
        type: "estructural",
        color: '#7030a0',
        rangesRM: [80, 90, 85],
        rangesVel: [0.30, 0.40, 0.35],
        rangesReps: [5, 8, 6],
        rangesRIR: [0, 2, 1],
        code: 5,
    },
    {
        name: "MM",
        type: "estructural",
        color: '#2f5597',
        rangesRM: [70, 80, 75],
        rangesVel: [0.40, 0.50, 0.45],
        rangesReps: [8, 12, 10],
        rangesRIR: [0, 1, 0],
        code: 4,
    },
    {
        name: "Mm",
        type: "estructural",
        color: '#4472c4',
        rangesRM: [60, 70, 65],
        rangesVel: [0.50, 0.70, 0.60],
        rangesReps: [12, 15, 13],
        rangesRIR: [0, 0, 0],
        code: 3,
    },
    {
        name: "mM",
        type: "estructural",
        color: '#008f00',
        rangesRM: [50, 60, 55],
        rangesVel: [0.70, 0.8, 0.75],
        rangesReps: [15, 20, 17],
        rangesRIR: [0, 0, 0],
        code: 2,
    },
    {
        name: "mm",
        type: "estructural",
        color: '#4eba00',
        rangesRM: [30, 50, 40],
        rangesVel: [0.8, 0.90, 0.85],
        rangesReps: [20, 20, 20],
        rangesRIR: [0, 0, 0],
        code: 1,
    },
]