import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import {
    BarChart,
    Bar,
    Cell,
    ResponsiveContainer
} from "recharts";


const ProgramNavigator = ({ items, programSummary, selected, setSelected }) => {
    const colors = ["#9BD332", "#89BFBF", "#2069B6", "#E391E8", "#4199BD", "#13B6C3"]
    const [data, setData] = useState([]);

    useEffect(() => {
        let dataex = {}
        if (items && programSummary) {
            dataex = items.map((item, index) => {
                const mcResume = programSummary[index];
                var intensity = 0;
                // const nDays = Object.keys(mcResume).length
                Object.keys(mcResume).map((keyResume) => {
                    const sessionResume = mcResume[keyResume];
                    intensity = intensity + sessionResume.IntensityMean;
                    return undefined
                })
                // intensity = nDays > 0 ? intensity / Object.keys(mcResume).length : 0;

                return { ...item, resume: { intensity: intensity + 10 } }
            })
        }
        setData(dataex)
    }, [items, programSummary])

    const handleClick = (index) => {
        if (setSelected) {
            setSelected((previous) => previous === index ? undefined : index);
        }
    }

    return (
        <Grid container sx={{ m: 2, mr: 5 }}>
            <Grid item xs={12}>

                <ResponsiveContainer height={150} width={data.length * 50} maxHeight={150}>
                    <BarChart
                        barSize={45}
                        data={data}
                        stackOffset="none"
                    >
                        <Bar
                            dataKey="resume.intensity"
                            stackId="100"
                            id="intensity"
                            name="intensity"
                        >
                            {data.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    stroke="#000000"
                                    strokeWidth={index === selected ? 4 : 1}
                                    fill={colors[Math.floor(index / 1) % colors.length]}
                                    onClick={() => handleClick(index)}
                                />
                            ))}
                        </Bar>

                    </BarChart>
                </ResponsiveContainer>
            </Grid>
        </Grid>

    );
}

export default ProgramNavigator;