import { Button, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BodyButton from "../../bodys/BodyButton";
import BodySelector from "../../bodys/BodySelector";
import ProgramComponent from "./ProgramComponent";

const ProgramSelector = () => {
    const dispatch = useDispatch();
    const { updatedPrograms, programs, currentBodyId } = useSelector((state) => state.trainer);
    const [selectedProgramId, setSelectedProgramId] = useState(undefined);

    return (
        updatedPrograms ?
            <Grid container sx={{ minHeight: "100%" }}>
                <Grid item xs={4} >
                    <Paper sx={{ p: 2, m: 0.5 }} >
                        <BodySelector />
                        <BodyButton bodyId={currentBodyId} trainerView />
                        <Typography sx={{ my: 3 }} variant="h6">Lista de programas</Typography>
                        {Object.keys(programs).length > 0 ?
                            Object.keys(programs).map((key) =>
                                <Grid key={key} item xs={12} sx={{ my: 1 }}>
                                    {/* <ButtonGroup fullWidth variant="outlined"> */}
                                    {selectedProgramId === key ?
                                        <Button fullWidth key={key} variant="contained" onClick={() => setSelectedProgramId(undefined)}>{programs[key].name}</Button>
                                        :
                                        <Button fullWidth key={key} variant="outlined" onClick={() => setSelectedProgramId(key)}>{programs[key].name}</Button>
                                    }
                                    {/* </ButtonGroup> */}
                                </Grid>
                            ) : <Typography sx={{ m: 1 }}>No hay programas</Typography>}
                    </Paper>
                </Grid>

                <Grid item xs={8} justifyContent="center">
                    {programs[selectedProgramId] ?
                        <ProgramComponent key={selectedProgramId + "Comp"} programId={selectedProgramId} />
                        :
                        <Paper sx={{ width: "100%", height: "100%", p: 2, m: 0.5 }}>

                            <Typography variant="h4" sx={{ m: 5 }}>MYOTRAINER DASHBOARD</Typography>
                        </Paper>
                    }
                </Grid>
            </Grid>
            : <></>
    );
}

export default ProgramSelector;