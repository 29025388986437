import { Typography } from "@mui/material";

const ValueTranslator = ({ varName, value, dictionaryList }) => {

    const found = dictionaryList.find((element) => varName === element.varName);

    var component = <></>
    const key = found.formType;

    if (key === "textField") {
        component = <Typography fontSize={14}> {found.name}: {value}</Typography>
    } else if (key === "slider" || key === "verticalSlider") {
        component = <Typography fontSize={14}> {found.name}: {value} {found.unit}</Typography>
    } else if (key === "select" || key === "buttonGroupSelect") {
        component = <Typography fontSize={14}> {found.name}: {found.optionList[value]} </Typography>
    } else if (key === "multiSelect") {
        component = <Typography fontSize={14}> {found.name}: {value.map((item) => { return found.optionList[item] + "  " })}</Typography>
    } else {
        component = undefined//<Typography> {varName}: {value} </Typography>
    }
    if (!value || value == "") {
        component = undefined//<Typography> {varName}: {value} </Typography>
    }
    return component;
}

export default ValueTranslator;