import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MuscleGroups, { AgonistAntagonistPairs, CoreGroups, TiGroups, TsGroups } from '../../constants/MuscleGroups';
import { Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import MuscleCard from './MuscleCard';
import GeneralMuscularGroupResume from './GeneralMuscularGroupResume';
import MuscleBox from './MuscleBox';
import { CompareArrows } from '@mui/icons-material';
import AgonistAntagonistComponent from './AgonistAntagonistComponent';
import MuscleGroupsBodyChart from '../charts/MuscleGroupsBodyChart';

const MuscleListViewSelector = ({bodyId}) => {
    
    // const body = useSelector((state) => state.trainer.bodys[bodyId]);

    // Tabs 1: Opciones de visualización de grupos musculares  
    const [tabMuscleGroups, setTabMuscleGroups] = useState(0);
    
    const reports = useSelector((state) => state.trainer.reports[state.trainer.currentBodyId])

    const handleChangeTab = (event, value) => {
        setTabMuscleGroups(value);
    }

    const [musclesAffected, setMusclesAffected] = useState([]);
    // Return True/false
    const CheckIfHasReport = (muscularGroup) => {
        // console.log("Comprobamos los reportes con ", muscularGroup);
        for (const br_k in reports) {
            // console.log("Tenemos ", reports[br_k].mainMuscle ); 
            if (reports[br_k].updated) {
                // console.log("Esta actualizado! -> ", reports[br_k].mainMuscle ); 
                if (reports[br_k].mainMuscle === muscularGroup) {
                    // console.log("Hemos encontrado uno");
                    return true;
                }
            }
        }
        return false;
    }

    const GetListOfMuscularWithReports = () => {
        var listOfMuscles =[];
        for (const m_k in MuscleGroups) {
            if(CheckIfHasReport(m_k)){
                listOfMuscles.push(m_k);
            }
        }
        return listOfMuscles;
    }

    useEffect(() => {
        setMusclesAffected(GetListOfMuscularWithReports());
    }, [reports]);
    
    return ( 
        <Grid container >
            <Grid item xs={12}>
                <Tabs value={tabMuscleGroups} onChange={handleChangeTab} >
                    <Tab label="Esquema deportista" />
                    <Tab label="Listado" />
                    {/* <Tab label="Gráfica grupos musculares" /> */}
                    {/* <Tab label="Agrupaciones" />
                    <Tab label="Agon-Antagon" /> */}
                </Tabs>
            </Grid>
            <Grid item xs={12}>
                {tabMuscleGroups === 0 &&
                    <div> 
                        <GeneralMuscularGroupResume muscularGroups={musclesAffected}/>
                    </div>
                }
                {tabMuscleGroups === 1 &&
                    <div> 
                        {musclesAffected.map((muscleKey) => 
                            <MuscleCard muscle_key={muscleKey} name={MuscleGroups[muscleKey]}/>
                            )}                        
                    </div>
                }
                {tabMuscleGroups === 2 &&
                    <div> 
                        <MuscleGroupsBodyChart muscularGroups={musclesAffected}/>
                    </div>
                }
                {tabMuscleGroups === 3 &&
                    <div> 
                        <Grid container>
                            <AgonistAntagonistComponent musclesAffected={musclesAffected}/> 
                        </Grid>
                    </div>
                }

                {/* Pestaña de grafica por sesion */}
                {tabMuscleGroups === 4 && <div>
                    <Paper>
                        <Grid container>
                            {/**Tren superior */}
                            <Grid item xs={12}>
                                <Typography>Tren Superior</Typography>
                            </Grid>
                            <Grid item container xs={12}>
                                {TsGroups.filter((muscle_key) => musclesAffected.includes(muscle_key) ).map((muscle_key) => 
                                    <Grid item>
                                        <MuscleBox muscle_key={muscle_key}/>
                                    </Grid>
                                )}
                            </Grid>
                            {/** Core */}
                            <Grid item xs={12}>
                                <Typography>Core</Typography>
                            </Grid>
                            <Grid item container xs={12}>
                                {CoreGroups.filter((muscle_key) => musclesAffected.includes(muscle_key) ).map((muscle_key) => 
                                    <Grid item>
                                        <MuscleBox muscle_key={muscle_key}/>
                                    </Grid>
                                )}
                            </Grid>
                            {/**Tren inferior */}
                            <Grid item xs={12}>
                                <Typography>Tren Inferior</Typography>
                            </Grid>
                            <Grid item container xs={12}>
                                {TiGroups.filter((muscle_key) => musclesAffected.includes(muscle_key) ).map((muscle_key) => 
                                    <Grid item>
                                        <MuscleBox muscle_key={muscle_key}/>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                </div>}
            </Grid>
        </Grid>
    );
}
 
export default MuscleListViewSelector;