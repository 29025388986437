import React from 'react'
import { Grid, Typography } from '@mui/material'
import texts from '../constants/texts/texts'
import ViewLayer from './utils/ViewLayer'

const AboutUs = () => {

    return (
        <ViewLayer>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h3"> {texts.aboutUsTitle} </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5"> {texts.aboutUsSubtitle} </Typography>
                </Grid>
            </Grid>
        </ViewLayer>
    )
}

export default AboutUs