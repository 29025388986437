import React, { useContext } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { Avatar, Box, Button, Grid, TextField, Typography } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { FirebaseContext } from '../../firebase';
import ViewLayer from '../utils/ViewLayer';
import texts from '../../constants/texts/texts';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createNotification } from '../../reducers/notificationReducer';
import * as Yup from 'yup'
import { useFormik } from 'formik';


const validationSchema = Yup.object({
  username: Yup.string('Introduce un nombre de usuario'),
  email: Yup.string()
    .email('Introduce un email válido')
    .required("Email es necesario"),
})

const ForgotPassword = () => {
  const { firebase } = useContext(FirebaseContext);
  const dispach = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const navigate = useNavigate();

  const redirect = (path) => {
    navigate(path);
  }

  const handleSubmit = (data) => {
    firebase.sendPasswordResetEmail(data.email).then(() => {
      dispach(createNotification({ message: texts.forgotpassemailsuccess, type: "success" }));
    }).catch(() => {
      dispach(createNotification({ message: "No se ha podido enviar el email para restablecer la contraseña", type: "error" }));
      handleClick();
    });
  };

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    }
  })

  const [disabled, setDisabled] = React.useState(false);

  const buttonTimer = () => {
    if (!disabled) {
      setDisabled(true);
      formik.handleSubmit();
      setTimeout(function () {
        setDisabled(false);
      }, 30000);
    }
  }

  return (
    <ViewLayer>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        {texts.forgotPasswordTitle}
      </Typography>
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          autoFocus
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={disabled}
          onClick={() => buttonTimer()}
          sx={{ mt: 3, mb: 2 }}
        >
          {texts.forgot}
        </Button>
        <Grid container>
          <Grid item xs>
            <Link component={NavLink} to="/signin" variant="body2">
              {texts.signin}
            </Link>
          </Grid>
          <Grid item>
            <Link component={NavLink} to="/signup" variant="body2">
              {texts.signup}
            </Link>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={open}
        onClose={handleClick}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {texts.forgotpassworderrordescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => redirect("/signup")}>
            {texts.signup2}
          </Button>
          <Button onClick={() => redirect("/signin")} autoFocus>
            {texts.signin}
          </Button>
        </DialogActions>
      </Dialog>
    </ViewLayer>
  );
}
export default ForgotPassword;