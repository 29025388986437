import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTeam } from '../../reducers/trainerReducer';
import TeamSmallCard from './TeamSmallCard';

const TeamGlobalSelector = () => {
    const dispatch = useDispatch();
    const { teams, currentTeamId } = useSelector((state) => state.trainer)
    const handleSelectTeam = (event) => {
        dispatch(setCurrentTeam(event.target.value));
    }



    return (
        <FormControl>
            <InputLabel >Equipo</InputLabel>
            <Select
                id="currentTeam"
                name="CurrentTeam"
                label="CurrentTeam" 
                defaultValue={currentTeamId??="default"}
                value={currentTeamId??="default"}
                onChange={handleSelectTeam}
                sx={{ my: 1 }}
            >
                <MenuItem key="default" value="default">
                    <Typography>Todos los deportistas</Typography>
                </MenuItem>
                {Object.keys(teams).map((key) =>  
                    <MenuItem key={key} value={key}>
                        <TeamSmallCard teamId={key}/>
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
}

export default TeamGlobalSelector;