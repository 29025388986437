import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DonutChart from "./charts/DonutChart";
import { useContext, useState } from "react";
import { FirebaseContext } from "../../firebase";
import { createNotification } from "../../reducers/notificationReducer";
import ConfirmationDialog from "../utils/ConfirmationDialog";
import BarsBilateralComparativeChart from "./charts/BarsBilateralComparativeChart";

const ComplexReportComponent = ({ crId, data }) => {
    const body = useSelector((state) => state.trainer.bodys[data.bodyId])
    const { firebase } = useContext(FirebaseContext);
    const dispatch = useDispatch();

    const [openConfirmation, setOpenConfirmation] = useState(false)

    const handleDelete = () => {
        firebase.deleteComplexReport(crId).then(() => {
            dispatch(createNotification({ message: "Eliminado informe correctamente", type: "success" }))
        }).catch(() => {
            dispatch(createNotification({ message: "Error al eliminar informe", type: "success" }))
        })

    }


    return (data && body &&
        <Accordion variant="elevation" elevation={6} sx={{ width: "100%", p: 1, m: 1 }} >
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6"> {data.name} de {body.name} </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 2, m: 1 }}>
                {openConfirmation === true ?
                    <ConfirmationDialog
                        message={"¿Seguro que quiere borrar el informe? "}
                        action={() => handleDelete()}
                        open={openConfirmation}
                        setOpen={setOpenConfirmation} />
                    :
                    <></>
                }
                {data.sections.map((item) =>
                    <Grid container spacing={2} sx={{ p: 1, m: 1 }}>
                        <Grid container>
                            <Grid container item alignContent="center" justifyContent="space-between" xs={12}>
                                <Grid item sx={{ m: 1, p: 1 }}>
                                    <Typography fontSize={16} variant="h6">{item.name}</Typography>
                                </Grid>
                                <Grid item sx={{ m: 1, p: 1 }}>
                                    <Button onClick={() => { setOpenConfirmation(true) }}>Eliminar </Button>
                                </Grid>
                            </Grid>
                            {item.observations && <Grid container sx={{ m: 1, p: 1 }}>
                                <Typography fontSize={14} variant="h6">{item.observations}</Typography>
                            </Grid>}
                            <Grid container item alignContent="center" xs={12} sx={{ m: 1, p: 1 }}>
                                <TableContainer >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>Izquierdo</TableCell>
                                                <TableCell>Derecho</TableCell>
                                                <TableCell>Totales</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell variant='head'>F. media (N)</TableCell>
                                                <TableCell>{item.bilateralData.left_f_average}  ({item.bilateralData.left_f_perc_average.toFixed(1)} %)</TableCell>
                                                <TableCell>{item.bilateralData.right_f_average} ({item.bilateralData.right_f_perc_average.toFixed(1)} %)</TableCell>
                                                <TableCell>{item.bilateralData.f_average_total.toFixed(1)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant='head'>F. pico (N)</TableCell>
                                                <TableCell>{item.bilateralData.left_f_max} ({item.bilateralData.left_f_perc_max.toFixed(1)} %)</TableCell>
                                                <TableCell>{item.bilateralData.right_f_max} ({item.bilateralData.right_f_perc_max.toFixed(1)} %)</TableCell>
                                                <TableCell>{item.bilateralData.f_max_total.toFixed(1)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant='head'>Impulso (N·s)</TableCell>
                                                <TableCell>{item.bilateralData.left_impulse} ({item.bilateralData.left_perc_impulse.toFixed(1)} %)</TableCell>
                                                <TableCell>{item.bilateralData.right_impulse} ({item.bilateralData.right_perc_impulse.toFixed(1)} %)</TableCell>
                                                <TableCell>{item.bilateralData.impulse_total.toFixed(1)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant='head'>Tiempo (s)</TableCell>
                                                <TableCell>{item.bilateralData.left_time} ({item.bilateralData.left_perc_time.toFixed(1)} %)</TableCell>
                                                <TableCell>{item.bilateralData.right_time} ({item.bilateralData.right_perc_time.toFixed(1)} %)</TableCell>
                                                <TableCell>{item.bilateralData.time_total.toFixed(1)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant='head'>T. al pico (s)</TableCell>
                                                <TableCell>{item.bilateralData.left_time_peak} ({item.bilateralData.left_perc_time_peak.toFixed(1)} %)</TableCell>
                                                <TableCell>{item.bilateralData.right_time_peak} ({item.bilateralData.right_perc_time_peak.toFixed(1)} %)</TableCell>
                                                <TableCell>{item.bilateralData.time_peak_total.toFixed(2)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item container alignContent="center" justifyContent="center" xs={6}>
                                <DonutChart bilateralData={item.bilateralData} type="force" />
                            </Grid>
                            <Grid item container alignContent="center" justifyContent="center" xs={6}>
                                <DonutChart bilateralData={item.bilateralData} type="time" />
                            </Grid>
                            <Grid item container alignContent="center" justifyContent="center" xs={6}>
                                <DonutChart bilateralData={item.bilateralData} type="impulse" />
                            </Grid>
                            <Grid item container alignContent="center" justifyContent="center" xs={6}>
                                <BarsBilateralComparativeChart bilateralData={item.bilateralData} />
                            </Grid>
                        </Grid>

                    </Grid>
                )}
            </AccordionDetails>
        </Accordion>);
}

export default ComplexReportComponent;