import { Avatar, Button, Drawer, Grid } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import BodyButton from '../components/bodys/BodyButton';
import BodyManager from '../components/bodys/ManageBody';




// Styles
import style from "./BodyManagerView.module.scss";
import TeamGlobalSelector from '../components/teams/TeamGlobalSelector';

const BodyManagerView = () => {
    // const { updatedPrograms} = useSelector((state) => state.trainer)
    // const [openDialog, setOpenDialog] = useState(false);

    const bodys = useSelector((state) => {
      const team = state.trainer.teams[state.trainer.currentTeamId];
      if(team && team.bodys){
          var bodys = {};
          team.bodys.concat(["defaultBody"]).forEach((key) => {
              if(state.trainer.bodys.hasOwnProperty(key)){
                  bodys[key] = state.trainer.bodys[key];
              }
          }) 
          return bodys;
      }else{
          return state.trainer.bodys;
      }            
    })


    const [openBodysList, setOpenBodysList] = useState(false);
    const handleBodyList = () => {
        setOpenBodysList((old) => !old);    
    };

    return ( 
        <Fragment>
            {/* <Grid container>
                <TeamGlobalSelector/>
                <Button onClick={handleBodyList}> caca </Button>
            </Grid> */}
          
            <div className={style.contenido}>
                    <div className={`tituloTarjeta ${style.listadoDeportistas}`}>
                        <p>Listado de deportistas</p> 
                        {Object.keys(bodys).map( (id) => <BodyButton bodyId={id} key={id}/> )}
                    </div>
                {/* {openBodysList ? 
                    :
                    <div className={`tituloTarjeta ${style.listadoDeportistas}`}>
                        {Object.keys(bodys).map( (id) =>
                            <Avatar sx={{m:2}} src={bodys[id].avatarUrl}/> 
                        )}
                    </div>                
                } */}
                {/* Parte derecha: detalle del deportista seleccionado  */}
                <div   className={style.listadoReports}> 
                  {/* <p >Información detallada </p>  */}
                  <BodyManager></BodyManager>
                </div>
            </div>

        </Fragment>
    )
}
    
export default BodyManagerView;