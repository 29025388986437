import React, { useState, useEffect } from 'react';
import RepRawChart from './charts/RepRawChart';
import { Grid, MenuItem, Select } from '@mui/material';

const RepSelector = ({ exercise, onChange }) => {
    const [repSelected, setRepSelected] = useState()
    useEffect(() => {
        var fuerza_max = -100000;
        var i_max = "";
        exercise.series.forEach((serie) => {
            serie.reps.forEach((rep) => {
                if (fuerza_max < rep.f_max) {
                    fuerza_max = rep.f_max;
                    i_max = serie.i_serie + "_" + rep.i_rep;
                }
            });
        });
        setRepSelected(i_max);
    }, [exercise])

    const handleChange = (event) => {
        setRepSelected(event.target.value);
        onChange(event.target.value);
    }

    return (
        repSelected && <Select value={repSelected} onChange={handleChange}>
            {exercise.series.map((serie) => serie.reps.map((rep) => {
                return <MenuItem value={serie.i_serie + "_" + rep.i_rep}>
                    <RepRawChart key={serie.i_serie + "_" + rep.i_rep} rawObject={rep} />
                </MenuItem>
            }))}
        </Select>
    );
}
export default RepSelector;