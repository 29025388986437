import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";


// Los items son los ejercicios que se van a comparar con los que se montará la grafica
const ComparativeChart = ({ title, unit, leftObject, rightObject, bothObject }) => {
    useEffect(() => {
        getData();
    }, [])

    const [options, setOptions] = useState()
    const getData = (result) => {
        setOptions({
            chart: {
                type: 'column'
            },
            title: {
                text: title,
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: [
                    'Fuerza',
                ]
            },
            yAxis: [{
                min: 0,
                title: {
                    text: unit,
                }
            }, {
                title: {
                    text: unit,
                },
                opposite: true
            }, {
                title: {
                    text: unit,
                },
            }],
            legend: {
                shadow: false
            },
            tooltip: {
                shared: true
            },
            plotOptions: {
                column: {
                    grouping: false,
                    shadow: true,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Fuerza media izq',
                color: 'rgba(165,170,217,1)',
                data: [leftObject.f_average],
                tooltip: {
                    valuePrefix: '',
                    valueSuffix: ' N'
                },
                pointPadding: 0.3,
                pointPlacement: -0.3
            }, {
                name: 'Fuerza máxima izq',
                color: 'rgba(126,86,134,.9)',
                data: [leftObject.f_max],
                tooltip: {
                    valuePrefix: '',
                    valueSuffix: ' N'
                },
                pointPadding: 0.4,
                pointPlacement: -0.3
            }, {
                name: 'Fuerza media der',
                color: 'rgba(248,161,63,1)',
                data: [rightObject.f_average],
                tooltip: {
                    valuePrefix: '',
                    valueSuffix: ' N'
                },
                pointPadding: 0.3,
                pointPlacement: 0,
                yAxis: 1
            }, {
                name: 'Fuerza máxima der',
                color: 'rgba(186,60,61,.9)',
                data: [rightObject.f_max],
                tooltip: {
                    valuePrefix: '',
                    valueSuffix: ' N'
                },
                pointPadding: 0.4,
                pointPlacement: 0,
                yAxis: 1
            },
            bothObject && {
                name: 'Fuerza media conjunto',
                color: 'rgba(148,61,63,1)',
                data: [bothObject.f_average],
                tooltip: {
                    valuePrefix: '',
                    valueSuffix: ' N'
                },
                pointPadding: 0.3,
                pointPlacement: 0.3,
            }, bothObject && {
                name: 'Fuerza máxima conjunto',
                color: 'rgba(150,160,161,.9)',
                data: [bothObject.f_max],
                tooltip: {
                    valuePrefix: '',
                    valueSuffix: ' N'
                },
                pointPadding: 0.4,
                pointPlacement: 0.3,
            }
            ].filter((item) => item !== undefined)
        })
    }


    return (
        <Grid container>
            <Grid item xs={12}>
                {options && < HighchartsReact highcharts={Highcharts} options={options} />}
            </Grid>
        </Grid>
    );
}

export default ComparativeChart;