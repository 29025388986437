import OptionLists from "./OptionLists"

const method = [
    {
        name: 'Nombre',
        varName: 'name',
        formType: 'textField',
        type: 'string',
        required: true,
        unit: '',
        //Range
        minValue: '0',
        maxValue: '100',
        defaultValue: "",
        description: 'Nombre dado al método'
    },
    {
        name: 'Breve Descripción',
        varName: 'short_description',
        formType: 'textField',
        type: 'string',
        required: false,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 300,
        defaultValue: "",
        description: 'Definición en una linea de lo que se busca conseguir con el método'
    },
    {
        name: 'Vertiente',
        varName: 'main_adaptation',
        formType: 'buttonGroupSelect',
        optionList: OptionLists.main_adaptation,
        type: 'string',
        required: false,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 200,
        defaultValue: "funcional",
        description: 'Indica la adaptación principal que se busca (Funcional o Estructural); condiciona si la velocidad de trabajo es máxima o por el contrario es controlada, es decir submáxima'
    },
    {
        name: 'Zona',
        varName: 'training_zone',
        formType: 'buttonGroupSelect',
        optionList: OptionLists.training_zone,
        type: 'string',
        required: false,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 100,
        defaultValue: "NN",
        description: 'Define el % de carga en el que se tiene que trabajar'
    },
    {
        name: 'Modo',
        varName: 'working_mode',
        formType: 'select',
        optionList: OptionLists.working_mode,
        type: 'string',
        required: false,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 200,
        defaultValue: "tonico",
        description: 'Tipo de resistencia externa utilizada; se diferencia el origen de la resistencia, es decir si es producida por la fuerza de la gravedad o si es generada por otro medio (elástico, polea inercial, máquina vibratoria, peso libre,...)'
    },
    {
        name: 'Series',
        varName: 'series',
        formType: 'slider',
        type: 'number',
        required: true,
        unit: '',
        //Range
        step: 1,
        minValue: 1,
        maxValue: 20,
        defaultValue: 1,
        description: 'Número de veces que se hace un nº determinado de repeticiones'
    },
    {
        name: 'Tiempo',
        varName: 'time',
        formType: 'slider',
        type: 'number',
        required: true,
        unit: 'seg',
        //Range
        step: 1,
        minValue: 0,
        maxValue: 300,
        defaultValue: 10,
        description: 'Tiempo que se debe mantener un ejercicio o durante el que se debe ejecutar un ejercicio'
    },
    {
        name: 'Repeticiones',
        varName: 'reps',
        formType: 'slider',
        type: 'number',
        required: true,
        unit: 'reps',
        //Range
        step: 1,
        minValue: 1,
        maxValue: 30,
        defaultValue: 2,
        description: 'Número de veces que se debe realizar un ejercicio, tarea o gesto'
    },
    {
        name: '%RM',
        varName: 'load',
        formType: 'verticalSlider',
        type: 'number',
        required: true,
        unit: '%',
        //Range
        minValue: 0,
        maxValue: 120,
        defaultValue: 110,
        description: 'Resistencia o carga con la que se realiza el ejercicio, pudiendo trabajar con resistencia externa o simplemente con el peso del  propio cuerpo'
    },
    {
        name: 'Velocidad',
        varName: 'velocity',
        formType: 'slider',
        type: 'number',
        required: true,
        unit: 'm/s',
        //Range
        step: 0.05,
        minValue: 0.05,
        maxValue: 2,
        defaultValue: 0.2,
        description: 'Velocidad media a la que se realiza el ejercicio'
    },
    {
        name: 'Recorrido',
        varName: 'range_of_motion',
        formType: 'slider',
        type: 'number',
        required: false,
        unit: 'cm',
        //Range
        step: 1,
        minValue: 0,
        maxValue: 1000,
        defaultValue: 50,
        description: 'Desplazamiento o distancia que describe un gesto/ejercicio'
    },
    {
        name: 'Tiempo de recuperación',
        varName: 'recovery_time',
        formType: 'slider',
        type: 'number',
        required: true,
        unit: 'seg',
        //Range
        step: 1,
        minValue: 0,
        maxValue: 600,
        defaultValue: 5,
        description: 'Tiempo que debe trascurrir entre serie y serie'
    },
    {
        name: 'RIR',
        varName: 'effort_index',
        formType: 'slider',
        type: 'number',
        required: false,
        unit: 'RIR',
        //Range
        step: 1,
        minValue: 0,
        maxValue: 10,
        defaultValue: 1,
        description: 'Repeticiones que se deben ejecutar con la carga que podría realizar "X" repeticiones máximas, se expresa r (RM)'
    },
    {
        name: 'Beneficios',
        varName: 'benefits',
        formType: 'textField',
        type: 'string',
        required: false,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 200,
        defaultValue: '',
        description: 'Describe el principal beneficio del método o principales beneficios'
    },
];

export default method