import React, { useState } from 'react';
import { Visibility, VisibilityOff, LockOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { FirebaseContext } from '../../firebase';
import { useContext, useEffect } from 'react';
import texts from '../../constants/texts/texts';
import ViewLayer from '../utils/ViewLayer';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, setAuthData } from '../../reducers/userReducer';
import { createNotification } from '../../reducers/notificationReducer';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Introduce un email válido')
    .required("Email es necesario"),
  password: Yup.string()
    .min(6, "La contraseña debe tener al menos 6 caracteres")
    .required("La contraseña es necesaria"),
})

export default function SignUp() {
  const { firebase } = useContext(FirebaseContext);

  // Redireccionamos si ya ha iniciado sesion
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      return navigate("/profile");
    }
  }, [user, navigate]);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const redirect = (path) => {
    navigate(path);
  }

  const handleSubmit = async (data) => {

    firebase.SignUp(data.email, data.password).then((userCredential) => {
      const userData = {
        name: userCredential.user.email,
        uid: userCredential.user.uid,
        accessToken: userCredential.user.accessToken,
      }
      firebase.SubscribeAuthChanges((data) => dispatch(setAuthData(data)));
      dispatch(login(userData));
      dispatch(createNotification({ message: texts.singupsuccess, type: "success" }))
      firebase.SendEmailVerification().then(() => {
        dispatch(createNotification({ message: "Se ha enviado el correo de verificacion de usuario", type: "success" }))
      })

      return navigate("/profile");
    }).catch(() => {
      dispatch(createNotification({ message: "No se ha podido enviar el correo de verificacion de usuario", type: "error" }))
      handleClick();
    })

  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    }
  })

  const [passwordVisible, setPasswordVisible] = useState(false);

  const toggleVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  return (
    <ViewLayer>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        {texts.signup}
      </Typography>
      <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="password"
              label="Password"
              type={passwordVisible ? "text" : "password"}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={toggleVisibility}
                    >
                      {passwordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              }}
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              autoComplete="new-password"
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {texts.signup}
        </Button>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link component={NavLink} to="/signin" variant="body2">
              {texts.alreadyaccount}
            </Link>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={open}
        onClose={handleClick}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {texts.signuperrordescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => redirect("/forgotpassword")}>{texts.forgotPassword}</Button>
          <Button onClick={() => redirect("/signin")} autoFocus>
            {texts.signin}
          </Button>
        </DialogActions>
      </Dialog>
    </ViewLayer>
  );
}