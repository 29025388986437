import { DirectionsRun, Handshake } from "@mui/icons-material";
import { Button, ButtonGroup, Grid, Icon, IconButton, Paper, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useEffect, useState } from "react";



// This component must create 
/**
    {
        <i_serie>: <side>,
        1: "right"        

    }

*/
const SerieSideSelector = ({ report, rawDataObject, setSeriesSides }) => {
    const [sides, setSides] = useState();

    useEffect(() => {
        if (report && report.series_sides) {
            setSides({ ...report.series_sides });
        } else {
            if (rawDataObject) {
                setSides(() => {
                    var newSides = {}
                    rawDataObject.series.map((serie) => {
                        newSides[serie.i_serie] = "both"
                    })
                    return { ...newSides };
                })
            }
        }
    }, [report, rawDataObject])
    useEffect(() => {
        if (setSeriesSides)
            setSeriesSides(sides);
    }, [sides])

    const handleChange = (i_serie, side) => {
        setSides((old) => {
            old[i_serie] = side;
            return { ...old };
        })
    }


    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography>
                    Selecciona el lateral trabajado
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {sides && rawDataObject && rawDataObject.series.map((serie) => {
                    return <Paper elevation={6}>
                        <Grid item xs={6}>
                            <Typography> Serie {serie.i_serie} </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <ToggleButtonGroup
                                color="primary"
                                value={sides[serie.i_serie]}
                                exclusive
                                onChange={(event) => { handleChange(serie.i_serie, event.currentTarget.value) }}>
                                <ToggleButton value="left">
                                    <Grid container justifyContent="center">
                                        <Grid container justifyContent="center">
                                            <DirectionsRun sx={{ transform: "scaleX(-1)" }} />
                                        </Grid>
                                        <Grid container justifyContent="center">
                                            <Typography> Izquierdo</Typography>
                                        </Grid>
                                    </Grid>
                                </ToggleButton>
                                <ToggleButton value="both">
                                    <Grid container justifyContent="center">
                                        <Grid container justifyContent="center">
                                            <Handshake />
                                        </Grid>
                                        <Grid container justifyContent="center">
                                            <Typography> Ambos</Typography>
                                        </Grid>
                                    </Grid>
                                </ToggleButton>
                                <ToggleButton value="right">
                                    <Grid container justifyContent="center">
                                        <Grid container justifyContent="center">
                                            <DirectionsRun />
                                        </Grid>
                                        <Grid container justifyContent="center">
                                            <Typography> Derecha</Typography>
                                        </Grid>
                                    </Grid>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Paper>
                })}

            </Grid>
        </Grid>
    );
}

export default SerieSideSelector;