import React, { useState, useRef } from "react";
import { Button, Grid, IconButton, Dialog, Badge } from "@mui/material";
import { Delete, PhotoCamera } from "@mui/icons-material";
import texts from "../../constants/texts/texts";
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const ImageForm = ({ setBlob, imageUrl }) => {
    const [dialogActive, setDialogActive] = useState(false);
    const [imageUpload, setImageUpload] = useState(null);
    const [cropper, setCropper] = useState(undefined);
    const [blobImage, setBlobImage] = useState(imageUrl);

    const uploadFile = () => {
        if (cropper) {
            cropper.getCroppedCanvas({
                maxWidth: 4096,
                maxHeight: 4096,
                imageSmoothingEnabled: false,
                imageSmoothingQuality: 'medium',
            }).toBlob((blob) => {
                setBlobImage(URL.createObjectURL(blob));
                if (setBlob) setBlob(blob);
            }, 'image/jpeg', 1);
        }
        setDialogActive(false);
        setImageUpload(undefined);
    }

    const handleSelectImage = (event) => {
        event.preventDefault();
        const reader = new FileReader();
        reader.onload = () => {
            setImageUpload(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
        setDialogActive(true);
    }

    const uploadInputRef = useRef(null);

    return (
        <div className="UploadImage">
            <Grid container >
                <Grid item>
                    <Badge
                        overlap="rectangular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            blobImage ?
                                <IconButton onClick={() => { setBlobImage(undefined); setBlob(undefined) }} style={{ backgroundColor: "#D0D0D0" }} >
                                    <Delete color="action" />
                                </IconButton>
                                :
                                <IconButton onClick={() => { uploadInputRef.current.click() }} style={blobImage ? { backgroundColor: "#D0D0D0" } : { transform: 'translate(-75px, -75px)', backgroundColor: "#D0D0D0" }}>
                                    <input
                                        type="file"
                                        id="icon_button"
                                        ref={uploadInputRef}
                                        hidden
                                        accept="image/*"
                                        onChange={handleSelectImage}
                                        onClick={(event) => { event.target.value = '' }}
                                    />
                                    <PhotoCamera color="action" />
                                </IconButton>
                        }
                    >
                        {blobImage ?

                            <img
                                width="150px"
                                height="150px"
                                src={blobImage}
                                alt=""
                            /> :
                            <img
                                width="150px"
                                height="150px"
                                alt=""
                            />
                        }

                    </Badge>
                </Grid>

            </Grid>
            <Dialog open={dialogActive} onClose={() => { setDialogActive(false); }}>
                <Grid container>
                    <Cropper
                        id="cropper"
                        style={{ height: 400, width: "100%" }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        aspectRatio={1}
                        preview=".img-preview"
                        src={imageUpload}
                        viewMode={1}
                        dragMode="move"
                        toggleDragModeOnDblclick={false}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        guides={true}
                    />
                    <Grid item>
                        <Button
                            variant="outlined"
                            component="label"
                            onClick={() => {
                                setDialogActive(false);
                                setImageUpload(undefined);
                            }}
                            color="primary"
                        >
                            {texts.cancel}
                        </Button>
                    </Grid>
                    <Grid item>
                        <IconButton aria-label="delete" onClick={() => {
                            cropper.rotate(90);
                        }}>
                            <Rotate90DegreesCcwIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={uploadFile}
                            variant="contained"
                        >
                            {texts.uploadImage}
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
}

export default ImageForm;