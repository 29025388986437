import OptionLists from "./OptionLists"

const body = [
    {
        name: 'Nombre',
        varName: 'name',
        formType: 'textField',
        type: 'string',
        required: true,
        unit: '',
        //Range
        minValue: 1,
        maxValue: 100,
        defaultValue: '',
        description: 'Nombre dado al cuerpo'
    },
    {
        name: 'Imagen deportista',
        varName: 'bodyImage',
        formType: 'image',
        type: 'string',
        required: false,
        unit: '',
        //Range
        minValue: '',
        maxValue: '',
        defaultValue: '',
        description: 'Imagen del deportista'
    },
    {
        name: 'Altura',
        varName: 'height',
        formType: 'numberField',
        type: 'number',
        required: true,
        unit: 'cm',
        //Range
        minValue: 80,
        maxValue: 250,
        defaultValue: 170,
        description: 'Altura del cuerpo'
    },
    {
        name: 'Peso',
        varName: 'weight',
        formType: 'numberField',
        type: 'number',
        required: true,
        unit: 'kg',
        //Range
        minValue: 20,
        maxValue: 250,
        defaultValue: 70,
        description: 'Peso del cuerpo'
    },
    {
        name: 'Genero',
        varName: 'gender',
        formType: 'select',
        type: 'string',
        optionList: OptionLists.gender,
        required: false,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 100,
        defaultValue: "",
        description: 'Genero'
    },
    {
        name: 'Observaciones',
        varName: 'comment',
        formType: 'textFieldMultiline',
        type: 'string',
        required: false,
        unit: '',
        //Range
        minValue: 0,
        maxValue: 300,
        defaultValue: '',
        description: 'Observaciones del deportista',
    },
];

export default body