import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserLocalData } from '../reducers/userReducer';
import { setBodyExecutions, setBodyReports, setBodys, setExercises, setMethods, setPrograms, setSessions, setTeams } from '../reducers/trainerReducer';
import { setComplexReports } from '../reducers/complexReportReducer';
import FirebaseContext from './context';

const InitializeData = () => {
    const { firebase } = useContext(FirebaseContext);
    // Redireccionamos si ya ha iniciado sesion, esto deberia estar en una funcion
    const { userUpdated, user, auth, logedIn } = useSelector((state) => state.user)
    const { bodys, updatedExercises, updatedMethods, updatedSessions, updatedPrograms, updatedBodys, updatedTeams } = useSelector((state) => state.trainer);
    const { updatedComplexReports } = useSelector((state) => state.complexReports);
    const dispatch = useDispatch();

    useEffect(() => {
        // console.log("Vamos a probar ", firebase.auth);
        if (auth) {
            // console.log("userUpdated  ", userUpdated, logedIn);
            if (!userUpdated && logedIn) {
                // console.log("Pedimos user info ");
                firebase.getUserInfo((data) => { dispatch(setUserLocalData(data)) });
            }
            if (!updatedExercises && logedIn) {
                console.log("PEdimos ejercicios ");
                firebase.getExercises((data) => { dispatch(setExercises(data)) })
            }
            if (!updatedMethods && logedIn) {
                console.log("Pedimos metodos ");
                firebase.getMethods((data) => { dispatch(setMethods(data)) })
            }
            if (!updatedSessions && logedIn) {
                console.log("Pedimos sesiones ");
                firebase.getSessions((data) => { dispatch(setSessions(data)) })
            }
            if (!updatedPrograms && logedIn) {
                // console.log("Pedimos PROGRAMS ");
                firebase.getPrograms((data) => { dispatch(setPrograms(data)) })
            }
            if (!updatedBodys && logedIn) {
                // console.log("Pedimos bodys ");
                firebase.getBodys((data) => { dispatch(setBodys(data)) })
            }
            if (updatedBodys && !updatedTeams && logedIn) {
                // console.log("Pedimos bodys ");
                firebase.getTeams((data) => { dispatch(setTeams(data)) })
            }
            if (!updatedComplexReports && logedIn) {
                // console.log("Pedimos reportes ");
                firebase.getComplexReports((data) => { dispatch(setComplexReports(data)) })
            }
            if(updatedBodys && logedIn){
                for( let bodyId of Object.keys(bodys)){
                    if(bodyId !== "defaultBody"){
            firebase.getSessionExecutions(bodyId, (data) => { dispatch(setBodyExecutions({bodyId: bodyId, executions: data})) })
                    }
                }
            }
            if (updatedBodys && logedIn) {
                for (let bodyId of Object.keys(bodys)) {
                    if (bodyId !== "defaultBody") {
                        firebase.getReports(bodyId, (data) => { dispatch(setBodyReports({ bodyId: bodyId, reports: data })) })
                    }
                }
            }
        }
    }, [dispatch, firebase, updatedExercises, updatedMethods, updatedSessions, updatedPrograms, updatedBodys, userUpdated, logedIn, auth])


    return (<></>);
}

export default InitializeData;