import React, { Component } from 'react';

import lapiz from '../../assets/icons/Vector.svg';
import './FlotanteEdit.scss';

export default  class FlotanteEdit extends Component {
    
    render() {
        return (
            <button className="editprofile--button"> 
                <img className='img-1' src={lapiz} />
            </button>
        )
    }
}
 