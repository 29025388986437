import React, { useState} from 'react';
import { Button, Dialog, Grid, Typography } from "@mui/material";
import { Add} from "@mui/icons-material";
import SessionSelector from "../../components/trainer/sessions/SessionSelector";
import CreateSession from '../../components/trainer/sessions/CreateSession';


// Components
import MainTopBar from "../../components/elements/MainTopBar";
import HeaderTop from "../../components/elements/HeaderTop";

// Styles
import "../../styles/global.scss";


const SessionsView = () => {    
    const [dialogSession, setDialogSession] = useState(false);

    return (
        <div>  
            <MainTopBar
                teamSelector
                bodySelector
                headComponent={
                    <HeaderTop
                        title="Sesiones"
                        subtitle="Detalles de las sesiones de entrenamiento" 
                        buttonActionFunction = {setDialogSession}
                        buttonText = "Crear sesión"
                        filters= {false}
                    />
                }
            />
            <Grid container>
                <Dialog open={dialogSession} onClose={() => { setDialogSession(false) }} fullWidth scroll={'paper'}>
                    <CreateSession/>
                </Dialog>
                {/* <Grid container item xs={12} spacing={2} >
                    <Grid item >
                        <Typography fontWeight={550} variant="h4"  >
                            Sesiones
                        </Typography>
                        <Typography fontWeight={450} variant="inherit">
                            Listado de sesiones
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Button variant="contained" onClick = {() => {setDialogSession(true)}}><Add fontSize="medium"/> Crear Sesión</Button>
                    </Grid>
                </Grid> */}
                <Grid item xs={12}>
                    <SessionSelector/>
                </Grid>
            </Grid>
        </div>
        
    
    );

}
 
export default SessionsView;