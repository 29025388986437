import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, rectIntersection, useDraggable, useDroppable, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Delete, DragIndicator, SettingsInputAntennaTwoTone } from '@mui/icons-material';
import { createNotification } from '../../reducers/notificationReducer';



// Styles
import style from './CreateBaseReport.module.scss'


// const SortableExerciseList = ({ items, setItems }) => {
//     const sensors = useSensors(
//         useSensor(PointerSensor),
//         useSensor(KeyboardSensor, {
//             coordinateGetter: sortableKeyboardCoordinates,
//         })
//     );
//     const handleDragEnd = (event) => {
//         const { active, over } = event;
//         if (active.id !== over.id) {
//             setItems((items) => {
//                 const oldIndex = items.indexOf(items.find(item => item.id === active.id));
//                 const newIndex = items.indexOf(items.find(item => item.id === over.id));
//                 return arrayMove(items, oldIndex, newIndex);
//             });
//         }
//     }
//     return (
//         <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
//             <SortableContext
//                 items={items}
//                 strategy={verticalListSortingStrategy}
//             >
//                 {items.map(item => <SortableItem item={item} key={item.id} id={item.id} />)}
//             </SortableContext>
//         </DndContext>
//     )
// }

// const SortableRow = (props) => {
//     const {
//         attributes,
//         listeners,
//         setNodeRef,
//         transform,
//         transition,
//     } = useSortable({ id: props.id, data: { type: "sortable" } });

//     const style = {
//         transform: CSS.Transform.toString(transform),
//         transition,
//     };

//     return (
//         <div style={style}  >
//             <DroppableRowExercise
//                 index={props.index}
//                 item={props.item}
//                 deleteRow={props.deleteRow}
//                 listeners={listeners}
//                 attributes={attributes}
//                 setRef={setNodeRef} />
//         </div >
//     );
// }


const DroppableRowBilateralRawData = ({ bodyId, item, index, deleteRow, setRef, attributes, listeners }) => {
    const reports = useSelector((state) => state.trainer.reports[bodyId])

    // const { isOver, setNodeRef, active } = useDroppable({
    //     id: "drop" + index,
    //     data: { type: "DroppableRow" }
    // });
    const dropL = useDroppable({
        id: "dropL_" + index,
        data: { type: "DroppableRawL" }
    });
    const dropR = useDroppable({
        id: "dropR_" + index,
        data: { type: "DroppableRawR" }
    });
    const dropB = useDroppable({
        id: "dropB_" + index,
        data: { type: "DroppableRawB" }
    });

    const [elevationL, setElevationL] = useState(2);
    const [elevationR, setElevationR] = useState(2);
    const [elevationB, setElevationB] = useState(2);

    useEffect(() => {
        if (dropL.active && dropL.isOver) {
            setElevationL(reports[dropL.active.id] ? 24 : 2)
        } else {
            setElevationL(2)
        }
        if (dropB.active && dropB.isOver) {
            setElevationB(reports[dropB.active.id] ? 24 : 2)
        } else {
            setElevationB(2)
        }
        if (dropR.active && dropR.isOver) {
            setElevationR(reports[dropR.active.id] ? 24 : 2)
        } else {
            setElevationR(2)
        }
    }, [dropL, dropB, dropR])

    return (
        <Paper sx={{ m: 1, p: 1 }} >
            <Grid container spacing={1}>
                <Grid container item xs={12} justifyContent="space-between">
                    <Typography> Equilibrio bilateral</Typography>
                    <Grid>
                        <IconButton onClick={deleteRow}> <Delete /></IconButton>
                        <IconButton ><DragIndicator /></IconButton>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Paper
                        ref={dropL.setNodeRef}
                        sx={{ p: 1, border: elevationL === 24 ? '2px dashed lightGrey' : '2px solid lightGrey' }}
                        elevation={elevationL}>
                        {reports[item.left] ? <Typography> {reports[item.left].name} </Typography> : <Typography color="GrayText"> Izquierda </Typography>}
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper
                        ref={dropB.setNodeRef}
                        sx={{ p: 1, border: elevationB === 24 ? '2px dashed lightGrey' : '2px solid lightGrey' }}
                        elevation={elevationB}>
                        {reports[item.both] ? <Typography> {reports[item.both].name} </Typography> : <Typography color="GrayText"> Ambos </Typography>}
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper
                        ref={dropR.setNodeRef}
                        sx={{ p: 1, border: elevationR === 24 ? '2px dashed lightGrey' : '2px solid lightGrey' }}
                        elevation={elevationR}>
                        {reports[item.right] ? <Typography> {reports[item.right].name} </Typography> : <Typography color="GrayText"> Derecha </Typography>}
                    </Paper>
                </Grid>

            </Grid>
        </Paper>
    )
}

const DroppableSingleRow = ({ bodyId, item, index, deleteRow, title, setRef, attributes, listeners }) => {
    const reports = useSelector((state) => state.trainer.reports[bodyId])
    const [elevation, setElevation] = useState(2);
    const drop = useDroppable({
        id: "singleDrop_" + index,
        data: { type: "DroppableSingleRaw" }
    });

    useEffect(() => {
        if (drop.active && drop.isOver) {
            setElevation(reports[drop.active.id] ? 24 : 2)
        } else {
            setElevation(2)
        }
    }, [drop])

    return (
        <Paper sx={{ m: 1, p: 1 }} >
            <Grid container spacing={1}>


                <Grid container item xs={12} justifyContent="space-between" alignContent="center">
                    <Typography> {title}</Typography>
                    <Grid>
                        <IconButton onClick={deleteRow}> <Delete /></IconButton>
                        <IconButton ><DragIndicator /></IconButton>
                    </Grid>
                </Grid>



                <Grid item xs={4}>
                    <Paper
                        ref={drop.setNodeRef}
                        sx={{ p: 1, border: elevation === 24 ? '2px dashed lightGrey' : '2px solid lightGrey' }}
                        elevation={elevation}>
                        {reports[item.raw] ? <Typography> {reports[item.raw].name} </Typography> : <Typography color="GrayText"> reporte único </Typography>}
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    )
}

const DraggableReport = ({ rawData, rawDataId }) => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({ id: rawDataId, data: { type: "rawData" } })

    // leemos por jquery 

    const style = transform ? {
        // position: 'fixed',
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;

    return (
        <Paper sx={{ p: 2, m: 1 }} ref={setNodeRef} style={style} {...listeners} {...attributes} >
            <Typography fontSize={16} variant="h5"> {rawData.name} {rawData.ndw}  </Typography>
            {rawData.series_resume && Object.keys(rawData.series_resume).map((i_serie) => {
                const serie = rawData.series_resume[i_serie];
                return <Typography fontSize={13}>Serie {i_serie} - {serie.side} </Typography>
            })}
        </Paper>
    )

}

const CreateBaseReport = ({ bodySelected, onSubmit }) => {
    const reports = useSelector((state) => state.trainer.reports[bodySelected]);
    const [reportList, setReportList] = useState([{ type: "bilateralIso", raw: undefined }]);
    const [valid, setValid] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setValid(validation());
    }, [reportList])

    const validation = () => {
        const l = reportList.map((item) => {
            if (item.type === "bilateral" && reports[item.right] && reports[item.left]) {
                return true;
            }
            else if (item.type === "single" && reports[item.raw]) {
                return true;
            } else if (item.type === "bilateralIso" && reports[item.raw]) {
                return true;
            } else {
                return false;
            }
        })
        if (l.length >= 1) {
            return l.reduce((a, b) => a && b)

        } else {
            return false;
        }
    }

    const handleDeleteRow = (index) => {
        setReportList((old) => {
            old.splice(index, 1);
            return [...old]
        })
    }

    const handleSubmit = () => {
        onSubmit(reportList);
    }

    const checkLeftAndRight = (rawDataId) => {
        const resume = reports[rawDataId].series_resume;

        let hasLeft = false;
        let hasRight = false;

        for (let key in resume) {
            if (resume.hasOwnProperty(key)) {
                if (resume[key].side === "left") {
                    hasLeft = true;
                } else if (resume[key].side === "right") {
                    hasRight = true;
                }
            }
        }
        return hasLeft && hasRight;
    }

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if(active && over){
            const [side, overIndex] = over.id.split("_");
            if (overIndex >= 0 && reportList[overIndex].type === "bilateral") {
                    setReportList((old) => {
                    if (side === "dropR")
                    old[overIndex].right = active.id;
                    if (side === "dropL")
                    old[overIndex].left = active.id;
                    if (side === "dropB")
                    old[overIndex].both = active.id;
                    return [...old];
                });
            } else if (overIndex >= 0 && reportList[overIndex].type === "single") {
                setReportList((old) => {
                    old[overIndex].raw = active.id;
                    return [...old];
                });
            } else if (overIndex >= 0 && reportList[overIndex].type === "bilateralIso") {
                if (checkLeftAndRight(active.id)) {

                    setReportList((old) => {
                        old[overIndex].raw = active.id;
                        return [...old];
                    });
                } else {
                    dispatch(createNotification({ message: "Necesita un reporte con series de ambos lados", type: "warning" }));
                    // alert("No tiene ambos lados para hacer bilateral isometrico");
                }
            } else {
                console.log("no se ha definido este tipo");
            }
        }
    }
    
    return (
        <DndContext onDragEnd={handleDragEnd}>
            <Grid container>
                <div className={style.contentBox}>
                {/* PARTE IZQUIUERDA: Listado de reportes */}
                    <div className={style.parteIzquierda}>
                        {reportList.map((item, index) => {
                            if (item.type === "bilateral")
                                return < DroppableRowBilateralRawData bodyId={bodySelected} item={item} index={index} deleteRow={() => { handleDeleteRow(index) }} />
                            else if (item.type === "single")
                                return < DroppableSingleRow title="Reporte simple" bodyId={bodySelected} item={item} index={index} deleteRow={() => { handleDeleteRow(index) }} />
                            else if (item.type === "bilateralIso")
                                return < DroppableSingleRow title="Reporte bilateral isométrico" bodyId={bodySelected} item={item} index={index} deleteRow={() => { handleDeleteRow(index) }} />
                        })}
                        <Grid item xs={12}>
                            {/* <Button onClick={() => { setReportList((old) => { return [...old, { type: "single" }] }) }}>Añadir reporte simlpe</Button>
                            <Button onClick={() => { setReportList((old) => { return [...old, { type: "bilateral" }] }) }}> Añadir equilibrio bilateral</Button> */}
                            <Button onClick={() => { setReportList((old) => { return [...old, { type: "bilateralIso" }] }) }}> Añadir equilibrio bilateral Isométrico</Button>
                        </Grid>
                    </div>

                    {/* PARTE DERECHA: Listado de datos brutos */}
                    <div className={style.parteDerecha}>
                        {Object.keys(reports).map((r_key) => {
                            if (reports[r_key].updated && reports[r_key].series_resume)
                                return <DraggableReport rawData={reports[r_key]} rawDataId={r_key} />
                        })}
                    </div>
                </div>
                {/* Boton de continuar */}
                <Grid item xs={12}>
                    <Button onClick={handleSubmit} disabled={!valid}> Continuar </Button>
                </Grid>
            </Grid>
        </DndContext>
    );
}

export default CreateBaseReport;



