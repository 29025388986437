import React, { Fragment, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Profile from "../../components/user/Profile";
import ProfileStepper from "../../components/user/ProfileStepper";
import { FirebaseContext } from "../../firebase";
import { setUserLocalData } from "../../reducers/userReducer";
import UserConfig from "../../components/user/UserConfig";

const ProfileView = () => {
    const {firebase} = useContext(FirebaseContext);
    const dispatch = useDispatch();
    const {userUpdated, user, auth} = useSelector((state) => state.user)

    useEffect(() => {
        if( !userUpdated && auth ) {
            firebase.getUserInfo((data) => { dispatch(setUserLocalData(data)) });
        }
    }, [userUpdated, firebase, dispatch, auth])

    return (
        <div> 
            <UserConfig/>
            {auth && user &&
                <Fragment>
                    <Profile userObj={ user }/>
                </Fragment>
            }
            { auth && userUpdated?
                <ProfileStepper/>
                :
                <></>
            }
        </div>
    );
}

export default ProfileView;