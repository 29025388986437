
// En KG
const conversion = 1;

class BilateralData {
    constructor(objectLeft, objectRight) {
        this.left_f_average = objectLeft.f_average / conversion;
        this.right_f_average = objectRight.f_average / conversion;
        this.f_average_total = this.right_f_average + this.left_f_average;
        this.right_f_perc_average = (this.right_f_average / this.f_average_total) * 100;
        this.left_f_perc_average = (this.left_f_average / this.f_average_total) * 100;

        this.left_f_max = objectLeft.f_max / conversion;
        this.right_f_max = objectRight.f_max / conversion;
        this.f_max_total = this.right_f_max + this.left_f_max;
        this.right_f_perc_max = (this.right_f_max / this.f_max_total) * 100;
        this.left_f_perc_max = (this.left_f_max / this.f_max_total) * 100;

        this.left_impulse = objectLeft.inertia;
        this.right_impulse = objectRight.inertia;
        this.impulse_total = this.right_impulse + this.left_impulse;
        this.right_perc_impulse = (this.right_impulse / this.impulse_total) * 100;
        this.left_perc_impulse = (this.left_impulse / this.impulse_total) * 100;

        this.left_time = objectLeft.time;
        this.right_time = objectRight.time;
        this.time_total = this.right_time + this.left_time;
        this.right_perc_time = (this.right_time / this.time_total) * 100;
        this.left_perc_time = (this.left_time / this.time_total) * 100;

        this.left_time_peak = objectLeft.time_to_peak;
        this.right_time_peak = objectRight.time_to_peak;
        this.time_peak_total = this.right_time_peak + this.left_time_peak;
        this.right_perc_time_peak = (this.right_time_peak / this.time_peak_total) * 100;
        this.left_perc_time_peak = (this.left_time_peak / this.time_total) * 100;
    }

    toJSON() {
        return {
            left_f_average: this.left_f_average,
            right_f_average: this.right_f_average,
            f_average_total: this.f_average_total,
            right_f_perc_average: this.right_f_perc_average,
            left_f_perc_average: this.left_f_perc_average,
            left_f_max: this.left_f_max,
            right_f_max: this.right_f_max,
            f_max_total: this.f_max_total,
            right_f_perc_max: this.right_f_perc_max,
            left_f_perc_max: this.left_f_perc_max,
            left_impulse: this.left_impulse,
            right_impulse: this.right_impulse,
            impulse_total: this.impulse_total,
            right_perc_impulse: this.right_perc_impulse,
            left_perc_impulse: this.left_perc_impulse,
            left_time: this.left_time,
            right_time: this.right_time,
            time_total: this.time_total,
            right_perc_time: this.right_perc_time,
            left_perc_time: this.left_perc_time,
            left_time_peak: this.left_time_peak,
            right_time_peak: this.right_time_peak,
            time_peak_total: this.time_peak_total,
            right_perc_time_peak: this.right_perc_time_peak,
            left_perc_time_peak: this.left_perc_time_peak,
        };
    }
}

export default BilateralData;