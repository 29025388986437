import React, { useContext, useEffect, useState } from 'react';
import { Visibility, VisibilityOff, LockOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { FirebaseContext } from '../../firebase';
import ViewLayer from '../utils/ViewLayer';
import texts from '../../constants/texts/texts';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../reducers/userReducer';
import { createNotification } from '../../reducers/notificationReducer';
import * as Yup from 'yup'
import { useFormik } from 'formik';


const validationSchema = Yup.object({
  email: Yup.string()
    .email('Introduce un email válido')
    .required("Email es necesario"),
  password: Yup.string()
    .min(6, "La contraseña debe tener al menos 6 caracteres")
    .required("La contraseña es necesaria"),
  remember: Yup.bool(),
})

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { firebase } = useContext(FirebaseContext);
  const { auth, logedIn } = useSelector((state) => state.user);

  useEffect(() => {
    if (auth && logedIn)
      navigate("/");
  }, [auth, navigate, logedIn])

  const handleSubmit = (data) => {
    firebase.SignIn(data.email, data.password, data.remember).then((userCredential) => {
      const userData = {
        name: userCredential.user.email,
      }
      dispatch(login(userData));
      dispatch(createNotification({ message: texts.singinsuccess, type: "success" }))
    }).catch((error) => {
      dispatch(createNotification({ message: texts.signinerror, type: "error" }))
    })
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    }
  })

  const [passwordVisible, setPasswordVisible] = useState(false);

  const toggleVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  return (<ViewLayer>
    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
      <LockOutlined />
    </Avatar>
    <Typography component="h1" variant="h5">
      {texts.signin}
    </Typography>
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type={passwordVisible ? "text" : "password"}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={toggleVisibility}
              >
                {passwordVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
        }}
        id="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        autoComplete="current-password"
      />
      <FormControlLabel
        control={<Checkbox color="primary"
          id="remember"
          name="remember"
          value={formik.values.remember}
          onChange={formik.handleChange} />}
        label={texts.rememberme}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        {texts.signin}
      </Button>
      <Grid container>
        <Grid item xs>
          <Link component={NavLink} to="/forgotpassword" variant="body2">
            {texts.forgotPassword}
          </Link>
        </Grid>
        <Grid item>
          <Link component={NavLink} to="/signup" variant="body2">
            {texts.donthaveaccount}
          </Link>
        </Grid>
      </Grid>
    </Box>
  </ViewLayer>
  );
}
export default SignIn;