import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { FirebaseContext } from '../../firebase';
import ViewLayer from '../utils/ViewLayer';
import { useDispatch } from 'react-redux';
import { createNotification } from '../../reducers/notificationReducer';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { CircularProgress, InputAdornment } from '@mui/material';


const FormUserInfo = ({ userData, onSubmit }) => {
  const { firebase } = useContext(FirebaseContext);
  const dispatch = useDispatch();
  const initialValues = userData ? userData : { username: "" };
  console.log("HAAAAAAAAAYAYAYA",userData);
  const [isSubmiting, setIsSubmiting ] = useState(false);
  
  const validationSchema = Yup.object({
    username: Yup.string()
      .min(4, "El nombre de usuario debe tener al menos 4 letras")
      .required("Nombre de usuario requerido")
      .test('username', "Este nombre de usuario ya existe", async(value) => {if (value)  return firebase.checkUsernameAvaiable(value); else return true} ),
  })

  const handleSubmit = async (data) => {
    setIsSubmiting(true);
    const new_data = { profileCompleted: false, ...userData, ...data }
    const isAvaiable = await firebase.checkUsernameAvaiable(data.username);

    if(isAvaiable ){
      firebase.setUserInfo(new_data).then(() => {
        if (onSubmit) {
          onSubmit();
        }
        dispatch(createNotification({ message: "Se ha modificado la información satisfactoriamente", type: "success" }))
      }).catch((error) => {
        console.log("el error : ", error);
        dispatch(createNotification({ message: "Ha habido un error al actualizar info", type: "error" }))
      })
    }else{
    }
    setIsSubmiting(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      handleSubmit(values);
    }
  })
  console.log(formik.errors);

  return (<ViewLayer>
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
      <TextField
        label="Username"
        margin="normal"
        required
        fullWidth
        id="username"
        name="username"
        autoComplete="username"
        value={formik.values.username}
        onChange={formik.handleChange}
        error={formik.touched.username && Boolean(formik.errors.username)}
        helperText={formik.touched.username && formik.errors.username}
        autoFocus
      />
      {isSubmiting? 
        <Button disabled type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          <CircularProgress color="primary" />
        </Button>
        : 
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Guardar
        </Button>
      }
    </Box>
  </ViewLayer>
  );
}
export default FormUserInfo;