import { useEffect } from "react";
import { useNavigate} from "react-router";

// Esta funcion deberá proteger las rutas privadas
export const ProtectedRoute = ({ auth, children }) => {
  const navigate = useNavigate();
  useEffect(() => {
      if ( !auth ) {
        return navigate("/signin");
      }
  }, [auth])

  return (children);
};
