import JSZip from 'jszip';
import useExerciseModel, { CreateExerciseModelPromise, ExerciseRawData } from '../reports/useExerciseModel';
import downloadXLSX, { convertToXLSX } from './DownloadXlsx';

export const DownloadAllBodiesReports = async (bodies, bodiesReports, setProgressMessage ,onFinish) => {
    if(Object.keys(bodies).length > 0){
        // console.log("Vamos a descargar");
        const zip = new JSZip();        
        for(let bodyId in bodies){
            if( bodies.hasOwnProperty(bodyId) && bodyId !== "defaultBody" ){
                const body = bodies[bodyId];
                const reports = bodiesReports[bodyId]
                if(Object.keys(reports).length > 0){
                    const folderName = body.name;
                    // console.log("Se están descargando de :", folderName);
                    let i = 0;
                    for(let repKey in reports){
                        i = i + 1;
                        if( reports.hasOwnProperty(repKey) ){
                            let report = reports[repKey];
                            const newName = ((report.name? report.name : "") + "_" + report.filename + ".csv").replace(/\s/g, "_");
                            const newNamexlsx = ((report.name? report.name : "") + "_" + report.filename + ".xlsx").replace(/\s/g, "_");

                            if(setProgressMessage) setProgressMessage(folderName + ": " + i + " / " + Object.keys(reports).length)

                            const response = await fetch(report.downloadUrl);
                            const csvData = await response.text();
                            try {
                                await CreateExerciseModelPromise(report.downloadUrl).then((exerciseModelData) => {
                                    const sheetList = exerciseModelData.series.map((serie) => [].concat(...serie.reps.map((rep) => rep.data )))
                                    const xlsx = convertToXLSX(sheetList);                                    
                                    zip.folder(folderName).file(newNamexlsx, xlsx);
                                });                                
                            } catch (err) {
                                console.log("error", err);                                
                            }
                            zip.folder(folderName).file(newName, csvData);
                        }
                    }
                }
            }
        }
        const zipFile = await zip.generateAsync({ type: 'blob' });
        
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipFile);
        link.download =  'reports.zip';
        link.click();
        
        // Limpiar el objeto URL creado
        URL.revokeObjectURL(link.href);
        if(onFinish) onFinish();
    }
}

const DownloadBodyReports = async (body, reports, setProgressMessage ,onFinish) => {    
    if(Object.keys(reports).length > 0){
        const zip = new JSZip();        
        // console.log(body);
        const folderName = body.name;
        let i = 0;

        for(let repKey in reports){
            i = i + 1;
            if( reports.hasOwnProperty(repKey)){
                if(setProgressMessage) setProgressMessage(folderName + ": " + i + " / " + Object.keys(reports).length)
                let report = reports[repKey];
                const newName = ((report.name? report.name : "") + "_" + report.filename + ".csv").replace(/\s/g, "_");
                const newNamexlsx = ((report.name? report.name : "") + "_" + report.filename + ".xlsx").replace(/\s/g, "_");

                const response = await fetch(report.downloadUrl);
                const csvData = await response.text();
                try {
                    await CreateExerciseModelPromise(report.downloadUrl).then((exerciseModelData) => {
                        
                        const sheetList = exerciseModelData.series.map((serie) => [].concat(...serie.reps.map((rep) => rep.data )))
                        const xlsx = convertToXLSX(sheetList);                                    
                        zip.folder(folderName).file(newNamexlsx, xlsx);
                    });                                
                } catch (err) {
                    console.log("error", err);                                
                }

                zip.folder(folderName).file(newName, csvData);
                
            }
        }
        const zipFile = await zip.generateAsync({ type: 'blob' });
        
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipFile);
        link.download = folderName + '.zip';
        link.click();
        
        // Limpiar el objeto URL creado
        URL.revokeObjectURL(link.href);
        if(onFinish) onFinish();
    }
}

export default DownloadBodyReports;