import { configureStore } from "@reduxjs/toolkit";
import bodyReducer from "./bodyReducer";
import notificationReducer from "./notificationReducer";
import trainerReducer from "./trainerReducer";
import userReducer from "./userReducer";
import complexReportReducer from "./complexReportReducer";


export const store = configureStore({
    reducer: {
        user: userReducer,
        notification: notificationReducer,
        trainer: trainerReducer,
        bodys: bodyReducer,
        complexReports: complexReportReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
})
