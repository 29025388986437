
const MuscleGroups = {
    "head": "Cabeza",
    "neck": "Cuello", // SI
    "trapezius": "Trapecio",// SI
    "biceps": "Bíceps", // SI
    "triceps": "Tríceps", // SI
    "chest": "Pectorales", // SI
    "upper-back": "Dorsal",  // SI
    "lower-back": "Lumbares",
    "forearm": "Antebrazo",
    "back-deltoids": "Deltoides posterior",
    "front-deltoids": "Deltoides anterior",
    "abs": "Abdominales", // SI
    "gluteal": "Glúteos", // SI
    "obliques": "Oblicuos",
    "quadriceps": "Cuádriceps", // SI
    "adductor": "Aductor",
    "abductors": "Abductores",
    "hamstring": "Isquiotibiales", // SI
    "calves": "Gemelos", // SI
    "knees": "Tibiales",
    // "left-soleus": "Sóleo izquierdo",
    // "right-soleus": "Sóleo derecho",
};

export const TsGroups = [
    "head",
    "neck",
    "trapezius",
    "biceps",
    "triceps",
    "chest",
    "upper-back",
    "front-deltoids",
];

export const TiGroups = [
    "abs",
    "gluteal",
    "obliques",
    "quadriceps",
    "adductor",
    "abductors",
    "hamstring",
    "calves",
    "knees",
    "left-soleus",
    "right-soleus",
];

export const CoreGroups = [
    "abs",
    "obliques",
];

export const AgonistAntagonistPairs = [
    {agonist: "biceps", antagonist: "triceps"},
    {agonist: "quadriceps", antagonist: "hamstring"},
]


    // TODO: Create assginments 
    // neck: "Cuello",
    // pectoral: "Pectoral",
    // dorsal: "Dorsal",
    
    // biceps: "Bíceps",
    // triceps: "Tríceps",
    // antebrazos: "Antebrazos",
    // abdominales: "Abdominales",
    // oblicuos: "Oblicuos",
    // lumbares: "Lumbares",
    // gluteos: "Glúteos",
    // tfl: "TFL",
    // flex_cad: "Flex. Cadera",
    // cuadriceps: "Cuádriceps",
    // isquiotibiales: "Isquiotibiales",
    // gemelos: "Gemelos",
    // soleos: "Sóleos",
    // tibiales: "Tibiales",
    // tobillo_pie: "Tobillo/Pie",
    // trapecios: "Trapecios",
    // deltoides: "Deltoides",
    
    /* Back */
    // trapezius
    // upper-back
    // lower-back
    
    // /* Chest */
    // chest
    
    // /* Arms */
    // biceps
    // triceps
    // forearm
    // back-deltoids
    // front-deltoids
    
    // /* Abs */
    // abs
    // obliques
    
    // /* Legs */
    // adductor
    // hamstring
    // quadriceps
    // abductors
    // calves
    // gluteal
    
    // /* Head */
    // head
    // neck
    


export default MuscleGroups;

