import * as Yup from "yup"
import texts from "../constants/texts/texts";

const SchemaGenerator = (schemasList) => {
    const schemas  = {};
    schemasList.forEach(element => {
        if(element.type === "number"){
            var o = Yup.number()
            .moreThan(element.minValue - 1, texts.number_more_than+" "+element.minValue )
            .lessThan(element.maxValue + 1, texts.number_less_than+" "+element.maxValue );
            if(element.required){
                o = o.required(texts.number_required)
            }else{
                o = Yup.number()
                .moreThan(element.minValue - 1, texts.number_more_than+" "+element.minValue )
                .lessThan(element.maxValue + 1, texts.number_less_than+" "+element.maxValue )
            }
            schemas[element.varName] = o;
        }else if(element.type === "string"){
            var o = {};
            if(element.required){
                o = Yup.string()
                .min(element.minValue, texts.string_more_than+" "+element.minValue )
                .max(element.maxValue, texts.string_less_than+" "+element.maxValue )
                .required(texts.string_required)
            }else{
                o = Yup.string()
                .min(element.minValue, texts.string_more_than+" "+element.minValue )
                .max(element.maxValue, texts.string_less_than+" "+element.maxValue )
            }
            schemas[element.varName] = o;
        }else if(element.type === "array"){
            var o = {};
            if(element.required){
                o = Yup.array()
                .required(texts.string_required)
            }else{
                o = Yup.array()
            }
        }
    });
    
    return Yup.object(schemas);
}
 
export default SchemaGenerator;

