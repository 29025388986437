import Papa from 'papaparse';
import { useEffect, useState } from "react";

const herz = 100;

// Funcion que calcula la diferencia de valores y genera un nuevo vector con dichos valores con la ventana que uno desee
const CalculateDiference = (vector, window) => {
    var new_vector = [];

    for (var i = 1; i < vector.length; i++) {
        const new_value = vector[i] - vector[i - 1];
        new_vector.push(new_value);
    }
    // Aqui calculariamos la ventana
    if (window >= 1 && new_vector.length > window) {


    }

    return new_vector;


}

class RepRawData {
    constructor(data, i_rep, i_serie) {
        this.division_index = data.findIndex((obj, index) => {
            if (index === 0) return false;
            return obj["phase"] === "e" && data[index - 1]["phase"] === "c";
        });

        this.data = data;
        this.i_rep = i_rep;
        this.i_serie = i_serie;

        this.f_serie = this.data.map((item) => item["strength"]);
        this.f_average = parseFloat((this.f_serie.reduce((a, b) => a + b) / this.f_serie.length).toFixed(1));
        this.f_max = parseFloat(Math.max(...this.f_serie).toFixed(1));

        this.pot_serie = this.data.map((item) => item["strength"] * item["velocity"] / 100);// Pasamos a m/s  (en realida vienen en cm/s)
        this.pot_average = parseFloat((this.pot_serie.reduce((a, b) => a + b) / this.pot_serie.length).toFixed(1));
        this.pot_max = parseFloat(Math.max(...this.pot_serie).toFixed(1));

        // Si están en ms hay que dividir entre 1000, esto es una aproximación para calcular el area bajo la curva
        this.inertia = parseFloat((this.f_serie.reduce((a, b) => a + b) / herz).toFixed(1))
        // Si queremos kg tenemos que dividir entre 9.81 

        this.vel_serie = this.data.map(row => { return row["velocity"] });
        this.vel_average = parseFloat((this.vel_serie.reduce((a, b) => a + b) / this.vel_serie.length).toFixed(1));
        this.vel_max = parseFloat(Math.max(...this.vel_serie).toFixed(1));

        this.pos_serie = this.data.map(row => { return row["position"] });
        this.pos_average = parseFloat((this.pos_serie.reduce((a, b) => a + b) / this.pos_serie.length).toFixed(1));
        this.pos_max = parseFloat(Math.max(...this.pos_serie).toFixed(1));

        this.time = parseFloat((this.data.length / herz).toFixed(2)); // ms 

        this.time_to_peak = parseFloat((this.f_serie.indexOf(Math.max(...this.f_serie)) / herz).toFixed(2));
    }
    getJsonResume() {
        return {
            f_average: this.f_average,
            f_max: this.f_max,
            vel_average: this.f_average,
            vel_max: this.f_max,
            pot_average: this.pot_average,
            pot_max: this.pot_max,
            inertia: this.inertia,
            time: this.time,
            time_to_peak: this.time_to_peak,
        }
    }

}

class SerieRawData {
    constructor(data, i_serie) {
        this.data = data;
        this.i_serie = i_serie;

        this.f_serie = this.data.map((item) => item["strength"]);
        this.f_average = parseFloat((this.f_serie.reduce((a, b) => a + b) / this.f_serie.length).toFixed(1));
        this.f_max = parseFloat(Math.max(...this.f_serie).toFixed(1));

        this.pot_serie = this.data.map((item) => item["strength"] * item["velocity"] / 100);// Pasamos a m/s  (en realida vienen en cm/s)
        this.pot_average = parseFloat((this.pot_serie.reduce((a, b) => a + b) / this.pot_serie.length).toFixed(1));
        this.pot_max = parseFloat(Math.max(...this.pot_serie).toFixed(1));


        // Si están en ms hay que dividir entre 1000, esto es una aproximación para calcular el area bajo la curva
        this.inertia = parseFloat((this.f_serie.reduce((a, b) => a + b) / herz).toFixed(1))
        // Si queremos kg tenemos que dividir entre 9.81 

        this.vel_serie = this.data.map(row => { return row["velocity"] });
        this.vel_average = parseFloat((this.vel_serie.reduce((a, b) => a + b) / this.vel_serie.length).toFixed(1));
        this.vel_max = parseFloat(Math.max(...this.vel_serie).toFixed(1));

        this.pos_serie = this.data.map(row => { return row["position"] });
        this.pos_average = parseFloat((this.pos_serie.reduce((a, b) => a + b) / this.pos_serie.length).toFixed(1));
        this.pos_max = parseFloat(Math.max(...this.pos_serie).toFixed(1));

        this.time = this.data.length / herz; // ms 

        this.n_reps = Math.max(...data.map((item) => item["rep"]));
        this.reps = Array.from({ length: this.n_reps + 1 }, (_, i) => i).map((i_rep) => {
            const r_data = data.filter((row) => row["rep"] === i_rep);
            if (r_data.length > 0) {
                return new RepRawData(r_data, i_rep, i_serie);
            }
            else return undefined;
        }).filter((item) => item !== undefined)

        this.best_rep = this.reps[0];
        this.time_to_peak = 0;
        this.reps.map((rep) => {
            this.time_to_peak = this.time_to_peak + rep.time_to_peak;
            if (rep.f_max > this.best_rep.f_max) {
                this.best_rep = rep;
            }
        })
        this.time_to_peak = this.time_to_peak / this.reps.length;

    }
    getJsonResume() {
        return {
            f_average: this.f_average,
            f_max: this.f_max,
            vel_average: this.f_average,
            vel_max: this.f_max,
            pot_average: this.pot_average,
            pot_max: this.pot_max,
            inertia: this.inertia,
            time: this.time,
            n_reps: this.n_reps,
            time_to_peak: this.time_to_peak,
        }
    }
}

export class ExerciseRawData {
    constructor(data) {
        this.data = data;

        this.f_serie = this.data.map((item) => item["strength"]);
        this.f_average = parseFloat((this.f_serie.reduce((a, b) => a + b) / this.f_serie.length).toFixed(1));
        this.f_max = parseFloat(Math.max(...this.f_serie).toFixed(1));

        // Si están en ms hay que dividir entre 1000, esto es una aproximación para calcular el area bajo la curva
        this.inertia = parseFloat((this.f_serie.reduce((a, b) => a + b) / herz).toFixed(1))
        // Si queremos kg tenemos que dividir entre 9.81 
        this.pot_serie = this.data.map((item) => (item["strength"] * (item["velocity"] / 1000)));// Pasamos a m/s  (en realida vienen en cm/s)
        this.pot_average = parseFloat((this.pot_serie.reduce((a, b) => a + b) / this.pot_serie.length).toFixed(1));
        this.pot_max = parseFloat(Math.max(...this.pot_serie).toFixed(1));// Pasamos a m/s  (en realida vienen en cm/s)

        this.vel_serie = this.data.map(row => { return (row["velocity"] / 10) });
        this.vel_average = parseFloat((this.vel_serie.reduce((a, b) => a + b) / this.vel_serie.length).toFixed(1));
        this.vel_max = parseFloat(Math.max(...this.vel_serie).toFixed(1));

        this.pos_serie = this.data.map(row => { return (row["position"] / 10) });
        this.pos_average = parseFloat((this.pos_serie.reduce((a, b) => a + b) / this.pos_serie.length).toFixed(1));
        this.pos_max = parseFloat(Math.max(...this.pos_serie).toFixed(1));

        this.imp_serie = []; // Este impulso está en N*S
        for (var i = 0; i < this.f_serie.length; i++) {
            var n_i = (this.f_serie[i] / herz) * (this.vel_serie[i] / herz);
            this.imp_serie.push(n_i)
        }

        this.time = this.data.length / herz; // ms 

        this.n_series = Math.max(...data.map((item) => item["serie"]));
        // List of objects SerieRawData 
        this.series = Array.from({ length: this.n_series + 1 }, (_, i) => i + 1).map((i_serie) => {
            const s_data = data.filter((row) => row["serie"] === i_serie);
            if (s_data.length > 0) {
                return new SerieRawData(s_data, i_serie);

            }
            else return undefined;
        }).filter((item) => item !== undefined)

        this.best_serie = this.series[0];
        this.series.map((serie) => {
            if (serie.f_max > this.best_serie.f_max) {
                this.best_serie = serie;
            }
        })
    }
    getJsonResume() {
        return {
            f_average: this.f_average,
            f_max: this.f_max,
            vel_average: this.f_average,
            vel_max: this.f_max,
            pot_average: this.pot_average,
            pot_max: this.pot_max,
            inertia: this.inertia,
            time: this.time,
            n_series: this.n_series
        }
    }
}

export const CreateExerciseModelPromise = (url) =>  {
    return new Promise((resolve, reject) => {
      Papa.parse(url, {
        header: true,
        download: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete: (result) => {
            let ex = new ExerciseRawData(result.data);
            resolve(ex);
        },
        error: (error) => {
            alert("Ha ocurrido un error al intentar leer el csv");
            reject(error);
        }
      });
    });
  }



// Esto es un hook que instancia la estructura de datos que se crean a partir de los datos brutos obtenidos del csv
const useExerciseModel = (url) => {
    const [exerRaw, setExerRaw] = useState();
    useEffect(() => {
        url && Papa.parse(url, {
            header: true,
            download: true,
            skipEmptyLines: true,
            dynamicTyping: true,
            complete: getData
        })
    }, [url])

    const getData = (result) => {
        try {
            const ex = new ExerciseRawData(result.data);
            setExerRaw(ex);
        } catch (error) {
            alert("Ha ocurrido un error al intentar leer el csv");
        }
    }

    return exerRaw;
}

export default useExerciseModel;