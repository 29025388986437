import { Fragment, useContext } from "react";
import { FirebaseContext } from "../../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { createNotification } from "../../../reducers/notificationReducer";
import texts from '../../../constants/texts/texts'
import { Button, DialogContent, Grid, Typography } from '@mui/material';
import exerciseData from "../../../constants/exercise";
import { FormSelector } from "../../utils/FormCreator";
import ImageForm from "../../utils/ImageForm";
import SchemaGenerator from '../../../schemas/SchemaGenerator';
import { useFormik } from "formik";
import { useEffect } from "react";

const CreateExercise = ({ exerciseId, onSubmit, copy }) => {
    const exercise = useSelector((state) => state.trainer.exercises[exerciseId])
    const dispatch = useDispatch();
    const { firebase } = useContext(FirebaseContext);

    const values = {};
    useEffect(() => {
        // if (copy) {
        //     values.iniImage = undefined;
        //     values.interImage = undefined;
        //     values.endImage = undefined;
        // }
    }, [])

    exerciseData.forEach(element => {
        values[element.varName] = exercise && exercise[element.varName] ? exercise[element.varName] : element.defaultValue;
    });
    const validationSchema = SchemaGenerator(exerciseData);

    const getDataObjects = (varNames, objectList) => {
        const formValues = varNames.map((nam) => objectList.find((item) => item.varName === nam)).filter((item) => item != undefined);
        return formValues
    }

    const formik = useFormik({
        initialValues: copy ? { ...values, iniImage: undefined, interImage: undefined, endImage: undefined } : values,
        validationSchema: validationSchema,
        onSubmit: (values) => {

            handleSubmit(values);
        }
    })

    const handleSubmit = (data) => {
        const { iniImage, interImage, endImage, ...rest } = data;
        if (exercise && !copy) {
            firebase.modifyExercise(exerciseId, data).then((doc) => {
                dispatch(createNotification({ message: "Ejercicio modificado", type: "success" }))
            }).catch((error) => {
                dispatch(createNotification({ message: "Error al modificar ejercicio" + error, type: "error" }))
            })
        } else {
            firebase.createNewExercise(data).then((doc) => {
                dispatch(createNotification({ message: texts.create_exercise_success, type: "success" }))
            }).catch((error) => {
                dispatch(createNotification({ message: texts.create_exercise_error, type: "error" }))
            })
        }
    }


    const part1 = getDataObjects(["name",], exerciseData);
    const images = getDataObjects(["iniImage", "interImage", "endImage"], exerciseData);
    const part2 = getDataObjects([
        "short_description",
        "important_points",
        "main_muscular_group",
        "muscular_group",
        "aplication",
        "position",
        "anatomic_plane",
        "exercise_benefits",
        "joint",
        "joint_movement",
        "move_chain",
    ], exerciseData);

    return (
        <Fragment>
            <Typography variant="h4" sx={{ pt: 3, px: 3 }}>{exercise ? copy ? "Copiar Ejercicio" : "Modificar Ejercicio" : "Crear Ejercicio"}</Typography>
            <DialogContent sx={{ px: 3, py: 1 }}>
                {part1.map((item) => {
                    return FormSelector(item, formik);
                })}
                <Grid container sx={{ mb: 2, mt: 1 }}>
                    {images.map((item) => {
                        if (item.formType === "image") {
                            return (
                                <Grid container item xs={4} alignContent="center" justifyContent="center" >
                                    <Typography >{item.name}</Typography>
                                    <ImageForm setBlob={(value) => formik.setFieldValue(item.varName, value)} imageUrl={formik.values[item.varName]} />
                                </Grid>
                            )
                        }
                    })}
                </Grid>
                {part2.map((item) => {
                    return FormSelector(item, formik);
                })}
            </DialogContent>
            <Button
                type="submit"
                // fullWidth
                onClick={formik.handleSubmit}
                variant="contained"
            >
                Aceptar
            </Button>

        </Fragment>
    );
}

export default CreateExercise;