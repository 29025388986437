import { Typography } from '@mui/material';
import React, { Fragment } from 'react';
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
} from 'recharts';
import texts from '../../constants/texts/texts';


const VerticalBarChart = ({ muscularResume }) => {
    var data = Object.keys(muscularResume).map((musc) => {
        return {
            name: texts.muscular_group[musc],
            intensity: muscularResume[musc],
        }
    })
    const height = Object.keys(muscularResume).length * 50;

    return (
        <Fragment>
            <Typography>Músculos involucrados ({Object.keys(muscularResume).length})</Typography>
            <ResponsiveContainer minWidth={100} maxHeight={500} height={height > 500 ? 500 : height} >
                <ComposedChart
                    data={data}
                    layout="vertical"
                    margin={{
                        top: 10,
                        right: 20,
                        bottom: 10,
                        left: 20,
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" scale="auto" fontSize={12} />
                    <Bar dataKey="intensity" fill="#413ea0" />
                </ComposedChart>
            </ResponsiveContainer>
        </Fragment>
    );
}
export default VerticalBarChart;