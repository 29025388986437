import React, { useState, useEffect, useContext } from 'react';
import { Switch, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, SwipeableDrawer } from '@mui/material';
import { AccountCircle, Logout, DarkMode, Settings, Receipt} from '@mui/icons-material' 
import texts from '../../constants/texts/texts'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FirebaseContext } from "../../firebase";
import { logout } from "../../reducers/userReducer";
import { clean } from "../../reducers/trainerReducer";
import { createNotification } from "../../reducers/notificationReducer";



// Images
import MyoLogoH from '../../assets/logos/bgpanel.png'; // logo


// Styles
import style from "./ProfileDrawer.module.scss";

export default function ProfileDrawer(props) {
    const nav = useNavigate();
    const { themeMode, setThemeMode } = props;
    const { user } = useSelector((state) => state.user);
    const [id, setId] = useState("");

    const navigate = (path) => {
        toggleDrawer();
        nav(path);
    };

    const toggleDrawer = () => {
        props.setState(!props.state);
        user.uid ? setId(user.uid) : <></>
    };

    useEffect(() => {
        if (user) {
            if (user.uid) {
                setId(user.uid)
            }
        }
        return;
    }, [user]);

    const handleToggleTheme = () => {
        setThemeMode((old) => {
            if (old === "dark") {
                localStorage.setItem("theme", "light");
                return "light";
            } else {
                localStorage.setItem("theme", "dark");
                return "dark";
            }
        });
    };

    const dispatch = useDispatch();
    const { firebase } = useContext(FirebaseContext);

    const handleSignOut = () => {
        toggleDrawer();
        firebase.SignOut().then(() => {
            dispatch(clean());
            dispatch(logout());
            dispatch(createNotification({ message: texts.logoutsuccess, type: "success" }));
        }).catch((err) => {
            dispatch(clean());
            dispatch(logout());
            dispatch(createNotification({ message: texts.logouterror, type: "warning" }));
        })
    }

    const list = (
        <Box className={style.panelLateralBox}
            onKeyDown={() => toggleDrawer()}
        >
            <div className={style.topBox} >
                <div className={ `${style.customTP} tituloPagina` }>{texts.settings}</div>
                
                <img className={style.myologoh} src={props.themeMode === "light" ?
                                    MyoLogoH
                                    :
                                    MyoLogoH
                                } alt="example" width={"90"} />
            </div>
            <List
                // subheader={<ListSubheader>{texts.settings}</ListSubheader>}
            >
                <ListItem key={texts.profile} onClick={() => navigate(`/profile/${id}`)} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <AccountCircle />
                        </ListItemIcon>
                        <ListItemText className={style.elementoTexto} primary={texts.profile} />
                    </ListItemButton>
                </ListItem>
                {/** TEMA OSCURO */}
                {/* <ListItem key={texts.darkMode}>
                    <ListItemIcon>
                        <DarkMode />
                    </ListItemIcon>
                    <ListItemText primary={texts.darkMode} />
                    <Switch
                        edge="end"
                        checked={themeMode === "dark"}
                        onClick={handleToggleTheme}
                    />
                </ListItem> */}
                {/* <ListItem key={texts.billing} disablePadding>
                    <ListItemButton >
                        <ListItemIcon sx={{ pl: 0.4 }}>
                            <Receipt />
                        </ListItemIcon>
                        <ListItemText className={style.elementoTexto} primary={texts.billing} />
                    </ListItemButton>
                </ListItem>
                 */}
                {/* <ListItem key={texts.configuration} disablePadding>
                    <ListItemButton >
                        <ListItemIcon sx={{ pl: 0.4 }}>
                            <Settings />
                        </ListItemIcon>
                        <ListItemText className={style.elementoTexto} primary={texts.configuration} />
                    </ListItemButton>
                </ListItem> */}
                <ListItem key={texts.billing} disablePadding>
                    <ListItemButton onClick={handleSignOut}>
                        <ListItemIcon sx={{ pl: 0.4 }}>
                            <Logout />
                        </ListItemIcon>
                        <ListItemText className={style.elementoTexto} primary={texts.signout} />
                    </ListItemButton>
                </ListItem>
 
            </List>
        </Box>
    );

    return (
        <div>
            <React.Fragment>
                <SwipeableDrawer
                    onClose={() => toggleDrawer()}
                    open={props.state}
                    onOpen={() => toggleDrawer()}
                    anchor="right"
                >
                    {list}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}