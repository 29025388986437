import { Tooltip } from "@mui/material";
import { CartesianGrid, ComposedChart, Dot, Legend, Rectangle, ReferenceArea, ReferenceDot, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis } from "recharts";

// Componente que muestra una gráfica con áreas de colores
// Las áreas son una lista de json con el siguiente formato:
/**
 * {
        name: "etiqueta",
        color: "brown",
        maxRM: 95,
        minRM: 80,
        minVel: 0.30,
        maxVel: 0.50,
        code: 9,
    },
 */
// Pinta un punto con unas coordenadas pasadas por props
const ZoneChart = ({ areas, x, y }) => {
    // Se calculan los límites automaticamente para ajustar la grafica
    const maxY = areas ? Math.max(areas.map((rec) => rec.maxRM)) : 0
    const minY = areas ? Math.min(areas.map((rec) => rec.minRM)) : 0
    const maxX = areas ? Math.max(areas.map((rec) => rec.maxVel)) : 0
    const minX = areas ? Math.min(areas.map((rec) => rec.minVel)) : 0

    return (
        <ResponsiveContainer minWidth={300} minHeight={300} maxWidth={500} maxHeight={300} >
            < ScatterChart
                margin={{
                    top: 10,
                    right: 30,
                    bottom: 10,
                    left: 0,
                }
                }
            >
                <CartesianGrid stroke="#f5f5f5" />
                <Tooltip />
                <Legend />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                {/**Ejes con los dominios calculados */}
                <XAxis dataKey="vel" type="number" label={{ value: "(m/s)", position: "left", dx: -8, dy: 4, fontSize: 12 }} fontSize={12} domain={[minX, maxX]} />
                <YAxis dataKey="rm" type="number" label={{ value: "% RM", position: "bottom", dx: -14, dy: -8, fontSize: 12 }} fontSize={12} domain={[minY, maxY]} />
                {/**Si hay un punto seleccionado mostrar en la gráfica */}
                {/**Pintamos las áreas recibidas en los props */}
                {areas && areas.map((rec) => {
                    return <ReferenceArea
                        ifOverflow="extendDomain"
                        y1={rec.rangesRM[0]}
                        y2={rec.rangesRM[1]}
                        x1={rec.rangesVel[0]}
                        x2={rec.rangesVel[1]}
                        fill={rec.color}
                        radius={3}
                        stroke={rec.color}
                        label={{ value: rec.name, fontSize: 15, position: "center" }}
                    />
                })}
                {x && y && <ReferenceDot ifOverflow="extendDomain" isFront stroke="black" fill="black" x={x} y={y} r={4} label={{ value: x + "m/s - " + y + "%", position: "right", fontSize: 12, color: "black" }} />}
            </ScatterChart >
        </ResponsiveContainer >
    );
}

export default ZoneChart;