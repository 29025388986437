import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import SideBarMenu from './components/utils/SideBarMenu';
import RootRoutes from './routes/public.routes';
import { Provider } from 'react-redux';
import { store } from './reducers';
import NotificationSnackbar from './components/utils/NotificationSnackBar';
import firebase, { FirebaseContext } from './firebase';
import { createTheme, ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import './styles/normalize.css'; // Primero reseteamos el explorador 
import './styles/global.scss'; // Carga de css global

const App = () => {
  const [themeMode, setThemeMode] = useState("light");
  const [language, setLanguage] = useState("es");

  useEffect(() => {
    const themeStoraged = localStorage.getItem("theme");
    if (themeStoraged) {
      setThemeMode(themeStoraged)
    }
    const langStoraged = localStorage.getItem("lang");
    if (langStoraged) {
      setLanguage(langStoraged)
    }
  }, [])

  // "sans-serif", "roboto"
  const theme = createTheme({ 
    palette: { 
      primary: {
        light: '#F3C352',
        main: '#f0b527',
        dark: '#A87E1B',
        contrastText: '#fff',
      },
      secondary: {
        light: '#A96B40',
        main: '#944711',
        dark: '#67310B',
        contrastText: '#fff',
      },
    }, 
    typography: {
      "fontFamily": `"Barlow"`,
      "fontSize": 16,
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 500,
      "fontWeightBold": 700   } ,
      components: {
        // Name of the component
        MuiTextField: { 
            root: {
              // Some CSS
              borderRadius: '15px',
            }, 
        },
      },
    });
  return (
    <BrowserRouter >
    <StyledEngineProvider  injectFirst>
      <ThemeProvider theme={theme} >
        <Provider store={store}>
          <FirebaseContext.Provider value={{ firebase }}  >
            <NotificationSnackbar />
            <SideBarMenu themeMode={themeMode} setThemeMode={setThemeMode} language={language} setLanguage={setLanguage} />
              <CssBaseline/>
              {/* <Box sx={{mx:2, ml: 26, mt:5 }}> */}
              <Box>
                <RootRoutes />
              </Box>
          </FirebaseContext.Provider>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
