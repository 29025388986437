import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Fade, Tooltip, tooltipClasses, Zoom } from '@mui/material';

const TooltipComponent = styled(({ className, ...props }) => (
    <Tooltip
        enterDelay={1000}
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 300 }}
        {...props} classes={{ popper: className }} />
))(({ }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
}));

export default TooltipComponent;