import { React, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';

const GifCreator = (props) => {

    const imageList = props.images;

    {   
        /*Código para el funcionamiento del gif
        
        const [imageIndex, setImageIndex] = useState(0);

        const switchImage = () => {
            imageIndex === imageList.length - 1 ?
                setImageIndex(0)
                :
                setImageIndex(imageIndex + 1);
        }
        
        useEffect(() => {
            const interval = setInterval(() => {
                switchImage();
            }, 1000)
            return () => clearInterval(interval)
        }, [imageIndex]);

        Cambiar este Box por el Grid container en el return
        <Box
            component="img"
            alt={"Imagen: " + imageIndex}
            src={imageList[imageIndex]}
            width="100%"
        />
        */
    }

    const [xs0, setXs0] = useState(0);
    const [xs1, setXs1] = useState(0);
    const [xs2, setXs2] = useState(0);
    const [width0, setWidth0] = useState("0%");
    const [width1, setWidth1] = useState("0%");
    const [width2, setWidth2] = useState("0%");

    useEffect(() => {
        if(imageList[0] && imageList[1] && imageList[2]){
            setXs0(4); setXs1(4); setXs2(4);
            setWidth0("100%"); setWidth1("100%"); setWidth2("100%");
        }else if(imageList[0] && imageList[1]){
            setXs0(6); setXs1(6);
            setWidth0("67%"); setWidth1("67%");
        }else if(imageList[0] && imageList[2]){
            setXs0(6); setXs2(6);
            setWidth0("67%"); setWidth2("67%");
        }else if(imageList[1] && imageList[2]){
            setXs1(6); setXs2(6);
            setWidth1("67%"); setWidth2("67%");
        }else if(imageList[0]){
            setXs0(12);
            setWidth0("33%");
        }else if(imageList[1]){
            setXs1(12);
            setWidth1("33%");
        }else if(imageList[2]){
            setXs2(12);
            setWidth2("33%");
        }
    }, [imageList]);

    return (
        <Grid container spacing={2} justify-items="center" align-items="center">
            {imageList[0] ?
            <Grid item xs={xs0}>
                <Box
                    component="img"
                    alt={"Imagen: " + 0}
                    src={imageList[0]}
                    width={width0}
                />
            </Grid>
            :
            <></>
            }
            {imageList[1] ?
            <Grid item xs={xs1}>
                <Box
                    component="img"
                    alt={"Imagen: " + 1}
                    src={imageList[1]}
                    width={width1}
                />
            </Grid>
            :
            <></>
            }
            {imageList[2] ?
            <Grid item xs={xs2}>
                <Box
                    component="img"
                    alt={"Imagen: " + 2}
                    src={imageList[2]}
                    width={width2}
                />
            </Grid>
            :
            <></>
            }
        </Grid>
    );
}

export default GifCreator