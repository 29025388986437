const texts = {
    menu1: "Trainer2",
    menu2: "TestView2",
    menu3: "About2",
    menu4: "Menu 42",
    profile: "Perfil2",
    about: "Acerca de nosotros2",
    signin: "Iniciar sesión2",
    signup: "Registrar usuario2",
    signup2: "Registrarse2",
    signout: "Cerrar sesión2",
    signuperrordescription: "El correo especificado ya está en uso. Inicie sesión o recupere su contraseña.2",
    forgotpassworderrordescription: "El correo especificado no existe. Regístrese o pruebe con otro.2",
    alreadyaccount: "¿Ya tienes cuenta? Inicia sesión2",
    forgotPassword: "¿Olvidaste la constraseña?2",
    forgotPasswordTitle: "Cambiar contraseña2",
    donthaveaccount: "Registrar usuario2",
    nosesion: "No tienes iniciada sesión2",
    rememberme: "Recuerdame2",
    forgot: "Cambiar contraseña2",
    // notifications
    signinerror: "Email o contraseña incorrectos2",
    signuperror: "Error al crear usuario2",
    singinsuccess: "Iniciada sesión correctamente2",
    singupsuccess: "Creado usuario correctamente2",
    forgotpassemailsuccess: "Correo de cambio de contraseña enviado2",
    forgotpassemailerror: "Error en cambio de contraseña2",
    logouterror: "Error al cerrar sesión2",
    logoutsuccess: "Cerrada sesión correctamente2",
    // 
    aboutUsTitle: "Sobre nosotros2",
    aboutUsSubtitle: "MyoTrainer2",
    //Profile
    username: "Nombre de usuario2",
    weight: "Peso:2",
    height: "Altura:2",
    uploadImage: "Subir2",
    changeImage: "Cambiar Imagen2",
    cancel: "Cancelar2",
    //Profile Settings
    settings: "Ajustes2",    
    billing: "Facturación2",
    configuration: "Configuración2",
    FAQ: "Preguntas frecuentes2",  
    modifyProfile: "Modificar Perfil2",
    privacyAndSecurity: "Privacidad y Seguridad2",
    notifications: "Notificaciones2",
    language: "Idioma2",
    conect: "Conecta2",
    cookies: "Cookies2",
    qrCode: "Código QR2",
    darkMode: "Modo Oscuro2",
    help: "Ayuda2",
        helpCenter: "Centro de Ayuda2",
        contact: "Contacto2",
        problemReport: "Informar Problema2",
        frecuentQuestions: "Preguntas Frecuentes2",
    //Create Exercise
    create_exercise: "Crear Ejercicio2",
    create_exercise_name: "Nombre2", 
    create_exercise_description: "Descripción2", 
    create_exercise_start_image_url: "Link Posicion Inicial2", 
    create_exercise_end_image_url: "Link Posicion Final2",
    create_exercise_video_url: "Link Video Demostrativo2", 
    create_exercise_important_points: "Puntos Clave2", 
    create_exercise_muscular_group: "Grupo Muscular2",
    create_exercise_move_chain: "Cadena de Movimiento2", 
    create_exercise_anatomical_plane: "Plano Anatómico2", 
    create_exercise_benefits: "Beneficios del Ejercicio2",
    create_exercise_save: "Guardar Ejercicio2",
    create_exercise_name_min: "El nombre del ejercicio debe tener al menos 3 letras2",
    create_exercise_name_required: "Nombre de ejercicio requerido2",
    create_exercise_success: "Se ha creado un nuevo ejercicio2",
    create_exercise_error: "Error al crear nuevo ejercicio2",
    //Create Method
    create_method: "Crear Método2",
    create_method_name: "Nombre2", 
    create_method_strand: "Vertiente2", 
    create_method_intervention_zone: "Zona de Intervención2", 
    create_method_series: "Series2", 
    create_method_time: "Tiempo de Trabajo2", 
    create_method_reps: "Repeticiones2", 
    create_method_recovery_time: "Tiempo de Recuperación2", 
    create_method_time_btw_reps: "Tiempo entre Repeticiones2", 
    create_method_mode: "Modo2", 
    create_method_load: "Carga2",
    create_method_benefits: "Beneficios del Método2",
    create_method_save: "Guardar Método2",
    create_method_name_min: "El nombre del método debe tener al menos 3 letras2",
    create_method_name_required: "Nombre de método requerido2",
    create_method_exercise_required: "Ejercicio necesario para crear un método2",
    create_method_series_more_than: "Un método no puede tener series negativas2",
    create_method_series_less_than: "Un método no puede tener más de 100 series2",
    create_method_time_more_than: "Un método no puede tener tiempos de trabajo negativos2",
    create_method_time_less_than: "Un método no puede tener tiempos de trabajo de más de 120 segundos2",
    create_method_reps_more_than: "Un método no puede tener repeticiones negativas2",
    create_method_reps_less_than: "Un método no puede tener más de 100 repeticiones2",
    create_method_recovery_time_more_than: "Un método no puede tener tiempos de recuperación negativos2",
    create_method_recovery_time_less_than: "Un método no puede tener tiempos de recuperación de más de 300 segundos2",
    create_method_time_btw_reps_more_than: "Un método no puede tener tiempos entre repeticiones negativos2",
    create_method_time_btw_reps_less_than: "Un método no puede tener tiempos entre repeticiones de más de un minuto2",
    create_method_success: "Se ha creado un nuevo método2",
    create_method_error: "Error al crear nuevo método2",
    //Create Sesion
    create_session_name: "Nombre de Sesión2",
    create_session: "Crear Sesión2",
    create_program: "Crear programa2",
    //Create program
    create_program_name_min: "El nombre del programa debe tener al menos 3 letras2",
    create_program_name_required: "Nombre de programa requerido2",
    
    // OPTIONS OF SELECTS
    joint_movement: {
        flexion: "Flexión2",
        extension: "Extensión2", 
        rotacion_inter: "Rotación Interna2", 
        rotacion_exter: "Rotación Externa2", 
        abduccion: "Abducción2", 
        transversal: "Transversal2", 
        circundicion: "Circunducción2"
    },
    muscular_group: {
        cuello: "Cuello2", 
        trapecios: "Trapecios2", 
        pectoral: "Pectoral2", 
        deltoides: "Deltoides2", 
        dorsal: "Dorsal2", 
        biceps: "Bíceps2", 
        triceps: "Tríceps2", 
        antebrazos: "Antebrazos2", 
        abdominales: "Abdominales2",
        oblicuos: "Oblicuos2",
        lumbares: "Lumbares2",
        glúteos: "Glúteos2",
        tfl: "TFL2",
        flex_cad: "Flex. Cadera2",
        cuadriceps: "Cuádriceps2",
        isquiotibiales: "Isquiotibiales2",
        gemelos: "Gemelos2",
        soleos: "Sóleos2",
        tibiales: "Tibiales2",
        tobillo_pie: "Tobillo/Pie2",
    },
    joint: {
        cervicales_cuello: "Cervicales/Cuello2", 
        espalda_col: "Espalda/Columna2", 
        hombro: "Hombro2", 
        codo: "Codo2", 
        munieca: "Muñeca2", 
        cadera: "Cadera2", 
        rodilla: "Rodilla2", 
        tobillo: "Tobillo2", 
        multiarticular: "Multiarticular2"
    },
    aplication: {
        lanzar: "Lanzar2", 
        empujar: "Empujar2", 
        traccionar: "Traccionar2", 
        desplazamiento: "Desplazamiento2", 
        carrera: "Carrera2", 
        salto: "Salto2",
        agarre: "Agarre2",
        cod: "COD2"
    },
    anatomic_plane: {
        tumbado: "Tumbado2",
        sentado: "Sentado2",
        de_pie: "De Pie2",
        tumbado_lat_izq: "Tumbado Lat Izq2",
        tumbado_lat_der: "Tumbado Lat Der2",
        rodillas: "Rodillas2",
        una_rodilla: "Una Rodilla2"
    },
    move_chain: {
        cadena_anterior: "Cadena Anterior2",
        cadena_posterior: "Cadena Posterior2",
        dominante_cadera: "Dominante de Cadera2",
        dominante_rodilla: "Dominante de Rodilla2",
        core: "Core2",
        empuje: "Empuje2",
        traccion: "Tracción2"
    },
    // METHOD
    main_adaptation: {
        estructural: "Estructural2", 
        funcional: "Funcional2", 
        decision: "Toma de decisión2" 
    },
    working_mode: {
        tonico: "Tónico2", 
        cinetico: "Cinético2", 
        elastico: "Elástico2", 
        inercial_conico: "Inercial Cónico2", 
        inercial_yoyo: "Inercial Yoyo2", 
        isometrico: "Isométrico2", 
        vibratorio: "Vibratorio2", 
        propioceptivo: "Propioceptivo2"
    },
    body_type: {

        
    }

}

export default texts