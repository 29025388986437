import texts from "./texts/texts";

const OptionLists = {
    joint_movement: texts.joint_movement,
    muscular_group: texts.muscular_group,
    joint: texts.joint,
    aplication: texts.aplication,
    anatomic_plane: texts.anatomic_plane,
    move_chain: texts.move_chain,
    main_adaptation: texts.main_adaptation,
    working_mode: texts.working_mode,
    training_zone: texts.training_zone,
    body_type: texts.body_type,
    gender: texts.gender,
    side_type: texts.side_type,
}
export default OptionLists;