import style from "./MuscleCard.module.scss";

const ComparativeMiniBar = ({left, right, title, unit}) => {
    

    const total = left + right;
    const leftPercent = (left / total) * 100;
    const rightPercent = (right / total) * 100;
    
    return ( 
        <div className={style.progressBar}>
            <div className={style.leftBar} style={{ width: `${leftPercent}%` }}></div>
            <div className={style.rightBar} style={{ width: `${rightPercent}%` }}></div>
            <div className={style.leftLabel}>{left}</div>
            <div className={style.rightLabel}>{right}</div>
        </div>
     );
}
 
export default ComparativeMiniBar;