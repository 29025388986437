import { CandlestickChart, CandlestickChartTwoTone, Delete, Download, Edit, RemoveRedEye, RemoveRedEyeTwoTone, SsidChart, SsidChartOutlined, SsidChartRounded, SsidChartSharp, SsidChartTwoTone } from "@mui/icons-material";
import { Accordion, Button, Dialog, Grid, IconButton, Paper, Typography } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FirebaseContext } from "../../firebase";
import { createNotification } from "../../reducers/notificationReducer";
import BodyMuscularSelector from "../bodys/BodyMuscularSelector";
import ConfirmationDialog from "../utils/ConfirmationDialog";
import CreateComplexReport from "./CreateComplexReport";
import CreateReportData from "./CreateReportData";
import RawDataChart from "./charts/RawDataChart";
import RawDataController from "./useExerciseModel";
import ExerciseRepsChart from "./charts/ExerciseRepsChart";
import useExerciseModel from "./useExerciseModel";
import DataTableResumeExercise from "./DataTableResumeExercise";
import ComparativeChart from "./charts/ComparativeChart";
import ComparativeGlobalReps from "./charts/ComparativeGlobalReps";
import CreateDownlowadFile from "./CreateDownloadFile";
import RepDownloadSelector from "./RepDownloadSelector";
import DonutChart from "./charts/DonutChart";
import BilateralData from "./BilateralData";
import BarsBilateralComparativeChart from "./charts/BarsBilateralComparativeChart";

// Styles
import style from "./RawDataComponentWithCsv.module.scss";
import ResumeObjectExercise from "./ResumeObjectExercise";

// Dependencias de Tabs
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


const RawDataComponentWithCsv = ({ bodyId, reportId }) => {
    const [extended, setExtended] = useState(true);
    const body = useSelector((state) => state.bodys[bodyId])

    const report = useSelector((state) => state.trainer.reports[bodyId][reportId])
    const { firebase } = useContext(FirebaseContext);
    const dispatch = useDispatch();

    const exerciseModel = useExerciseModel(report.downloadUrl);

    const handleDelete = () => {
        firebase.deleteReport(bodyId, reportId, report).then(() => {
            dispatch(createNotification({ message: "Eliminados datos brutos correctamente", type: "success" }))
        }).catch(() => {
            dispatch(createNotification({ message: "Error al eliminar datos brutos", type: "error" }))
        });
    }

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    useEffect(() => {
        exerciseModel && setAverages({
            f_max: exerciseModel.f_max,
            f_average: exerciseModel.f_average,
            vel_max: exerciseModel.vel_max,
            vel_average: exerciseModel.vel_average,
        });
    }, [exerciseModel])




    const [bilateralData, setBilateralData] = useState();
    useEffect(() => {
        if (report.series_sides && report.series_resume) {
            let ladoIzquierdo, ladoDerecho;
            for (const key in report.series_resume) {
                if (report.series_resume.hasOwnProperty(key)) {
                    const serie = report.series_resume[key];
                    if (serie.side === "left") {
                        ladoIzquierdo = serie;
                    } else if (serie.side === "right") {
                        ladoDerecho = serie;
                    }
                }
            }
            if (ladoIzquierdo && ladoDerecho) {
                const bilateralObject = new BilateralData(ladoIzquierdo, ladoDerecho);
                setBilateralData(bilateralObject);
            }
        }
    }, [report])



    const [averages, setAverages] = useState();
    const [downloadDial, setDownloadDial] = useState(false);




    // Tabs 1: gráfica general 
    const [tabValue, setTabValue] = useState(0);
    const handleChange = (event, value) => {
        setTabValue(value);
    }


    // Tabs 2: gráficas fuerzas 
    const [tabFuerzas, setTtabFuerzas] = useState(0);
    const handleChangeTabFuerzas = (event, value) => {
        setTtabFuerzas(value);
    }

    
    // Tabs 3: gráficas de donutss
    const [tabDonuts, setTtabDonuts] = useState(0);
    const handleChangeTabDonuts = (event, value) => {
        setTtabDonuts(value);
    }



    return (

        <Fragment>

            {/* Diálogos flotantes */}
            {openConfirmation && <ConfirmationDialog message={"¿Seguro que quiere borrar los datos brutos?"} action={handleDelete} open={openConfirmation} setOpen={setOpenConfirmation} />}
            {openEdit && <Dialog maxWidth="md" open={openEdit} onClose={() => { setOpenEdit(false) }} fullWidth scroll={'paper'}>
                <CreateReportData key={reportId + "body"} bodyId={bodyId} reportId={reportId} report={report} onClose={() => { setOpenEdit(false) }} />
            </Dialog>
            }


            {/* Opciones de descarga */}
            <Dialog open={downloadDial} onClose={() => setDownloadDial(false)}>
                <RepDownloadSelector report={report} exercise={exerciseModel} onSubmit={() => setDownloadDial(false)} />
            </Dialog>

            <div className={style.tarjetaDesplegada}   >

                {exerciseModel && <div className={style.mainbox} >

                    {/* Sección 1: cabecera tarjeta desplegada */}
                    <div className={style.seccion}>

                        {/* Bloque izquierdo: Título de dato bruto */}
                        <div className={`${style.bloqueizquierdo} `} >
                            <p className="tituloTarjeta"> Detalle Dato Bruto </p>
                            <span><b>ID:</b>{reportId}</span>
                            <span>{report.filename}</span> 
                            
                                {/* <p className="textoTarjeta">{report.filename}  </p> */}
                        </div>

                        {/* Bloque central: Observaciones */}
                        <div className={`${style.bloquecentral} `} >
                            <p className="tituloTarjeta"> Observaciones </p>
                            <span>{report.observations || "-"}</span>
                        </div>

                        {/* Bloque derecho: iconos */}
                        <div className={style.bloquederechobox}>


                            <IconButton className={`${style.CButton} ${style.Bedit} `} onClick={() => { (setOpenEdit(true)) }} >
                                <Edit />
                            </IconButton>
                            <IconButton className={`${style.CButton} ${style.Bdelete} `} onClick={() => setOpenConfirmation(true)}>
                                <Delete />
                            </IconButton>

                            <IconButton className={`${style.CButton} ${style.BDownload} `} onClick={() => setDownloadDial(true)}>
                                <Download />
                            </IconButton>

                            {/* Botón de descarga */}
                            {/* <Grid container item xs={12} justifyContent="right" sx={{ m: 1 }}>
                                    <Button variant="contained" onClick={() => setDownloadDial(true)}> <Download sx={{ mr: 1 }} />  Descargar </Button>
                                </Grid> */}
                        </div>


                    </div>
                                


                    {/* Sección 2: - body y gráfica de barras */}
                    <div className={style.seccion}>

                        {/* Componente del cuerpo */}
                        <div className={style.boxBody}>

                            <div className={style.tituloElemento}>Grupo muscular</div>

                            <BodyMuscularSelector disabled={true} muscles={report} />

                            <div className={style.leyenda}>
                                <div className={style.elementoLeyenda}>
                                    <div className={`${style.circulo} ${style.principal} `}> </div>
                                    <div className="textoLeyenda"> = Grupo principal</div>
                                </div>
                                <div className={style.elementoLeyenda}>
                                    <div className={`${style.circulo} ${style.secundario} `}> </div>
                                    <div className="textoLeyenda"> = Grupos secundarios</div>
                                </div>

                            </div>
                        </div>

                        {/* Vista de Datos brutos 
                        
                                mode(pin):"elastic"
                                elastic_const(pin):31.999999999999996
                                series(pin):1
                                reps(pin):10
                        
                        */}
                        <div className={style.bloqueFuerzas}>
                            <div className={style.tituloConTabsIzq}>
                                <div className={style.tituloElemento}>Visión de fuerza</div>
                                <div className={style.boxpestañas}>
                                    <Tabs value={tabFuerzas} onChange={handleChangeTabFuerzas} >
                                        <Tab label="F.series" />
                                        <Tab label="F.repeticiones" />
                                    </Tabs>
                                </div>
                            </div>
                            {tabFuerzas === 0 &&
                                <div>
                                    <ComparativeGlobalReps key={reportId + "comparative"} objects={exerciseModel.series} />
                                </div>
                            }

                            {/* Pestaña de grafica por sesion */}
                            {tabFuerzas === 1 &&
                                <div>
                                    <ComparativeGlobalReps key={reportId + "comparative"} objects={[].concat(...exerciseModel.series.map((serie) => serie.reps))} />
                                </div>
                            }
                        </div>

                    </div>





                    {/* <Grid item container xs={9} >                            
                            {averages && Object.keys(averages).map((k) => {
                                return <Grid item >
                                    <Paper elevation={3} sx={{ width: 150, height: 100, p: 1, m: 1 }}>
                                        <Grid container item xs={12} justifyContent="space-around" alignContent="center">
                                            <Grid item xs={12}>
                                                <Typography variant="h6"> {dataResume[k].name} </Typography>
                                            </Grid>

                                            <Grid container item justifyContent="center" alignContent="center">
                                                <Typography variant="h4"> {averages[k]} </Typography>
                                                <Typography variant="button" alignContent="center"> {dataResume[k].unit} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            })}
                        </Grid> */}



                    

                    {/* Sección 3: - Tabs de Gráfica resumen */}
                    <div className={style.seccion}>
                        <div className={style.boxResumen}>

                            <div className={style.tituloConTabsIzq}>
                                <div className={style.tituloElemento}>Gráficas de resumen</div>
                                <div className={style.boxpestañas}>
                                    <Tabs value={tabValue} onChange={handleChange} >
                                        <Tab label="Vista global" />
                                        <Tab label="Repeticiones superpuestas" />
                                    </Tabs>
                                </div>
                            </div>

                            {/* Pestaña de gráfica resumen */}
                            {tabValue === 0 &&
                                <div>
                                    <RawDataChart key={reportId} exercise={exerciseModel} url={report.downloadUrl} />
                                </div>
                            }

                            {/* Pestaña de grafica por sesion */}
                            {tabValue === 1 &&
                                <div>
                                    <ExerciseRepsChart key={reportId + "reps"} exercise={exerciseModel} />
                                </div>
                            }
                        </div>

                    </div>

                    {/* Sección 4: - Tabla de datos */}
                    <div className={style.seccion}>
                        <div className={style.elementounicoenseccion}>
                            <div className={style.tituloElemento}>Tabla de datos de resumen</div>
                            <DataTableResumeExercise exercise={exerciseModel} />
                        </div>
                    </div>


                    {/* Sección 5: - Gráficos de barras */} 
                    {/* SOLO SI HAY BILATERAL */}
                    {bilateralData && 
                        <Fragment>
                            <div className={style.sobreTituloSeccion}> Comparativa Bilateral</div>
                            <div className={style.seccion}>

                                {/* Bloque izquierdo, con gráficas de fuerzas*/} 

                                <div className={style.bloqueDonuts}>
                                    <div className={style.tituloConTabs}>
                                        <div className={style.boxpestañas}>
                                            <Tabs value={tabDonuts} onChange={handleChangeTabDonuts} >
                                                <Tab label="Fuerza" />
                                                <Tab label="Tiempo" />
                                                <Tab label="Impulso" />
                                            </Tabs>
                                        </div>
                                    </div>
                                    {tabDonuts === 0 &&
                                        <div> 
                                            <DonutChart bilateralData={bilateralData} type="force" />
                                        </div>
                                    }

                                    {/* Pestaña de grafica por sesion */}
                                    {tabDonuts === 1 &&
                                        <div> 
                                            <DonutChart bilateralData={bilateralData} type="time" />
                                        </div>
                                    }
                                    {tabDonuts === 2 &&
                                        <div> 
                                            <DonutChart bilateralData={bilateralData} type="impulse" />
                                        </div>
                                    }
                                </div>

                                {/* Bloque derecho: Comparativa general */}
                                <div className={style.bloqueComparativaGeneral}>
                                    <div className={style.tituloElemento}> </div>
                                    <div>
                                        <BarsBilateralComparativeChart bilateralData={bilateralData} />
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    }
                </div>}

            </div>
        </Fragment>
    );
}

export default RawDataComponentWithCsv;