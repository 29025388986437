import React, { useState } from "react";
// import { AppBar, Box, Container, Tooltip, Link, Toolbar, IconButton, Avatar } from "@mui/material"
import texts from "../../constants/texts/texts"
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ProfileDrawer from "./ProfileDrawer";
import { AccountBoxOutlined, AssessmentOutlined, Dashboard, DnsOutlined, FitnessCenter, GroupOutlined, Home, Payment, SportsBasketballOutlined, TodayOutlined, Tune } from '@mui/icons-material';
import FeedbackComponent from "./FeedbackComponent";
import { Avatar, Box, Chip, Drawer, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, Tooltip } from '@mui/material';

// Styles
import style from './SideBarMenu.module.scss';

// Images
import MyoLogoH from '../../assets/logos/mainlogo.png'; // logo
// import MyoLogoH from '../../assets/logos/mainlogo.svg'; // logo

// Components
import FlotanteEdit from '../elements/FlotanteEdit.jsx';
import BotonMenuLateral from '../elements/BotonMenuLateral.jsx';
import styled from "@emotion/styled";
import SubscriptionChip from "../user/SubscriptionChip";


const pages = [
    {
        Name: "Deportistas",
        Path: '/',
        Icon: <AccountBoxOutlined fontSize="small" />,
        disabled: false,
        private: true,
    },
    {
        Name: "Equipos",
        Path: '/teams',
        Icon: <GroupOutlined fontSize="small" />,
        disabled: false,
        private: true,
    },
    {
        Name: "Informes",
        Path: '/reports',
        Icon: <AssessmentOutlined fontSize="small" />,
        private: true,
    },
    // probablemente el gym esté integrado dentro del body
    // {
    //     Name: texts.menu4,
    //     Path: '/gym',
    //     Icon: <FitnessCenter fontSize="small" />,
    //     disabled: true,
    //     private: true,
    // },
    // {
    //     Name: "Dashboard",
    //     Path: '/dashboard',
    //     Icon: <Dashboard fontSize="small" />,
    //     disabled: true,
    //     private: true,
    // },
    // {
    //     Name: "Métodos",
    //     Path: '/methods',
    //     Icon: <Tune fontSize="small" />,
    //     disabled: true,
    //     private: true,
    // },
    // {
    //     Name: "Ejercicios",
    //     Path: '/exercises',
    //     Icon: <SportsBasketballOutlined fontSize="small" />,
    //     disabled: true,
    //     private: true,
    // },
    // {
    //     Name: "Sesiones",
    //     Path: '/sessions',
    //     Icon: <DnsOutlined fontSize="small" />,
    //     disabled: true,
    //     private: true,
    // },
    // {
    //     Name: "Programas",
    //     Path: '/programs',
    //     Icon: <TodayOutlined fontSize="small" />,
    //     disabled: true,
    //     private: true,
    // },
    // {
    //     Name: "Payments",
    //     Path: '/payment',
    //     Icon: <Payment fontSize="small" />,
    //     private: true,
    // },
];

const SideBarMenu = (props) => {
    const user = useSelector((state) => state.user.user);

    const [stateDrawer, setStateDrawer] = useState(false);

    const handleStateDrawer = () => {
        setStateDrawer(!stateDrawer);
    };

    const location = useLocation();

    return (

        <Box sx={{ width: 200, overflowX: "hidden" }} >

            {/* Comprobamos de usuario iniciado */}
            {/* {user ? <FeedbackComponent /> : <></>}             */}
            {user && <ProfileDrawer {...props} state={stateDrawer} setState={setStateDrawer} />}

            {/* Drawer menú lateral MUI */}
            {user && 
            <Drawer 
                variant="permanent" 
                anchor="left"   
                PaperProps={{
                    sx: {
                        boxShadow: 10
                    }
                }} >

                {/* MAIN BOX */}
                <div className={style.mainbox}>

                    {/* box menu */}
                    <List
                        sx={{ width: 300, overflowX: "hidden" }}
                        className={style.boxmenu}
                    >


                        {/* Bloque de logo MYOTRAINER */}
                        <Box sx={{ flexGrow: 2, display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                            <Link
                                sx={{ mr: 5 }}
                                color="textPrimary"
                                component={NavLink}
                                to="/"
                                key="home"
                            >
                                <img className={style.myologoh} src={props.themeMode === "light" ?
                                    MyoLogoH
                                    :
                                    MyoLogoH
                                } alt="example" width={"90"} />
                            </Link>
                        </Box>
                        <Grid container justifyContent="center">
                            {user.role && <SubscriptionChip label={user.role}/>}
                        </Grid>

                        {/* bloque imagen perfil usuario */}
                        <div className={style.boxuserimage}>
                            {user ?
                                <div className={style.imagenIcon}>
                                    <Avatar
                                        sx={{ width: 120, height: 120 }}
                                        alt={user.username}
                                        src={user.avatarUrl}
                                    />

                                    <div className={style.boxedit} onClick={() => setStateDrawer(true)}>
                                        <FlotanteEdit />
                                    </div>
                                </div>

                                :

                                <div className={style.imagenIcon}>
                                    <Avatar alt="person icon" sx={{ width: '100%', height: '100%', backgroundColor: '#bdbdbd', color: '#757575' }}>P</Avatar>


                                    <div className={style.boxedit} onClick={() => setStateDrawer(true)}>
                                        <FlotanteEdit />
                                    </div>
                                </div>
                            }
                            {/*<Box sx={{ flexGrow: 2, display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                 <Tooltip title="Abrir opciones" onClick={() => setStateDrawer(true)}>
                                    {user ?
                                        <Avatar
                                            sx={{ width: 100, height: 100 }}
                                            alt={user.username}
                                            src={user.avatarUrl}
                                        />
                                        
                                        : <Avatar alt="person icon" sx={{ width: '100%', height: '100%', backgroundColor: '#bdbdbd', color: '#757575' }}>P</Avatar>}
                                        
                                </Tooltip> 
                            </Box>    */}
                        </div>


                        {/* Iterando items del menu */}
                        <div className={style.menulistbox}>
                            {pages.map((page) => {

                                // <BotonMenuLateral 
                                //     component={NavLink} 
                                //     to={page.Path} 
                                //     page={page}
                                //     location={location}
                                // />



                                // TODO: ¿que pasa con el "{page.private && !user ?"
                                // return ( 
                                // <div className={style.menumainitembox} >
                                //     <div className={style.columnacolor}>&nbsp;</div>

                                //             <NavLink  className={style.icontextbox}   to={page.Path} selected={location.pathname === page.Path}>
                                //                 <div className={style.itemicon}>{page.Icon}</div>
                                //                 <span className={style.itemtext}> {page.Name}</span>
                                //             </NavLink> 

                                // </div>
                                // )


                                return (
                                    page.private && !user ?


                                        <div className={style.menumainitembox} >
                                            {location.pathname === page.Path ?
                                                <div className={style.columnacolor}>&nbsp;</div> :
                                                <div>&nbsp;</div>}

                                            <ListItem sx={{ m: 1, p: 1 }} component={NavLink} to={page.Path} selected={location.pathname === page.Path}>
                                                <div className={style.itemicon}>{page.Icon}</div>
                                                <span className={style.itemtext}> {page.Name}</span>
                                            </ListItem>

                                        </div>


                                        :


                                        <div className={style.menumainitembox} >
                                            {location.pathname === page.Path ?
                                                <div className={style.columnacolor}>&nbsp;</div> :
                                                <div>&nbsp;</div>}

                                            {location.pathname === page.Path ?
                                                <ListItem className={style.icontextboxActive} button component={NavLink} to={page.Path} selected={location.pathname === page.Path}>
                                                    <div className={style.itemicon}>{page.Icon}</div>
                                                    <span className={style.itemtext}> {page.Name}</span>
                                                </ListItem>

                                                :
                                                <ListItem className={style.icontextbox} button component={NavLink} to={page.Path} selected={location.pathname === page.Path}>
                                                    <div className={style.itemicon}>{page.Icon}</div>
                                                    <span className={style.itemtext}> {page.Name}</span>
                                                </ListItem>
                                            }
                                        </div>
                                )

                                // return (
                                //     page.private && !user ?
                                //         <ListItem sx={{ m: 1, p: 1 }} component={NavLink} to={page.Path} selected={location.pathname === page.Path}>
                                //             <ListItemIcon>{page.Icon}</ListItemIcon>
                                //             <ListItemText primary={page.Name} />
                                //         </ListItem>
                                //         :

                                //         <ListItem sx={{ m: 2, p: 1 }} button component={NavLink} to={page.Path} selected={location.pathname === page.Path}>
                                //             <ListItemIcon>{page.Icon}</ListItemIcon>
                                //             <ListItemText primary={page.Name} />
                                //         </ListItem>
                                // )

                            })}
                        </div>

                    </List>

                    {/* copyright */}
                    <div className={style.copyright}>
                        <span>MYO Team</span><br></br>
                        © {(new Date().getFullYear())} All Rights Reserved
                    </div>
                </div>
            </Drawer>}
        </Box>
    );
}




export default SideBarMenu;