import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Grid, List, ListItem, ListItemButton, Paper, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import BodyCardSmall from '../bodys/BodyCardSmall';
import { CompareArrows } from '@mui/icons-material';
import { FirebaseContext } from '../../firebase';
import { createNotification } from '../../reducers/notificationReducer';
import texts from '../../constants/texts/texts';
import { useFormik } from 'formik';
import * as Yup from 'yup'

const CreateTeamComponent = ({teamId, copy, onSubmit}) => {
    const dispatch = useDispatch();
    const {bodys} = useSelector((state) => state.trainer);
    const {firebase} = useContext(FirebaseContext);
    const team = useSelector((state) => state.trainer.teams[teamId]);;


    const validationSchema = Yup.object({
        name: Yup.string()
            .min(3, texts.create_program_name_min)
            .required(texts.create_program_name_required)
    })
    
    const formik = useFormik({
        initialValues: { name: team && team.name? team.name : "" },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleCreateTeam(values);
        }
    })

    const [noSelected, setNoSelected] = useState([]);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        const bodysList = Object.keys(bodys).filter((e) => e !== "defaultBody");
        if(team && team.bodys){
            setSelected(team.bodys);
            setNoSelected(bodysList.filter(bodyId => !team.bodys.includes(bodyId)));
        }else{
            setSelected([]);
            setNoSelected(bodysList);
        }

    },[bodys, team])

    const handleAddItem = (bodyId) => {
        if (bodyId) {
            setNoSelected(prevA => prevA.filter(e => e !== bodyId));
            setSelected(prevB =>  Array.from(new Set([...prevB, bodyId])) );
        }
    };
    
    const handleRemoveItem = (bodyId) => {
        if (bodyId) {
            
            setSelected(prevB => prevB.filter(e => e !== bodyId));
            setNoSelected(prevB =>  Array.from(new Set([...prevB, bodyId])) );
        }
    };

    const handleCreateTeam = (values) => {
        const newTeam = {
            name: values.name,
            bodys: selected,
        }
        
        if(selected.length > 0){
            if(teamId && team){
                firebase.modifyTeam(teamId, newTeam).then(() => {
                    dispatch(createNotification({message: "Se ha modificado el equipo correctamente" , type: "success"}));
                }).catch((err) => {            
                    dispatch(createNotification({message: "Ha habido algún error al intentar modificar el equipo" , type: "error"}));
                })
            }else{
                firebase.createTeam(newTeam).then(() => {
                    dispatch(createNotification({message: "Se ha creado el equipo correctamente" , type: "success"}));
                }).catch((err) => {            
                    dispatch(createNotification({message: "Ha habido algún error al intentar crear un nuevo equipo" , type: "error"}));
                })
            }            
        }else{
            dispatch(createNotification({message: "El equipo debe tener al menos un deportista" , type: "warning"}));
        }
    };

  return (
    <Paper sx={{p:0}}>
        <Grid container spacing={2}>
            <Typography variant="h4" sx={{ pt: 3, px: 3 }}>{teamId && team ?  "Modificar equipo" : "Crear equipo"}</Typography>
        </Grid>
        <Grid  container sx={{ pt: 2, px: 3 }}>
            <Grid item xs={5} sx={{ pr: 1 }}>
                <TextField label="Nombre del equipo" name="name" id="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    fullWidth
                    size='small'
                />
            </Grid>
            <Grid item xs={7} sx={{ pl: 1 }}>
                <Button variant="contained"  type='submit' onClick={formik.handleSubmit} >{teamId && team ? "Modificar equipo" : "Crear equipo"}</Button>
            </Grid>
            <Grid item xs={5}>
                <Paper elevation={3}>
                    <List>
                    {noSelected.length > 0 ? noSelected.map((item) => (
                        <ListItem  key={item}>
                        <ListItemButton onClick={() => handleAddItem(item)}>
                            <BodyCardSmall bodyId={item}/>
                        </ListItemButton>
                        </ListItem>
                    )):
                        <Typography> No hay más deportistas </Typography>
                    }
                    </List>
                </Paper>
            </Grid>
            <Grid container item xs={2} justifyContent="center" alignContent="center">
                <CompareArrows fontSize='large' />
            </Grid>
            <Grid item xs={5}>
            <Paper elevation={3}>
                <List>
                {selected.length > 0 ? selected.map((item) => (
                    <ListItem key={item}>
                    <ListItemButton onClick={() => handleRemoveItem(item)}>
                        <BodyCardSmall bodyId={item}/>
                    </ListItemButton>
                    </ListItem>
                )):
                    <Typography> No se ha seleccionado ningún deportista </Typography>
                }
                </List>
            </Paper>
            </Grid>
        </Grid>
    </Paper>
  );
};

export default CreateTeamComponent;