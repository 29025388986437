import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// constants 
const initialState = {
    updatedBodys: false,
    bodys: {},
    currentBody: {
        name: "default",
        height: 170,
        weight: 70,
        executions: {}
    }
}

const bodySlice = createSlice({
    name: 'bodys',
    initialState,
    reducers: {
        setBodys(state, action){
            state.bodys = action.payload;
            state.updatedBodys = true;
        },
        setCurrentBody(state, action){// Recibe un id
            state.currentBody = state.bodys[action.payload]
        },
        clean(state, action){
            state.updatedBodys = false;
            state.bodys = {};
        },
        setBodyExecutions(state, action){
            state.bodys[action.payload.bodyId].executions = action.payload.execution; 
        }
    }
})

export const { setBodys, setCurrentBody, setBodyExecutions, clean } = bodySlice.actions;
export default bodySlice.reducer;