import { useState, Fragment } from "react";
import {  Button, Grid, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Typography, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { useSelector } from "react-redux";
import RawDataComponent from "../components/reports/RawDataComponent";
import ManageReports from "../components/reports/ManageReports";
import CreateReport from "../components/reports/CreateReport";
import { Download, Groups2, Person } from "@mui/icons-material";
import BodyMuscularSelector from "../components/bodys/BodyMuscularSelector";

// MUI elements
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


// Components
import MainTopBar from "../components/elements/MainTopBar";
import HeaderTop from "../components/elements/HeaderTop";

// Styles
import style from "./ReportsView.module.scss";

// Icons
import GroupsIcon from '@mui/icons-material/Groups';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BodySelector from "../components/bodys/BodySelector";
import DownloadBodyReports, { DownloadAllBodiesReports } from "../components/utils/DownloadBodyRerports";
import TeamGlobalSelector from "../components/teams/TeamGlobalSelector";


const ReportsView = () => {
    const {currentBodyId, reports, bodys } = useSelector((state) => state.trainer)

    const reportList = useSelector((state) => {
        const list = {}
        for (let bodyID in state.trainer.reports) {
            for (let reportId in state.trainer.reports[bodyID]){
                list[reportId] =  state.trainer.reports[bodyID][reportId];
            }
        }
        return list;
    })

    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, value) => {
        setTabValue(value);
    }


    const [perBodyView, setPerBodyView] = useState("bodys")
    const handlePerBodyView = (event, newAlignment) => {
        setPerBodyView(newAlignment);
      };

    const [dialogCreateComplexReport, setDialogCreateComplexReport] = useState(false);
    
    const [downloadBodiesReportsDialog, setDownloadBodiesReportsDialog] = useState(false);
    const [downloadBodyReportsDialog, setDownloadBodyReportsDialog] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(false);
    const [downloadProgressMessage, setDownloadProgressMessage] = useState("");

    return ( bodys && reports && reportList && currentBodyId &&
    
        <div>
            {dialogCreateComplexReport && <Dialog 
                className={style.ventanaFlotanteBox}
                open={dialogCreateComplexReport} 
                onClose={() => setDialogCreateComplexReport(false)}  
                > 
                <CreateReport onSubmit={() => {setDialogCreateComplexReport(false)}} />
            </Dialog>}
            {downloadBodiesReportsDialog && <Dialog 
                className={style.ventanaFlotanteBox}
                open={downloadBodiesReportsDialog} 
                onClose={() => setDownloadBodiesReportsDialog(false)}  
                > 
                <DialogTitle> {downloadProgress ? "Descargando datos brutos - " + downloadProgressMessage : "¿Desea descargar todos los datos brutos de todos los deportistas?"}</DialogTitle>
                {!downloadProgress && <DialogContent>
                    <Button onClick={() => setDownloadBodiesReportsDialog(false)}> Cancelar </Button> 
                    <Button onClick={() => {
                        setDownloadProgress(true);
                        DownloadAllBodiesReports(bodys, reports, setDownloadProgressMessage, 
                            () =>{ setDownloadBodiesReportsDialog(false); setDownloadProgress(false); setDownloadProgressMessage("")  });        
                        }} > Aceptar</Button> 
                </DialogContent>}
            </Dialog>}
            {downloadBodyReportsDialog && <Dialog 
                className={style.ventanaFlotanteBox}
                open={downloadBodyReportsDialog} 
                onClose={() => setDownloadBodyReportsDialog(false)}  
                > 
                <DialogTitle>{downloadProgress ? "Descargando datos brutos - " + downloadProgressMessage : "¿Desea descargar todos los datos brutos de este deportista: "+ bodys[currentBodyId].name  +" ?"}</DialogTitle>
                {!downloadProgress && <DialogContent>
                    <Button onClick={() => setDownloadBodyReportsDialog(false)}> Cancelar </Button> 
                    <Button onClick={() => {
                        setDownloadProgress(true);
                        DownloadBodyReports(bodys[currentBodyId], reports[currentBodyId], setDownloadProgressMessage, 
                            () =>{ setDownloadBodyReportsDialog(false); setDownloadProgress(false); setDownloadProgressMessage("")  }); 
                        }} > Aceptar</Button> 
                </DialogContent>}
            </Dialog>}



            <MainTopBar
                teamSelector
                bodySelector
                headComponent={
                    <HeaderTop
                        title="Informes"
                        subtitle="Historial de informes"  
                        buttonActionFunction = {setDialogCreateComplexReport}                  
                        buttonText = "Crear informe"
                        filters= {false}
                    />
                }
            />
           
            {/* Pestañas */}
            <div className={style.toptable}> 
                <Tabs value={tabValue} onChange={handleChange} >
                    <Tab value={0} label="Datos brutos" />
                    <Tab value={1} label="Informes" />
                    {/* <Tab value={2} label="Crear informe" /> */}
                </Tabs> 
                <Button variant="outlined" onClick={() =>{ setDownloadBodiesReportsDialog(true)}}> 
                    <Download/> <Groups2/> 
                </Button>
                <div className={style.boxbotoneraselectorvista}>
                    <p>Vista de resultados:  </p>
                    {/* <Button  onClick={( ) => setPerBodyView((old) => !old)}> </Button> */}
                    
                    <div>
                        <ToggleButtonGroup 
                            className="deshabilitadoBR8"
                            size="small"
                            value={perBodyView}
                            exclusive
                            onChange={handlePerBodyView} 
                            aria-label="text alignment"
                        >
                            <ToggleButton value="bodys" >
                                <GroupsIcon />
                            </ToggleButton>
                            <ToggleButton  value="lifo" >
                                <AccessTimeIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
            </div>


            
            
            
            {/* Contenido */} 

            {/* PESTAÑA DE DATOS BRUTOS */}
            { tabValue === 0 &&  
                <Grid container item xs={12} > 

                    
                    {perBodyView ==="bodys" ?
                        
                        // Nuevo listado de boys:
                        <Fragment>
                            <div className={style.contenido}>
                                {/* Parte izquierda -> Listado de deportistas */}
                                {/* <div className={`tituloTarjeta ${style.listadoDeportistas}`}>
                                    <p>1 - Selecciona un deportista</p> 
                                    <div className={style.listadobuttonsbodys}>
                                        { Object.keys(bodys).map( (id) =>  
                                             <BodyButton bodyId={id } key={id}/> 
                                         )} 
                                    </div>
                                </div> */}


                                {/* Parte derecha: detalle del deportista seleccionado */}
                                
                                <div className={`tituloTarjeta ${style.listadoreportes}`}>

                                        { currentBodyId ?  
                                        
                                        <Fragment>
                                            <Grid container justifyContent="space-between">
                                                <p>2 - Selecciona el dato a inspeccionar</p> 
                                                {currentBodyId !== "defaultBody" && 
                                                    <Button sx={{m:1}} variant="outlined" onClick={() => { setDownloadBodyReportsDialog(true)}} >
                                                        <Download/> <Person/>
                                                    </Button>
                                                }
                                            </Grid>

                                            { Object.keys(reports[currentBodyId] || {} ).length > 0
                                            ?
                                                Object.keys(reports[currentBodyId] || {}).sort((a,b) =>{
                                                    
                                                    // Obtener los valores asociados a cada identificador
                                                    var valueA = reports[currentBodyId][a];
                                                    var valueB = reports[currentBodyId][b];
                                                    
                                                    // Comparar los atributos name usando localeCompare
                                                    return valueA.filename.localeCompare(valueB.filename);
                                                }).reverse().map((reportId) => {
                                                    return ( 

                                                        // Disparamos el componente de tarjeta-resumen
                                                        <RawDataComponent key={currentBodyId + reportId}  bodyId={currentBodyId} reportId={reportId} />
                                                    )
                                                })
                                            : 
                                                <p className={`${style.sindatos} ${style.simpletext} textoPagina `} > No hay datos brutos asignados a este deportista</p> 
                                            }
                                        </Fragment>
                                          :
                                    <Grid container justifyContent="space-around" sx={{ mt: 5 }}>
                                        <Typography variant="h4"> Selecciona un deportista</Typography>
                                    </Grid>
                                    
                                }
                                </div>
                            </div>
                        </Fragment>                    

                        // Listado por bodys 
                        // ------------------
                        // Object.keys(reports).map((bodyId) => {
                        //     var keys = [...Object.keys(reports[bodyId])]
                        //     keys.sort((a,b) => {
                        //         // Obtener los valores asociados a cada identificador
                        //         var valueA = reports[bodyId][a];
                        //         var valueB = reports[bodyId][b];
                        //         // Comparar los atributos name usando localeCompare
                        //         return valueA.filename.localeCompare(valueB.filename);
                        //     }).reverse();
                            
                        //     return ( <Grid container xs={12} sx={{width: "100%", m:1}}> 
                        //         <Accordion variant="elevation" elevation={6} sx={{width: "100%", p:1, m:1}} >
                        //             <AccordionSummary expandIcon={<ExpandMore />}>
                        //                 <Typography variant="h6">{bodys[bodyId].name} </Typography>
                        //             </AccordionSummary>
                        //             <AccordionDetails>
                        //                 {keys.length > 0  ? keys.map((reportId) => {
                        //                     return <RawDataComponent key={bodyId + reportId}  bodyId={bodyId} reportId={reportId} />            
                        //                 }) : <Typography> No hay datos brutos asignados a este deportista</Typography>}
                        //             </AccordionDetails>
                        //         </Accordion>
                        //     </Grid>)
                        // })
                        :

                        // Listado global  
                        // ------------------
                            <Table>
                                <TableHead className={style.cabeceraTabla}>
                                    <TableRow>
                                        <TableCell>Nombre</TableCell>
                                        <TableCell>Deportista</TableCell>
                                        <TableCell>Nº series</TableCell>
                                        <TableCell>F. pico</TableCell>
                                        <TableCell>F. media</TableCell>
                                        <TableCell>Grupos musculares</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(reportList).sort((a,b) => {
                                        // Obtener los valores asociados a cada identificador
                                        var valueA = reportList[a];
                                        var valueB = reportList[b];
                                        // Comparar los atributos name usando localeCompare
                                        return valueA.filename.localeCompare(valueB.filename);
                                    }).reverse().map((reportId) =>{
                                        const repo = reportList[reportId];
                                        const body = bodys[repo.bodyId];
                                        
                                        return (
                                        
                                        <TableRow>
                                            <TableCell sx={{ width:250 }}>
                                                {repo.name ? 
                                                <Typography> {repo.name} </Typography>:
                                                <Typography color="red"> Pendiente de actualizar </Typography>}
                                            </TableCell>
                                            <TableCell sx={{ width:200 }}>
                                                <Typography>{body.name}</Typography>
                                            </TableCell>
                                            <TableCell sx={{ width:100 }}>
                                                <Typography> {repo.resume ? repo.resume.n_series : ""} </Typography>
                                            </TableCell>
                                            <TableCell sx={{ width:100 }}>
                                                <Typography> {repo.resume ? repo.resume.f_max + " N": ""} </Typography>
                                            </TableCell>
                                            <TableCell sx={{ width:100 }}>
                                                <Typography> {repo.resume ? repo.resume.f_average + " N": ""} </Typography>
                                            </TableCell>
                                            <TableCell sx={{ width:20 }}>
                                                <div className={style.bodyimagebox}> 
                                                        
                                                    <div className={style.bodyimage}>
                                                        <BodyMuscularSelector
                                                            disabled={true}
                                                            muscles={repo}
                                                        />
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        )} 
                                        // <CardRawData report={reportList[reportId]} body={bodys[reportList[reportId].bodyId]}/>)    
                                    )}
                                </TableBody>
                            </Table> 
                        }
                </Grid>
            }

            {/* PESTAÑA DE INFORMES */}
            { tabValue === 1 &&
                <Grid container item xs={12} justifyContent="center">  
                    <ManageReports />
                </Grid>
            } 

            {/* PESTAÑA CREAR INFORME */}
            { tabValue === 2 &&
                <Grid container item xs={12} justifyContent="center">
                    <Grid container item xs={12} justifyContent="center">
                        <CreateReport/>
                    </Grid>
                </Grid>
            }  
        </div>
    );
}

export default ReportsView;