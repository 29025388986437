import { List, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import MethodComponent from "./MethodComponent";

const MethodSelector = () => {
    const { updatedMethods, methods } = useSelector((state) => state.trainer)
    return (
        updatedMethods ?
            <List>
                {Object.keys(methods).map((key) =>
                    <MethodComponent key={key} methodId={key} item={methods[key]} />
                )}
            </List>
            :
            <></>
    );
}

export default MethodSelector;