import React, { useState, useEffect } from 'react';
import { Button, Dialog, Grid, Typography } from "@mui/material";
import ProgramSelector from "../../components/trainer/programs/ProgramSelector";
import CreateProgram from '../../components/trainer/programs/CreateProgram';
import { Add } from '@mui/icons-material';



// Components
import MainTopBar from "../../components/elements/MainTopBar";
import HeaderTop from "../../components/elements/HeaderTop";

// Styles
import "../../styles/global.scss";

const ProgramsView = () => {    
    const [dialogProgram, setDialogProgram] = useState(false);
    
    return (
        
        <div>            
            <MainTopBar
                teamSelector
                bodySelector
                headComponent={
                    <HeaderTop
                        title="Programas"
                        subtitle="Configura las rutinas de entrenamiento" 
                        buttonActionFunction = {setDialogProgram}
                        buttonText = "Nuevo programa"
                        filters= {false}
                    />
                }
            />
            <Grid container>
                <Dialog open={dialogProgram} onClose={() => { setDialogProgram(false) }} fullWidth scroll={'paper'}>
                    <CreateProgram/>
                </Dialog>
                <Grid>
                    <ProgramSelector/>
                </Grid>
            </Grid>
        </div>            
    );
}
 
export default ProgramsView;