import { Box, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";

const LinearProgressBar = ({ value, bufferValue, variant }) => {

    // Para repetir algo en el tiempo 
    // const [progress, setProgress] = useState(0);
    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress((oldProgress) => {
    //             if (oldProgress === 100) {
    //                 return 0;
    //             }
    //             const diff = Math.random() * 10;
    //             return Math.min(oldProgress + diff, 100);
    //         });
    //     }, 500);

    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress variant="buffer" value={value} valueBuffer={bufferValue ??= 0} />
        </Box>
    );
}

export default LinearProgressBar;