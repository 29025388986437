import { Box, CircularProgress, Typography } from "@mui/material";

const CircularProgressLabel = ({ value, maxValue, small }) => {

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress size={small ? 35 : 50} variant="determinate" value={value} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography fontSize={small ? 10 : 12} variant="subtitle2" >
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

export default CircularProgressLabel;