
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Dialog,  AvatarGroup, Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useContext, useState } from "react";
import { FirebaseContext } from "../../firebase";
import { createNotification } from "../../reducers/notificationReducer";
import ConfirmationDialog from "../utils/ConfirmationDialog";
import { Delete, Edit, Download, ExpandMore} from '@mui/icons-material';



// Styles
import style from "./TeamComponent.module.scss";
import BodyCardTableFormat from "../bodys/BodyCardTableFormat";
import CreateTeamComponent from "./CreateTeamComponent";



const TeamComponent = ({teamId}) => {
    const {firebase} = useContext(FirebaseContext);
    const dispatch = useDispatch();
    
    const team = useSelector((state) => state.trainer.teams[teamId]);
    const nameAvatar = useSelector((state) => {
        if(team.bodys){
            return team.bodys.map((id) => {
                
                const obj = state.trainer.bodys[id] ? {
                    name: state.trainer.bodys[id].name,
                    avatarUrl: state.trainer.bodys[id].avatarUrl                
                }
                :
                {
                    name:"",
                    avatarUrl: ""                
                }
                return obj;
            })
        } 
    })
    const [openEdit, setOpenEdit] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    
    const handleDeleteTeam = () => {
        firebase.deleteTeam(teamId).then(() => {
            dispatch(createNotification({message: "Equipo eliminado correctamente", type:"success"}))
        }).catch(() => {            
            dispatch(createNotification({message: "Ha habido un error al eliminar equipo", type:"error"}))
            
        })
    }

    const [expanded, setExpanded] = useState(false);
    
    return ( team && <Fragment>
            {openEdit && <Dialog maxWidth="lg" fullWidth open={openEdit} onClose={() => { setOpenEdit(false) }} >
                <CreateTeamComponent teamId={teamId} onSubmit={() =>  setOpenEdit(false)}/>
            </Dialog>}
            {openConfirmation  &&
                <ConfirmationDialog
                    message={"¿Seguro que quiere borrar el equipo? "}
                    action={() => handleDeleteTeam()}
                    open={openConfirmation}
                    setOpen={setOpenConfirmation} />}

            <Accordion expanded={expanded} onChange={() => {setExpanded((old) => !old)}}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    
                {/* Cabecera */}
                <div class="seccionInteriorTarjeta">
                    
                    {/* Bloque izquierdo: Título de dato bruto */}
                    <div className={`${style.bloqueizquierdo} `} >
                        <p className="tituloTarjeta"> {team.name} </p>
                        <span></span>
                        <p className="textoTarjeta"><b>Fecha de creación:</b>{team.date} &nbsp;&nbsp; - &nbsp;&nbsp; <b>Nº Deportistas:</b> {team.bodys.length}  </p>  
                    </div>
                    <AvatarGroup  max={7} >
                        {nameAvatar.map((item) => 
                            <Avatar src={item.avatarUrl}>{item.name[0] + item.name[1]}</Avatar>)                        
                        }
                    </AvatarGroup>
                    
                    {/* Bloque derecho: iconos */}
                    <div className={style.bloquederechobox}>
                        <IconButton className={`${style.CButton} ${style.Bedit} `} onClick={(event) => { event.stopPropagation(); setOpenEdit(true) }} >
                            <Edit />
                        </IconButton>
                        <IconButton className={`${style.CButton} ${style.Bdelete} `} onClick={(event) =>{event.stopPropagation(); setOpenConfirmation(true)}}>
                            <Delete />
                        </IconButton> 
                    </div> 
                </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div class="seccionInteriorTarjeta">                     
                        <Grid container>
                            {team.bodys.map((bodyId) => 
                                <Grid item>
                                    <BodyCardTableFormat bodyId={bodyId}/>    
                                </Grid>
                            )} 
                        </Grid>
                    </div>
                </AccordionDetails>
            </Accordion>
        </Fragment>
     );
}
 
export default TeamComponent;