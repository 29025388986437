import { Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Typography variant="h1">Error 404</Typography>
            <Typography variant="h3">No se ha encontrado la página que estabas buscando</Typography>

            <Button variant="contained" onClick={() => navigate("/")}>Volver a Inicio</Button>
        </div>
    )
}

export default NotFound