

const convertToCSV = (objArray) => {
    const headers = Object.keys(objArray[0]);
    const csvRows = [];

    // Agregar encabezados al archivo CSV
    csvRows.push(headers.join(','));

    // Agregar filas al archivo CSV
    for (const obj of objArray) {
        const values = headers.map((header) => obj[header]);
        csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
};

const downloadCSV = (data, filename) => {
    const csvContent = convertToCSV(data);
    const link = document.createElement('a');
    const csvFile = new Blob([csvContent], { type: 'text/csv' });
    link.href = URL.createObjectURL(csvFile);
    link.download = `${filename}.csv`;
    link.click();
};
 
export default downloadCSV;