import React, { useContext, Fragment, useState, useEffect } from 'react';
import bodyData from '../../constants/body';
import { FormSelector } from '../utils/FormCreator';
import { useDispatch, useSelector } from 'react-redux';
import { FirebaseContext } from '../../firebase';
import { createNotification } from '../../reducers/notificationReducer';
import texts from '../../constants/texts/texts';
import { Button, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import SchemaGenerator from '../../schemas/SchemaGenerator';
import { useFormik } from 'formik';
import ImageForm from '../utils/ImageForm';

const CreateBody = ({ bodyId, onSubmit }) => {
    const { firebase } = useContext(FirebaseContext);
    const body = useSelector((state) => state.trainer.bodys[bodyId])
    const dispatch = useDispatch();

    const validationSchema = SchemaGenerator(bodyData);
    var values = {};
    bodyData.forEach(element => {
        values[element.varName] = values[element.varName] ??= element.defaultValue;
    });

    const formik = useFormik({
        initialValues: body ? { ...values, ...body } : { ...values },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            handleSubmit(values);
        }
    })

    const handleSubmit = (data) => {
        if (body) {
            firebase.modifyBody(bodyId, data).then(() => {
                updateImage(bodyId, imageData);
                dispatch(createNotification({ message: "Deportista modificado", type: "success" }))
                if (onSubmit) onSubmit();
            }).catch((error) => {
                dispatch(createNotification({ message: "Error al modificar deportista", type: "error" }))
            })
        } else {
            firebase.createNewBody(data).then((data) => {
                updateImage(data.id, imageData);

                dispatch(createNotification({ message: texts.create_body_success, type: "success" }))
                if (onSubmit) onSubmit();
            }).catch((error) => {
                dispatch(createNotification({ message: texts.create_body_error, type: "error" }))
            })
        }
    }
    const [imageData, setImageData] = useState();
    const updateImage = ( id, data) => {
        if(imageData ){
            firebase.updateBodyImage(id, data);
        }
    }

    const getDataObjects = (varNames, objectList) => {
        const formValues = varNames.map((nam) => objectList.find((item) => item.varName === nam)).filter((item) => item != undefined);
        return formValues
    }


    const part1 = getDataObjects(["name",], bodyData);
    const part2 = getDataObjects(["weight", "height", "gender"], bodyData);
    const part3 = getDataObjects(["comment"], bodyData);
    const image = getDataObjects(["bodyImage",], bodyData);

    return (
        <Fragment>
            <DialogTitle>{body ? "Modificar deportista" : "Crear deportista"}</DialogTitle>
            <DialogContent sx={{ px: 3, py: 2 }}>
                <Grid container spacing={1} >
                    {image.map((item) => {
                        if (item.formType === "image") {
                            return (
                                <Grid container item xs={4} alignContent="center" justifyContent="center" >
                                    <Typography >{item.name}</Typography>
                                    <ImageForm imageUrl={body && body.avatarUrl? body.avatarUrl : undefined } setBlob={(value) => setImageData(value)} />
                                </Grid>
                            )
                        }
                    })}
                    <Grid container item xs={8}>

                        {part1.map((item) => {
                            return (
                                <Grid item xs={12}>
                                    {FormSelector(item, formik)}
                                </Grid>)
                        })}

                        {part2.map((item) => {
                            return (
                                <Grid item xs={6}>
                                    {FormSelector(item, formik)}
                                </Grid>)
                        })}
                    </Grid>
                    {part3.map((item) => {
                        return (
                            <Grid item xs={12}>
                                {FormSelector(item, formik)}
                            </Grid>)
                    })}
                </Grid>
            </DialogContent>
            <Button
                type="submit"
                // fullWidth
                onClick={formik.handleSubmit}
                variant="contained"
            >
                Aceptar
            </Button>
        </Fragment>
    );
}

export default CreateBody;