import { Button, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import Model, { IExerciseData, IMuscleStats } from 'react-body-highlighter';

const BodyMuscularSelector = ({ disabled, muscles, onSubmit }) => {
    const [selectMain, setSelectMain] = useState(true);
    const [mainMuscle, setMainMuscle] = useState();
    const [secondaryMuscles, setSecondaryMuscles] = useState([]);

    useEffect(() => {
        if (muscles) {
            setMainMuscle(muscles.mainMuscle);
            setSecondaryMuscles(muscles.secondaryMuscles ? [...muscles.secondaryMuscles] : []);
        }
    }, [muscles])

    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit({
                mainMuscle: mainMuscle,
                secondaryMuscles: secondaryMuscles,
            })
        };

    }

    const handleClickSecondary = useCallback(({ muscle, data }) => {
        const { exercises, frequency } = data;
        setSecondaryMuscles((old) => {
            if (old.includes(muscle) || muscle === mainMuscle) {
                var filtered = old.filter((value) => {
                    return value !== muscle;
                });
                return filtered;
            } else {
                old.push(muscle);
                return [...old]
            }
        })
    }, []);

    const handleClickMain = useCallback(({ muscle, data }) => {
        const { exercises, frequency } = data;
        setMainMuscle(() => muscle)
        if (secondaryMuscles.includes(muscle)) {
            setSecondaryMuscles((old) => {
                var filtered = old.filter((value) => {
                    return value !== muscle;
                });
                return filtered;
            })
        }
    }, []);

    return (
        <Grid container>
            <Grid item xs={6}>
                <Model
                    bodyColor='#B6BDC3'
                    highlightedColors={['#e65a5a', '#e6cc5a', '#aecc5a']}
                    data={
                        [
                            { name: 'Musculo principal', muscles: mainMuscle ? [mainMuscle] : [], frequency: 2 },
                            { name: 'Musculos secundarios', muscles: secondaryMuscles, frequency: 1 },
                        ]
                    }
                    type="anterior"

                    // style={{ width: '30rem', padding: '5rem' }}
                    onClick={disabled ? () => { } : selectMain ? handleClickMain : handleClickSecondary}
                />

            </Grid>
            <Grid item xs={6}>
                <Model
                    data={
                        [
                            { name: 'Musculo principal', muscles: mainMuscle ? [mainMuscle] : [], frequency: 2 },
                            { name: 'Musculos secundarios', muscles: secondaryMuscles, frequency: 1 },
                        ]
                    }
                    highlightedColors={['#e65a5a', '#e6cc5a', '#aecc5a']}
                    type="posterior"
                    // style={{ width: '30rem', padding: '5rem' }}
                    onClick={disabled ? () => { } : selectMain ? handleClickMain : handleClickSecondary}
                />

            </Grid>

            {!disabled && <Grid container item xs={12} justifyContent="center">
                <Grid container item xs={12} justifyContent="center">
                    <Typography>{selectMain ? "Selecciona músculo principal" : "Selecciona músculos secundarios"}</Typography>
                </Grid>

                {selectMain ?
                    <Button
                        disabled={mainMuscle === undefined}
                        onClick={() => setSelectMain(false)}>
                        Siguiente
                    </Button>
                    :
                    <Grid item container xs={12} justifyContent="space-around">
                        <Button onClick={() => setSelectMain(true)}> Atrás </Button>
                        <Button onClick={handleSubmit}> Aceptar </Button>
                    </Grid>
                }
            </Grid>}

        </Grid>
    );
}

export default BodyMuscularSelector;