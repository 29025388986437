import React, { useReducer, useState } from 'react'
import { Button, Grid, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import CircularProgressLabel from '../utils/CircularProgressLabel';
import LinearProgressBar from '../utils/LinearProgress';
import StepExecutionForm from './StepExecutionForm';
import { useEffect } from 'react';

const calcPercent = (value, maxValue) => {
    return Math.round((value * 100 / maxValue) * 100) / 100;
}

const ExerciseExecution = ({ executionData, handleChange, handleFinish }) => {

    // TODO: Revisar y cambiar a useReducer
    const [sliderValue, setSliderValue] = useState(executionData.exec[0]);
    const [i_serie, set_i_serie] = useState(0);

    useEffect(() => {
        setSliderValue(executionData.exec.length > i_serie ? executionData.exec[i_serie] : 0)
    }, [i_serie])

    const onChangeSubmit = () => {
        handleChange(i_serie, sliderValue);
        set_i_serie((old) => old + 1);
    }

    const partialArraySum = (list, index) => {
        let sum = 0;
        for (let i = 0; i <= index; i++) {
            sum += list[i];
        }
        return sum;
    }
    return (

        <Grid container >
            <Stepper nonLinear activeStep={i_serie} orientation="vertical">
                {executionData.exec.map((x, i) => {
                    // const sum = partialArraySum(executionData.exec, i);

                    return <Step key={i}>
                        <StepLabel onClick={() => { set_i_serie(i); }} StepIconComponent={CircularProgressLabel} StepIconProps={{ value: calcPercent(x, executionData.reps), small: true }} >
                            <Grid container sx={{ width: 400 }} alignContent="center" >
                                <Grid container item xs={3} alignContent="center">
                                    <Typography>{x} / {executionData.reps + " " + executionData.type}   </Typography>
                                </Grid>
                                <Grid item xs={9} container alignContent="center">
                                    <LinearProgressBar
                                        value={
                                            calcPercent(i_serie > i ?
                                                partialArraySum(executionData.exec, i) : sliderValue + partialArraySum(executionData.exec, i) - executionData.exec[i_serie],
                                                executionData.totalReps
                                            )
                                        }
                                        bufferValue={calcPercent(executionData.reps * (i + 1), executionData.totalReps)} />
                                </Grid>
                            </Grid>
                        </StepLabel>
                        <StepContent>
                            <StepExecutionForm
                                executionData={executionData}
                                value={sliderValue}
                                setValue={setSliderValue}
                            />
                            <Grid container justifyContent="right">
                                <Grid item >
                                    <Button onClick={onChangeSubmit}>
                                        Aceptar
                                    </Button>
                                </Grid>
                            </Grid>

                        </StepContent>
                    </Step>

                })}
                <Button onClick={handleFinish}>
                    Siguiente ejercicio
                </Button>
            </Stepper>
        </Grid >
    );
}

export default ExerciseExecution