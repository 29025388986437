import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const DonutChart = ({ bilateralData, type }) => {
    const chartOptions = type === "time" ?
        {
            chart: {
                type: 'pie',
            },
            title: {
                text: '',
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '80%'],
                    distance: -50,
                    size: '150%',
                    innerSize: '70%',
                    dataLabels: {
                        enabled: true,
                        format: '{point.percentage:.1f}% - {point.name}: {point.y}',
                    },
                },
            },
            series: [
                {
                    name: 'Tiempo al pico',
                    data: [
                        { name: 'Tiempo pico izquierda', y: bilateralData.left_time_peak, percentage: bilateralData.left_perc_time_peak },
                        { name: 'Tiempo pico derecha', y: bilateralData.right_time_peak, percentage: bilateralData.right_perc_time_peak },
                    ],
                    slicedOffset: 10, // Espacio entre las secciones del donut exterior
                    size: '100%', // Tamaño del donut interior
                },
                {
                    name: 'Tiempo',
                    data: [
                        { name: 'Tiempo izquierda', y: bilateralData.left_time, percentage: bilateralData.left_perc_time, color: '#00FF00' },
                        { name: 'Tiempo derecha', y: bilateralData.right_time, percentage: bilateralData.right_perc_time, color: '#0F0F00' },
                    ],
                    slicedOffset: 10, // Espacio entre las secciones del donut exterior
                    size: '60%', // Tamaño del donut interior
                },
            ],
        }
        :
        type === "force" ? {
            chart: {
                type: 'pie',
            },
            title: {
                text: '',
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '80%'],
                    distance: -50,
                    size: '150%',
                    innerSize: '70%',
                    dataLabels: {
                        enabled: true,
                        format: '{point.percentage:.1f}% - {point.name}: {point.y}',
                    },
                },
            },
            series: [
                {
                    name: 'Fuerza media (N)',
                    data: [
                        { name: 'F. media izquierda (N)', y: bilateralData.left_f_average, percentage: bilateralData.left_f_perc_average },
                        { name: 'F. media derecha (N)', y: bilateralData.right_f_average, percentage: bilateralData.right_f_perc_average },
                    ],
                    slicedOffset: 10, // Espacio entre las secciones del donut exterior
                    size: '100%', // Tamaño del donut interior
                },
                {
                    name: 'Fuerza pico (N)',
                    data: [
                        { name: 'F. pico izquierda (N)', y: bilateralData.left_f_max, percentage: bilateralData.left_f_perc_max, color: '#00FF00' },
                        { name: 'F. pico derecha (N)', y: bilateralData.right_f_max, percentage: bilateralData.right_f_perc_max, color: '#0F0F00' },
                    ],
                    slicedOffset: 10, // Espacio entre las secciones del donut exterior
                    size: '60%', // Tamaño del donut interior
                },
            ],
        } : {
            chart: {
                type: 'pie',
            },
            title: {
                text: '',
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '80%'],
                    distance: -50,
                    size: '150%',
                    innerSize: '70%',
                    dataLabels: {
                        enabled: true,
                        format: '{point.percentage:.1f}% - {point.name}: {point.y}',
                    },
                },
            },
            series: [
                {
                    name: 'Impulso',
                    data: [
                        { name: 'Impulso izquierda (N*s)', y: bilateralData.left_impulse, percentage: bilateralData.left_perc_impulse },
                        { name: 'Impulso derecha (N*s)', y: bilateralData.right_impulse, percentage: bilateralData.right_perc_impulse },
                    ],
                    slicedOffset: 10, // Espacio entre las secciones del donut exterior
                    size: '100%', // Tamaño del donut interior
                },
            ],
        };

    return (
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    );
};

export default DonutChart;