import React, { useState, useEffect } from 'react';
import { Button, Grid, Paper, Typography, styled } from "@mui/material";
import { useContext } from "react";
import { FirebaseContext } from "../../firebase";
import { useDispatch } from "react-redux";
import { Fragment } from 'react';
import MainTopBar from '../elements/MainTopBar';
import HeaderTop from '../elements/HeaderTop';


const GradientButtonBasic = styled(Button)`
    background: linear-gradient(to right, #F0B527, #F0B527);
    border: 0;
    color: white;
    padding: 10px 30px;
    margin: 5px;
    box-shadow: 0 3px 5px 2px rgba(255, 160, 0, 0.3);
`;

//   background: linear-gradient(to right, #F0B527, #F49742, #F0B527);
const GradientButtonPremium = styled(Button)`
    background: linear-gradient(to right, #EDC418, #F49742);
    border: 0;
    color: white;
    padding: 10px 30px;
    margin: 5px;
    box-shadow: 0 3px 5px 2px rgba(255, 160, 0, 0.3);
`;

const GradientButtonProfesional = styled(Button)`
    background: linear-gradient(45deg, #F0B527 20%, #F49742 60%, #F0B527 100%);
    border: 0;
    color: white;
    padding: 10px 30px;
    margin: 5px;
    box-shadow: 0 3px 5px 2px rgba(255, 160, 0, 0.3);
  `;

const SubscriptionPlans = () => {
    const dispatch = useDispatch();
    const {firebase} = useContext(FirebaseContext);
    const [subscriptionType, setSubscriptionType] = useState();

    const SubscribeTest = () => {
        firebase.UpgradePlan("test").then(() => {
            console.log("Test");
        });;
    }

    const SubscribePremium = () => {
        firebase.UpgradePlan("premium").then(() => {
            console.log("AMONO 1");
        });;
    }
    
    const SubscribeProfesional =  () => {
        firebase.UpgradePlan("profesional").then(() => {
            console.log("AMONO 2");
        });
    }

    return (  
        <Fragment>
            <Paper elevation={12} sx={{ p: 2, m: 1 }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid container item xs={12} justifyContent="left" >
                            <Typography variant="h5"> Tus suscripciones</Typography>                                
                    </Grid>
                    <Grid container item xs={12} justifyContent="space-around">
                        <Grid item>
                            <GradientButtonBasic onClick={SubscribeTest}  variant="contained" >
                                <div>
                                    <Typography variant="h5" style={{ fontSize: '20px' }}>
                                        Plan test
                                    </Typography>
                                </div>
                            </GradientButtonBasic >
                            <Typography variant="h4" style={{ marginLeft: 20, fontSize: '16px' }}>
                                • 1 Deportista
                            </Typography>
                            <Typography variant="h4" style={{ marginLeft: 20, fontSize: '16px'  }}>
                                • 0 Equipos
                            </Typography>
                            <Typography variant="h4" style={{ marginLeft: 20, fontSize: '20px', color: "black" }}>
                                • 1€/día
                            </Typography>
                        
                        </Grid>
                        <Grid item justifyContent="center">
                            <GradientButtonPremium variant="contained" onClick={SubscribePremium}>  
                                <div>
                                    <Typography variant="h5" style={{ fontSize: '20px',  }}>
                                        Plan Premium
                                    </Typography>
                                </div>
                            </GradientButtonPremium>
                            <Typography variant="h4" style={{ marginLeft: 20, fontSize: '16px' }}>
                                • 10 Deportistas
                            </Typography>
                            <Typography variant="h4" style={{ marginLeft: 20, fontSize: '16px'  }}>
                                • 3 Equipos
                            </Typography>
                            <Typography variant="h4" style={{ marginLeft: 20, fontSize: '20px', color: "black" }}>
                                • 10€/mes
                            </Typography>
                        </Grid>
                        <Grid item justifyContent="center">
                            <GradientButtonProfesional variant="contained" onClick={SubscribeProfesional}>
                                <div>
                                    <Typography variant="h5" style={{ fontSize: '20px' }}>
                                        Plan Profesional
                                    </Typography>
                                </div>
                            </GradientButtonProfesional>
                            <Typography variant="h4" style={{ marginLeft: 20, fontSize: '16px' }}>
                                • 30 Deportistas
                            </Typography>
                            <Typography variant="h4" style={{ marginLeft: 20, fontSize: '16px'  }}>
                                • 10 Equipos
                            </Typography>
                            <Typography variant="h4" style={{ marginLeft: 20, fontSize: '20px', color: "black" }}>
                                • 40€/mes
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Fragment>
    );
}
 
export default SubscriptionPlans;