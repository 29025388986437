import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Cell, ResponsiveContainer } from "recharts";

const WeekChart = ({ week, microcicleSummary, selected, setSelected }) => {
    const handleClick = (day) => { if (setSelected) { setSelected(day) } }
    const [data, setData] = useState([]);

    useEffect(() => {
        if (week) {
            const days = ["L", "M", "X", "J", "V", "S", "D"]
            const new_data = []
            for (var i in days) {
                const day = days[i];
                const aux = {
                    name: day,
                    // Hay que revisar y verificar los rangos y si tenemos que normalizar la intensidad 
                    kg: microcicleSummary[day] ? microcicleSummary[day].TotalLoad + 10 : 0,
                    N: microcicleSummary[day] ? microcicleSummary[day].TotalNewtons + 10 : 0,
                }
                new_data.push(aux)
            }
            setData(new_data)
        }
    }, [week, microcicleSummary])

    return (
        <ResponsiveContainer minWidth={300} minHeight={100} maxWidth={400} maxHeight={200}>
            <BarChart
                data={data}
                margin={{
                    top: 10,
                    right: 40,
                    left: 0,
                    bottom: 0
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis fontSize={12} />
                <Bar dataKey="kg" stackId="a" fill="#8884d8">
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            stroke="#000000"
                            strokeWidth={entry.name === selected ? 3 : 1}
                            // fill={colors[Math.floor(index / 1) % colors.length]}
                            onClick={() => handleClick(entry.name)}
                        />

                    ))}
                </Bar>
                <Bar dataKey="N" stackId="b" fill="#82ca9d" >
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            stroke="#000000"
                            strokeWidth={entry.name === selected ? 3 : 1}
                            // fill={colors[Math.floor(index / 1) % colors.length]}
                            onClick={() => handleClick(entry.name)}
                        />

                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>

    );
}

export default WeekChart;