import { Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";


// Styles
import style from "./RawDataComponent.module.scss";

// Componentes
import RawDataComponentWithCsv from "./RawDataComponentWithCsv";

// Icons  
// import { ReactComponent as elasticoIcon } from '../../assets/icons/modos_maquina/elastico.svg'; 

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ErrorIcon from '@mui/icons-material/Error';
import ModeIconWithText from "../utils/IconMode";


var dataResume = {
    f_max: {
        name: "F.Máxima",
        key: "f_max",
        value: 0,
        unit: 'N',
    },
    f_average: {
        name: "F.Media",
        key: "f_average",
        value: 0,
        unit: 'N',
    },
    vel_max: {
        name: "V.Máxima",
        key: "vel_max",
        value: 0,
        unit: 'mm/s',
    },
    vel_average: {
        name: "V.Media",
        key: "vel_average",
        value: 0,
        unit: 'mm/s',
    },
}

const RawDataComponent = ({ bodyId, reportId }) => {
    const [extended, setExtended] = useState(false);
    const report = useSelector((state) => state.trainer.reports[bodyId][reportId])
    
    
    return (report && 
        <Fragment>
 
            <div className={`${extended && style.tarjetaactiva} ${style.mainbox}`} >
                    
                <div className={`${extended && style.cabeceraactiva} ${style.cabecera}`} onClick={() => { setExtended((old) => !old) }}> 
                
                    {/* Icono del modo */}
                    {/* TODO: elegir icono según el modo que traiga el dato */}
                    {/* <div className={style.boxiconomodo}>
                        <IconMode mode={report.config && report.config.mode? report.config.mode : ""  }/>
                    </div> */}
                     
                    <ModeIconWithText mode={report.config && report.config.mode? report.config.mode : ""  }/>
                      

                    {/* <elasticoIcon className={style.iconomodo} />   */}

                    {/* Bloque izquierdo: nombre y fecha del dato */}
                    <div className={style.bloqueizquierdobox}>  
                        {!report.name || !report.filename ?
                            <div className={style.pendienteAct}><ErrorIcon/>Pendiente de Actualizar</div>
                        :
                            <Fragment>
                                <strong>{report.name} </strong> <br/>
                                {/* <p className="textoTarjeta">{report.filename}  </p> */}
                                <p className="textoTarjeta">  {report.date ?  report.date : "--/--/----"}</p>
                            </Fragment>
                        } 
                        
                    </div>

                    {/* Bloque central: resumen del dato */}
                    {report.resume && 
                        <div className={style.bloquecentralbox}> 
                            <div className={style.datoresumenbox}>
                                <div className={style.eldato}>  <b>{report.resume ?  report.resume.f_max : "-"}</b> N</div>
                                <div className={style.laleyenda}> F<b>pico</b></div>
                            </div>
                            <div className={style.datoresumenbox}>
                                <div className={style.eldato}> <b>{report.resume ?  report.resume.f_average : "-"}</b> N</div>
                                <div className={style.laleyenda}> F<b>med</b></div>
                            </div>
                            <div className={style.datoresumenbox}>
                                <div className={style.eldato}> <b>{report.resume ?  "x"+report.resume.n_series : "-"}</b> </div>
                                <div className={style.laleyenda}>series</div>
                            </div>
                            <div className={style.datoresumenbox}>
                                <div className={style.eldato}> {report.resume ?  <Fragment><b>{report.resume.time}</b> s </Fragment> : "-"}</div>
                                <div className={style.laleyenda}>tiempo</div>
                            </div>
                            {/* <div className={style.datoresumenbox}>
                                <div className={style.eldato}> V<b>max</b> = {report.resume ?  report.resume.vel_max : "-"} m/s</div>
                                <div className={style.laleyenda}>Velocidad max</div>
                            </div> */}
                            

                        </div>
                    }
                    {/* Bloque derecho: iconos */}
                    <div className={style.bloquederechobox}> 
                    
                        {extended ?
                            <KeyboardArrowDownIcon className={style.pabajo}/>
                        :
                            <KeyboardArrowDownIcon className={style.parriba}/>
                        }
                    </div>
                </div> 
                {/* Fin de cabecera ^^^^^^^^ */}
            
            
                {extended &&
                    // Si está "clickeado", mostramos todos los datos del informe/dato bruto
                    <RawDataComponentWithCsv bodyId={bodyId} reportId={reportId} />
                }
            </div>

        </Fragment>
    )
}


 
export default RawDataComponent;