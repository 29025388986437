import React, { useState, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const data = [];

const BodyMetProgressChart = ({bodyId, bodyMetInfo}) => {
    const [options, setOptions] = useState();    

    useEffect(() => {
        const seriesData = {
            mass: [],
            fat_mass: [],
            bone_mass: [],
            muscle_mass: [],
        };
        if(bodyMetInfo && bodyMetInfo.length > 0){ 
          bodyMetInfo.forEach(item => {
              seriesData.mass.push([item.timestamp, item.mass]);
              seriesData.fat_mass.push([item.timestamp, item.p_fat_mass * item.mass/100 ]);
              seriesData.bone_mass.push([item.timestamp, item.p_bone_mass * item.mass/100]);
              seriesData.muscle_mass.push([item.timestamp, item.p_muscle_mass * item.mass/100]);

            });
        }else{
          data.forEach(item => {
            seriesData.mass.push([item.timestamp, item.mass]);
            seriesData.fat_mass.push([item.timestamp, item.p_fat_mass * item.mass/100 ]);
            seriesData.bone_mass.push([item.timestamp, item.p_bone_mass * item.mass/100]);
            seriesData.muscle_mass.push([item.timestamp, item.p_muscle_mass * item.mass/100]);            
          });
        }

        setOptions({

            chart: {
                  // type: 'spline',
              //   type: 'area'
              type: 'column'
            },
            title: {
              text: 'Evolución de Datos Corporales',
            },
            xAxis: {
              type: 'datetime',
              
              title: {
                text: 'Fecha',
              },
              // categories: [seriesData.mass.map((item) => item[0])],
              labels: {
                formatter: function () {
                  return Highcharts.dateFormat('%d/%m/%y', this.value); // Formato dd/mm/aa
                },
              }
            },
            yAxis: {
              min: 0,
              stackLabels: {
                  enabled: true
              },              
              title: {
                // text: 'Valor',
                enabled: false
              },
              labels: {
                format: '{value}Kg'
              },
            },
            credits: {
              enabled: false
            },
            tooltip: {
              split: true,
              // pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
              formatter: function () {
                const fecha = Highcharts.dateFormat('%d/%m/%y', this.x); // Formatear la fecha
                let tooltipContent = `<b>${fecha}</b><br/>`;

                // Recorre todas las series de datos
                this.points.forEach(function (point) {
                  tooltipContent += `<span style="color:${point.series.color}">${point.series.name}</span>: ${point.y} kg<br/>`;
                });

                // Agregar el total al final
                tooltipContent += `Total: ${this.points.reduce((total, point) => total + point.y, 0)} kg`;

                return tooltipContent;
              },
            },
            // tooltip: {
            //   pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.1f}%</b> ({point.y:,.1f} )<br/>',
            //   split: true
            // },
            // plotOptions: {
            //   area: {
            //       stacking: 'percent',
            //       marker: {
            //           enabled: false
            //       }
            //   }
            // },
            plotOptions: {
              column: {
                  stacking: 'normal',
                  dataLabels: {
                      enabled: true
                  },
                  colors: ['#FF0000', '#00FF00', '#0000FF'],
              }
            },
            series: [
              // { name: 'Peso corporal (Kg)', data: seriesData.mass },
              { name: 'grasa', data: seriesData.fat_mass, color: 'grey' },
              { name: 'músculo', data: seriesData.muscle_mass, color: 'orange' },
              { name: 'masa ósea', data: seriesData.bone_mass, color: 'light-grey' },
              // { name: 'IMC', data: seriesData.bmi },
            ],

        })

    }, [bodyMetInfo])

    return (  
        options && <HighchartsReact key={bodyId} highcharts={Highcharts} options={options}/>
    );
}
 
export default BodyMetProgressChart;