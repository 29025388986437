import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const TableObjectSelector = ({ exercise }) => {
    const [selectedRows, setSelectedRows] = useState([]);

    const handleRowClick = (index) => {
        const isRowSelected = selectedRows.includes(index);
        let newSelectedRows = [...selectedRows];
        if (isRowSelected) {
            newSelectedRows = newSelectedRows.filter((item) => item !== index);
        } else {
            newSelectedRows.push(index);
        }
        setSelectedRows(newSelectedRows);
    }

    const isRowSelected = (index) => {
        return selectedRows.includes(index);
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Serie</TableCell>
                        <TableCell align="center">Tiempo (s)</TableCell>
                        <TableCell align="center">T. bajo tensión (s)</TableCell>
                        <TableCell align="center">F. media (N)</TableCell>
                        <TableCell align="center">F. pico (N)</TableCell>
                        <TableCell align="center">T. al pico (s)</TableCell>
                        <TableCell align="center">Impulso (N·s)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {exercise.series.map((serie, index) =>
                        <React.Fragment key={index}>
                            <TableRow hover onClick={() => handleRowClick(index)}>
                                <TableCell padding="checkbox">
                                    <Checkbox checked={isRowSelected(index)} />
                                </TableCell>
                                <TableCell component="code" scope="row">Serie {serie.i_serie}</TableCell>
                                <TableCell align="center">{serie.time}</TableCell>
                                <TableCell align="center">{serie.time}</TableCell>
                                <TableCell align="center">{serie.f_average}</TableCell>
                                <TableCell align="center">{serie.f_max}</TableCell>
                                <TableCell align="center">--</TableCell>
                                <TableCell align="center">{serie.inertia}</TableCell>
                            </TableRow>
                            {serie.reps.map((rep, index_rep) =>
                                <TableRow key={index_rep}>
                                    <TableCell padding="checkbox">
                                        <Checkbox checked={isRowSelected(index_rep)} />
                                    </TableCell>
                                    <TableCell component="code" scope="row">Rep {rep.i_rep}</TableCell>
                                    <TableCell align="center">{rep.time}</TableCell>
                                    <TableCell align="center">{rep.time}</TableCell>
                                    <TableCell align="center">{rep.f_average}</TableCell>
                                    <TableCell align="center">{rep.f_max}</TableCell>
                                    <TableCell align="center">--</TableCell>
                                    <TableCell align="center">{rep.inertia}</TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )

}

export default TableObjectSelector;