
import { Alert, Snackbar, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteNotification } from "../../reducers/notificationReducer";
import ClearIcon from '@mui/icons-material/Clear';

const NotificationSnackbar = () => {

    const [open, setOpen] = useState(false);
    const notification = useSelector((state) => state.notification.notification);
    const dispatch = useDispatch();

    useEffect(() => {
        if (notification) {
            setOpen(true);
        }
    }, [notification])

    const handleClose = () => {
        dispatch(deleteNotification())
        setOpen(false);
    }

    return (
        notification ?
            <Snackbar
                open={open}
                onClose={handleClose}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    severity={notification.type}
                    sx={{
                        width: '300px',
                        minHeight: '80px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '120%',
                        border: 1,
                        borderColor: 'inherit',
                        boxShadow: 6,
                        borderRadius: 2
                    }}
                    action={
                        <IconButton color="inherit" onClick={handleClose}><ClearIcon /></IconButton>
                    }
                >
                    {notification.message}
                </Alert>
            </Snackbar>

            :
            <></>
    );
}

export default NotificationSnackbar;