import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import texts from "../../../constants/texts/texts";
import ExerciseMethodComponent from "../ExerciseMethod";
import SessionSummaryComponent from "./SessionSummaryComponent";

const SessionComponent = ({ session, sessionSummary, day }) => {
    const { methods, exercises } = useSelector((state) => state.trainer)
    return (
        <Box sx={{ width: "100%" }} elevation={24}  >
            <Typography sx={{ mx: 2 }} variant="h5">{texts.days[day]} - {session.name}</Typography>
            <SessionSummaryComponent sessionSummary={sessionSummary} />
            <div style={{ width: "100%", overflow: "auto", display: "inline-flex" }}  >
                {session.method_ids.map((item) => {
                    const methodItem = methods[item.methodId];
                    const exerciseItem = exercises[item.exerciseId];
                    return (
                        <ExerciseMethodComponent key={item.methodId + "-" + item.exerciseId} exerciseItem={exerciseItem} methodItem={methodItem} />
                    )
                })}
            </div>
        </Box>
    );
}

export default SessionComponent;