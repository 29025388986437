import { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FirebaseContext } from "../../firebase";
import ViewLayer from "../utils/ViewLayer";
import { Button, Step, StepContent, StepLabel, Stepper, Typography, Grid } from "@mui/material";
import FormUserInfo from "./FormUserInfo";
import { createNotification } from "../../reducers/notificationReducer";
import CreateBody from "../bodys/CreateBody";


const ProfileStepper = () => {
    const dispatch = useDispatch();
    const { firebase } = useContext(FirebaseContext);
    const { user } = useSelector((state) => state.user)
    const { profileCompleted } = useSelector((state) => state.user.user)
    const { emailVerified } = useSelector((state) => state.user.auth)

    const [activeStep, setActiveStep] = useState(user.emailVerified ? 1 : 0);

    const handleNext = () => {
        setActiveStep((prevact) => prevact + 1);
    }

    const handleBack = () => {
        setActiveStep((prevact) => prevact - 1);
    }

    const finish = () => {
        // Mofificar el documento del usuario para añadir la variable de que ha terminado el proceso inicial
        firebase.setUserInfo({ ...user, profileCompleted: true }).then(() => {
            dispatch(createNotification({ message: "Se ha completado los pasos de creación de perfil", type: "success" }))
        }).catch((error) => {
            dispatch(createNotification({ message: "Ha habido un error en finalizacion", type: "error" }))
        })
    }

    const onEmailVerification = () => {
        // Gestionar el tiempo entre llamadas, no se debe permitir poder hacer mas de una llamada cada cierto tiempo
        firebase.SendEmailVerification().then(() => {
            dispatch(createNotification({ message: "Email de verificacion reenviado", type: "success" }));
        }).catch((error) => {
            dispatch(createNotification({ message: "No se ha podido reenviar el email de verificación", type: "error" }))
        })
    }

    const steps = [
        {
            label: 'Verifica tu email',
            description: emailVerified ? `Email verificado` : `Revisa tu correo y verifica tu dirección de email accediendo al enlace 
                            que te hemos enviado.`,
            component: emailVerified ?
                <></> : < Button onClick={onEmailVerification} > Reenviar email</Button>
        },
        {
            label: 'Nombre de usuario',
            description: 'Este será tu nombre dentro de la aplicación.',
            component: <FormUserInfo userData={user} onSubmit={handleNext} />
        },
        {
            label: 'Crea un deportista',
            description: 'Completa el formulario para hacer seguimiento de tus avances.',
            component: <CreateBody onSubmit={handleNext} />
        },
        {
            label: 'Paso final',
            description: `Hemos llegado al final, ahora podemos empezar a crear un entrenamiento.`,
            component: <></>
        },
    ];

    // Habrá que cambiarlo para saltarse el paso de verificacion del email 
    return (
        profileCompleted ?
            <></>
            :
            <ViewLayer>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel>
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography>
                                            {step.description}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {step.component}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {index === 1 || index === 2 ?
                                            <></>
                                            :
                                            <Button onClick={index === steps.length - 1 ? finish : handleNext}>
                                                {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                            </Button>
                                        }
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button onClick={handleBack}>
                                            Back
                                        </Button>
                                    </Grid>
                                </Grid>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </ViewLayer>
    );
}

export default ProfileStepper;