import { createSlice } from '@reduxjs/toolkit'

// constants 
const initialState = {
    updatedMethods: false,
    updatedExercises: false,
    updatedSessions: false,
    updatedPrograms: false,
    updatedBodys: false,
    updatedTeams: false,
    currentBodyId: 'defaultBody',
    currentTeamId: 'default',
    methods: {},
    exercises: {},
    sessions: {},
    programs: {},
    executions: {},
    reports: {},
    bodys: {
        'defaultBody': {
            height: 170,
            weight: 70,
        }
    },
    teams: {},
}

const trainerSlice = createSlice({
    name: 'methods',
    initialState,
    reducers: {
        setMethods(state, action){
            state.methods = action.payload;
            state.updatedMethods = true;
        },
        setExercises(state, action){
            state.exercises = action.payload;
            state.updatedExercises = true;
        },
        setSessions(state, action){
            state.sessions = action.payload;
            state.updatedSessions = true;
        },
        setPrograms(state, action){
            state.programs = action.payload;
            state.updatedPrograms = true;
        },
        setBodys(state, action){
            state.bodys = action.payload;
            state.bodys["defaultBody"] = {
                name: "default",
                height: 170,
                weight: 70,
                programs: [],
                creator: "",
            }
            state.updatedBodys = true;
        },
        setTeams(state, action){
            state.teams = action.payload;
            state.updatedTeams = true;

        },
        setBodyExecutions(state, action){
            if(state.bodys.hasOwnProperty(action.payload.bodyId)){
                state.executions[action.payload.bodyId] = action.payload.executions; 
            }
        },
        setBodyReports(state, action){
            if(state.bodys.hasOwnProperty(action.payload.bodyId)){
                state.reports[action.payload.bodyId] = action.payload.reports; 
            }
        },
        setCurrentBody(state, action){
            if(state.bodys.hasOwnProperty(action.payload)){
                state.currentBodyId = action.payload;
            }
        },
        setCurrentTeam(state, action){
            if(state.teams.hasOwnProperty(action.payload) || action.payload === "default" ){
                state.currentTeamId = action.payload;
            }
        },

        clean(state, action){ 
            state.updatedMethods = false;
            state.updatedExercises = false;
            state.updatedSessions = false;
            state.updatedPrograms = false;
            state.updatedBodys = false;
            state.currentBodyId = "defaultBody";
            state.methods = {};
            state.exercises = {};
            state.sessions = {};
            state.programs = {};
            state.reports = {};
            state.executions = {};
            state.bodys = {};
        },
    }
})

export const { setMethods, setExercises, setSessions, setPrograms, setBodys, setTeams, setBodyExecutions, setBodyReports, setCurrentBody, setCurrentTeam, updateSummarys, clean } = trainerSlice.actions;
export default trainerSlice.reducer;