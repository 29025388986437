import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// constants 
const initialState = {
    user: null,
    auth: null,
    logedIn: false,
    userUpdated: false,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login(state, action){
            state.logedIn = true;
            state.auth = action.payload
        },
        setAuthData(state, action){
            state.auth = action.payload;
        },
        setUserLocalData(state, action){
            state.user = {
                profileCompleted: false,
                ...state.user, 
                ...state.auth, 
                ...action.payload
            };
            state.userUpdated = true;
        },
        logout(state){
            state.user = null
            state.auth = null
            state.userUpdated = false;
        },

    }
})

export const { login, setAuthData, logout, setUserLocalData } = userSlice.actions;
export default userSlice.reducer;