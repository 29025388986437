import { Grid, Paper, Select, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';


// TIPOS DE ROLES:
/**
 *
 * Admin -> Lo que le de la gana, acceso a panel de administrador
 * User -> Funcionalidad básica de creacion de sesiones y programas
 * Trainer -> Informes conectividad deportistas creacion de ejercicios y metodos propios
 *
 */

const UserConfig = () => {

    return (
        <Paper elevation={12} sx={{width:100}}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography>  </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>  </Typography>
                </Grid>

            </Grid>
        </Paper>

    );
}

export default UserConfig;