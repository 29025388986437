import React, { useState, useEffect } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { useSelector } from 'react-redux';
import { Grid, IconButton, Paper, Typography } from '@mui/material';
import { Delete, DragIndicator } from '@mui/icons-material';
import OptionLists from '../../constants/OptionLists';


const DroppableRowExercise = ({ item, index, deleteRow, setRef, attributes, listeners }) => {
    const exercise = useSelector((state) => state.trainer.exercises[item.exerciseId])
    const method = useSelector((state) => state.trainer.methods[item.methodId])
    const { methods, exercises } = useSelector((state) => state.trainer)
    const { isOver, setNodeRef, active } = useDroppable({
        id: "drop" + index,
        data: { type: "DroppableRow" }
    });

    const [elevationM, setElevationM] = useState(2);
    const [elevationE, setElevationE] = useState(2);

    useEffect(() => {
        if (active && isOver) {
            setElevationM(methods[active.id] ? 24 : 2)
            setElevationE(exercises[active.id] ? 24 : 2)
        } else {
            setElevationM(2)
            setElevationE(2)
        }
    }, [active, isOver, methods, exercises])


    return (
        <Paper sx={{ m: 1, p: 1 }} ref={setNodeRef} elevation={elevationM} >
            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <Paper sx={{ p: 1, border: elevationE === 24 ? '2px dashed lightGrey' : '2px solid lightGrey' }} elevation={elevationE}>
                        <Typography> {exercise.name} </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={5}>
                    <Paper sx={{ p: 1, border: elevationM === 24 ? '2px dashed lightGrey' : method ? '2px solid lightGrey' : '2px dashed lightGrey' }} elevation={elevationM} >
                        {method ?
                            <Typography>{OptionLists.training_zone[method.training_zone]}_{method.reps ? method.reps + "r" : method.time + "s"}X{method.series}_{method.load}% </Typography>
                            : <Typography color="GrayText"> Añade método </Typography>}

                    </Paper>
                </Grid>
                <Grid container item xs={2} >
                    <Grid item xs={6} >
                        <IconButton onClick={() => { deleteRow(index) }}>
                            <Delete />
                        </IconButton>
                    </Grid>
                    <Grid item xs={6} ref={setRef} {...listeners} {...attributes} >
                        <IconButton >
                            <DragIndicator />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default DroppableRowExercise;
