import { useSelector } from "react-redux";

const ProgramSummary = (program, sessions, methods, exercises) => {
    // Debemos calcular el resumen de cada session, hacer un resumen del microciclo 
    // const { sessions, methods, exercises } = useSelector((state) => state.trainer)
    const currentBody = useSelector((state) => state.trainer.bodys[state.trainer.currentBodyId])

    const SessionSummary = (session) => {

        var intensity = 0;
        var totalLoad = 0;
        var totalNewtons = 0;
        var totalTime = 0;
        var totalWorkRecoveryTime = 0;
        var totalPath = 0;
        var totalVelocity = 0;
        var MuscularResume = {};
        var maxVel = 0;
        var maxLoad = 0;

        session.method_ids.map((item) => {
            const methodItem = methods[item.methodId];
            const exerciseItem = exercises[item.exerciseId];
            if (!methodItem || !exerciseItem) return undefined;

            const currentLoad = methodItem.reps ?
                exerciseItem.position * methodItem.load * currentBody.weight * methodItem.reps * methodItem.series :
                exerciseItem.position * methodItem.load * currentBody.weight * (methodItem.time / methodItem.velocity) * methodItem.series;
            maxLoad = maxLoad > currentLoad ? maxLoad : currentLoad;

            totalLoad = totalLoad + currentLoad;

            intensity = intensity + exerciseItem.position;
            totalNewtons = totalLoad * 9.8;
            totalTime = methodItem.reps ?
                totalTime + methodItem.reps * methodItem.series * ((methodItem.range_of_motion / 100) / methodItem.velocity) :
                totalTime + methodItem.time;

            maxVel = maxVel > methodItem.velocity ? maxVel : methodItem.velocity;

            // Sumatoria de tiempo de recuperacion, tras esto hay que sumarle el tiempo de los ejercicios
            totalWorkRecoveryTime = totalWorkRecoveryTime + methodItem.recovery_time;
            // Si hay reps : sino 
            totalPath = totalPath + (methodItem.reps ?
                methodItem.range_of_motion * methodItem.reps * methodItem.series :
                methodItem.range_of_motion * (methodItem.time * methodItem.velocity) * methodItem.series * 100/** cambio a cm */);

            totalVelocity = totalVelocity + methodItem.velocity;

            // Hay que rellenar el diccionario de muscular resume
            exerciseItem.muscular_group.forEach(element => {
                if (MuscularResume.hasOwnProperty(element)) {
                    MuscularResume[element] = MuscularResume[element] + currentLoad;
                } else {
                    MuscularResume[element] = currentLoad;
                }
            });
            return undefined
        });

        console.log("MUSCULAR RESUME : ", MuscularResume);
        const res = {
            // Promedio de Intensidad  (% 1RM)
            IntensityMean: session.method_ids.length > 0 ? intensity / session.method_ids.length : 0,
            // Promedio de RPE del sujeto
            AthleteRPE: 1,
            // RPE del Entrenador
            TrainerRPE: 1,
            // Sumatoria Carga Desplazada
            TotalLoad: totalLoad,
            // Sumatoria de Newtons (N) Generados
            TotalNewtons: totalNewtons,
            // Carga máxima alcanzada
            MaxLoad: maxLoad,
            // Velocidad media
            VelocityMean: session.method_ids.length > 0 ? totalVelocity / session.method_ids.length : 0,
            // Velocidad máxima alcanzada
            MaxVelocity: maxVel,
            // Recorrido acumulado
            TotalPath: totalPath,
            // Recorrido medio
            PathMean: session.method_ids.length > 0 ? totalPath / session.method_ids.length : 0,
            // N desplazados x metro x cm (o traducción a Imperial)
            NxDistance: totalNewtons / totalPath,
            // N desplazados x segundo (tomado del tiempo útil)
            NxTime: totalTime > 0 ? totalNewtons / totalTime : 0,
            // N x kg de masa corporal
            NxMass: currentBody.weight > 0 ? totalNewtons / currentBody.weight : 0,
            // Tiempo total de sesión
            TotalTime: totalTime,
            // Tiempo total de trabajo y de recuperación
            TotalWorkRecoveryTime: totalWorkRecoveryTime + totalTime,
            // Número de ejercicios
            ExerciseCounter: session.method_ids.length,
            // Número de grupos musculares
            MuscularCounter: Object.keys(MuscularResume).length,
            // N x grupo muscular
            MuscularResume: MuscularResume,
        }
        return res;
    }


    const days = ["L", "M", "X", "J", "V", "S", "D"]
    const data = [];

    program.sessions.map((microcicle) => {
        const mc_summary = {};
        days.map((day) => {
            const session = sessions[microcicle[day]];
            if (session && sessions[microcicle[day]]) {
                const session_summary = SessionSummary(session);
                mc_summary[day] = session_summary;
            }
        })
        data.push(mc_summary);
    })

    return data;
}

export default ProgramSummary;