import { Grid, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import MethodComponent from "../trainer/methods/MethodComponent";
import CircularProgressLabel from "../utils/CircularProgressLabel";

const ExecutionResultsComponent = ({ execution }) => {
    const { methods, exercises } = useSelector((state) => state.trainer)
    const program = useSelector((state) => state.trainer.programs[execution.programId])
    const session = useSelector((state) => state.trainer.sessions[execution.sessionId])

    const calcPercent = (value, maxValue) => {
        return Math.round((value * 100 / maxValue) * 100) / 100;
    }

    return (
        <Paper>
            <Typography> {program ? program.name : "Ya no existe este programa"} </Typography>
            <Typography>Microciclo {execution.mc} - {session ? session.name : "Ya no existe esta sesión"} </Typography>
            {execution.execution_data.map((item) => {
                const method = methods[item.methodId];
                const exercise = exercises[item.exerciseId];
                return <Paper>
                    <Grid container sx={{ maxWidth: 600, minWidth: 400 }}>
                        <Grid item xs={5}>
                            <Paper elevation={3} sx={{ m: 1, p: 1, mr: 0 }}>
                                <Typography> {exercise ? exercise.name : "No disponible"} </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            {method ?
                                <MethodComponent methodId={item.methodId} draggable item={method} />
                                :
                                <Paper elevation={3} sx={{ m: 1, p: 1, mr: 0 }}>
                                    <Typography> No disponible </Typography>
                                </Paper>
                            }
                        </Grid>
                        <Grid item xs={1}>
                            <CircularProgressLabel value={calcPercent(item.doneReps, item.totalReps)} />
                        </Grid>
                    </Grid>
                </Paper>


            })}
        </Paper>
    );
}

export default ExecutionResultsComponent;