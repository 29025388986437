import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentBody } from '../../reducers/trainerReducer';

// Styles
import style from "./BodyButton.module.scss";

// Icons
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';

// Components
import IconoPeso from '../elements/advanceIcons/Peso'
import IconoAltura from '../elements/advanceIcons/Altura'

const BodyButton = ({ bodyId, trainerView }) => {
    const dispatch = useDispatch();
    const body = useSelector(state => state.trainer.bodys[bodyId]);
    const { currentBodyId } = useSelector(state => state.trainer);
    const handleClick = () => {
        dispatch(setCurrentBody(bodyId))
    }

    return (
        body && bodyId !== "defaultBody" &&
            <div 
                onClick={handleClick} 
                className= {bodyId === currentBodyId ? `${style.tarjeta} ${style.tarjetaActiva} ` :  `${style.tarjeta} ${style.tarjetaInactiva}`}
               > 
                    
                <Avatar className={style.avatar}  src={body.avatarUrl} >
                    {body.name[0] + body.name[1]}
                </Avatar> 

                <div className={style.bloquedatos}>
                    <div className={style.nombredeportista}>
                        <div className="tituloElemento"> {body.name} </div>  
                        {body.gender && body.gender === "male" ? <MaleIcon/> : body.gender === "female" ? <FemaleIcon/> : <></>}
                    </div>

                    {/* Versión con iconografía */}
                    <div className={style.bloqueiconos}>
                        <IconoPeso peso={body.weight} texto={false} />
                        <IconoAltura altura={body.height} texto={false} />
                    </div>
                        
                </div>
            </div> 
    );
}

export default BodyButton;