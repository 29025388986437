import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Button, DialogContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import downloadCSV from '../utils/DownloadCsv';
import downloadXLSX from '../utils/DownloadXlsx';


const RepDownloadSelector = ({ report, exercise, onSubmit }) => {
    const [repsSelected, setRepsSelected] = useState();

    useEffect(() => {
        setRepsSelected(() => {
            const newSel = {}
            for (const serie of exercise.series) {
                newSel[serie.i_serie] = {};
                for (const rep of serie.reps) {
                    newSel[serie.i_serie][rep.i_rep] = true;
                }
            }
            return newSel;
        })
    }, [exercise])

    const handleChange = (i_serie, i_rep) => {
        setRepsSelected((old) => {
            if (old[i_serie][i_rep] === true) {
                old[i_serie][i_rep] = false;
            } else {
                // Clave no encontrada, agregarla con el valor true
                old[i_serie][i_rep] = true;
            }
            return { ...old }
        });
    }

    const handleSubmitCsv = () => {
        const newData = exercise.series.map((serie) =>
            [].concat(...serie.reps.map((rep) => {
                if (repsSelected[rep.i_serie][rep.i_rep] === true) {
                    return rep.data;
                } else {
                    return [];
                }
            })));
        const data = [].concat(...newData);
        downloadCSV(data, `${report.filename}_filtered`);
        onSubmit();
    }

    const handleSubmitXlsx = () => {
        const sheetList = exercise.series.map((serie) =>
            [].concat(...serie.reps.map((rep) => {
                if (repsSelected[rep.i_serie][rep.i_rep] === true) {
                    return rep.data;
                } else {
                    return [];
                }
            })));
        downloadXLSX(sheetList, `${report.filename}_filtered`);
        onSubmit();
    }


    return (repsSelected &&
        <DialogContent>
            <Typography variant="h5" sx={{ m: 1 }}>Selecciona repeticiones</Typography>
            <Typography sx={{ m: 1 }}>Estas serán los datos con los que se descargará el csv</Typography>
            <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><CheckBox /></TableCell>
                            <TableCell>Serie</TableCell>
                            <TableCell>Rep</TableCell>
                            <TableCell>F.max (N)</TableCell>
                            <TableCell>F.media (N)</TableCell>
                            <TableCell>Tiempo</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {exercise.series.map((serie) =>
                            serie.reps.map((rep) => {
                                return (
                                    <TableRow onClick={() => { handleChange(rep.i_serie, rep.i_rep) }} >
                                        <TableCell>
                                            {repsSelected[rep.i_serie][rep.i_rep] === true ?
                                                < CheckBox /> :
                                                < CheckBoxOutlineBlank />
                                            }
                                        </TableCell>
                                        <TableCell>{rep.i_serie}</TableCell>
                                        <TableCell>{rep.i_rep}</TableCell>
                                        <TableCell>{rep.f_max}</TableCell>
                                        <TableCell>{rep.f_average}</TableCell>
                                        <TableCell>{rep.time}</TableCell>
                                    </TableRow>)
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container item xs={12} justifyContent="right" spacing={1} >
                <Grid item margin={0.5}>
                    <Button size='small' variant="contained" onClick={handleSubmitCsv}> Descargar CSV </Button>
                </Grid >
                <Grid item margin={0.5}>
                    <Button size='small' variant="contained" onClick={handleSubmitXlsx}> Descargar XLSX</Button>
                </Grid >
            </Grid >
        </DialogContent>
    );
}

export default RepDownloadSelector;