import { Button, Dialog, Grid, Tab, Tabs, Typography } from "@mui/material";
import BodyMetInfoChart from "../charts/BodyMetInfoChart";
import BodyMetProgressChart from "../charts/BodyMetProgressChart";
import { useState } from "react";
import BodyMetForm from "./BodyMetForm";
import { useSelector } from "react-redux";
import MuscularRadarChart from "../utils/MuscularRadarChart";
import CreateBodyRm from "./CreateBodyRm";
import SpiderChart from "../charts/SpiderChart";
import MuscleGroupsBodyChart from "../charts/MuscleGroupsBodyChart";
import {TsGroups} from "../../constants/MuscleGroups";

const BodyMetInfoComponent = ({bodyId}) => {
    
    const  [selectedTab, setSelectedTab] = useState(0);
    const handleChangeTab = (event, value) => {
        setSelectedTab(value);
    }

    const body = useSelector((state) => state.trainer.bodys[bodyId]);
    const bodyMetInfo = body && body.bodyMetInfo? body.bodyMetInfo: []; 
    const [openRM, setOpenRM] = useState(false);
    const [openMet, setOpenMet] = useState(false);


    return ( 
        <Grid container>
            <Dialog open={openMet} onClose={() => { setOpenMet(false) }} fullWidth scroll={'paper'}>
                <BodyMetForm bodyId={bodyId} />
            </Dialog>
            <Dialog open={openRM} onClose={() => { setOpenRM(false) }} fullWidth scroll={'paper'}>
                <CreateBodyRm />
            </Dialog>
            <Grid item xs={12}>
                <Tabs value={selectedTab} onChange={handleChangeTab} >
                    <Tab label="General" />
                    <Tab label="Composición" />
                </Tabs>
            </Grid>
            {selectedTab === 0 &&
                <Grid container item xs={12}>
                    <Grid item xs={6}>
                        <Grid container item justifyContent="center">
                            <SpiderChart bodyId={bodyId} muscularRm={body.muscular_rm} />
                        </Grid>
                        <Grid container justifyContent="center">
                            <Button sx={{}} onClick={() => { setOpenRM(true) }}>Modificar</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <MuscleGroupsBodyChart  muscleData={body.muscular_rm} />
                    </Grid>
                </Grid>}
            {selectedTab === 1 &&
                <Grid container item xs={12}>
                    <Grid item xs={3}>
                        <BodyMetInfoChart bodyId={bodyId} bodyMetInfo={bodyMetInfo} />
                        <Grid container justifyContent="center">
                            <Button onClick={() => {setOpenMet(true)}}>Añadir lectura</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={9}>
                        <BodyMetProgressChart bodyId={bodyId} bodyMetInfo={bodyMetInfo}/>
                    </Grid>
                </Grid>}
        </Grid>
     );
}
 
export default BodyMetInfoComponent;