import { Delete } from "@mui/icons-material";
import { Button, Grid, IconButton, MenuItem, Paper, Select, Typography } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FirebaseContext } from "../../firebase";
import CreateComplexReport from "./CreateComplexReport";
import CreateBaseReport from "./CreateBaseReport";


// Icons
import { ReactComponent as Altura } from '../../assets/icons/Altura.svg';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';



// Styles
import style from "./CreateReport.module.scss";


// Stepperss
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';   



const CreateReport = ({onSubmit}) => {
    const { firebase } = useContext(FirebaseContext);
    const [reportType, setReportType] = useState();
    const [bodySelected, setBodySelected] = useState();
    const { bodys } = useSelector((state) => state.trainer);
    const [baseReport, setBaseReport] = useState();

    const types = {
        single: {
            name: "Individual",
            description: "Informe indidual de uno o varios grupos musculares",
            key: "single",
            icon: <PersonIcon />
        },
        group: {
            name: "Comparativa de equipo",
            description: "Comparativa grupal para grupo muscular y ejercicio",
            key: "group",
            icon: <GroupsIcon />,
            max: 6,
            min: 1,
        }
    }

    const reportOptions = {
        single: {
            key: 'sin',
            name: "Reporte único",
            raw_data: [
                {
                    name: "Reporte",
                    key: "raw_data",
                    required: true,
                }
            ]
        },
        fim: {
            key: 'fim',
            name: "Fuerza Isométrica máxima",
            raw_data: [
                {
                    name: "Reporte lado izquierdo",
                    key: "left_raw_data",
                    required: true,
                },
                {
                    name: "Reporte ambos lados",
                    key: "both_raw_data",
                    required: true,
                },
                {
                    name: "Reporte lado derecho",
                    key: "right_raw_data",
                    required: true,
                },
            ]
        },
        // fdme: {
        //     key: 'fdme',
        //     name: "Fuerza Dinámica máxima Excéntrica",
        // },
        // fdmc: {
        //     key: 'fdmc',
        //     name: "Fuerza Dinámica máxima Concéntrica",
        // },
        // fem: {
        //     key: 'fem',
        //     name: "Fuerza Explosiva máxima",
        // },
        // db: {
        //     key: 'db',
        //     name: "Déficit bilateral",
        // },
        // eb: {
        //     key: 'eb',
        //     name: "Equilibrio bilateral",
        // },
        // baa: {
        //     key: 'baa',
        //     name: "Balance agonista-antagonista",
        // },
        // cnc: {
        //     key: 'cnc',
        //     name: "Control Neuromuscular Consciente (control propioceptivo)",
        // },
        // tfa: {
        //     key: 'tfa',
        //     name: "Test de fatiga FIM FDM FEM",
        // },
    }




    // Cosas de steppers
    const steps = [
        {
            label: "Selección tipo de informe", 
        },
        {
            label: "Selección deportista",
        },
        { 
            label: "Base de informe",
        },
        {
            label: "Configuración ",
        },
      ];

        const [activeStep, setActiveStep] = React.useState(0);

        const handleNext = () => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        };

        const handleBack = () => {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        };

        const handleReset = () => {
            setActiveStep(0);
        };



    // fin cosas steppers

    return (
        <div className={style.flotantemainbox} >

            <p className={` tituloPagina ${style.ctitulpagina}`}> Creación nuevo informe</p>

            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                <Step className={style.mtituloElemento}  key={step.label}>
                    <StepLabel> {step.label} </StepLabel>
                    <StepContent>
                        {/* <Typography>{step.description}</Typography> */}

                        {/* Contenido del paso */}

                        {index==0 ? 
                            // Paso 1: Selección del tipo de informe
                            <Fragment> 
                                <div className={style.selectorBotonera}>            
                                    {Object.keys(types).map((k) => {
                                        return types[k].key === reportType ?
                                            <div className={style.tipoSeleccionado}>
                                                {types[k].icon}
                                                {types[k].name}
                                            </div>                        
                                            :           
                                            <div className={` ${types[k].key === "group" ? style.deshabilitado :<></>} ${style.tipoSinSeleccionar} `} 
                                                onClick={() => { setReportType(types[k].key) }}>
                                                {types[k].icon}
                                                {types[k].name}
                                            </div>      
                                    })}
                                </div>
                            </Fragment>
                        
                        
                        
                        : index==1 ? 
                            // Paso 2 Selección de deportista
                            <Fragment>   
                            
                                <div className={style.selectorBodys}>
                                    <Select   
                                        defaultValue="ninguno"
                                        onChange={(event) => { setBodySelected(event.target.value) }}>
                                            
                                        <MenuItem value="ninguno"><em>Ninguno seleccionado</em></MenuItem>
                                        {Object.keys(bodys).map((k) => {
                                            return <MenuItem value={k}> {bodys[k].name} </MenuItem>
                                        })}
                                    </Select> 
                                </div>   
                            </Fragment>
                        
                        : index==2 ? 
                            // Paso 3
                            <Fragment>
                                {reportType && bodySelected &&
                                <Grid container item xs={12} >
                                    <Grid item xs={12}>
                                        <Typography>{types[reportType].description}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CreateBaseReport bodySelected={bodySelected} onSubmit={(data) => { setBaseReport(data); handleNext();  }} />
                                    </Grid>
                                </Grid >
                                }
                                </Fragment>
                            
                            : index == 3? reportType && bodySelected && baseReport &&
                                <Grid container item xs={12}>
                                    <CreateComplexReport bodyId={bodySelected} reportType={reportType} baseReport={baseReport} onSubmit={onSubmit} />
                                </Grid >
                            
                            :
                            <></>
                            }
                            
                            {/* Botonera de control de pasos */}
                            <Box sx={{ mb: 0, mt:3 }}>
                                 <div>
                                 {index < 2 &&   <Button
                                        disabled={index === steps.length - 1}
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {index === steps.length - 1 ? 'Crear' : 'Continuar'}
                                </Button>}
                                <Button
                                    disabled={index === 0}
                                    onClick={handleBack}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    Atrás
                                </Button>
                            </div>
                        </Box>
                    </StepContent>
                </Step>
                ))}
            </Stepper>




            
            


        </div >
    );
}

export default CreateReport;