import React, { useState, useContext, useEffect, Fragment } from 'react';
import { Button, Grid, Paper, TextField, Typography, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, rectIntersection, useDraggable, useDroppable, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import SortableItem from '../../dnd/SortableItem';
import texts from '../../../constants/texts/texts';
import { useFormik } from 'formik';
import * as Yup from "yup"
import { createNotification } from '../../../reducers/notificationReducer';
import { FirebaseContext } from '../../../firebase';
import { Search } from '@mui/icons-material';
import MethodComponent from '../methods/MethodComponent';

const ExerciseDraggable = ({ exercise, exerciseId }) => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({ id: exerciseId, data: { type: "exercise" } })

    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;

    return (
        <Paper sx={{ p: 2, m: 1 }} ref={setNodeRef} style={style} {...listeners} {...attributes} >
            <Typography fontSize={14}> {exercise.name} </Typography>
        </Paper>
    )
}

const MethodDraggable = ({ method, methodId }) => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({ id: methodId, data: { type: "method" } })

    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;

    return (
        <div ref={setNodeRef} style={style}  {...attributes} {...listeners}>
            <MethodComponent key={methodId} methodId={methodId} item={method} draggable />
        </div>

        // <Paper sx={{ p: 2, m: 1, overflow: "hidden" }} ref={setNodeRef} style={style} {...listeners} {...attributes} >
        //     <Typography fontSize={14} maxWidth="100%"> {method.name} </Typography>
        // </Paper>
    )
}

const DroppableColumn = (props) => {
    const { isOver, setNodeRef } = useDroppable({
        id: "central",
    });
    const elevation = isOver ? 24 : 2;
    return (
        <Paper sx={{ minHeight: 400, p: 1, mt: 1 }} >
            {props.children}
            <Paper sx={{ m: 3, p: 1, border: '2px dashed lightGrey' }} ref={setNodeRef} elevation={elevation}>
                <Grid container item ref={setNodeRef} justifyContent="center" >
                    <Typography fontSize={14}>Añade ejercicio</Typography>
                </Grid>
            </Paper>
        </Paper>
    )
}

const collisionFunction = ({ droppableContainers, active, ...args }) => {
    const rectIntersectionCollisions = rectIntersection({
        ...args,
        droppableContainers: droppableContainers.filter(({ id }) => {
            if (active.data.current.type !== "sortable") {
                const a = "" + id;
                return id === 'center' || a.includes("drop")
            }
            return false;
        })
    });

    if (rectIntersectionCollisions.length > 0) {
        return rectIntersectionCollisions;
    }

    // Compute other collisions
    return closestCenter({
        ...args,
        droppableContainers: droppableContainers.filter(({ id }) => id !== 'center')
    });
}

const validationSchema = Yup.object({
    name: Yup.string()
        .min(3, texts.create_exercise_name_min)
        .required(texts.create_exercise_name_required)
})

const CreateSession = ({ sessionId, copy, onSubmit }) => {
    const { exercises, methods } = useSelector((state) => state.trainer)
    const session = useSelector((state) => state.trainer.sessions[sessionId])
    const [exercisesMethodsList, setExercisesMethodsList] = useState([]);
    const [counterId, setCounterId] = useState(1);
    const { firebase } = useContext(FirebaseContext);
    const dispatch = useDispatch();
    useEffect(() => {
        setExercisesMethodsList(session ? session.method_ids.map((item, index) => { return { ...item, id: index + 1 } }) : [])
    }, [session])

    const handleSubmit = (data) => {

        const exercisesMethods = exercisesMethodsList.map((item) => {
            delete item["id"];
            return item;
        })
        const new_session = {
            name: data.name,
            method_ids: exercisesMethods,
        }

        if (session && !copy) {
            firebase.modifySession(sessionId, new_session).then(() => {
                dispatch(createNotification({ message: "Se ha modificado una sesión", type: "success" }))
                setExercisesMethodsList([]);
                setCounterId(1);
                formik.setFieldValue("name", "")
            }).catch((err) => {
                dispatch(createNotification({ message: "Error al modificar sesión", type: "error" }))
            });
        } else {

            firebase.createNewSession(new_session).then(() => {
                dispatch(createNotification({ message: "Se ha creado una sesión", type: "success" }))
                setExercisesMethodsList([]);
                setCounterId(1);
                formik.setFieldValue("name", "")
            }).catch((err) => {
                dispatch(createNotification({ message: "Error al crear sesión", type: "error" }))
            });
        }
        if (onSubmit) onSubmit();
    }

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.data.current.type === "sortable") {
            if (active.id !== over.id) {
                setExercisesMethodsList((items) => {
                    const oldIndex = items.indexOf(items.find(item => item.id === active.id));
                    const newIndex = items.indexOf(items.find(item => item.id === over.id));
                    console.log("old:", oldIndex, " new : ", newIndex);
                    return arrayMove(items, oldIndex, newIndex);
                });
            }

        }
        if ((active.data.current.type === "exercise" || active.data.current.type === "method") && over) {
            const { id } = event.active;
            // Si es un metodo hay que buscar si colisiona con un ejercicio
            const overId = event.collisions[0].id;

            if (overId === "central") {
                if (exercises[id]) {
                    setExercisesMethodsList((old) => [...old, { id: counterId, exerciseId: id, methodId: undefined }])
                    setCounterId(old => old + 1)
                }
            } else {
                const overIdModif = overId.replace("drop", "");
                if (exercises[id]) {
                    if (overIdModif < exercisesMethodsList.length)
                        setExercisesMethodsList((old) => {
                            old[overIdModif] = { ...old[overIdModif], exerciseId: id };
                            return [...old]
                        })
                }
                if (methods[id]) {
                    setExercisesMethodsList((old) => {
                        old[overIdModif] = { ...old[overIdModif], methodId: id };
                        return [...old];
                    })
                }
            }
        }
    }

    const handleDeleteRow = (index) => {
        setExercisesMethodsList((old) => {
            old.splice(index, 1);
            return [...old]
        })
    }

    const formik = useFormik({
        initialValues: { name: session ? session.name : "" },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    })

    const [searchExercise, setSearchExercise] = useState("");

    const handleChangeExercise = (event) => {
        setSearchExercise(event.target.value);
    };

    const [searchMethod, setSearchMethod] = useState("");

    const handleChangeMethod = (event) => {
        setSearchMethod(event.target.value);
    };

    return (
        <DndContext sensors={sensors} collisionDetection={collisionFunction} onDragEnd={handleDragEnd}>
            <Typography variant="h4" sx={{ pt: 3, px: 3 }}>{session ? copy ? "Copiar Sesión" : "Modificar Sesión" : "Crear Sesión"}</Typography>
            <Grid container sx={{ px: 3, pt: 1 }} spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        label={texts.create_session_name}
                        id="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <Button variant="contained" onClick={formik.handleSubmit}>{session && !copy ? "Modificar sesión" : "Crear Sesión"} </Button>
                </Grid>
            </Grid>
            <Grid container sx={{ p: 3 }}>
                <Grid item xs={3} sx={{ pr: 2 }}>
                    <Typography>Ejercicios</Typography>
                    <TextField
                        margin="dense"
                        fullWidth
                        placeholder={"Ejercicio"}
                        value={searchExercise}
                        onChange={handleChangeExercise}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>
                        }}
                    />
                    <Grid container sx={{/*overflow:"auto",*/ height: 350 }}>
                        <Grid item xs={12}>
                            {Object.keys(exercises).map((key) =>
                                exercises[key].name.toLowerCase().startsWith(searchExercise.toLowerCase()) ?
                                    <ExerciseDraggable exercise={exercises[key]} exerciseId={key} key={key} />
                                    :
                                    <></>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <SortableContext
                        items={exercisesMethodsList}
                        strategy={verticalListSortingStrategy}
                    >
                        <DroppableColumn>
                            {exercisesMethodsList.map((item, index) =>
                                <SortableItem item={item} key={item.id} id={item.id} index={index} deleteRow={handleDeleteRow} />
                            )}
                        </DroppableColumn>
                    </SortableContext>
                </Grid>
                <Grid item xs={3} sx={{ pl: 2 }}>
                    <Typography>Métodos</Typography>
                    <TextField
                        margin="dense"
                        fullWidth
                        placeholder={"Método"}
                        value={searchMethod}
                        onChange={handleChangeMethod}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>
                        }}
                    />
                    <Grid container sx={{/*overflow:"auto",*/ height: 350 }}>
                        <Grid item xs={12}>
                            {Object.keys(methods).map((key) =>
                                methods[key].name.toLowerCase().startsWith(searchMethod.toLowerCase()) ?
                                    <MethodDraggable method={methods[key]} methodId={key} key={key} />
                                    :
                                    <></>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DndContext>

    );
}

export default CreateSession;