import { Button, DialogContent, DialogTitle, Grid, Paper, Slider, TextField, Typography } from '@mui/material';
import React, { useState, useEffect, Fragment, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import OptionLists from '../../constants/OptionLists';
import { FirebaseContext } from '../../firebase';
import { createNotification } from '../../reducers/notificationReducer';
import MuscleGroups from '../../constants/MuscleGroups';
// import RmCalculatorComponent from '../muscleGroups/RmCalculatorComponent';


const listMuscles = [
    // TS
        // "head",
        "neck",
        "trapezius",
        "biceps",
        "triceps",
        "chest",
        "upper-back",
        "lower-back",
        "front-deltoids",
    // CORE
        "abs",
        "obliques",
    
    // TI
        // "abs",
        "gluteal",
        // "obliques",
        "quadriceps",
        "adductor",
        "abductors",
        "hamstring",
        "calves",
        "knees",
        // "left-soleus",
        // "right-soleus",
    ]


const MuscFormRm = ({ musc, value, onChange, disabled }) => {
    const [load, setLoad] = useState(10);
    const [nReps, setNReps] = useState(5);
        
    const calculate = () => {
        // const epley = pesoLevantado * (1 + 0.0333 * numeroReps); // Fórmula Epley
        // const lander = pesoLevantado / (1.013 - 0.0267123 * numeroReps); // Fórmula Lander
        // const lombardi = pesoLevantado * Math.pow(2.71828, 0.0333 * numeroReps); // Fórmula Lombardi
        // const schwartzMalone = (load / (1.0278 - 0.0278 * nReps)) * (1 + (bodyWeight - 77.161) * 0.006065);
        const oConner = parseFloat((load / (1.0278 - 0.0278 * nReps)).toFixed(2)); // Fórmula O'Conner
        onChange(musc, oConner);
    };

    const getImagePath = (imgName) => {
        try {
            const imagePath = require(`../../assets/muscleIcons/${imgName}.png`);
            return imagePath;
            
        } catch (error) {
            return ""
        }
    }

    return (
        <Grid container sx={{my: 2}}>
            <Grid item xs={2} container alignItems="center">
                <Grid item xs={12}>
                    <img src={getImagePath(musc)} alt="Imagen PNG" height="40" width="40" />
                </Grid>
                <Grid item xs={12}>
                    <Typography fontSize={14}>{MuscleGroups[musc]}</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={10}>
                <Grid item xs={4}>
                    <TextField
                    label="Peso Levantado (kg)"
                    type="number"
                    value={load}
                    onChange={(e) => setLoad(e.target.value)}
                    />
                </Grid>            
                <Grid item xs={4}>
                    <TextField
                    label="Número de Repeticiones"
                    type="number"
                    value={nReps}
                    onChange={(e) => setNReps(e.target.value)}
                    />
                </Grid>            
                <Grid item xs={4}>
                    <Button variant="contained" color="primary" onClick={calculate}>
                        calcular
                    </Button>
                </Grid>            
                <Grid item xs={8} container alignItems="center">
                    <Slider
                        sx={{mx:1}}
                        disabled={disabled}
                        value={value}
                        size="small"
                        id={musc + "RmForm"}
                        name={musc}
                        step={1}
                        max={100}
                        min={0}
                        valueLabelDisplay="auto"
                        onChange={(event) => { onChange(musc, event.target.value) }}
                    />
                </Grid>
                <Grid item xs={4} container alignItems="center" >
                    <TextField type='number' variant='outlined' sx={{mt:1}} value={value} onChange={(e) => onChange(musc, e.target.value)}
    />
                </Grid>
            </Grid>          
        </Grid >
    );
}

const CreateBodyRm = () => {

    const def_musc = Object.keys(MuscleGroups).reduce((acc, key) => {
        acc[key] = 0;
        return acc;
    }, {});

    const body = useSelector((state) => state.trainer.bodys[state.trainer.currentBodyId])
    const { currentBodyId } = useSelector((state) => state.trainer)
    const { firebase } = useContext(FirebaseContext)
    const dispatch = useDispatch();
    
    const [muscularRm, setMuscularRm] = useState(body.muscular_rm ? { ...def_musc, ...body.muscular_rm } : def_musc)
    const [disabledRm, setDisabledRm] = useState(true);
    
    useEffect(() => {
        if (body) {
            // TODO: hay que modificar para que solo coja las claves que estan definidas en def_musc y no las que habían antes
            setMuscularRm(body.muscular_rm ? { ...def_musc, ...body.muscular_rm } : def_musc);
        }
    }, [currentBodyId, body, disabledRm])

    const handleModify = () => {
        console.log("MODIFY ");
        //ESTO ESTA POR TERMiNAR 
        firebase.updateBodyRm(currentBodyId, muscularRm).then(() => {
            setDisabledRm(true)
            dispatch(createNotification({ message: "Modificado Rm", type: "success" }))
        }).catch(() => {
            dispatch(createNotification({ message: "Error al modificar Rm", type: "error" }))
        });
    }

    const onChangeMusc = (musc, newValue) => {
        setMuscularRm((old) => {
            old[musc] = newValue;
            return { ...old }
        })
    }

    return (
        <Fragment>
            <DialogTitle>Modificar 1RM</DialogTitle>
            <DialogContent  >
                <Grid container sx={{ px: 3, py: 3 }} >
                    {listMuscles.map((musc) =>
                        <MuscFormRm musc={musc} value={muscularRm[musc]} onChange={onChangeMusc} />
                    )}
                </Grid>
            </DialogContent>
            <Button
                type="submit"
                fullWidth
                onClick={handleModify}
                variant="contained"
            >
                Aceptar
            </Button>
        </Fragment >
    );
}

export default CreateBodyRm;