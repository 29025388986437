const texts = {
    menu1: "Trainer",
    menu2: "Plan",
    menu3: "About",
    menu4: "Gym",
    profile: "Perfil",
    about: "Acerca de nosotros",
    signin: "Iniciar sesión",
    signup: "Registrar usuario",
    signup2: "Registrarse",
    signout: "Cerrar sesión",
    signuperrordescription: "El correo especificado ya está en uso. Inicie sesión o recupere su contraseña.",
    forgotpassworderrordescription: "El correo especificado no existe. Regístrese o pruebe con otro.",
    alreadyaccount: "¿Ya tienes cuenta? Inicia sesión",
    forgotPassword: "¿Olvidaste la constraseña?",
    forgotPasswordTitle: "Cambiar contraseña",
    donthaveaccount: "Registrar usuario",
    nosesion: "No tienes iniciada sesión",
    rememberme: "Recuerdame",
    forgot: "Cambiar contraseña",
    // notifications
    signinerror: "Email o contraseña incorrectos",
    signuperror: "Error al crear usuario",
    singinsuccess: "Iniciada sesión correctamente",
    singupsuccess: "Creado usuario correctamente",
    forgotpassemailsuccess: "Correo de cambio de contraseña enviado",
    forgotpassemailerror: "Error en cambio de contraseña",
    logouterror: "Error al cerrar sesión",
    logoutsuccess: "Cerrada sesión correctamente",
    // 
    aboutUsTitle: "Sobre nosotros",
    aboutUsSubtitle: "MyoTrainer",
    //Profile
    username: "Nombre de usuario",
    weight: "Peso:",
    height: "Altura:",
    uploadImage: "Subir",
    changeImage: "Cambiar Imagen",
    cancel: "Cancelar",
    //Profile Settings
    settings: "Ajustes",    
    billing: "Facturación",
    configuration: "Configuración",
    FAQ: "Preguntas frecuentes",  
    modifyProfile: "Modificar Perfil",
    privacyAndSecurity: "Privacidad y Seguridad",
    notifications: "Notificaciones",
    language: "Idioma",
    conect: "Conecta",
    cookies: "Cookies",
    qrCode: "Código QR",
    darkMode: "Modo Oscuro",
    help: "Ayuda",
    helpCenter: "Centro de Ayuda",
    contact: "Contacto",
    problemReport: "Informar Problema",
    frecuentQuestions: "Preguntas Frecuentes",
    //Create Exercise
    create_exercise: "Crear Ejercicio",
    create_exercise_name: "Nombre",
    create_exercise_description: "Descripción",
    create_exercise_start_image_url: "Link Posicion Inicial",
    create_exercise_end_image_url: "Link Posicion Final",
    create_exercise_video_url: "Link Video Demostrativo",
    create_exercise_important_points: "Puntos Clave",
    create_exercise_muscular_group: "Grupo Muscular",
    create_exercise_move_chain: "Cadena de Movimiento",
    create_exercise_anatomical_plane: "Plano Anatómico",
    create_exercise_benefits: "Beneficios del Ejercicio",
    create_exercise_save: "Guardar Ejercicio",
    create_exercise_name_min: "El nombre del ejercicio debe tener al menos 3 letras",
    create_exercise_name_required: "Nombre de ejercicio requerido",
    create_exercise_success: "Se ha creado un nuevo ejercicio",
    create_exercise_error: "Error al crear nuevo ejercicio",
    //Create Method
    create_method: "Crear Método",
    create_method_name: "Nombre",
    create_method_strand: "Vertiente",
    create_method_intervention_zone: "Zona de Intervención",
    create_method_series: "Series",
    create_method_time: "Tiempo de Trabajo",
    create_method_reps: "Repeticiones",
    create_method_recovery_time: "Tiempo de Recuperación",
    create_method_time_btw_reps: "Tiempo entre Repeticiones",
    create_method_mode: "Modo",
    create_method_load: "Carga",
    create_method_benefits: "Beneficios del Método",
    create_method_save: "Guardar Método",
    create_method_name_min: "El nombre del método debe tener al menos 3 letras",
    create_method_name_required: "Nombre de método requerido",
    create_method_exercise_required: "Ejercicio necesario para crear un método",
    create_method_series_more_than: "Un método no puede tener series negativas",
    create_method_series_less_than: "Un método no puede tener más de 100 series",
    create_method_time_more_than: "Un método no puede tener tiempos de trabajo negativos",
    create_method_time_less_than: "Un método no puede tener tiempos de trabajo de más de 120 segundos",
    create_method_reps_more_than: "Un método no puede tener repeticiones negativas",
    create_method_reps_less_than: "Un método no puede tener más de 100 repeticiones",
    create_method_recovery_time_more_than: "Un método no puede tener tiempos de recuperación negativos",
    create_method_recovery_time_less_than: "Un método no puede tener tiempos de recuperación de más de 300 segundos",
    create_method_time_btw_reps_more_than: "Un método no puede tener tiempos entre repeticiones negativos",
    create_method_time_btw_reps_less_than: "Un método no puede tener tiempos entre repeticiones de más de un minuto",
    create_method_success: "Se ha creado un nuevo método",
    create_method_error: "Error al crear nuevo método",
    //Create Sesion
    create_session_name: "Nombre de Sesión",
    create_session: "Crear Sesión",
    create_program: "Crear programa",
    //Create program
    create_program_name_min: "El nombre del programa debe tener al menos 3 letras",
    create_program_name_required: "Nombre de programa requerido",
    //Create body
    create_body_success: "Se ha creado un nuevo deportista",
    create_body_error: "Error al crear nuevo deportista",
    // FORM VALIDATION
    number_more_than: "Debe ser mayor que",
    number_less_than: "Debe ser menor que",
    number_required: "Número necesario para completar",

    string_more_than: "Debe tener mas letras de",
    string_less_than: "Debe tener menos letras de",
    string_required: "Texto necesario para completar",


    // OPTIONS OF SELECTS
    // EXERCISE
    joint_movement: {
        flexion: "Flexión",
        extension: "Extensión",
        rotacion_inter: "Rotación Interna",
        rotacion_exter: "Rotación Externa",
        abduccion: "Abducción",
        transversal: "Transversal",
        circundicion: "Circunducción"
    },
    muscular_group: {
        cuello: "Cuello",
        trapecios: "Trapecios",
        pectoral: "Pectoral",
        deltoides: "Deltoides",
        dorsal: "Dorsal",
        biceps: "Bíceps",
        triceps: "Tríceps",
        antebrazos: "Antebrazos",
        abdominales: "Abdominales",
        oblicuos: "Oblicuos",
        lumbares: "Lumbares",
        gluteos: "Glúteos",
        tfl: "TFL",
        flex_cad: "Flex. Cadera",
        cuadriceps: "Cuádriceps",
        isquiotibiales: "Isquiotibiales",
        gemelos: "Gemelos",
        soleos: "Sóleos",
        tibiales: "Tibiales",
        tobillo_pie: "Tobillo/Pie",
    },
    joint: {
        cervicales_cuello: "Cervicales/Cuello",
        espalda_col: "Espalda/Columna",
        hombro: "Hombro",
        codo: "Codo",
        munieca: "Muñeca",
        cadera: "Cadera",
        rodilla: "Rodilla",
        tobillo: "Tobillo",
        multiarticular: "Multiarticular"
    },
    aplication: {
        lanzar: "Lanzar",
        empujar: "Empujar",
        traccionar: "Traccionar",
        desplazamiento: "Desplazamiento",
        carrera: "Carrera",
        salto: "Salto",
        agarre: "Agarre",
        cod: "COD"
    },
    anatomic_plane: {
        tumbado: "Tumbado",
        sentado: "Sentado",
        de_pie: "De Pie",
        tumbado_lat_izq: "Tumbado Lat Izq",
        tumbado_lat_der: "Tumbado Lat Der",
        rodillas: "Rodillas",
        una_rodilla: "Una Rodilla"
    },
    move_chain: {
        cadena_anterior: "Cadena Anterior",
        cadena_posterior: "Cadena Posterior",
        dominante_cadera: "Dominante de Cadera",
        dominante_rodilla: "Dominante de Rodilla",
        core: "Core",
        empuje: "Empuje",
        traccion: "Tracción"
    },
    // METHOD
    main_adaptation: {
        funcional: "Funcional",
        estructural: "Estructural",
        // decision: "Toma de decisión"
    },
    working_mode: {
        tonico: "Tónico",
        cinetico: "Cinético",
        elastico: "Elástico",
        inercial_conico: "Inercial Cónico",
        inercial_yoyo: "Inercial Yoyo",
        isometrico: "Isométrico",
        vibratorio: "Vibratorio",
        propioceptivo: "Propioceptivo"
    },
    icon_mode:{
        kinetic: "Cinético",
        elastic: "Elástico",
        inertial: "Inercial",
        isometric: "Isométrico",
        tonic: "Tónico",
        vibratory: "Vibratorio",
        conical: "Cónico",
    },
    training_zone: {
        NN: "NN",
        NM: "NM",
        Nn: "Nn",
        nN: "nN",
        nn: "nn",
        MN: "MN",
        MM: "MM",
        Mm: "Mm",
        mM: "mM",
        mm: "mm",
    },
    body_type: {
        ectomorfo: "Ectomorfo",
        mesomorfo: "Mesomorfo",
        endomorfo: "Endomorfo",
    },
    gender: {
        male: "Masculino",
        female: "Femenino",
    },
    //Gym
    userPrograms: "Programas asignados al deportista",
    programsUnavailable: "No hay programas disponibles",

    //Session Selector
    microcicle: "Microciclo",

    //Session Execution
    finished: "Sesión terminada",
    reset: "Reiniciar",
    finish: "Finalizar",
    executingExercise: "Ejecutando Ejercicio",
    prevExercise: "Ejercicio Anterior",
    nextExercise: "Siguiente Ejercicio",
    endSession: "Fin de Sesión",
    finishedExercise: "Ejercicio Terminado",
    completedExercise: "Completado Ejercicio",

    // Resumen Sesion
    IntensityMean: "Intensidad media",
    TrainerRPE: "RPE",
    TotalNewtons: "Newtons totales",
    VelocityMean: "Velocidad media",
    TotalPath: "Recorrido total",
    NxDistance: "N/Distancia",
    NxMass: "N/Masa",
    TotalWorkRecoveryTime: "T trabajo y recuperacion",
    AthleteRPE: "RPE Atleta",
    TotalLoad: "Carga total",
    MaxLoad: "Carga máxima",
    MaxVelocity: "Velocidad máxima",
    PathMean: "Recorrido medio",
    NxTime: "N/Tiempo",
    TotalTime: "Tiempo total",
    ExerciseCounter: "Número de ejercicios",

    days: {
        L: "Lunes",
        M: "Martes",
        X: "Miércoles",
        J: "Jueves",
        V: "Viernes",
        S: "Sábado",
        D: "Domingo",
    },
    
    //ExecutionSummary Component
    sessionSummary: "Resumen de la Sesión",
    exercisesCompleted: "¡Has completado todos los ejercicios!",
    exercisesPerformance: "Rendimiento por ejercicios:",
    averagePerformance: "Rendimiento medio: ",
    performance: " - Rendimiento: ",
    exercise: "Ejercicio ",
    percentage: "%",

    //ConfirmationDialog
    confirm: "Confirmar",
    confirmDialogMessage: "Confirmar eliminación",


    // Definition of keys used in raw data files of reports
    reports: {
        cone_height: "Altura del cono",
        cone_load: "Carga del cono",
        discus_load: "Carga del disco",
        duration: "Duración",
        elastic_const: "Constante elástica",
        end_load_conc: "Carga final concéntrica",
        end_load_ecc: "Carga final excéntrica",
        end_vel_conc: "Velocidad final concéntrica",
        end_vel_ecc: "Velocidad final excéntrica",
        mode: "Modo de ejercicio",
        file_type: "Tipo de archivo",
        load_dec_reps: "Decremento de carga entre repeticiones",
        load_dec_series: "Decremento de carga entre series",
        load_inc_reps: "Incremento de carga entre repeticiones",
        load_inc_series: "Incremento de carga entre series",
        max_load_obj: "Objetivo máximo de fuerza",
        max_vel_obj: "Objetivo máximo de velocidad",
        min_load_obj: "Objetivo mínimo de fuerza",
        min_vel_obj: "Objetivo mínimo de velocidad",
        rom: "Recorrido",
        rec_time: "Tiempo de recuperación",
        reps: "Repeticiones",
        series: "Series",
        start_load_conc: "Carga inicial concéntrica",
        start_load_ecc: "Carga inicial excéntrica",
        start_vel_conc: "Velocidad inicial concéntrica",
        start_vel_ecc: "Velocidad inicial excéntrica",
        vel_dec_reps: "Decremento de velocidad entre repeticiones",
        vel_dec_series: "Decremento de velocidad entre series",
        vel_inc_reps: "Incremento de velocidad entre repeticiones",
        vel_inc_series: "Incremento de velocidad entre series",
        vibration_lvl: "Nivel de vibración",
    },
    config_raw_data_title: "Configuracion de datos brutos",
    side_type_explanation: "Si se ha realizado de un solo lado/completo o se han alternado lados en las series",
    side_type: {
        single: "Único",
        altern: "Lados alternos"
    },
    single: {
        right: "Derecha",
        left: "Izquierda",
        both: "Grupo muscular completo",
    },
    altern: {
        right: "Derecha",
        left: "Izquierda",
        both: "Grupo muscular completo",
    },
}

export default texts