import BodyManagerView from "../BodyManagerView";
import React, { useState } from 'react';
import { Dialog, Grid } from '@mui/material';




// Components
import MainTopBar from "../../components/elements/MainTopBar";
import HeaderTop from "../../components/elements/HeaderTop";
import CreateBody from '../../components/bodys/CreateBody';

// Styles
import "../../styles/global.scss";


const BodysView = () => {    

    
    const [openDialog, setOpenDialog] = useState(false);

    return (

        <div>

            <MainTopBar 
            teamSelector
            bodySelector
            headComponent={
                <HeaderTop
                    title="Deportistas"
                    subtitle="Consulta información" 
                    buttonActionFunction = {setOpenDialog}        
                    buttonText = "Crear Deportista"             
                    filters= {false}
                />
            }
            />

            <Grid> 
                <BodyManagerView/>
            </Grid>

          <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }} fullWidth scroll={'paper'}>
            <CreateBody></CreateBody>
          </Dialog>

        </div>
    );
}
 
export default BodysView;