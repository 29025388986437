import { List, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import ExerciseComponent from "./ExerciseComponent";

const ExerciseSelector = () => {
    const { updatedExercises, exercises } = useSelector((state) => state.trainer)
    return (
        updatedExercises ?
            <List>
                {Object.keys(exercises).map((key) =>
                    <ExerciseComponent key={key} exercise={exercises[key]} exerciseId={key} />
                )}
            </List>
            :
            <></>
    );
}

export default ExerciseSelector;