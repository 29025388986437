import { createSlice} from '@reduxjs/toolkit'

const initialState = {
    notification: null,
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        createNotification(state, action){
            state.notification = {
                message: action.payload.message,
                type: action.payload.type
            }

        },
        deleteNotification(state){
            state.notification = null
        }
    }
})

export const { createNotification, deleteNotification } = notificationSlice.actions;
export default notificationSlice.reducer;