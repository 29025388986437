import React, { useState, useEffect } from 'react';
import { Typography, Paper, Grid, Tabs, Tab } from '@mui/material';
import { useSelector } from 'react-redux';
import texts from '../../constants/texts/es'
import SessionExecution from './SessionExecution';
import ExecutionSummary from './ExecutionSummary';
import SessionColumnView from '../trainer/sessions/SessionColumnView';

const SessionSelector = ({ programId, }) => {
    const program = useSelector((state) => state.trainer.programs[programId])
    const days = ["L", "M", "X", "J", "V", "S", "D"];

    const [selectedSession, setSelectedSession] = useState(undefined);
    const [mcSelected, setMcSelected] = useState(0);

    const handleChange = (event, newValue) => {
        setMcSelected(newValue);
    };

    return (
        program ? selectedSession ?
            <SessionExecution programId={programId} sessionId={selectedSession} mc={mcSelected} onClose={() => { setSelectedSession(undefined) }} />
            :
            <div>
                <Typography variant="h5">Lista de microciclos y sesiones del programa</Typography>
                <Paper elevation={6} sx={{ m: 1, p: 1 }} >
                    <Tabs value={mcSelected} onChange={handleChange} >
                        {Object.keys(program.sessions).map((key, index) => (
                            <Tab label={texts.microcicle + "   " + (index + 1)} value={index} />
                        ))}
                    </Tabs>

                    <Grid container>
                        <div style={{ width: "100%", overflow: "auto", display: "inline-flex" }}  >
                            {days.map((day) => (
                                program.sessions[mcSelected][day] ?
                                    <SessionColumnView sessionId={program.sessions[mcSelected][day]} onClick={setSelectedSession} day={day} />
                                    :
                                    <></>
                            ))}
                        </div>
                    </Grid>
                </Paper >
            </div >
            :
            <Typography>No hay sesiones disponibles</Typography>
    );
}

export default SessionSelector