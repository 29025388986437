import React from 'react';

// Icons
import { ReactComponent as Peso } from '../../../assets/icons/Peso.svg';

// Styles
import style from './Peso.module.scss';

const  IconoPeso = (props) => {
    
        return (        
            <div className={style.iconbox}>
                <div className={style.boxsuperior}>
                    <Peso />
                    <strong> {props.peso} kg</strong>                    
                </div>
                {props.texto ? <p className='textoIcono'>Peso</p> : <></>}
            </div>
        ) 
}

export default IconoPeso;