import { Grid, Slider, TextField, InputAdornment } from '@mui/material';
import { amber } from '@mui/material/colors';

const StepExecutionForm = ({ executionData, value, setValue }) => {

    const handleChange = (event) => {
        const newValue = event.target.value === 0 ? 0 : Number(event.target.value);
        setValue(newValue);
    };

    const handleBlur = (maxValue) => {
        if (value < 0) {
            setValue(0);
        } else if (value > maxValue) {
            setValue(maxValue);
        }
    };

    const marks = [
        { value: 0, label: executionData.type },
        { value: Math.round(executionData.reps / 2), label: Math.round(executionData.reps / 2) + " " + executionData.type },
        { value: executionData.reps, label: executionData.reps + " " + executionData.type }
    ];

    return (
        <Grid container sx={{ m: 2 }}>
            <Grid container spacing={5} alignItems="center">
                <Grid item xs>
                    <Slider
                        value={value}
                        onChange={handleChange}
                        aria-labelledby="input-slider"
                        step={1}
                        valueLabelDisplay="auto"
                        max={executionData.reps ? executionData.reps : executionData.time}
                        min={0}
                        marks={marks}
                        sx={{ color: amber[700] }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        value={value}
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur(executionData.reps)}
                        inputProps={{
                            step: 1, min: 0, max: executionData.reps, type: 'number',
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{executionData.type}</InputAdornment>
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default StepExecutionForm;